import React from 'react'
import { useSelector } from 'react-redux';
import { convertAmount } from '../../../../tools/format';


const State18 = () => {

  const {dataPost, imgPostLogo, imgProduct} = useSelector(s => s.post)
  

  return (
    <div className="poster">
      <div className="img" style={{
        left: '14.4%', top: '34%', width: '71%', height: '59%'
      }}>
        {
          imgProduct && (
            <img
              src={imgProduct}
              className='b7'
              alt="img" />
          )
        }
      </div>
      <div className="svg">
        <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 490 870">
          <path fill={`${dataPost.colors[1]}`}  d="m0,.44v870h490V.44H0Zm418,807H72v-508h346v508Z"/>
          <rect fill={`${dataPost.colors[2]}`}  x="327" width="3" height="103.44"/>
          <g>
            <rect fill={`${dataPost.colors[0]}`}  x="40" y="395.44" width="32" height="221"/>
            <rect fill={`${dataPost.colors[0]}`}  x="418" y="395.44" width="33" height="221"/>
          </g>
          <polygon fill={`${dataPost.colors[2]}`}  points="418 616.44 418 807.44 72 807.44 72 616.44 39.5 616.44 39.5 837.44 450.5 837.44 450.5 616.44 418 616.44"/>
        </svg>
      </div>

      <img
        src={imgPostLogo}
        alt="logo" className="logo text tr"
        style={{margin:'5%'}}
      />

      {
        dataPost.text[0] && (
          <div
            className="text tr px-2 f-7 clamp-2 text-end"
            style={{color: dataPost.colors[2], width: '55%', right: '35%',
            top: '3.3%', fontSize: '28px', lineHeight: '30px'
            
          }}
          >{dataPost.text[0]}</div>
        )
      }
      {
        dataPost.text[1] && (
          <div
            className="w-content text px-3 f-3 tc"
            style={{color: dataPost.colors[2],
              fontSize: ' 33px', top: '26%'
            }}
          >{convertAmount(dataPost.text[1])}</div>
        )
      }
      {
        dataPost.text[2] && (
          <div
            className="f-3 text clamp-2 text-center"
            style={{color: dataPost.colors[2], fontSize: '18px',
              width: '85%', bottom: '76%', lineHeight: '23px'

            }}
          >{dataPost.text[2]}</div>
        )
      }    
    </div>
  );
};

export default State18;
