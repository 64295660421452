import React from 'react'
import { useSelector } from 'react-redux'

const TycMenu = () => {

  const {menu} = useSelector(state => state.user)


  return (
   <div className="content tycmenu">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-8 col-10 faqsMenu">
          {
            menu?.m?.length >0 && (
              <div className="contentListTYC">
                <h4 className='text-center mb-4'>Términos y condiciones</h4>
                {
                  menu?.m?.map((el,index)=> (
                    <div key={index}>
                      <strong>{`${index +1}.`}</strong>
                      <p className='m-0'>{el}</p>
                    </div>
                  ))
                } 
              </div>
            )
          }
        </div>
      </div>
    </div>
   </div>
  )
}

export default TycMenu