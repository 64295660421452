import React from 'react'
import logo from '../../img/vector/logo_fooder.svg'
import InfoGreen from '../admin/dashboard/InfoGreen'

const Terms = () => {
  return (
    <>
      <header className="sticky-top bg-white shadow p-3 text-center">
        <img src={logo} alt="" style={{width: '120px'}}/>
      </header>
      <main className='bg-light'>
        <section>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8 py-5 col-11">
                <div className="text-center mb-5">
                  <h2 className='mb-5'>Términos y Condiciones de Uso de Fooder</h2>
                  <hr />
                </div>
                <h5 className='mt-5'>1. Introducción</h5>
                <p><strong>Bienvenido a <strong>Fooder</strong></strong>, una plataforma que permite a los restaurantes crear su propio menú digital y gestionar pedidos en línea de manera eficiente. Estos Términos y Condiciones regulan el uso de nuestra plataforma, por lo que es importante que los lea detenidamente. Al registrarse y utilizar los servicios de <strong>Fooder</strong>, usted acepta estos términos en su totalidad. Si no está de acuerdo con alguno de los puntos, le recomendamos no utilizar nuestra plataforma.</p>
                <h5 className='mt-5'>2. Definiciones</h5>
                <ul>
                  <li><strong><strong>Fooder</strong>:</strong> Plataforma web y móvil que permite a los dueños de restaurantes gestionar menús digitales, pedidos y otras operaciones no vinculadas a un sistema de punto de venta (POS).</li>
                  <li><strong>Super Administrador:</strong> Persona o equipo encargado de aprobar solicitudes de registro y crear usuarios.</li>
                  <li><strong>Administrador</strong> Usuario aprobado que gestiona un restaurante dentro de la plataforma <strong>Fooder</strong>.</li>
                  <li><strong>Cliente:</strong> Persona que accede al menú digital de un restaurante para realizar un pedido.</li>
                  <li><strong>Créditos <strong>Fooder</strong>:</strong> Moneda virtual usada dentro de la plataforma que se descuenta cada vez que se genera un pedido.</li>
                </ul>
                <h5 className='mt-5'>3. Registro y Aprobación</h5>
                <p>El registro inicial en <strong>Fooder</strong> se realiza a través de la Landing Page, pero no garantiza automáticamente la creación de una cuenta. Las solicitudes de registro son revisadas por el <strong>Super Administrador</strong>, quien se reserva el derecho de aprobar o rechazar la solicitud en función de criterios internos de verificación. Solo después de la aprobación, el solicitante se convertirá en Administrador y podrá acceder a su panel de control para gestionar su restaurante.</p>
                <h5 className='mt-5'>4. Planes de Suscripción y Créditos</h5>
                <p><strong>Fooder</strong> opera con un sistema de suscripción basado en créditos recargables, que dependen del plan que el administrador haya seleccionado:</p>
                <ul>
                  <li><strong>Plan Básico:</strong> 1 crédito por pedido.</li>
                  <li><strong>Plan Corporativo:</strong> 2 créditos por pedido.</li>
                  <li><strong>Plan Premium:</strong> 3 créditos por pedido.</li>
                </ul>
                <p>Cada nuevo usuario recibe una bonificación inicial de <strong>50 créditos gratuitos al registrarse</strong>, que pueden usarse para gestionar los primeros pedidos sin costo adicional. Una vez que los créditos se agoten, los administradores deben recargar créditos de acuerdo con los planes de recarga ofrecidos.</p>
                <h5 className="mt-5">5. Recargas de Créditos</h5>
                <p>Los administradores pueden recargar créditos mediante las siguientes tarifas:</p>
                <ul>
                  <li>350 créditos por 50.000 COP</li>
                  <li>700 créditos por 92.000 COP</li>
                  <li>1500 créditos por 150.000 COP</li>
                  <li>3000 créditos por 250.000 COP</li>
                </ul>

                <InfoGreen
                  text='NOTA IMPORTANTE: Fooder es un proyecto personal, y no generamos factura por los pagos de créditos. Todas las transacciones se realizarán sin emisión de factura fiscal. Por tanto el cliente tendrá un certificado de pago de Bold y una ORDEN DE COMPRA FOODER que respalda dicha transacción de recarga de créditos FOODER.'
                />

                <h5 className="mt-5">6. Uso de la Plataforma</h5>
                <p><strong>Fooder</strong> ofrece varias funcionalidades para gestionar el restaurante, <strong>con la aclaración de que no es un sistema de punto de venta (POS).</strong> <strong>Fooder</strong> se enfoca exclusivamente en proporcionar un menú digital y herramientas relacionadas. Las funcionalidades incluyen:</p>
                <ul>
                  <li><strong>Menú Digital:</strong> Página pública que muestra el menú del restaurante bajo una URL personalizada y dinámica.</li>
                  <li><strong>Seguimiento de Pedido:</strong> Los clientes pueden verificar el estado de su pedido en tiempo real (en cola, en proceso, finalizando, entregado).</li>
                  <li><strong>Mi Cocina:</strong> El administrador puede gestionar el estado de los pedidos desde esta sección.</li>
                  <li><strong>Reportes y Estadísticas:</strong> Herramientas que permiten al administrador visualizar el rendimiento de su menú, ventas y pedidos.</li>
                </ul>
                <p>Además, cada módulo dentro del <strong>Administrador de <strong>Fooder</strong></strong> incluye material de apoyo audiovisual, disponible para facilitar el uso correcto de cada función, lo que permite al administrador familiarizarse rápidamente con las herramientas.</p>

                <h5 className="mt-5">7. Inactividad de Cuentas</h5>
                <p><strong>Fooder</strong> se reserva el derecho de eliminar las cuentas de administradores que presenten <strong>inactividad durante un período de 6 meses consecutivos</strong>. Esto incluye la falta de acceso al panel de administración y la ausencia de pedidos a través del menú digital. Antes de eliminar la cuenta, se enviará un aviso por correo electrónico al administrador para ofrecer la opción de reactivarla.</p>

                <h5 className="mt-5">8. Comisiones y Pagos </h5>
                <p><strong>(No Disponible en la Primera Versión)</strong> En futuras versiones, <strong>Fooder</strong> ofrecerá la posibilidad de habilitar pasarelas de pago. Sin embargo, en la versión actual de la plataforma, las pasarelas de pago no están disponibles. El sistema funciona exclusivamente como un menú digital donde los clientes pueden visualizar productos y realizar pedidos, pero los pagos deben gestionarse fuera de la plataforma, ya sea en efectivo o mediante otros métodos acordados directamente entre el cliente y el restaurante.</p>

                <h5 className="mt-5">9. Responsabilidades del Administrador</h5>
                <p>El administrador es responsable de:</p>
                <ul>
                  <li>Asegurarse de que toda la información, precios y productos del menú digital sean correctos y estén actualizados.</li>
                  <li>Gestionar adecuadamente los pedidos y su estado en la sección "Mi Cocina".</li>
                  <li>Recargar créditos antes de que se agoten para evitar la interrupción del servicio.</li>
                  <li>Asegurarse de que los pagos de clientes, si los hay, se gestionen correctamente por métodos alternos, ya que la plataforma aún no ofrece pasarelas de pago integradas.</li>
                </ul>

                <h5 className="mt-5">10. Propiedad Intelectual</h5>
                <p>Todos los derechos de propiedad intelectual relacionados con la plataforma <strong>Fooder</strong>, incluyendo su diseño, código fuente, logos y contenido, son propiedad exclusiva de <strong>Fooder</strong>. Queda estrictamente prohibida su copia, modificación o distribución sin el consentimiento previo por escrito de <strong>Fooder</strong>.</p>

                <h5 className="mt-5">11. Uso Inadecuado de la Plataforma</h5>
                <p>Está prohibido utilizar la plataforma <strong>Fooder</strong> para actividades ilegales, fraudulentas o que perjudiquen su funcionamiento o la experiencia de otros usuarios. <strong>Fooder</strong> se reserva el derecho de suspender o cancelar cuentas que infrinjan esta política.</p>

                <h5 className="mt-5">12. Limitación de Responsabilidad </h5>
                <p><strong>Fooder</strong> no se hace responsable de la calidad de los productos ofrecidos por los restaurantes, ni de los retrasos o errores en la entrega de pedidos. La responsabilidad de la experiencia del cliente final recae exclusivamente en los administradores de los restaurantes. Además, <strong>Fooder</strong> no garantiza la disponibilidad ininterrumpida del servicio, aunque trabajamos continuamente para minimizar interrupciones.</p>

                <h5 className="mt-5">13. Cancelación y Terminación del Servicio</h5>
                <p>Los administradores pueden cancelar su suscripción en cualquier momento. En caso de cancelar el servicio, los créditos no utilizados no serán reembolsados. <strong>Fooder</strong> se reserva el derecho de cancelar cuentas que violen estos términos, sin previo aviso.</p>

                <h5 className="mt-5">14. Modificaciones a los Términos y Condiciones</h5>
                <p><strong>Fooder</strong> se reserva el derecho de modificar estos términos y condiciones en cualquier momento. Se notificará a los usuarios de cualquier cambio significativo, y el uso continuado de la plataforma implicará la aceptación de los nuevos términos.</p>

                <h5 className="mt-5">15. Contacto</h5>
                <p>Para consultas o dudas sobre estos Términos y Condiciones, los administradores pueden ponerse en contacto con nuestro equipo de soporte a través del correo electrónico <strong>soporte@fooder.com.</strong></p>
                <hr />

                <div className="text-center my-4">
                  <p><strong>Última actualización:</strong> 14 de octubre de 2024</p>
                  <small>Al continuar utilizando <strong>Fooder</strong>, usted acepta haber leído y entendido estos Términos y Condiciones.</small>
                </div>




              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}

export default Terms