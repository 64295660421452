import React from 'react'

const WelcomeMeesage = ({counterWelcome, welcome}) => {
  
  return (
    <>
      <div className="bg-b1 p-3 rounded-lg">
        <div className="d-flex align-items-center justify-content-between">
          <p className="text-bold text-apple m-0 pe-2">Escribe un mensaje de bienvenida para tu menú</p>
          <span className='optionalLabel'>Opcional</span>
        </div>
        <textarea
          className={`textareaDash mt-3 ${counterWelcome > 100 ? 'text-orange': ''}`}
          name="whoAmI"
          rows="5"
          placeholder='¿Cuál es tu antojo hoy?'
          {...welcome}
        ></textarea>
        <div className="text-end">
          <span className={`${counterWelcome > 100 ? 'text-orange': ''}`}>{counterWelcome}/100</span>
        </div>
      </div>
    </>
  )

}

export default WelcomeMeesage