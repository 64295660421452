import React, { useState, useEffect } from 'react';
import closed from '../../../../img/vector/closed_message.svg';

const Closed = () => {
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowMessage(true)
    }, 2000)

    return () => clearTimeout(timer)
  }, [])

  if (!showMessage) {
    return null
  }

  return (
    <div className="closedMessage">
      <div>
        <img src={closed} alt="Cerrado temporalmente" />
        <strong>¡Volveremos pronto!</strong>
      </div>
    </div>
  );
}

export default Closed;
