import React from 'react'
import { useSelector } from 'react-redux';
import { convertAmount } from '../../../../tools/format';


const Post10 = () => {

  const {dataPost, imgPostLogo, imgProduct} = useSelector(s => s.post)
  

  return (
    <div className="poster">
      <div className="img">
        {
          imgProduct && (
            <img
              src={imgProduct}
              className='b7'
              alt="img" />
          )
        }
        <div className="overlay" style={{background: dataPost.colors[2], opacity: .1}}/>
      </div>
      <div className="svg">
        <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1023.75 1024">
          <polygon fill={`${dataPost.colors[0]}`}  points="0 0 0 1024 1024 0 0 0"/>
          <polygon fill={`${dataPost.colors[1]}`}  points="57 54 57 890 893 54 57 54"/>
          <polygon fill={`${dataPost.colors[2]}`}  points="104.5 84 104.5 125.95 146.46 84 104.5 84"/>
          <polygon fill={`${dataPost.colors[2]}`}  points="219.01 84 219.01 125.95 260.96 84 219.01 84"/>
          <polygon fill={`${dataPost.colors[2]}`}  points="333.51 84 333.51 125.95 375.46 84 333.51 84"/>
          <polygon fill={`${dataPost.colors[2]}`}  points="448.02 84 448.02 125.95 489.97 84 448.02 84"/>
          <polygon fill={`${dataPost.colors[2]}`}  points="562.52 84 562.52 125.95 604.47 84 562.52 84"/>
          <polygon fill={`${dataPost.colors[2]}`}  points="677.02 84 677.02 125.95 718.98 84 677.02 84"/>
          <polygon fill={`${dataPost.colors[2]}`}  points="791.53 84 791.53 125.95 833.48 84 791.53 84"/>
          <polygon fill={`${dataPost.colors[2]}`}  points="134.01 166 134.01 207.95 175.96 166 134.01 166"/>
          <polygon fill={`${dataPost.colors[2]}`}  points="248.51 166 248.51 207.95 290.46 166 248.51 166"/>
          <polygon fill={`${dataPost.colors[2]}`}  points="363.02 166 363.02 207.95 404.97 166 363.02 166"/>
          <polygon fill={`${dataPost.colors[2]}`}  points="477.52 166 477.52 207.95 519.47 166 477.52 166"/>
          <polygon fill={`${dataPost.colors[2]}`}  points="592.02 166 592.02 207.95 633.98 166 592.02 166"/>
          <polygon fill={`${dataPost.colors[2]}`}  points="706.53 166 706.53 207.95 748.48 166 706.53 166"/>
          <polygon fill={`${dataPost.colors[2]}`}  points="163.51 248 163.51 289.95 205.46 248 163.51 248"/>
          <polygon fill={`${dataPost.colors[2]}`}  points="278.02 248 278.02 289.95 319.97 248 278.02 248"/>
          <polygon fill={`${dataPost.colors[2]}`}  points="392.52 248 392.52 289.95 434.47 248 392.52 248"/>
          <polygon fill={`${dataPost.colors[2]}`}  points="507.02 248 507.02 289.95 548.98 248 507.02 248"/>
          <polygon fill={`${dataPost.colors[2]}`}  points="621.53 248 621.53 289.95 663.48 248 621.53 248"/>
          <polygon fill={`${dataPost.colors[2]}`}  points="78.51 330 78.51 371.95 120.46 330 78.51 330"/>
          <polygon fill={`${dataPost.colors[2]}`}  points="193.02 330 193.02 371.95 234.97 330 193.02 330"/>
          <polygon fill={`${dataPost.colors[2]}`}  points="307.52 330 307.52 371.95 349.47 330 307.52 330"/>
          <polygon fill={`${dataPost.colors[2]}`}  points="422.02 330 422.02 371.95 463.98 330 422.02 330"/>
          <polygon fill={`${dataPost.colors[2]}`}  points="536.53 330 536.53 371.95 578.48 330 536.53 330"/>
          <polygon fill={`${dataPost.colors[2]}`}  points="108.02 412 108.02 453.95 149.97 412 108.02 412"/>
          <polygon fill={`${dataPost.colors[2]}`}  points="222.52 412 222.52 453.95 264.47 412 222.52 412"/>
          <polygon fill={`${dataPost.colors[2]}`}  points="337.02 412 337.02 453.95 378.98 412 337.02 412"/>
          <polygon fill={`${dataPost.colors[2]}`}  points="451.53 412 451.53 453.95 493.48 412 451.53 412"/>
          <polygon fill={`${dataPost.colors[2]}`}  points="137.52 494 137.52 535.95 179.47 494 137.52 494"/>
          <polygon fill={`${dataPost.colors[2]}`}  points="252.02 494 252.02 535.95 293.98 494 252.02 494"/>
          <polygon fill={`${dataPost.colors[2]}`}  points="366.53 494 366.53 535.95 408.48 494 366.53 494"/>
          <polygon fill={`${dataPost.colors[2]}`}  points="167.02 576 167.02 617.95 208.98 576 167.02 576"/>
          <polygon fill={`${dataPost.colors[2]}`}  points="281.53 576 281.53 617.95 323.48 576 281.53 576"/>
          <polygon fill={`${dataPost.colors[2]}`}  points="82.02 658 82.02 699.95 123.98 658 82.02 658"/>
          <polygon fill={`${dataPost.colors[2]}`}  points="196.53 658 196.53 699.95 238.48 658 196.53 658"/>
          <polygon fill={`${dataPost.colors[2]}`}  points="111.53 740 111.53 781.95 153.48 740 111.53 740"/>
          <polygon fill={`${dataPost.colors[2]}`}  points="70 248 70 268.96 90.96 248 70 248"/>
          <polygon fill={`${dataPost.colors[2]}`}  points="70 576 70 600.47 94.47 576 70 576"/>
        </svg>
      </div>

      <img
        src={imgPostLogo}
        alt="logo" className="logo text br"
        style={{margin: '7%'}}
      />

      {
        dataPost.text[0] && (
          <div
            className="text tl px-2 f-5 clamp-2 text-uppercase text-center"
            style={{color: dataPost.colors[0], marginLeft: '4%',
            marginTop: '21%', fontSize: '23px', width: '44%',
            transform: 'rotate(-45deg)', lineHeight: '26px'
          }}
          >{dataPost.text[0]}</div>
        )
      }
      {
        dataPost.text[2] && (
          <div
            className="text tl px-2 f-3 text-truncate text-uppercase text-center"
            style={{color: dataPost.colors[3], marginLeft: '-6%',
            marginTop: '40%', fontSize: '15px', width: '95%',
            transform: 'rotate(-45deg)', lineHeight: '18px'
          }}
          >{dataPost.text[2]}</div>
        )
      }
      {
        dataPost.text[1] && (
          <div
            className="text tl px-2 f-3 text-truncate text-uppercase text-center"
            style={{color: dataPost.colors[3], marginLeft: '9%',
            marginTop: '31%', fontSize: '30px', width: '50%',
            transform: 'rotate(-45deg)', lineHeight: '2.5625rem'
          }}
          >{convertAmount(dataPost.text[1])}</div>
        )
      }
    
    </div>
  );
};

export default Post10;
