import React from 'react';
import Viewer from './create/Viewer';
import CategoryProduct from './create/CategoryProduct';
import InfoGreen from '../InfoGreen';
import InfoProduct from './create/InfoProduct';
import OneOption from './create/OneOption';
import MultipleOption from './create/MultipleOption';
import AditionalComments from './create/AditionalComments';
import Chilli from './create/Chilli';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import uniqid from 'uniqid';
import { setCategoryProductView, setChilli, setComments, setIDProductSelected, setMultipleOption, setNewDescription, setNewTitle, setProductDiscount, setProductPrice, setResetProductValues, setUniqueOption } from '../../../../redux/reducer/product';
import { addProduct, undateProductImage } from '../../../../firebase/credentials';
import { setIsEdit, setIsError, setStateLoading, setStateNotification, setTextNotificate } from '../../../../redux/reducer/interactions';
import updateOnlyOnePiece from '../../../../firebase/update/updateOnlyOnePiece';

const CreateProduct = () => {
  const { updateProductoFile, description, uniqueoption, multipleoption, title, chilli, comments, category, price, discount } = useSelector(state => state.product);
  const { userID, maxProductLength, step } = useSelector(state => state.log);
  const { listCategories, listProducts } = useSelector(state => state.menu);
  const { productSelectedID } = useSelector(state => state.product);
  const { isEdit } = useSelector(state => state.ux);
  const dispatch = useDispatch();

  const methods = useForm({
    mode: 'onChange',
  });

  const { handleSubmit, formState: { errors }, setValue,register, watch, trigger, reset, clearErrors } = methods;

  const onSubmit = async (data) => {
    if (isEdit) {
      dispatch(setStateLoading(true))
      let newList = listProducts.map(product => JSON.parse(JSON.stringify(product)))
      let indexRef = newList.findIndex(el => el.c === productSelectedID)

      const editProduct = {
        a: newList[indexRef].a, //activo
        b: newList[indexRef].b, //destacado
        c: productSelectedID, //id
        d: null, //link (a la espera)
        e: title, //título
        f: [price,parseInt(discount) || null], //precio normal | precio descuento
        g: category , //categoría
        h: description, //descripción
        i: uniqueoption,
        j: multipleoption,
        k: chilli, // chilli
        l: comments, // comentarios
      }

      if(updateProductoFile.substring(0,6) === 'https:'){
        editProduct.d = updateProductoFile
      }else{
        try { // carga de la imagen y retorno de la url
          const response = await undateProductImage(userID,updateProductoFile,`${editProduct.c}`)
          if(response.success){
            editProduct.d = response.url
          }
        } catch (error) {
          console.log(error)
          dispatch(setStateLoading(false))
        }
      }
      newList[indexRef] = editProduct
      try {
        await updateOnlyOnePiece(userID, process.env.REACT_APP_DATAMENU,'p', newList)
        dispatch(setTextNotificate('¡Este producto ha sido modificado!'))
        dispatch(setStateNotification(true))
        dispatch(setIsError(false))
        dispatch(setStateLoading(false))
        //leave editor
        dispatch(setIsEdit(false))
        document.querySelector('#catalogP-tab').click()
        dispatch(setResetProductValues())
        dispatch(setIDProductSelected(''))
      } catch (error) {
        dispatch(setTextNotificate('Ha ocurrido un error, inténtalo mas tarde'))
        dispatch(setStateNotification(true))
        dispatch(setIsError(true))
        dispatch(setStateLoading(false))
      }
    } else {
      let {category,price,discountedPrice} = data
  
      const newProduct = {
        a: true, //activo
        b: false, //destacado
        c: uniqid(), //id
        d: null, //link (a la espera)
        e: title, //título
        f: [price,parseInt(discountedPrice) || null], //precio normal | precio descuento
        g: category , //categoría
        h: description, //descripción
        i: uniqueoption,
        j: multipleoption,
        k: chilli, // chilli
        l: comments, // comentarios
      }

      if(step === 5){
        document.querySelector('#catalogP-tab').click()
        await updateOnlyOnePiece(userID, process.env.REACT_APP_DATAUSER,'d', 6)
      }
  
      dispatch(setStateLoading(true))
  
      try { // carga de la imagen y retorno de la url
        const response = await undateProductImage(userID,updateProductoFile,`${newProduct.c}`)
        if(response.success){
          newProduct.d = response.url
        }
      } catch (error) {
        console.log(error)
        dispatch(setStateLoading(false))
      }
  
      // subir información del producto
      try {
        await addProduct(userID, newProduct)
        dispatch(setTextNotificate('¡Nuevo producto agregado!'))
        dispatch(setStateNotification(true))
        dispatch(setIsError(false))
        dispatch(setStateLoading(false))
        let newArrayCategories = listCategories.map(categoryObj => {
          if (categoryObj.name === category) {
            let counter = categoryObj.count + 1
            return { ...categoryObj, count: counter }
          }
          return categoryObj
        })
        await updateOnlyOnePiece(userID, process.env.REACT_APP_DATAMENU,'n', newArrayCategories)
        //reset values
        dispatch(setResetProductValues())
        reset()
        
      } catch (error) {
        dispatch(setTextNotificate('Hubo un error al subir este producto, inténtelo nuevamente.'))
        dispatch(setStateNotification(true))
        dispatch(setIsError(true))
        dispatch(setStateLoading(false))
        console.log(error)
      }
    }
  }

  return (
    <FormProvider {...methods}>
      {listProducts.length < maxProductLength ? (
        <>
          <form className="py-2 py-md-4 mb-5 mb-md-0" onSubmit={handleSubmit(onSubmit)}>
            <div className="row mb-5 mb-md-0">
              <div className="col-md-8">
                {isEdit ? (
                  <h4 className='mb-4 text-apple'>Editando producto</h4>
                ) : (
                  <div className="d-flex mb-4 align-items-center justify-content-between">
                    <h4 className=' text-center text-apple m-0'>Crear producto</h4>
                    <span className='text-apple d-inline-flex ms-4'>({listProducts.length} de {maxProductLength})</span>
                  </div>
                )}
                <div className="row">
                  {listCategories.length === 0 ? (
                    <div className="col-12 mb-2">
                      <InfoGreen
                        bg={true}
                        text='Para agregar productos debes crear al menos una categoría'
                      />
                    </div>
                  ) : null}
                  <div className="col-12 mb-4">
                    <CategoryProduct
                      errors={errors}
                      list={listCategories}
                    />
                  </div>
                  <div className="col-12 mb-4">
                    <InfoProduct
                      price={register('price', {
                        required: { value: false, message: 'Campo requerido' },
                        pattern: {value: /^\d{10}$/, message: 'Ingresa un número válido'},
                        maxLength: { value: 6, message: 'Valor muy grande (máximo 6 caracteres)' },
                        valueAsNumber: true,
                        onChange: (e) => {
                          dispatch(setProductPrice(e.target.value))
                          setValue('price', e.target.value.trim())
                        },
                        validate: value =>{
                          if(!/^\d+$/.test(value)){
                            return 'Este campo solo acepta dígitos'
                          }
                        }
                      })}
                      discountedPrice={register('discountedPrice', {
                        required: false,
                        pattern: {value: /^\d+$/,message: 'Este campo solo acepta dígitos'},
                        onChange: e => {
                          let value = e.target.value
                          dispatch(setProductDiscount(value))
                          setValue('discountedPrice', value.trim())
                        },
                        validate: (value) => {
                          let normalPrice = parseInt(watch('price'))
                          let currentPrice = parseInt(value)
                          if (normalPrice < currentPrice) {
                            return 'El precio en descuento debe ser menor al precio normal'
                          }
                          clearErrors('discountedPrice')
                          return true
                        }
                      })}
                      errors={errors}
                      counterDescription={description.length}
                      registerNameProduct={register('registerNameProduct', {
                        required:{value: true, message: 'Campo requerido'},
                        minLength:{value: 3, message:'Verifica el título del producto'},
                        maxLength:{value: 40, message:'Título demasiado largo'},
                        pattern: {
                          value: /^(?!\s*$).+/,
                          message: 'No se permiten espacios en blanco en el título',
                        },
                        onChange: e => dispatch(setNewTitle(e.target.value)),
                      })}
                      registerDescription={register('registerDescription', {
                        required:{value: true, message: 'Ingresa los ingredientes'},
                        minLength:{value: 3, message:'Describe mejor tu producto'},
                        maxLength:{value: 230, message:'Descripción muy extensa'},
                        pattern: {
                          value: /^(?!\s*$).+/,
                          message: 'No se permiten espacios en blanco en la descripción',
                        },
                        onChange: e => dispatch(setNewDescription(e.target.value)),
                      })}
                    />
                  </div>
                  <div className="col-12 mb-4">
                    <Chilli
                      registerChilli={register('registerChilli', {
                        onChange: e => dispatch(setChilli(e.target.checked))
                      })}
                    />
                  </div>
                  <div className="col-12 mb-4">
                    <OneOption
                      errors={errors}
                      trigger={trigger}
                      watch={watch}
                      setValue={setValue}
                      registerTitleOneOption={register('registerTitleOneOption', {
                        required: {value: uniqueoption.active, message:'Campo requerido'},
                        minLength:{value: 3, message:'Título muy corto'},
                        maxLength:{value: 40, message:'Título muy largo'},
                        onChange: e =>{
                          dispatch(setUniqueOption({type:'title',value: e.target.value}))
                        }
                      })}
                      nameUniqueOption={register('nameUniqueOption', {
                        required: {value: (uniqueoption.active && uniqueoption.op.length === 0) ? true: false , message:'Campo requerido'},
                        minLength:{value: 3, message:'Nombre muy corto'},
                        maxLength:{value: 40, message:'Nombre muy largo'}
                      })}
                      valueUniqueOption={register('valueUniqueOption', {
                        required: false,
                        maxLength:{value: 7, message:'Valor muy grande'},
                        pattern: {value: /^\d+$/, message: 'Este campo solo acepta dígitos'},
                      })}
                    />
                  </div>
                  <div className="col-12 mb-4">
                    <MultipleOption
                      errors={errors}
                      trigger={trigger}
                      watch={watch}
                      setValue={setValue}
                      registerTitleMultipleOption={register('registerTitleMultipleOption', {
                        required: {value: multipleoption.active , message:'Campo requerido'},
                        minLength:{value: 3, message:'Título muy corto'},
                        maxLength:{value: 40, message:'Título muy largo'},
                        onChange: e =>{
                          dispatch(setMultipleOption({type:'title',value: e.target.value}))
                        }
                      })}
                      nameMultipleOption={register('nameMultipleOption', {
                        required: {value:(multipleoption.active && multipleoption.op.length === 0) ? true: false, message:'Campo requerido'},
                        minLength:{value: 3, message:'Nombre muy corto'},
                        maxLength:{value: 40, message:'Nombre muy largo'}
                      })}
                      valueMultipleOption={register('valueMultipleOption', {
                        valueAsNumber: true,
                        required: false,
                        maxLength:{value: 7, message:'Valor muy grande'},
                        pattern: {value: /^\d+$/, message: 'Este campo solo acepta dígitos'},
                      })}
                      registerCountMultiple={register('registerCountMultiple', {
                        required: {value:multipleoption.active, message:'Campo requerido'},
                        onChange: e =>{
                          setValue('registerCountMultiple',e.target.value)
                          dispatch(setMultipleOption({type:'max',value: parseInt(e.target.value)}))
                        }
                      })}
                    />
                  </div>
                  <div className="col-12 mb-5 pb-5">
                    <AditionalComments
                      registerComments={register('registerComments', {
                        onChange: e =>{
                          dispatch(setComments(e.target.checked))
                        }
                      })}
                    />
                  </div>
                </div>
                {updateProductoFile.length > 0 && (
                  <div className="centerSubmit saveRight">
                    <button type='submit' className='btnSave'>Guardar</button>
                  </div>
                )}
                <div className='btnView'>
                  <button type='button'
                    data-bs-toggle="modal"
                    data-bs-target="#modalViewProduct" />
                </div>
              </div>
              <div className="col-md-4 mb-5 mb-md-0 d-none d-md-block">
                <Viewer />
              </div>
            </div>
          </form>
        </>
      ) : <FullProducts max={maxProductLength} />}
    </FormProvider>
  )
}

export default CreateProduct

const FullProducts = ({max}) => {
  return (
    <>
      <div className="row justify-content-center">
        <div className="col-md-7">
          <div className="bg-b1 rounded-3 my-5 p-4 text-center">
            <h5 className="text-apple mb-4">Tienes {max} productos registrados en tu menú</h5>
            <p>El sistema bajo demanda FOODER garantiza que se cobre por el uso de tu aplicación, por lo que la cobertura de {max} productos únicamente hace parte del PLAN BÁSICO FOODER.</p>
            <div className="mt-3">
              <strong className="text-white">¡ Pero tenemos buenas noticias para ti !</strong>
              <p className='my-4'>Puedes solicitar ampliar la capacidad de tu menú digital, envíanos un mensaje.</p>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-7">
                <a
                  href="https://api.whatsapp.com/send?phone=573125599832&text=Hola%20!%20quisiera%20ampliar%20mi%20plan%20b%C3%A1sico%20FOODER."
                  target='_blank'
                  rel="noopener noreferrer"
                  className='btn-green py-4'
                >CONTACTAR ASESOR</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
