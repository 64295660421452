import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteOneInOrder, setIsError, setStateLoading, setStateNotification, setTextNotificate } from '../../../../redux/reducer/interactions'
import deleteProductKitchen from '../../../../firebase/update/deleteProductKitchen'

const ModalDeleteInOrder = () => {

  const {filteredOne,filteredTwo,filteredThree, phone, indicative} = useSelector(s => s.log)
  const {deleteProdunctInOrder} = useSelector(s => s.ux)
  const dispatch = useDispatch()
  


  const handleReset = ()=> dispatch(deleteOneInOrder({}))
  

  const updateState = async()=>{
    let {orderIndex, index, type} = deleteProdunctInOrder
    dispatch(setStateLoading(true))

    

    if(type === 'inprocess'){

      let dataOne = JSON.parse(JSON.stringify(filteredOne[orderIndex]))
      let deleteTotal = dataOne.order[index].count * dataOne.order[index].price
      dataOne.payment.total = dataOne.payment.total - deleteTotal
      dataOne.order.splice(index, 1)
      const response = deleteProductKitchen(indicative, phone, dataOne.op, dataOne)

      if(!response){
        dispatch(setTextNotificate('Ha ocurrido un error al eliminar el producto, inténtalo mas tarde'))
        dispatch(setStateNotification(true))
        dispatch(setIsError(true)) 
      }
      dispatch(setStateLoading(false))
    }

    if(type === 'finished'){
      let dataOne = JSON.parse(JSON.stringify(filteredTwo[orderIndex]))
      let deleteTotal = dataOne.order[index].count * dataOne.order[index].price
      dataOne.payment.total = dataOne.payment.total - deleteTotal
      dataOne.order.splice(index, 1)
      

      const response = deleteProductKitchen(indicative, phone, dataOne.op, dataOne)

      if(!response){
        dispatch(setTextNotificate('Ha ocurrido un error al eliminar el producto, inténtalo mas tarde'))
        dispatch(setStateNotification(true))
        dispatch(setIsError(true)) 
      }
      dispatch(setStateLoading(false))
    }


    if(type === 'delivered'){
      let dataOne = JSON.parse(JSON.stringify(filteredThree[orderIndex]))
      let deleteTotal = dataOne.order[index].count * dataOne.order[index].price
      dataOne.payment.total = dataOne.payment.total - deleteTotal
      dataOne.order.splice(index, 1)
      

      const response = deleteProductKitchen(indicative, phone, dataOne.op, dataOne)

      if(!response){
        dispatch(setTextNotificate('Ha ocurrido un error al eliminar el producto, inténtalo mas tarde'))
        dispatch(setStateNotification(true))
        dispatch(setIsError(true)) 
      }
      dispatch(setStateLoading(false))
    }

    
  }



  return (
    <div className="modal modalDashboard fade" data-bs-backdrop="static" id="deleteInOrder" tabIndex="-1" aria-labelledby="deleteInOrderLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body ">
            <div className="p-3 p-md-5 text-center text-white">
              <h4 className='text-apple'>Confirmar la eliminación de este producto en la orden</h4>
              <p className="my-4">Recuerda notificarle al cliente acerca de tu decisión.</p>
              <div className="row justify-content-center">
                <div className="col-12">
                  
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-md-6">
                  <button
                    className='btn-line-apple w-100 m-0'
                    data-bs-dismiss="modal"
                    onClick={handleReset}
                  >No, cerrar</button>
                </div>
                <div className="col-md-6 order-first order-md-last mb-4 mb-md-0">
                  <button
                    className='btn-orange w-100 m-0'
                    data-bs-dismiss="modal"
                    onClick={updateState}
                  >Sí, borrar producto</button>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default ModalDeleteInOrder