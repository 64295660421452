import React, { useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import Slider from "react-slick"
import imgDummi from '../../../../img/dashboard/post/img_upload_file.svg'
import { saveAs } from 'file-saver'
import html2canvas from 'html2canvas'
import uniqid from 'uniqid'
import { resetAllPost, setEditText, setImgPostLogo, setNewLogo, setNewPostColor, setTypeCrop, updateStep } from '../../../../redux/reducer/post'
import {setStateNotification, setTextNotificate,setIsError } from '../../../../redux/reducer/interactions/index.js'


const FormPost = () => {

  const dispatch = useDispatch()
  const stepEdit = useRef(null)
  const [countStep, setCountStep] = useState(0)
  const [loading, setLoading] = useState(false)
  const {logoMenu} = useSelector(s => s.log)
  const {dataPost, imgPostLogo, newLogo, imgProduct, step} = useSelector(s => s.post)

  const {register, handleSubmit, formState: { errors}, setValue} = useForm({
    mode: 'onChange'
  })


  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    swipe: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerPadding: '10px',
    className: 'px-0 px-md-3',
    afterChange: index => setCountStep(index)
  }

  const handleCropPhoto = ()=>{
    dispatch(setTypeCrop(false))
  }
  const handleCropLogo = ()=> {
    dispatch(setTypeCrop(true))
  }
  const resetLogoValue = ()=>{
    dispatch(setImgPostLogo(logoMenu))
    dispatch(setNewLogo(false))
  }

  const prevStep = ()=>{
    if(stepEdit){
      stepEdit.current.slickPrev()
    }
    dispatch(updateStep(step-1))
  }
  const nextStep = ()=>{
    if(stepEdit){
      stepEdit.current.slickNext()
    }
    dispatch(updateStep(step+1))
  }

  const handleDownload = () => {
    const viewPostDiv = document.querySelector('.poster')
    if (viewPostDiv) {
      setLoading(true)
      html2canvas(viewPostDiv, { useCORS: true, allowTaint: true }).then((canvas) => {
        setLoading(false)
        const imgData = canvas.toDataURL('image/jpeg')
        saveAs(imgData, `post-${uniqid()}.jpg`)
        dispatch(resetAllPost())
        dispatch(setTextNotificate('¡Imagen descargada! No olvides publicarla en tus redes sociales'))
        dispatch(setStateNotification(true))
        dispatch(setIsError(false))
      })
    }
  }


  const onsubmit = data =>{
    console.log(data)
  }


  return (
    <>

      <form onSubmit={handleSubmit(onsubmit)} >
        <Slider {...settings} ref={stepEdit}>
          <div>
            <div className="row justify-content-center mb-5 mb-md-3 pb-5 pb-md-3">
              <div className="col-md-12 mb-3 mt-4 mt-md-2">
                <h5 className="text-apple text-center">Selecciona las imagenes para tu diseño</h5>
              </div>
              <div className="col-md-5 col-6">
                <div className="logoPreview">
                  <img
                    src={imgPostLogo}
                    alt="logo"
                    className="img-fluid"
                    data-bs-toggle="modal"
                    data-bs-target="#modalPost"
                  />
                  {newLogo && <button type='button' onClick={resetLogoValue}>Restaurar</button>}
                </div>
                <button
                  type="button"
                  className="uploadBtnDash"
                  data-bs-toggle="modal"
                  data-bs-target="#modalPost"
                  onClick={handleCropLogo}
                >
                  CAMBIAR LOGO
                </button>
              </div>
              <div className="col-md-5 col-6">
                <div className="logoPreview">
                  <img
                    src={imgProduct || imgDummi} 
                    alt="logo" 
                    className="img-fluid"
                    data-bs-toggle="modal"
                    data-bs-target="#modalPost"
                    onClick={handleCropPhoto}
                  />
                </div>
                <button
                  type="button"
                  className="uploadBtnDash"
                  data-bs-toggle="modal"
                  data-bs-target="#modalPost"
                  onClick={handleCropPhoto}
                >
                  SUBIR FOTO
                </button>
              </div>
            </div>
          </div>
          <div>
            <div className="row justify-content-center pb-5 pb-md-2 mb-5 mb-md-0">
              <div className="col-md-12 col-11 mb-3 mt-4">
                <h5 className="text-apple">Rellena los campos</h5>
              </div>
              {
                dataPost.text[0] && (<>
                  <div className="col-md-6 col-11 mb-3">
                    <div className="inputDash">
                      <input
                        autoComplete="off"
                        type="text"
                        placeholder={dataPost.text[0]}
                        {...register('text1',{
                          required: false,
                          onChange: e =>{
                            dispatch(setEditText({index: 0, text: e.target.value}))
                          }
                        })}
                      />
                    </div>
                  </div>
                </>)
              }
              <div className="col-md-6 col-11 mb-3">
                <div className="inputDash">
                  <input
                    autoComplete="off"
                    type="tel"
                    placeholder={dataPost.text[1]}
                    {...register('text2',{
                      required: false,
                      pattern: {value: /^[0-9]+$/, message: 'Escribe únicamente dígitos'},
                      onChange: e =>{
                        if(e.target.value.length === 0){
                          setValue('text2', 0)
                        }
                        dispatch(setEditText({index: 1, text: parseInt(e.target.value)}))
                      }
                    })}
                  />
                </div>
                {errors.text2 && <small className='errorDash'>{errors.text2.message}</small> }
              </div>
              {
                dataPost.text[2] && (<>
                  <div className="col-md-6 col-11 mb-3">
                    <div className="inputDash">
                      <input
                        autoComplete="off"
                        type="text"
                        placeholder={dataPost.text[2]}
                        {...register('text3',{
                          required: false,
                          onChange: e =>{
                            dispatch(setEditText({index: 2, text: e.target.value}))
                          }
                        })}
                      />
                    </div>
                  </div>
                </>)
              }
              {
                dataPost.text[3] && (<>
                  <div className="col-md-6 col-11 mb-3">
                    <div className="inputDash">
                      <input
                        autoComplete="off"
                        type="text"
                        placeholder={dataPost.text[3]}
                        {...register('text4',{
                          required: false,
                          onChange: e =>{
                            dispatch(setEditText({index: 3, text: e.target.value}))
                          }
                        })}
                      />
                    </div>
                  </div>
                </>)
              }
              {
                dataPost.text[4] && (<>
                  <div className="col-md-12 col-11 mb-3">
                    <div className="inputDash">
                      <input
                        autoComplete="off"
                        type="text"
                        placeholder={dataPost.text[4]}
                        {...register('text5',{
                          required: false,
                          onChange: e =>{
                            dispatch(setEditText({index: 4, text: e.target.value}))
                          }
                        })}
                      />
                    </div>
                  </div>  
                </>)
              }
            </div>
          </div>
          <div>
            <div className="row justify-content-center">
              <div className="col-md-12 mb-3 mt-4">
                <h5 className="text-apple text-center">Personaliza los colores de la plantilla</h5>
              </div>
              {
                dataPost.colors[0] && (
                  <>
                    <div className="col-md-2 col-3 mb-3">
                      <div className="inputColor">  
                        <input
                          autoComplete="off"
                          type="color"
                          defaultValue={dataPost.colors[0]}
                          {...register('color1',{
                            required: false,
                            onChange: e =>{
                              dispatch(setNewPostColor({index: 0, value: e.target.value}))
                            }
                          })}
                        />
                      </div>
                    </div>
                  </>
                )
              }
              {
                dataPost.colors[1] && (
                  <>
                    <div className="col-md-2 col-3 mb-3">
                      <div className="inputColor">  
                        <input
                          autoComplete="off"
                          type="color"
                          defaultValue={dataPost.colors[1]}
                          {...register('color2',{
                            required: false,
                            onChange: e =>{
                              dispatch(setNewPostColor({index: 1, value: e.target.value}))
                            }
                          })}
                        />
                      </div>
                    </div>
                  </>
                )
              }
              {
                dataPost.colors[2] && (
                  <>
                    <div className="col-md-2 col-3 mb-3">
                      <div className="inputColor">  
                        <input
                          autoComplete="off"
                          type="color"
                          defaultValue={dataPost.colors[2]}
                          {...register('color3',{
                            required: false,
                            onChange: e =>{
                              dispatch(setNewPostColor({index: 2, value: e.target.value}))
                            }
                          })}
                        />
                      </div>
                    </div>
                  </>
                )
              }
              {
                dataPost.colors[3] && (
                  <>
                    <div className="col-md-2 col-3 mb-3">
                      <div className="inputColor">  
                        <input
                          autoComplete="off"
                          type="color"
                          defaultValue={dataPost.colors[3]}
                          {...register('color4',{
                            required: false,
                            onChange: e =>{
                              dispatch(setNewPostColor({index: 3, value: e.target.value}))
                            }
                          })}
                        />
                      </div>
                    </div>
                  </>
                )
              }
              {
                dataPost.colors[4] && (
                  <>
                    <div className="col-md-2 col-3 mb-3">
                      <div className="inputColor">
                        <input
                          autoComplete="off"
                          type="color"
                          defaultValue={dataPost.colors[4]}
                          {...register('color5',{
                            required: false,
                            onChange: e =>{
                              dispatch(setNewPostColor({index: 4, value: e.target.value}))
                            }
                          })}
                        />
                      </div>
                    </div>
                  </>
                )
              }
            </div>
            <div className="row justify-content-center">
              <div className="col-md-6 col-10 mt-4 align-self-center">
                <button
                  className={`btn-download ${loading ? 'loading': ''}`}
                  type='button'
                  onClick={handleDownload}
                >Descargar Post</button>
              </div>
            </div>
          </div>
        </Slider>
        <div className="row justify-content-center mt-3 footerPost">
          <div className="col-md-4 col-6 px-0 px-md-3">
            <button className={`btn-link-step ${countStep === 0 ? 'disabled': ''}`} onClick={prevStep}>Anterior</button>
          </div>
          <div className="col-md-4 col-6 px-0 px-md-3">
            <button className={`btn-link-step ${countStep === 2 ? 'disabled': ''}`} onClick={nextStep}>Siguiente</button>
          </div>
        </div>
      </form>
    </>
  )
}

export default FormPost