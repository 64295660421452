import React from 'react'
import woman from '../../../../../img/dashboard/empty_product_ui.svg'

const EmptyCatalog = () => {
  return (
    <div className="p-4 bg-b1 mt-2 mt-md-4">
      <div className="row justify-content-center my-4">
        <div className="col-md-6 align-self-center text-white mb-5 mb-md-0">
          <p>En esta sección podrás hacer lo siguiente con tus productos:</p>
          <ul>
            <li className='mb-2'><strong className='text-apple'>Destacar:</strong> Selecciona que productos quieres colocar al inicio de tu menú digital, esta estrategia aumenta la probabilidad de venta para esos productos.</li>
            <li className='mb-2'><strong className='text-apple'>Desactivar:</strong> Puede que quieras ocultar ciertos productos por algún día específico o porque los insumos están agotados, entonces estos no aparecerán en tu menú hasta que cambies nuevamente el estado.</li>
            <li className='mb-2'><strong className='text-apple'>Editar:</strong> Modifica todas las propiedades que estuvieron habilitadas en la creación del producto.</li>
            <li ><strong className='text-apple'>Eliminar:</strong> ¡Todos tenemos derecho a equivocarnos! </li>
          </ul>
          <h5 className="text-center text-apple">¡No esperes más para crear tu primer producto!</h5>
        </div>
        <div className="col-md-3 col-7 order-first order-md-last mb-4 mb-md-0">
          <img src={woman} alt="empty" className='img-fluid'/>
        </div>
      </div>
    </div>
  )
}

export default EmptyCatalog