import React from 'react'
import { useSelector } from 'react-redux';
import { convertAmount } from '../../../../tools/format';


const Post8 = () => {

  const {dataPost, imgPostLogo, imgProduct} = useSelector(s => s.post)
  

  return (
    <div className="poster">
      <div className="img" style={{width: '48%', height: '90%', margin:'4.7%'}}>
        {
          imgProduct && (
            <img
              src={imgProduct}
              className='b7'
              alt="img" />
          )
        }
      </div>
      <div className="svg">
        <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">
          <defs>
            <style>
              {`
              .fdg44343df {
                stroke-dasharray: 0 0 8.02 8.02;
              }

              .fdg44343df, .gfffe, .lkjkhgjjy {
                fill: none;
                stroke-linecap: round;
                stroke-linejoin: round;
                stroke-width: 5px;
              }

              .lkjkhgjjy {
                stroke-dasharray: 0 0 8.07 8.07;
              }
              `}
            </style>
          </defs>
          <path fill={`${dataPost.colors[0]}`}  d="m0,0V1024h1024V0H0Zm530,966H49V51h481v915Z"/>
          <rect fill={`${dataPost.colors[1]}`}   x="835" y="75.76" width="115" height="872.48"/>
          <g>
            <polyline fill={`${dataPost.colors[3]}`} className="gfffe"  style={{stroke: `${dataPost.colors[2]}`}} points="812 943.5 812 947.5 808 947.5"/>
            <line fill={`${dataPost.colors[3]}`} style={{stroke: `${dataPost.colors[2]}`}} className="fdg44343df" x1="799.98" y1="947.5" x2="82.01" y2="947.5"/>
            <polyline fill={`${dataPost.colors[3]}`} className="gfffe" style={{stroke: `${dataPost.colors[2]}`}} points="78 947.5 74 947.5 74 943.5"/>
            <line fill={`${dataPost.colors[3]}`} className="lkjkhgjjy" style={{stroke: `${dataPost.colors[2]}`}} x1="74" y1="935.43" x2="74" y2="84.53"/>
            <polyline fill={`${dataPost.colors[3]}`} className="gfffe" style={{stroke: `${dataPost.colors[2]}`}} points="74 80.5 74 76.5 78 76.5"/>
            <line fill={`${dataPost.colors[3]}`} className="fdg44343df" style={{stroke: `${dataPost.colors[2]}`}} x1="86.02" y1="76.5" x2="803.99" y2="76.5"/>
            <polyline fill={`${dataPost.colors[3]}`} className="gfffe" style={{stroke: `${dataPost.colors[2]}`}} points="808 76.5 812 76.5 812 80.5"/>
            <line fill={`${dataPost.colors[3]}`} className="lkjkhgjjy" style={{stroke: `${dataPost.colors[2]}`}} x1="812" y1="88.57" x2="812" y2="939.47"/>
          </g>
        </svg>
      </div>

      <img
        src={imgPostLogo}
        alt="logo" className="logo text tl"
        style={{margin: '9.5%'}}
      />

      {
        dataPost.text[0] && (
          <div
            className="text   py-1 px-2 f-3 text-truncate text-center text-uppercase"
            style={{color: dataPost.colors[1], fontSize: '25px', transform: 'rotate(-90deg)',
            marginLeft: '21%', maxWidth: '83%'
            
          }}
          >{dataPost.text[0]}</div>
        )
      }
      {
        dataPost.text[1] && (
          <div
            className="text br  py-1 px-2 f-3 text-truncate text-uppercase"
            style={{color: dataPost.colors[1], fontSize: '15px', letterSpacing: '10px',
            marginRight: '4%',
            
          }}
          >{convertAmount(dataPost.text[1])}</div>
        )
      }
      {
        dataPost.text[2] && (
          <div
            className="text  px-2 f-3 clamp-2 text-center text-uppercase"
            style={{color: dataPost.colors[1], fontSize: '16px', transform: 'rotate(-90deg)',
            marginLeft: '42%', lineHeight: '20px', maxWidth: '83%'
            
          }}
          >{dataPost.text[2]}</div>
        )
      }
      
    
    </div>
  );
};

export default Post8;
