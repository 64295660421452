import React, { useEffect, useState } from 'react'
import BounceLoader from "react-spinners/BounceLoader"
import DotLoader from "react-spinners/DotLoader"
import GridLoader from "react-spinners/GridLoader"
import HashLoader from "react-spinners/HashLoader"
import PacmanLoader from "react-spinners/PacmanLoader"
import PuffLoader from "react-spinners/PuffLoader"
import ScaleLoader from "react-spinners/ScaleLoader"
import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader"
import { useSelector } from 'react-redux'

const LoadDigitalMenu = () => {

  const {menu} = useSelector(state => state.user)
  const [fade, setFade] = useState(false)
  const [loaderVisible, setLoaderVisible] = useState(true)
  let loader ;

  useEffect(()=>{
    setTimeout(()=>{
      setFade(true)
    }, 2500)
  },[])

  useEffect(()=>{
    setTimeout(()=>{
      setLoaderVisible(false)
    },3100)
  })

  switch (menu?.f) {
    case 0:
      loader = <BounceLoader color={menu?.g?.[1]} size={80}/>
      break
    case 1:
      loader = <DotLoader color={menu?.g?.[1]} size={85}/>
      break
    case 2:
      loader = <GridLoader color={menu?.g?.[1]} size={15}/>
      break
    case 3:
      loader = <HashLoader color={menu?.g?.[1]} size={60}/>
      break
    case 4:
      loader = <PacmanLoader color={menu?.g?.[1]} size={27}/>
      break
    case 5:
      loader = <PuffLoader color={menu?.g?.[1]} size={100}/>
      break
    case 6:
      loader = <ScaleLoader color={menu?.g?.[1]} size={80}/>
      break
    case 7:
      loader = <ClimbingBoxLoader color={menu?.g?.[1]} size={25}/>
      break
    default:
      loader = <BounceLoader color={menu?.g?.[1]} size={80}/>
      break
  }

  return (
    <>
      {
        loaderVisible && (
          <>
            <div className={`menuLoading ${fade ? 'disabled': ''}`} style={{background:menu?.g?.[2]}}>
              {loader}
            </div>
          </>
        )
      }
    </>
  )
}

export default LoadDigitalMenu