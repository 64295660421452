import { auth, db } from "../../../../firebase/credentials"
import { doc, deleteDoc } from "firebase/firestore"
import { getStorage, ref, listAll, deleteObject } from "firebase/storage"
import { deleteUser } from "firebase/auth"


const deleteUserFooder = async (id, country, tel) => {
  const storage = getStorage()

  const deleteOperations = [
    { collection: 'admin', id: id },
    { collection: 'agendaclient', id: id },
    { collection: 'allReports', id: id },
    { collection: 'digitalmenu', id: id },
    { collection: 'mycustomers', id: id },
    { collection: 'orderLink', id: `${country}${tel}` },
  ]

  const deletePromises = deleteOperations.map(async (operation) => {
    try {
      await deleteDoc(doc(db, operation.collection, operation.id))
      return { collection: operation.collection, id: operation.id, status: 'deleted' }
    } catch (error) {
      if (error.code === 'not-found') {
        return { collection: operation.collection, id: operation.id, status: 'not found' }
      } else {
        return { collection: operation.collection, id: operation.id, status: 'error', error: error.message }
      }
    }
  })

  // Función para eliminar archivos en una carpeta
  const deleteFolderContents = async (folderPath) => {
    const folderRef = ref(storage, folderPath)
    const list = await listAll(folderRef)
    const deletePromises = list.items.map((itemRef) => deleteObject(itemRef))
    await Promise.all(deletePromises)
    return { folderPath, status: 'deleted' }
  }

  // Añadir eliminación de carpetas a las promesas
  const folderDeletePromises = [
    deleteFolderContents(`product/${id}`)
  ]

  // Eliminar al usuario de Firebase Authentication
  const deleteAuthUser = async () => {
    
    deleteUser(auth,id).then(() => {
      return { id: id, status: 'deleted from authentication' }
    }).catch((error) => {
      return { id: id, status: 'error deleting from authentication', error: error.message }
    });
  }

  // Añadir la eliminación del usuario a las promesas
  const authUserDeletePromise = deleteAuthUser()

  // Esperar a que se completen todas las operaciones de eliminación
  const results = await Promise.all([...deletePromises, ...folderDeletePromises, authUserDeletePromise])

  console.log(results) // <-- Ver resultados en la consola
  return results
}

export default deleteUserFooder
