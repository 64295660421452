import React from 'react'
import emptyImg from '../../../../img/dashboard/empy_list.svg'

const EmptyClient = () => {
  return (
    <>
      <div className="row justify-content-center mt-5">
        <div className="col-md-4">
          <img src={emptyImg} alt="Lista vacía" className='img-fluid'/>
          <h4 className="mt-4 text-center text-apple">
            ¿List@ para inscribir tus clientes?
          </h4>
        </div>
      </div>
    </>
  )
}

export default EmptyClient