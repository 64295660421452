import { doc, updateDoc } from "firebase/firestore"
import { db } from "../../../firebase/credentials"


const setCountVisitors = async(userID, currentValue) =>{

  const ref = doc(db, 'admin', userID)
  const increment = currentValue + 1
  await updateDoc(ref, {
    r: increment
  })

}

export default setCountVisitors