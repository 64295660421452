import React from 'react'
import { useSelector } from 'react-redux'

const HeadPost = ()=>{

  const {step} = useSelector(s => s.post)

  return(
    <div className="headPost">
      <h5 className=" m-0 text-apple">
        {step === 1 && 'Elige el diseño que quieres modificar'}
        {step === 2 && 'Sube una foto'}
        {step === 3 && 'Modifica los textos del post'}
        {step === 4 && 'Elige los colores de tu diseño'}
      </h5>
      <div>
        <div className="pointer">
          <strong>1</strong>
          <small>Diseño</small>
        </div>
        <div className={`pointer ${step <= 1 ? 'disabled': ''}`}>
          <strong>2</strong>
          <small>Foto</small>
        </div>
        <div className={`pointer ${step <= 2 ? 'disabled': ''}`}>
          <strong>3</strong>
          <small>Texto</small>
        </div>
        <div className={`pointer ${step <=3 ? 'disabled': ''}`}>
          <strong>4</strong>
          <small>Color</small>
        </div>
      </div>
    </div>
  )
}

export default HeadPost