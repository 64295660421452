import React from 'react'

const EmptyCart = ({color})=>{
  return(
    <>
      <div className="emptyCart">
        <div className="content">
          <svg className='img-fluid' id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 54.14 50.97" fill={color}>
            <path d="m17.79,38.82h.73c8.53,0,17.06,0,25.59,0,.56,0,1.1.06,1.46.54.33.43.42.92.19,1.43-.25.54-.7.82-1.28.84-.49.02-.97,0-1.56,0,1.08,1.25,1.44,2.64,1.22,4.15-.17,1.11-.68,2.11-1.54,2.84-2.17,1.84-4.72,1.84-6.84-.05-1.45-1.3-2.51-4.03-.38-6.91h-10.78c1.02,1.34,1.46,2.79,1.09,4.42-.29,1.27-1.01,2.28-2.07,3.02-1.82,1.28-4.36,1.13-6.06-.35-1.05-.91-1.65-2.07-1.75-3.44-.1-1.33.31-2.56,1.27-3.68-.46,0-.8,0-1.15,0-1.15-.04-1.77-.89-1.4-1.97.57-1.66,1.17-3.3,1.79-5.03-.3,0-.56,0-.82,0-.83-.04-1.49-.58-1.64-1.39-.45-2.42-.89-4.84-1.33-7.27-.56-3.05-1.13-6.1-1.69-9.16-.56-3.04-1.13-6.07-1.66-9.12-.1-.59-.32-.91-.91-1.12-2.04-.75-4.05-1.57-6.07-2.37-.99-.39-1.42-1.32-1.05-2.24.34-.85,1.29-1.24,2.24-.87,2.53.98,5.05,1.98,7.58,2.97.74.29,1.1.84,1.23,1.6.73,4.02,1.47,8.03,2.21,12.05.7,3.8,1.39,7.6,2.08,11.4.1.56.23,1.13.3,1.69.05.43.27.52.67.52,7.34-.01,14.69,0,22.03,0,1.12,0,2.24,0,3.36,0,.25,0,.42-.02.52-.33,1.89-5.96,3.8-11.9,5.71-17.86.02-.08.04-.16.08-.29-1.37-.07-2.7-.14-4.03-.2-2.93-.13-5.86-.26-8.79-.38-2.78-.12-5.55-.22-8.33-.33-3.03-.13-6.06-.26-9.09-.41-.83-.04-1.47-.85-1.44-1.73.03-.94.75-1.64,1.73-1.61,1.73.05,3.45.14,5.18.22,1.96.09,3.93.18,5.89.27,2.84.13,5.69.25,8.53.38,1.95.08,3.89.15,5.84.24,2.32.1,4.64.19,6.96.31.99.05,1.71,1.02,1.44,1.98-.52,1.83-1.11,3.64-1.69,5.45-1.71,5.36-3.44,10.71-5.15,16.07-.38,1.18-.86,1.54-2.11,1.54-8.07,0-16.15,0-24.22-.01-.48,0-.68.15-.83.58-.39,1.18-.82,2.35-1.27,3.61Zm5.22,6.15c0-1.25-.96-2.21-2.2-2.21-1.24,0-2.22.97-2.23,2.2,0,1.23.99,2.23,2.21,2.23,1.24,0,2.22-.98,2.21-2.21Zm16.25,2.21c1.24-.06,2.21-1.08,2.16-2.28-.06-1.29-1.09-2.2-2.45-2.13-1.08.05-2.08,1.18-2.02,2.27.07,1.23,1.11,2.2,2.31,2.14Z"/>
          </svg>
          <strong>No tienes productos</strong>
        </div>
      </div>
    </>
  )
}

export default EmptyCart