import React from 'react'
import ProductCategory from './ProductCategory'

const CatalogDash = () => {
  return (
    <>
      <div className="row mb-5">
        <h3 className="text-apple text-center">Mi lista de productos</h3>
        <div className="col-12">
          <ProductCategory />
        </div>
      </div>
    </>
  )
}

export default CatalogDash