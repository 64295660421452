import React from 'react'
import { useSelector } from 'react-redux';
import { convertAmount } from '../../../../tools/format';


const State8 = () => {

  const {dataPost, imgPostLogo, imgProduct} = useSelector(s => s.post)
  

  return (
    <div className="poster">
      <div className="img" style={{
        left: '6.5%', top: '4.2%', width: '86.6%', height: '63%'
      }}>
        {
          imgProduct && (
            <img
              src={imgProduct}
              className='b7'
              alt="img" />
          )
        }
      </div>
      <div className="svg">
        <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 490 870" >
          <defs>
            <style>
              {`
              .cls-1 {
                fill: ${dataPost.colors[0]}
              }

              .cls-2 {
                fill: ${dataPost.colors[1]}
              }
              `}
            </style>
          </defs>
          <path fill={`${dataPost.colors[0]}`}  d="m0,0v870h490V0H0Zm453,576H34V38h419v538Z" style={{stroke: 'none'}}/>
          <g >
            <polygon fill={`${dataPost.colors[2]}`} points="103.47 751.55 85.82 769.2 88.9 772.28 103.47 757.72 118.04 772.28 121.12 769.2 103.47 751.55"/>
            <g>
              <path fill={`${dataPost.colors[2]}`} d="m50.63,807.21c-.51,0-.89-.13-1.16-.4-.26-.26-.4-.65-.4-1.16v-21.21c0-.51.13-.89.4-1.16s.65-.4,1.16-.4h6.66c3.93,0,6.97,1.04,9.11,3.1,2.14,2.07,3.21,5.08,3.21,9.04,0,1.98-.27,3.72-.81,5.23-.54,1.51-1.34,2.78-2.4,3.81-1.06,1.04-2.35,1.82-3.86,2.35-1.52.53-3.27.79-5.24.79h-6.66Zm1.28-2.41h5.17c1.61,0,3.01-.21,4.19-.62,1.18-.41,2.17-1.02,2.97-1.83.79-.8,1.39-1.82,1.78-3.05.39-1.23.59-2.65.59-4.26,0-3.24-.79-5.67-2.38-7.3-1.59-1.62-3.97-2.43-7.14-2.43h-5.17v19.49Z"/>
              <path fill={`${dataPost.colors[2]}`} d="m81.85,807.52c-1.77,0-3.29-.35-4.57-1.05-1.28-.7-2.27-1.7-2.97-2.98-.7-1.29-1.05-2.83-1.05-4.62s.34-3.27,1.03-4.57c.69-1.3,1.64-2.32,2.85-3.05,1.21-.74,2.6-1.1,4.19-1.1,1.13,0,2.14.19,3.04.57.9.38,1.66.93,2.29,1.64.63.71,1.11,1.58,1.45,2.59.33,1.01.5,2.15.5,3.42,0,.37-.1.64-.31.81s-.51.26-.9.26h-12v-1.83h11.35l-.55.45c0-1.24-.18-2.29-.55-3.16-.37-.86-.9-1.52-1.6-1.98-.7-.46-1.58-.69-2.64-.69-1.17,0-2.17.27-2.98.81-.82.54-1.43,1.29-1.85,2.24-.41.95-.62,2.06-.62,3.33v.21c0,2.12.51,3.73,1.54,4.83,1.02,1.1,2.48,1.66,4.36,1.66.78,0,1.56-.1,2.33-.31.77-.21,1.52-.55,2.26-1.03.32-.21.61-.3.88-.29.26.01.48.09.64.24.16.15.27.33.33.55.06.22.03.45-.07.71-.1.25-.31.47-.6.66-.76.55-1.66.98-2.69,1.28-1.03.3-2.06.45-3.07.45Z"/>
              <path fill={`${dataPost.colors[2]}`} d="m98.3,807.52c-1.03,0-2.08-.13-3.12-.38-1.05-.25-2.02-.69-2.91-1.31-.23-.16-.39-.35-.48-.57-.09-.22-.12-.44-.09-.66.03-.22.13-.41.28-.57.15-.16.33-.26.55-.29.22-.03.47.02.74.16.9.55,1.76.94,2.6,1.16.84.22,1.67.33,2.5.33,1.31,0,2.3-.24,2.97-.72.67-.48,1-1.14,1-1.97,0-.64-.22-1.16-.66-1.53-.44-.38-1.13-.67-2.07-.88l-3.14-.69c-1.45-.3-2.52-.82-3.23-1.55-.7-.74-1.05-1.69-1.05-2.86,0-1.03.27-1.93.81-2.67.54-.75,1.3-1.33,2.29-1.74.99-.41,2.14-.62,3.45-.62,1.01,0,1.97.13,2.88.4.91.26,1.73.68,2.47,1.26.23.16.39.35.47.57.08.22.09.44.03.66-.06.22-.17.4-.33.55-.16.15-.36.24-.59.26-.23.02-.47-.05-.72-.21-.69-.46-1.39-.8-2.1-1.02-.71-.22-1.41-.33-2.1-.33-1.29,0-2.27.25-2.93.76-.67.51-1,1.17-1,2,0,.64.21,1.17.62,1.59.41.41,1.06.71,1.93.9l3.14.66c1.49.32,2.62.83,3.36,1.53.75.7,1.12,1.65,1.12,2.85,0,1.52-.61,2.73-1.83,3.62-1.22.9-2.84,1.35-4.86,1.35Z"/>
              <path fill={`${dataPost.colors[2]}`} d="m114.34,807.52c-1.66,0-2.92-.51-3.79-1.52-.87-1.01-1.31-2.46-1.31-4.35v-17.59c0-.46.11-.81.34-1.05.23-.24.57-.36,1.03-.36.44,0,.78.12,1.03.36.25.24.38.59.38,1.05v17.39c0,1.24.25,2.17.74,2.78.49.61,1.22.91,2.19.91.21,0,.39-.01.55-.03.16-.02.32-.03.48-.03.25-.02.43.03.53.17.1.14.16.41.16.83s-.09.72-.28.93c-.18.21-.48.35-.9.41-.18.02-.38.05-.59.07-.21.02-.4.03-.59.03Z"/>
              <path fill={`${dataPost.colors[2]}`} d="m120.83,786.41c-.58,0-1.02-.16-1.35-.47-.32-.31-.48-.73-.48-1.26s.16-.97.48-1.26c.32-.29.77-.43,1.35-.43s1.05.14,1.36.43c.31.29.47.71.47,1.26s-.16.95-.47,1.26c-.31.31-.76.47-1.36.47Zm0,20.97c-.46,0-.81-.13-1.03-.4-.23-.26-.34-.64-.34-1.12v-14.11c0-.48.11-.85.34-1.1.23-.25.57-.38,1.03-.38.44,0,.78.13,1.03.38.25.25.38.62.38,1.1v14.11c0,.48-.12.86-.36,1.12s-.59.4-1.05.4Z"/>
              <path fill={`${dataPost.colors[2]}`} d="m127.76,807.21c-.3,0-.54-.07-.72-.21-.18-.14-.31-.32-.38-.55-.07-.23-.07-.48,0-.76.07-.28.22-.54.45-.79l9.73-13v.72h-9.21c-.37,0-.65-.1-.85-.29-.2-.2-.29-.47-.29-.81s.1-.61.29-.79c.2-.18.48-.28.85-.28h10.59c.34,0,.63.07.84.21.22.14.36.32.43.53.07.22.07.46.02.72-.06.26-.19.52-.4.78l-9.9,13.14v-.79h9.69c.76,0,1.14.36,1.14,1.07,0,.35-.1.62-.29.81-.2.2-.48.29-.85.29h-11.14Z"/>
              <path fill={`${dataPost.colors[2]}`} d="m148.53,807.52c-1.15,0-2.18-.22-3.09-.67-.91-.45-1.63-1.06-2.16-1.85-.53-.78-.79-1.66-.79-2.62,0-1.24.32-2.22.95-2.93.63-.71,1.68-1.22,3.14-1.52,1.46-.3,3.45-.45,5.98-.45h1.55v1.83h-1.52c-1.86,0-3.33.09-4.4.26s-1.82.47-2.24.88c-.43.41-.64,1-.64,1.76,0,.94.33,1.71.98,2.31.66.6,1.55.9,2.67.9.92,0,1.73-.22,2.43-.66.7-.44,1.25-1.03,1.66-1.79.4-.76.6-1.63.6-2.62v-3.93c0-1.43-.29-2.45-.86-3.09-.57-.63-1.52-.95-2.83-.95-.81,0-1.61.1-2.41.31-.81.21-1.66.54-2.55,1-.32.16-.6.21-.83.16-.23-.06-.41-.18-.55-.36-.14-.18-.22-.4-.24-.64-.02-.24.03-.48.16-.71.13-.23.33-.41.6-.55,1.01-.51,2.01-.87,3-1.1.99-.23,1.93-.34,2.83-.34,1.45,0,2.64.24,3.59.71.94.47,1.64,1.19,2.1,2.14.46.95.69,2.18.69,3.67v9.35c0,.46-.11.82-.33,1.07-.22.25-.53.38-.95.38-.44,0-.77-.13-1-.38-.23-.25-.34-.61-.34-1.07v-2.69h.31c-.18.87-.53,1.62-1.05,2.24-.52.62-1.16,1.1-1.91,1.45-.76.35-1.61.52-2.55.52Z"/>
              <path fill={`${dataPost.colors[2]}`} d="m51.53,829.96c-.28,0-.48-.08-.62-.23-.14-.15-.21-.37-.21-.64v-13.19c0-.28.07-.49.21-.63.14-.15.35-.22.62-.22.26,0,.47.07.62.22.15.15.23.36.23.63v6.08h-.27c.29-.8.76-1.41,1.42-1.82.66-.41,1.41-.61,2.27-.61.8,0,1.47.15,2,.44.53.29.93.73,1.19,1.32.26.59.39,1.33.39,2.23v5.56c0,.28-.07.49-.22.64-.15.15-.35.23-.61.23-.28,0-.49-.08-.63-.23-.15-.15-.22-.37-.22-.64v-5.46c0-.94-.18-1.63-.55-2.06-.37-.44-.95-.65-1.75-.65-.93,0-1.66.29-2.21.86-.55.57-.82,1.34-.82,2.29v5.02c0,.58-.28.87-.85.87Z"/>
              <path fill={`${dataPost.colors[2]}`} d="m65.66,830c-.69,0-1.31-.13-1.86-.4-.55-.27-.98-.64-1.3-1.11-.32-.47-.48-1-.48-1.58,0-.75.19-1.33.57-1.76.38-.43,1.01-.73,1.89-.91.88-.18,2.08-.27,3.6-.27h.93v1.1h-.91c-1.12,0-2,.05-2.64.16s-1.09.28-1.35.53c-.26.25-.38.6-.38,1.06,0,.57.2,1.03.59,1.39.39.36.93.54,1.61.54.55,0,1.04-.13,1.46-.39.42-.26.75-.62,1-1.08.24-.46.36-.98.36-1.58v-2.36c0-.86-.17-1.48-.52-1.86-.35-.38-.91-.57-1.7-.57-.48,0-.97.06-1.45.19-.48.12-1,.33-1.54.6-.19.1-.36.13-.5.09-.14-.03-.25-.11-.33-.22-.08-.11-.13-.24-.15-.38-.01-.15.02-.29.09-.43.08-.14.2-.25.36-.33.61-.3,1.21-.53,1.8-.66.59-.14,1.16-.21,1.7-.21.87,0,1.59.14,2.16.43.57.28.99.71,1.27,1.29.28.57.41,1.31.41,2.21v5.62c0,.28-.07.49-.2.64-.13.15-.32.23-.57.23-.26,0-.46-.08-.6-.23-.14-.15-.21-.37-.21-.64v-1.62h.19c-.11.53-.32.97-.63,1.35-.31.37-.69.66-1.15.87-.46.21-.97.31-1.54.31Z"/>
              <path fill={`${dataPost.colors[2]}`} d="m77.75,830c-1.01,0-1.88-.22-2.61-.66-.73-.44-1.3-1.06-1.69-1.86-.39-.79-.59-1.72-.59-2.77,0-.8.11-1.52.33-2.16.22-.64.55-1.18.98-1.63.43-.45.94-.79,1.55-1.03.6-.23,1.28-.35,2.04-.35.5,0,1.02.08,1.56.23.54.15,1.03.41,1.47.79.14.1.23.21.27.35.04.14.04.27,0,.4-.04.13-.11.24-.21.33-.1.09-.22.14-.36.16-.15.01-.29-.03-.45-.15-.36-.28-.73-.47-1.1-.58-.37-.11-.73-.17-1.08-.17-.54,0-1.01.09-1.41.26-.4.17-.74.42-1.02.74-.28.32-.49.71-.63,1.18s-.22,1.02-.22,1.64c0,1.2.28,2.15.85,2.85.57.7,1.38,1.05,2.43,1.05.35,0,.7-.05,1.07-.17.37-.11.74-.3,1.11-.58.15-.11.3-.16.44-.15.14.01.26.07.35.16.1.09.16.2.2.34.03.14.03.27-.01.4-.04.13-.13.25-.27.34-.44.36-.93.62-1.45.78-.53.16-1.04.24-1.53.24Z"/>
              <path fill={`${dataPost.colors[2]}`} d="m83.91,817.3c-.35,0-.62-.09-.81-.28-.19-.19-.29-.44-.29-.76s.1-.58.29-.76c.19-.17.46-.26.81-.26s.63.09.82.26c.19.17.28.43.28.76s-.09.57-.28.76c-.19.19-.46.28-.82.28Zm0,12.61c-.28,0-.48-.08-.62-.24-.14-.16-.21-.38-.21-.67v-8.48c0-.29.07-.51.21-.66.14-.15.35-.23.62-.23.26,0,.47.08.62.23.15.15.23.37.23.66v8.48c0,.29-.07.52-.22.67s-.36.24-.63.24Z"/>
              <path fill={`${dataPost.colors[2]}`} d="m91.07,830c-.69,0-1.31-.13-1.86-.4-.55-.27-.98-.64-1.3-1.11-.32-.47-.48-1-.48-1.58,0-.75.19-1.33.57-1.76.38-.43,1.01-.73,1.89-.91.88-.18,2.08-.27,3.6-.27h.93v1.1h-.91c-1.12,0-2,.05-2.64.16s-1.09.28-1.35.53c-.26.25-.38.6-.38,1.06,0,.57.2,1.03.59,1.39.39.36.93.54,1.61.54.55,0,1.04-.13,1.46-.39.42-.26.75-.62,1-1.08.24-.46.36-.98.36-1.58v-2.36c0-.86-.17-1.48-.52-1.86-.35-.38-.91-.57-1.7-.57-.48,0-.97.06-1.45.19-.48.12-1,.33-1.54.6-.19.1-.36.13-.5.09-.14-.03-.25-.11-.33-.22-.08-.11-.13-.24-.15-.38-.01-.15.02-.29.09-.43.08-.14.2-.25.36-.33.61-.3,1.21-.53,1.8-.66.59-.14,1.16-.21,1.7-.21.87,0,1.59.14,2.16.43.57.28.99.71,1.27,1.29.28.57.41,1.31.41,2.21v5.62c0,.28-.07.49-.2.64-.13.15-.32.23-.57.23-.26,0-.46-.08-.6-.23-.14-.15-.21-.37-.21-.64v-1.62h.19c-.11.53-.32.97-.63,1.35-.31.37-.69.66-1.15.87-.46.21-.97.31-1.54.31Z"/>
              <path fill={`${dataPost.colors[2]}`} d="m107.42,830c-.69,0-1.31-.13-1.86-.4-.55-.27-.98-.64-1.3-1.11-.32-.47-.48-1-.48-1.58,0-.75.19-1.33.57-1.76.38-.43,1.01-.73,1.89-.91.88-.18,2.08-.27,3.6-.27h.93v1.1h-.91c-1.12,0-2,.05-2.64.16s-1.09.28-1.35.53c-.26.25-.38.6-.38,1.06,0,.57.2,1.03.59,1.39.39.36.93.54,1.61.54.55,0,1.04-.13,1.46-.39.42-.26.75-.62,1-1.08.24-.46.36-.98.36-1.58v-2.36c0-.86-.17-1.48-.52-1.86-.35-.38-.91-.57-1.7-.57-.48,0-.97.06-1.45.19-.48.12-1,.33-1.54.6-.19.1-.36.13-.5.09-.14-.03-.25-.11-.33-.22-.08-.11-.13-.24-.15-.38-.01-.15.02-.29.09-.43.08-.14.2-.25.36-.33.61-.3,1.21-.53,1.8-.66.59-.14,1.16-.21,1.7-.21.87,0,1.59.14,2.16.43.57.28.99.71,1.27,1.29.28.57.41,1.31.41,2.21v5.62c0,.28-.07.49-.2.64-.13.15-.32.23-.57.23-.26,0-.46-.08-.6-.23-.14-.15-.21-.37-.21-.64v-1.62h.19c-.11.53-.32.97-.63,1.35-.31.37-.69.66-1.15.87-.46.21-.97.31-1.54.31Z"/>
              <path fill={`${dataPost.colors[2]}`} d="m116.09,829.96c-.28,0-.49-.08-.63-.23-.15-.15-.22-.37-.22-.64v-8.65c0-.28.07-.49.21-.63.14-.15.34-.22.6-.22s.47.07.61.22c.15.15.22.36.22.63v1.68h-.21c.22-.82.64-1.44,1.27-1.89.62-.44,1.39-.68,2.3-.71.21-.01.37.03.5.14.12.1.19.28.21.53.01.24-.04.42-.17.56-.12.14-.32.22-.58.25l-.33.04c-.94.08-1.66.38-2.17.9-.5.52-.76,1.23-.76,2.13v5.02c0,.28-.07.49-.22.64-.15.15-.36.23-.63.23Z"/>
              <path fill={`${dataPost.colors[2]}`} d="m123.51,829.96c-.28,0-.49-.08-.63-.23-.15-.15-.22-.37-.22-.64v-8.65c0-.28.07-.49.21-.63.14-.15.34-.22.6-.22s.47.07.61.22c.15.15.22.36.22.63v1.68h-.21c.22-.82.64-1.44,1.27-1.89.62-.44,1.39-.68,2.3-.71.21-.01.37.03.5.14.12.1.19.28.21.53.01.24-.04.42-.17.56-.12.14-.32.22-.58.25l-.33.04c-.94.08-1.66.38-2.17.9-.5.52-.76,1.23-.76,2.13v5.02c0,.28-.07.49-.22.64-.15.15-.36.23-.63.23Z"/>
              <path fill={`${dataPost.colors[2]}`} d="m130.92,817.3c-.35,0-.62-.09-.81-.28-.19-.19-.29-.44-.29-.76s.1-.58.29-.76c.19-.17.46-.26.81-.26s.63.09.82.26c.19.17.28.43.28.76s-.09.57-.28.76c-.19.19-.46.28-.82.28Zm0,12.61c-.28,0-.48-.08-.62-.24-.14-.16-.21-.38-.21-.67v-8.48c0-.29.07-.51.21-.66.14-.15.35-.23.62-.23.26,0,.47.08.62.23.15.15.23.37.23.66v8.48c0,.29-.07.52-.22.67s-.36.24-.63.24Z"/>
              <path fill={`${dataPost.colors[2]}`} d="m140,830c-.91,0-1.7-.23-2.35-.71-.66-.47-1.1-1.1-1.32-1.89l.23-.23v1.91c0,.28-.07.49-.22.64-.15.15-.35.23-.61.23-.28,0-.48-.08-.62-.23-.14-.15-.21-.37-.21-.64v-13.19c0-.28.07-.49.21-.63.14-.15.35-.22.62-.22.26,0,.47.07.62.22.15.15.23.36.23.63v6.18h-.25c.24-.77.68-1.39,1.33-1.85.65-.46,1.43-.68,2.34-.68s1.7.21,2.36.63c.66.42,1.18,1.02,1.55,1.8.37.78.55,1.71.55,2.79s-.18,1.99-.55,2.77c-.37.78-.89,1.39-1.56,1.82-.67.43-1.46.64-2.35.64Zm-.35-1.35c.64,0,1.19-.15,1.65-.46.46-.3.82-.75,1.08-1.33.26-.58.38-1.28.38-2.1,0-1.26-.28-2.22-.84-2.88s-1.32-1-2.27-1c-.62,0-1.17.15-1.64.45-.47.3-.83.73-1.09,1.31-.26.57-.38,1.28-.38,2.13,0,1.24.28,2.2.85,2.87.57.67,1.32,1.01,2.26,1.01Z"/>
              <path fill={`${dataPost.colors[2]}`} d="m150.17,830c-.69,0-1.31-.13-1.86-.4-.55-.27-.98-.64-1.3-1.11-.32-.47-.48-1-.48-1.58,0-.75.19-1.33.57-1.76.38-.43,1.01-.73,1.89-.91.88-.18,2.08-.27,3.6-.27h.93v1.1h-.91c-1.12,0-2,.05-2.64.16s-1.09.28-1.35.53c-.26.25-.38.6-.38,1.06,0,.57.2,1.03.59,1.39.39.36.93.54,1.61.54.55,0,1.04-.13,1.46-.39.42-.26.75-.62,1-1.08.24-.46.36-.98.36-1.58v-2.36c0-.86-.17-1.48-.52-1.86-.35-.38-.91-.57-1.7-.57-.48,0-.97.06-1.45.19-.48.12-1,.33-1.54.6-.19.1-.36.13-.5.09-.14-.03-.25-.11-.33-.22-.08-.11-.13-.24-.15-.38-.01-.15.02-.29.09-.43.08-.14.2-.25.36-.33.61-.3,1.21-.53,1.8-.66.59-.14,1.16-.21,1.7-.21.87,0,1.59.14,2.16.43.57.28.99.71,1.27,1.29.28.57.41,1.31.41,2.21v5.62c0,.28-.07.49-.2.64-.13.15-.32.23-.57.23-.26,0-.46-.08-.6-.23-.14-.15-.21-.37-.21-.64v-1.62h.19c-.11.53-.32.97-.63,1.35-.31.37-.69.66-1.15.87-.46.21-.97.31-1.54.31Z"/>
            </g>
          </g>
          <circle fill={`${dataPost.colors[1]}`} cx="99.5" cy="510.5" r="94.5" style={{stroke: 'none'}}/>
        </svg>
      </div>

      <img
        src={imgPostLogo}
        alt="logo" className="logo text bl"
        style={{left: '8%', bottom: ' 21%'}}
      />

      {
        dataPost.text[0] && (
          <div
            className="text br py-1 px-2 f-2 clamp-3 text-end"
            style={{color: dataPost.colors[2], width: '50%',
            fontSize: '26px', right: '6%', bottom: ' 18%', lineHeight: '29px'
          }}
          >{dataPost.text[0]}</div>
        )
      }
      {
        dataPost.text[1] && (
          <div
            className="w-content text  f-2 cl text-center"
            style={{color: dataPost.colors[2], width: '37%',
              fontSize:'25px', left: '2%', bottom: '38%' ,
              textShadow: `2px 2px 0px ${dataPost.colors[0]}`
            }}
          >{convertAmount(dataPost.text[1])}</div>
        )
      }
      {
        dataPost.text[2] && (
          <div
            className="f-4 text tc text-truncate px-3"
            style={{color: dataPost.colors[2], fontSize: '17px',
            background: dataPost.colors[1], top: '2.7%'
            }}
          >{dataPost.text[2]}</div>
        )
      }
      {
        dataPost.text[3] && (
          <div
            className="f-4 text-end text br clamp-3 text-uppercase"
            style={{color: dataPost.colors[2], fontSize: '15px',
              right: '8%', bottom: '3.5%', width: '55%'
            }}
          >{dataPost.text[3]}</div>
        )
      }
      
    </div>
  );
};

export default State8;
