import { combineReducers, configureStore, getDefaultMiddleware } from "@reduxjs/toolkit"
import { persistReducer, persistStore } from "redux-persist"
import storage from "redux-persist/lib/storage"


import ux from "./reducer/interactions/index.js"
import log from "./reducer/admin/index.js"
import menu from "./reducer/menu/index.js"
import product from "./reducer/product/index.js"
import userReducer from "./reducer/user/index.js"
import post from "./reducer/post/index.js"
import orderLink from "./reducer/order/index.js"
import ordering from "./reducer/ordering/index.js"
import sudo from "./reducer/sudo/index.js"


const userPersistConfig = {
  key: 'user',
  storage: storage,
  whitelist: ['order', 'favoriteStorage'] // Solo persistir estas dos variables de estado
}

const persistedUserReducer = persistReducer(userPersistConfig, userReducer)

// Combinar reducers
const reducer = combineReducers({
  ux, 
  log, 
  menu, 
  product, 
  user: persistedUserReducer,
  post, 
  orderLink, 
  ordering, 
  sudo
})


const store = configureStore({
  reducer,
  middleware: getDefaultMiddleware({
    serializableCheck: false
  })
})


const persistor = persistStore(store)

export { store, persistor }
