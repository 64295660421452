import { doc, setDoc, collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../../../firebase/credentials";

const userRegister = async(data, date) => {
  // Concatenar country y telRegister para formar el valor de 'c'
  const concatValue = data.country + data.telRegister;

  try {
    // Crear una referencia a la colección 'mycustomers'
    const myCustomersRef = collection(db, "mycustomers");

    // Crear una consulta para buscar documentos donde el campo 'c' coincida con concatValue
    const queryByConcat = query(myCustomersRef, where("c", "==", concatValue));
    const querySnapshotByConcat = await getDocs(queryByConcat);

    // Crear una consulta para buscar documentos donde el campo 'e' coincida con data.emailRegister
    const queryByEmail = query(myCustomersRef, where("e", "==", data.emailRegister));
    const querySnapshotByEmail = await getDocs(queryByEmail);

    // Si se encuentra algún documento en cualquiera de las consultas, retornar false
    if (!querySnapshotByConcat.empty || !querySnapshotByEmail.empty) {
      return false;
    }

    // Si no se encuentra ningún documento, proceder con el registro
    await setDoc(doc(db, "register", data.telRegister), {
      name: data.fullNameRegister,
      country: data.country,
      tel: data.telRegister,
      email: data.emailRegister,
      date: date,
      solve: false,
    });

    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export default userRegister;
