import React from 'react'
import { useSelector } from 'react-redux';
import { convertAmount } from '../../../../tools/format';


const State15 = () => {

  const {dataPost, imgPostLogo, imgProduct} = useSelector(s => s.post)
  

  return (
    <div className="poster">
      <div className="img" style={{
        height:' 58%'
      }}>
        {
          imgProduct && (
            <img
              src={imgProduct}
              className='b7'
              alt="img" />
          )
        }
        <div className="overlay" style={{background: dataPost.colors[2], opacity: .2}}/>
      </div>
      <div className="svg">
        <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 490 870">
          <path fill={`${dataPost.colors[1]}`}  d="m357.45.44l86.2,140.95c71.6,117.08,34.74,270.04-82.35,341.65h0c-117.08,71.6-270.04,34.74-341.65-82.34L0,368.56v501.88h490V.44h-132.55Z"/>
          <path fill={`${dataPost.colors[0]}`}  d="m428.29,150.79c16.24,26.55,26.79,55.48,31.36,85.98,4.42,29.47,3.1,59.17-3.92,88.29-7.02,29.11-19.38,56.15-36.73,80.38-17.96,25.07-40.54,46.02-67.09,62.25-36.26,22.17-77.76,33.89-120.02,33.89-38.8,0-77.28-9.85-111.27-28.49-35.03-19.2-64.63-47.48-85.6-81.78L0,334.06v34.5l19.65,32.14c46.86,76.62,128.55,118.88,212.23,118.88,44.19,0,88.95-11.79,129.42-36.54h0c117.08-71.6,153.95-224.57,82.35-341.65L357.45.44h-21.1l91.95,150.34Z"/>
          <path fill={`${dataPost.colors[0]}`}  d="m178.44,556.33H0v227.12h178.44c62.72,0,113.56-50.84,113.56-113.56h0c0-62.72-50.84-113.56-113.56-113.56Z"/>
          <polygon fill={`${dataPost.colors[0]}`}  points="245 822.99 227.35 840.65 230.43 843.73 245 829.16 259.57 843.73 262.65 840.65 245 822.99"/>
        </svg>
      </div>

      <img
        src={imgPostLogo}
        alt="logo" className="logo text bl"
        style={{left: '6%', bottom: '25%'}}
      />

      {
        dataPost.text[0] && (
          <div
            className="text tl  px-2 f-10 clamp-2"
            style={{color: dataPost.colors[0], fontSize: '29px',
            width: '70%', margin:'5%', lineHeight: '39px',
            textShadow: `0 0 10px ${dataPost.colors[2]}`
          }}
          >{dataPost.text[0]}</div>
        )
      }
      {
        dataPost.text[1] && (
          <div
            className="w-content text f-10 bl"
            style={{color: dataPost.colors[2],
              fontSize:'31px', bottom: '13%', left: '6.5%'
              
            }}
          >{convertAmount(dataPost.text[1])}</div>
        )
      }
      {
        dataPost.text[2] && (
          <div
            className="text-truncate text px-3 f-4 cr"
            style={{color: dataPost.colors[2],
              fontSize:'17px',right: '5%', background: dataPost.colors[0],
              borderRadius: '30px'
              
            }}
          >{dataPost.text[2]}</div>
        )
      }
      {
        dataPost.text[3] && (
          <div
            className="w-content text f-4 bl"
            style={{color: dataPost.colors[2], width: '55%',
              fontSize:'18px', bottom: '19%', left: '6.5%'
              
            }}
          >{dataPost.text[3]}</div>
        )
      }
      {
        dataPost.text[4] && (
          <div
            className="clamp-4 text text-end f-4 br"
            style={{color: dataPost.colors[0],
              fontSize:'15px',right: '4%',
              width: '35%', marginBottom: '28%'
              
              
            }}
          >{dataPost.text[4]}</div>
        )
      }
    
    </div>
  );
};

export default State15;
