import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const Layout = ({ children, title, logoMenu, titleOg, descriptionOg }) => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>{title || 'Fooder - Digitaliza tus comidas'}</title>
        <link href={logoMenu} rel="icon" type="image/png" sizes="16x16" />
        <link href={logoMenu} rel="icon" type="image/png" sizes="32x32" />
        <meta property="og:title" content={titleOg} />
        <meta property="og:description" content={descriptionOg} />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="es_CO" />
      </Helmet>
      {children}
    </HelmetProvider>
  );
};

export default Layout;
