import React, { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import LateralSudo from './LateralSudo';
import HeaderSudo from './HeaderSudo';
import NotificationSect from './Notifications/NotificationSect';
import ErrorsSection from './Errors/ErrorsSection';
import getUserRegistered from './Notifications/fx/getUserRegistered.js';
import { useDispatch } from 'react-redux';
import { getAllErrors, getAllUsers, setAllCustomers } from '../../redux/reducer/sudo';
import getErrors from './Errors/fx/getErrors';
import ModalDeleteRequest from './Notifications/ModalDeleteRequest';
import Notification from '../admin/dashboard/Notification';
import ModalCreateUser from './Notifications/ModalCreateUser';
import FooderCustomers from './Customers/FooderCustomers';
import getMyCustumers from './Customers/fx/getMyCustumers';
import ModalDeleteFooderUser from './Customers/ModalDeleteFooderUser';
import ModalSolveIssue from './Errors/ModalSolveIssue';

const Panel = () => {
  const dispatch = useDispatch();

  const contentList = [
    { component: <FooderCustomers />, class: 'personal', active: true },
    { component: <NotificationSect />, class: 'novedades', active: false },
    { component: <ErrorsSection />, class: 'state', active: false },
  ];

  useEffect(() => {
    const unsubscribe = getUserRegistered((response) => {
      if (response) {
        dispatch(getAllUsers(response));
        console.log('Actualizando solicitudes de registro');
      }
    });

    return () => unsubscribe();
  }, [dispatch]);

  useEffect(() => {
    const unsubscribeErrors = getErrors((response) => {
      if (response) {
        dispatch(getAllErrors(response));
        console.log('Actualizando errores');
      }
    });

    return () => unsubscribeErrors();
  }, [dispatch]);

  useEffect(() => {
    const getFooderUsers = getMyCustumers((response) => {
      if (response) {
        dispatch(setAllCustomers(response));
        console.log('Llamando lista de usuarios Fooder');
      }
    });

    return () => getFooderUsers();
  }, [dispatch]);

  return (
    <HelmetProvider>
      <Helmet>
        <title>DON GATO</title>
      </Helmet>
      <div className="dashpage">
        <main>
          <LateralSudo />
        </main>
        <aside>
          <HeaderSudo />
          <div className="tab-content">
            {contentList.map((el, index) => (
              <div
                key={`${index}-tab`}
                className={`tab-pane fade ${el.active ? 'active show' : ''}`}
                id={`${el.class}-tab-pane`}
                role="tabpanel"
                aria-labelledby={`${el.class}-tab`}
                tabIndex="0"
              >
                {el.component}
              </div>
            ))}
          </div>
        </aside>
      </div>
      <ModalDeleteRequest />
      <ModalCreateUser />
      <ModalDeleteFooderUser />
      <ModalSolveIssue />
      <Notification />
    </HelmetProvider>
  );
};

export default Panel;
