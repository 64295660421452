import { db } from "../credentials"
import { setDoc, doc } from "firebase/firestore"


//update one item into any collection refered
async function updateOnlyOnePiece(userID, collection, character, newValue) {
  const docRef = doc(db, collection, userID)
  try {
    const updateObject = {
      [character]: newValue,
    }
    await setDoc(docRef, updateObject, { merge: true })
  } catch (error) {
    console.log(`Error en la actualización de datos ${character}`, error)
  }
}

export default updateOnlyOnePiece