import React from 'react'
import { useSelector } from 'react-redux';
import { convertAmount } from '../../../../tools/format';


const Post5 = () => {

  const {dataPost, imgPostLogo, imgProduct} = useSelector(s => s.post)
  
  
  return (
    <div className="poster">
      <div className="img" style={{height: '66.7%', top: '33.3%'}}>
        {
          imgProduct && (
            <img
              src={imgProduct}
              className='b7'
              style={{top: '30%'}}
              alt="img" />
          )
        }
      </div>
      <div className="svg">
        <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">
          <rect fill={`${dataPost.colors[0]}`}x="1" width="1024" height="343"/>
          <polygon fill={`${dataPost.colors[3]}`} points="777 1 777 153 877 124.48 977 153 977 1 777 1"/>
          <rect fill={`${dataPost.colors[1]}`}  y="734" width="431.62" height="133"/>
          <path fill={`${dataPost.colors[3]}`} d="m60.86,149.23c3.24,0,5.86-2.63,5.86-5.86V0h-11.73v143.37c0,3.24,2.63,5.86,5.86,5.86Z"/>
          <path fill={`${dataPost.colors[3]}`} d="m86.27,0v112.1c0,3.24,2.63,5.86,5.86,5.86h0c3.24,0,5.86-2.63,5.86-5.86V0h-11.73Z"/>
          <path fill={`${dataPost.colors[3]}`}  d="m973.14,735.77c-3.24,0-5.86,2.63-5.86,5.86v283.37h11.73v-283.37c0-3.24-2.63-5.86-5.86-5.86Z"/>
          <path fill={`${dataPost.colors[3]}`}  d="m947.73,1025v-252.1c0-3.24-2.63-5.86-5.86-5.86h0c-3.24,0-5.86,2.63-5.86,5.86v252.1h11.73Z"/>
        </svg>
      </div>

      <img
        src={imgPostLogo}
        alt="logo" className="logo text "
        style={{right: '6.4%', top: '1.5%'}}
      />

      {
        dataPost.text[0] && (
          <div
            className="text text-center f-5 text-uppercase clamp-2"
            style={{color: dataPost.colors[2], top: ' 19%',
            fontSize: '27px', lineHeight: '29px'
          }}
          >{dataPost.text[0]}</div>
        )
      }
      {
        dataPost.text[1] && (
          <div
            className="text text-center f-5 "
            style={{color: dataPost.colors[2], width: '42%' ,
              fontSize:'34px', bottom: '16%',
              left:'0%'
            }}
          >{convertAmount(dataPost.text[1])}</div>
        )
      }
      {
        dataPost.text[2] && (
          <div
            className="f-3 text tc text-truncate p-2"
            style={{color: dataPost.colors[2], top: '5%', maxWidth: '50%',
              border: `1px solid ${dataPost.colors[2]}`, fontSize: '15px'
            }}
          >{dataPost.text[2]}</div>
        )
      }
    </div>
  );
};

export default Post5;
