import React from 'react'
import { useSelector } from 'react-redux';
import { convertAmount } from '../../../../tools/format';


const State23 = () => {

  const {dataPost, imgPostLogo, imgProduct} = useSelector(s => s.post)
  

  return (
    <div className="poster">
      <div className="img" style={{
        left: '6.5%', top: '3%', width: '87%', height: '50%'
      }}>
        {
          imgProduct && (
            <img
              src={imgProduct}
              className='b7'
              alt="img" />
          )
        }
      </div>
      <div className="svg">
        <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 490 870">
          <path fill={`${dataPost.colors[0]}`} d="m0,0v870h490V0H0Zm237.5,452H34.5v-196h203v196Zm0-211H34.5V29h203v212Zm218,211h-203v-196h203v196Zm0-211h-203V29h203v212Z"/>
        </svg>
      </div>

      <img
        src={imgPostLogo}
        alt="logo" className="logo text br" style={{
          margin: '6.8%'
        }}
      />

      {
        dataPost.text[0] && (
          <div
            className="text tl px-2 f-2 clamp-2"
            style={{color: dataPost.colors[1], left: ' 5%',
            fontSize: '30px', width: '65%', top: ' 55%',
            lineHeight: '34px'
          }}
          >{dataPost.text[0]}</div>
        )
      }
      {
        dataPost.text[1] && (
          <div
            className="w-content text f-4 bl"
            style={{color: dataPost.colors[1],
              fontSize: '30px', letterSpacing: ' 3px', left: '7%',
              bottom: '8%'
            }}
          >{convertAmount(dataPost.text[1])}</div>
        )
      }
      {
        dataPost.text[2] && (
          <div
            className="text-end clamp-3 text f-4 tr"
            style={{color: dataPost.colors[1],
              fontSize: '18px', right: '8%',
              top: '67%', width: '55%', lineHeight: '24px'
            }}
          >{dataPost.text[2]}</div>
        )
      }
      {
        dataPost.text[3] && (
          <div
            className="text-truncate text f-4 bl text-uppercase"
            style={{color: dataPost.colors[1],
              fontSize: '18px', left: '7%',
              bottom: '3.7%', width: '60%', lineHeight: ' 1.875rem'
            }}
          >{dataPost.text[3]}</div>
        )
      }
     
    
    </div>
  );
};

export default State23;
