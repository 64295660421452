import React from 'react'
import { useSelector } from 'react-redux';
import { convertAmount } from '../../../../tools/format';


const State24 = () => {

  const {dataPost, imgPostLogo, imgProduct} = useSelector(s => s.post)
  

  return (
    <div className="poster">
      <div className="img">
        {
          imgProduct && (
            <img
              src={imgProduct}
              className='b7'
              alt="img" />
          )
        }
      </div>
      <div className="svg">
        
        <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 490 870">
          <g>
            <path fill={`${dataPost.colors[1]}`}  d="m371.13,45.62c41.84,17.7,81.54,39.18,118.87,64.27V0H215.14c53.44,9.08,105.61,24.31,155.99,45.62Z"/>
            <path fill={`${dataPost.colors[1]}`}  d="m319.14,473.62c-59.61-59.61-138.87-92.44-223.18-92.44-33.06,0-65.33,5.07-95.96,14.8v474.03h359.91c33.62-51,51.67-110.71,51.67-173.21,0-84.31-32.83-163.57-92.44-223.18Z"/>
          </g>
          <rect fill={`${dataPost.colors[0]}`}  x="181" y="377" width="297" height="133" rx="66.5" ry="66.5"/>
          <polygon fill={`${dataPost.colors[2]}`}  points="441.47 24.55 423.82 42.2 426.9 45.28 441.47 30.72 456.04 45.28 459.12 42.2 441.47 24.55"/>
        </svg>
      </div>

      <img
        src={imgPostLogo}
        alt="logo" className="logo text cl"
        style={{left: '7.6%', marginTop: '4%'}}
      />

      {
        dataPost.text[0] && (
          <div
            className="text tl px-2 f-10 clamp-3"
            style={{color: dataPost.colors[2], fontSize: '33px',
            left: '3%', top: '62%', width: '70%', lineHeight: '36px',
            padding: ' 3px 0'
          }}
          >{dataPost.text[0]}</div>
        )
      }
      {
        dataPost.text[1] && (
          <div
            className="w-content text px-3 f-9 cr text-center text-truncate"
            style={{color: dataPost.colors[2], fontSize: '33px', right: '3%',
            width: '60%', marginTop: '12%'
            }}
          >{convertAmount(dataPost.text[1])}</div>
        )
      }
      {
        dataPost.text[2] && (
          <div
            className="w-content text f-9 cr text-center text-truncate"
            style={{color: dataPost.colors[2], fontSize: '16px', right: '3%',
            width: '60%', marginTop: ' -9%'
            }}
          >{dataPost.text[2]}</div>
        )
      }
      {
        dataPost.text[3] && (
          <div
            className="text f-9 tl clamp-3"
            style={{color: dataPost.colors[2], fontSize: '17px', left: '5%',
            width: '70%', top: ' 83%'
            }}
          >{dataPost.text[3]}</div>
        )
      }
     
    
    </div>
  );
};

export default State24;
