import React from 'react'
import logo1 from '../../../../img/dashboard/fooder_min_dashboard.svg'
import logo2 from "../../../../img/dashboard/logo_fooder_short_2.svg"
import logo3 from "../../../../img/dashboard/logo_fooder_short_3.svg"
import { useDispatch, useSelector } from 'react-redux'
import { setInteriorMenu } from '../../../../redux/reducer/interactions'
import {setResetPriceMultiple,setProductSelected,setCounter,setComment,setPriceOne, setOrderProducts, setModifyOrder, setCleanOrder, setDataClient, setOrderMethods} from '../../../../redux/reducer/ordering'

const HeaderOrder = () => {

  const {cuota} = useSelector(s => s.log)
  const dispatch = useDispatch()

  const handleClose = ()=> {
    dispatch(setInteriorMenu(false))
    dispatch(setResetPriceMultiple())
    dispatch(setProductSelected(null))
    dispatch(setCounter(1))
    dispatch(setComment(''))
    dispatch(setPriceOne(0))
    dispatch(setOrderProducts([])) 
    dispatch(setModifyOrder(false))
    dispatch(setCleanOrder())
    dispatch(setDataClient({}))
    dispatch(setOrderMethods({}))
    if(document.getElementById('resetModalOrderInMenuM')){document.getElementById('resetModalOrderInMenuM').click()}
  }

  return (
    <header className='headerOrderInDashboard'>
      {cuota === 1 ? <img src={logo1} alt="" className='img-fluid'/>: null}
      {cuota === 2 ? <img src={logo2} alt="" className='img-fluid'/>: null}
      {cuota === 3 ? <img src={logo3} alt="" className='img-fluid'/>: null}
      <h6>Lista de productos</h6>
      <button  onClick={handleClose}/>
    </header>
  )
}

export default HeaderOrder