import React from 'react'

const Vision = ({counterVision,vision}) => {

  return (
    <>
      <div className="bg-b1 p-3 rounded-lg">
        <div className="d-flex align-items-center justify-content-between">
          <p className="text-bold text-apple m-0">Visión <small className='text-white'>(No dejes espacios en blanco)</small></p>
          <span className='optionalLabel'>Opcional</span>
        </div>
        <textarea
          className={`textareaDash mt-3 ${counterVision > 300 ? 'text-orange': ''}`}
          name="whoAmI"
          rows="5"
          placeholder='¿Cómo se ve tu empresa en el futuro? ó ¿Qué logros o resultados deseas alcanzar?'
          {...vision}
        ></textarea>
        <div className="text-end">
          <span className={`${counterVision > 300 ? 'text-orange': ''}`}>{counterVision}/300</span>
        </div>
      </div>
    </>
  )
}

export default Vision