import React from 'react'
import { useSelector } from 'react-redux';
import { convertAmount } from '../../../../tools/format';


const State17 = () => {

  const {dataPost, imgPostLogo, imgProduct} = useSelector(s => s.post)
  

  return (
    <div className="poster">
      <div className="img" style={{
        left: '12%', top: '13%', width: '68%', height: '59%'
      }}>
        {
          imgProduct && (
            <img
              src={imgProduct}
              className='b7'
              alt="img" />
          )
        }
      </div>
      <div className="svg">
        <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 490 870">
          <path fill={`${dataPost.colors[0]}`}  d="m0,0v870h490V0H0Zm390,619H60V121h330v498Z"/>
          <g fill={`${dataPost.colors[2]}`}>
            <rect fill={`${dataPost.colors[2]}`} x="38" y="315.06" width="22" height="155.94"/>
            <rect fill={`${dataPost.colors[2]}`} x="390" y="315.06" width="63" height="155.94"/>
          </g>
          <polygon fill={`${dataPost.colors[2]}`} points="430.47 499.55 412.82 517.2 415.9 520.28 430.47 505.72 445.04 520.28 448.12 517.2 430.47 499.55"/>
          <polygon fill={`${dataPost.colors[2]}`} points="430.47 532.55 412.82 550.2 415.9 553.28 430.47 538.72 445.04 553.28 448.12 550.2 430.47 532.55"/>
          <polygon fill={`${dataPost.colors[2]}`} points="430.47 565.55 412.82 583.2 415.9 586.28 430.47 571.72 445.04 586.28 448.12 583.2 430.47 565.55"/>
          <g>
            <circle fill={`${dataPost.colors[0]}`}  cx="405.09" cy="227.11" r="3.35"/>
            <circle fill={`${dataPost.colors[0]}`}  cx="434.17" cy="227.11" r="3.35"/>
            <circle fill={`${dataPost.colors[0]}`}  cx="405.09" cy="254.7" r="3.35"/>
            <circle fill={`${dataPost.colors[0]}`}  cx="434.17" cy="254.7" r="3.35"/>
            <circle fill={`${dataPost.colors[0]}`}  cx="405.09" cy="338.28" r="3.35"/>
            <circle fill={`${dataPost.colors[0]}`}  cx="434.17" cy="338.28" r="3.35"/>
            <circle fill={`${dataPost.colors[0]}`}  cx="405.09" cy="365.87" r="3.35"/>
            <circle fill={`${dataPost.colors[0]}`}  cx="434.17" cy="365.87" r="3.35"/>
            <circle fill={`${dataPost.colors[0]}`}  cx="405.09" cy="393.45" r="3.35"/>
            <circle fill={`${dataPost.colors[0]}`}  cx="434.17" cy="393.45" r="3.35"/>
          </g>
          <polygon fill={`${dataPost.colors[1]}`}   points="38 41 38 275.06 60 275.06 60 121 390 121 390 275.06 453 275.06 453 41 38 41"/>
          <polygon fill={`${dataPost.colors[2]}`} points="60 281 54 281 54 62 365 62 365 121 367 121 367 60 52 60 52 283 60 283 60 281"/>
        </svg>
      </div>

      <img
        src={imgPostLogo}
        alt="logo" className="logo text br"
        style={{right: '8.65%', bottom: '18%'}}
      />

      {
        dataPost.text[0] && (
          <div
            className="text tl  py-1 px-2 f-2 clamp-2"
            style={{color: dataPost.colors[2], lineHeight: '26px' ,
            fontSize: '24px', left: '4%', top: '73%', width: '60%'
          }}
          >{dataPost.text[0]}</div>
        )
      }
      {
        dataPost.text[1] && (
          <div
            className="w-content text px-3 f-3 tl"
            style={{color: dataPost.colors[2],
              fontSize: ' 20px', letterSpacing: ' 4px', left: '12%',
              top: '8.3%'
            }}
          >{convertAmount(dataPost.text[1])}</div>
        )
      }
      {
        dataPost.text[2] && (
          <div
            className="f-3 text tc clamp-2 text-uppercase text-center"
            style={{color: dataPost.colors[2], fontSize: ' 16px', top: '86%',
            width:'85%'
            }}
          >{dataPost.text[2]}</div>
        )
      }
     
    </div>
  );
};

export default State17;
