import React from 'react'
import { useSelector } from 'react-redux';
import { convertAmount } from '../../../../tools/format';


const Post9 = () => {

  const {dataPost, imgPostLogo, imgProduct} = useSelector(s => s.post)
  
  
  return (
    <div className="poster">
      <div className="img" style={{width: '80%', height: '58%', top: ' 14%', left: '10%'}}>
        {
          imgProduct && (
            <img
              src={imgProduct}
              className='b7'
              alt="img" />
          )
        }
      </div>
      
      <div className="svg">
        <svg data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1023.75 1024">
          <polygon fill={`${dataPost.colors[0]}`}  points="106.3 734.14 106.3 146.14 511.25 146.14 511.25 0 -1.25 0 -1.25 1024 511.25 1024 511.25 734.14 106.3 734.14"/>
          <polygon fill={`${dataPost.colors[2]}`}  points="511.25 0 511.25 146.14 915.3 146.14 915.3 734.14 511.25 734.14 511.25 1024 1023.75 1024 1023.75 0 511.25 0"/>
          <path fill={`${dataPost.colors[1]}`}  d="m931.75,750H89.75V131h842v619Zm-826-16h810V147H105.75v587Z"/>
          <polygon fill={`${dataPost.colors[1]}`}  points="993.75 66.79 953.75 43.7 953.75 89.89 993.75 66.79"/>
          <polygon fill={`${dataPost.colors[1]}`}  points="953.75 97.73 993.75 120.82 993.75 74.63 953.75 97.73"/>
          <polygon fill={`${dataPost.colors[1]}`}  points="993.75 128.66 953.75 105.57 953.75 151.75 993.75 128.66"/>
          <polygon fill={`${dataPost.colors[1]}`}  points="953.75 159.59 993.75 182.69 993.75 136.5 953.75 159.59"/>
          <polygon fill={`${dataPost.colors[1]}`}  points="993.75 190.53 953.75 167.43 953.75 213.62 993.75 190.53"/>
          <polygon fill={`${dataPost.colors[1]}`}  points="953.75 221.46 993.75 244.55 993.75 198.37 953.75 221.46"/>
          <polygon fill={`${dataPost.colors[1]}`}  points="442.75 884.19 510.75 751 511.25 1024 442.75 884.19"/>
        </svg>
      </div>

      <img
        src={imgPostLogo}
        alt="logo" className="logo text tc "
        style={{
          marginTop: '1.7%'          
        }}
      />

      {
        dataPost.text[0] && (
          <div
            className="text br  px-1 f-2 clamp-2 text-uppercase text-end"
            style={{color: dataPost.colors[1], lineHeight: ' 21px', width: '42%',
            fontSize: '19px', marginRight: '8%', marginBottom: '14%'
          }}
          >{dataPost.text[0]}</div>
        )
      }
      {
        dataPost.text[1] && (
          <div
            className="w-content text px-3 f-2 bl"
            style={{color: dataPost.colors[1], fontSize:'26px',
              marginLeft: ' 4.5%', marginBottom: '9%'
            }}
          >{convertAmount(dataPost.text[1])}</div>
        )
      }
      {
        dataPost.text[2] && (
          <div
            className="f-3 text br text-truncate px-2"
            style={{color: dataPost.colors[1], fontSize: '13px',
            border: `1px solid ${dataPost.colors[1]}`, marginRight: '8%',
            marginBottom: '6%', maxWidth: '40%'
            }}
          >{dataPost.text[2]}</div>
        )
      }
    </div>
  );
};

export default Post9;
