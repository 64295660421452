import React from 'react'
import MainFreeTheme from '../../freetemplate/MainFreeTheme'
import BgMenu from '../fastfood2/BgMenu'


const FastFood2 = () => {
  return (
    <MainFreeTheme
      bgMenu={<BgMenu />}
    />
  )
}

export default FastFood2