import { db } from "../credentials"
import { doc, updateDoc } from "firebase/firestore"

const discountCredits = async (userID, credits, cuota)=>{
  const creditsDiscounted = credits - cuota

  const ref = doc(db, 'admin', userID)
  await updateDoc(ref, {
    c: creditsDiscounted
  })

}

export default discountCredits