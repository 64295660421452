import React from 'react'
import { useSelector } from 'react-redux';
import { convertAmount } from '../../../../tools/format';


const Post24 = () => {

  const {dataPost, imgPostLogo, imgProduct} = useSelector(s => s.post)
  

  return (
    <div className="poster">
      <div className="img" style={{
        width: '96%', height: '53%', left: ' 2.4%', top: '2%'
      }}>
        {
          imgProduct && (
            <img
              src={imgProduct}
              className='b7'
              alt="img" />
          )
        }
      </div>
      <div className="svg">
        <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">
          <g>
            <rect fill={`${dataPost.colors[0]}`}  x="27" y="559.61" width="970" height="432"/>
            <path fill={`${dataPost.colors[1]}`}  d="m982,976.61H42V36.61h940v940Zm-938-2h936V38.61H44v936Z"/>
            <path fill={`${dataPost.colors[1]}`}  d="m112.5,458.4c-31.59,0-57.29-25.7-57.29-57.29s25.7-57.29,57.29-57.29,57.29,25.7,57.29,57.29-25.7,57.29-57.29,57.29Zm0-113c-30.72,0-55.71,24.99-55.71,55.71s24.99,55.71,55.71,55.71,55.71-24.99,55.71-55.71-24.99-55.71-55.71-55.71Z"/>
            <path fill={`${dataPost.colors[1]}`}  d="m112.5,538.4c-31.59,0-57.29-25.7-57.29-57.29s25.7-57.29,57.29-57.29,57.29,25.7,57.29,57.29-25.7,57.29-57.29,57.29Zm0-113c-30.72,0-55.71,24.99-55.71,55.71s24.99,55.71,55.71,55.71,55.71-24.99,55.71-55.71-24.99-55.71-55.71-55.71Z"/>
            <path fill={`${dataPost.colors[1]}`}  d="m112.5,618.4c-31.59,0-57.29-25.7-57.29-57.29s25.7-57.29,57.29-57.29,57.29,25.7,57.29,57.29-25.7,57.29-57.29,57.29Zm0-113c-30.72,0-55.71,24.99-55.71,55.71s24.99,55.71,55.71,55.71,55.71-24.99,55.71-55.71-24.99-55.71-55.71-55.71Z"/>
            <path fill={`${dataPost.colors[1]}`}  d="m112.5,698.4c-31.59,0-57.29-25.7-57.29-57.29s25.7-57.29,57.29-57.29,57.29,25.7,57.29,57.29-25.7,57.29-57.29,57.29Zm0-113c-30.72,0-55.71,24.99-55.71,55.71s24.99,55.71,55.71,55.71,55.71-24.99,55.71-55.71-24.99-55.71-55.71-55.71Z"/>
            <g>
              <rect fill={`${dataPost.colors[1]}`}  x="882" y="537.95" width="13.62" height="13.62"/>
              <rect fill={`${dataPost.colors[1]}`}  x="914.69" y="537.95" width="13.62" height="13.62"/>
              <rect fill={`${dataPost.colors[1]}`}  x="947.38" y="537.95" width="13.62" height="13.62"/>
              <rect fill={`${dataPost.colors[1]}`}  x="882" y="562.47" width="13.62" height="13.62"/>
              <rect fill={`${dataPost.colors[1]}`}  x="914.69" y="562.47" width="13.62" height="13.62"/>
              <rect fill={`${dataPost.colors[1]}`}  x="947.38" y="562.47" width="13.62" height="13.62"/>
              <rect fill={`${dataPost.colors[1]}`}  x="882" y="586.98" width="13.62" height="13.62"/>
              <rect fill={`${dataPost.colors[1]}`}  x="914.69" y="586.98" width="13.62" height="13.62"/>
              <rect fill={`${dataPost.colors[1]}`}  x="947.38" y="586.98" width="13.62" height="13.62"/>
            </g>
            <rect fill={`${dataPost.colors[1]}`}  x="456" y="700.61" width="112" height="8"/>
          </g>
          <path fill={`${dataPost.colors[1]}`}  d="m0,0v1024h1024V0H0Zm997,560v432H27V22h970v538Z"/>
          <polygon fill={`${dataPost.colors[1]}`}  points="801 22 801 174 901 145.48 1001 174 1001 22 801 22"/>
        </svg>
      </div>

      <img
        src={imgPostLogo}
        alt="logo" className="logo text tr"
        style={{right: '3.5%', top: '3.3%'}}
      />

      {
        dataPost.text[0] && (
          <div
            className="text bc text-center px-1 f-7 text-truncate"
            style={{color: dataPost.colors[1], bottom: '33%',
            fontSize: '25px', width: '92%'
            }}
          >{dataPost.text[0]}</div>
        )
      }
      {
        dataPost.text[1] && (
          <div
            className="w-content text px-2 f-7 bc"
            style={{color: dataPost.colors[1],
              fontSize:'30px', bottom: '6%'
            }}
          >{convertAmount(dataPost.text[1])}</div>
        )
      }
      {
        dataPost.text[2] && (
          <div
            className="f-3 text text-center bc clamp-2 text-uppercase"
            style={{color: dataPost.colors[1], fontSize: '12px',
              marginBottom: '17%', width: ' 60%'
            }}
          >{dataPost.text[2]}</div>
        )
      }
      
    
    </div>
  );
};

export default Post24;
