import React from 'react'
import { useSelector } from 'react-redux';
import { convertAmount } from '../../../../tools/format';


const State2 = () => {

  const {dataPost, imgPostLogo, imgProduct} = useSelector(s => s.post)
  

  return (
    <div className="poster">
      <div className="img" style={{
        width: '75%', height: '57.4%', top: '10%', left: '13%'
      }}>
        {
          imgProduct && (
            <img
              src={imgProduct}
              className='b7'
              alt="img" />
          )
        }
      </div>
      <div className="svg">
        <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 490 870">
          <path fill={`${dataPost.colors[0]}`}  d="m0,0v870h490V0H0Zm426,584H68V94h358v490Z"/>
          <rect fill={`${dataPost.colors[1]}`}  x="0" width="52" height="52"/>
          <rect fill={`${dataPost.colors[1]}`}  x="438" y="817.56" width="52" height="52"/>
        </svg>
      
      </div>

      <img
        src={imgPostLogo}
        alt="logo" className="logo text tc"
        style={{top: '2%'}}
      />

      {
        dataPost.text[0] && (
          <div
            className="text  px-2 f-13 clamp-2 text-center"
            style={{color: dataPost.colors[2], bottom: '16%',
            fontSize: '33px', lineHeight: '40px', width: '90%'
          }}
          >{dataPost.text[0]}</div>
        )
      }
      {
        dataPost.text[1] && (
          <div
            className="w-content text px-3 f-9 bc"
            style={{color: dataPost.colors[2],
              fontSize:'22px', letterSpacing: ' 5px', bottom: '10%'
              
            }}
          >{convertAmount(dataPost.text[1])}</div>
        )
      }
      {
        dataPost.text[2] && (
          <div
            className="f-9 text bc clamp-2 text-center"
            style={{color: dataPost.colors[2], fontSize: '15px',
              width: '80%', bottom: '2%'
            }}
          >{dataPost.text[2]}</div>
        )
      }
      {
        dataPost.text[3] && (
          <div
            className="text  py-1 px-3 f-3 text-truncate"
            style={{color: dataPost.colors[0], fontSize: '18px',
            bottom:'31%', background: dataPost.colors[2]}}
          >{dataPost.text[3]}</div>
        )
      }
    </div>
  );
};

export default State2;
