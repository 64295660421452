import React from 'react'
import { useSelector } from 'react-redux';
import { convertAmount } from '../../../../tools/format';


const State5 = () => {

  const {dataPost, imgPostLogo, imgProduct} = useSelector(s => s.post)
  

  return (
    <div className="poster">
      <div className="img" style={{
        width: '77.5%', height: '60%', left: '12%', top: '21%'
      }}>
        {
          imgProduct && (
            <img
              src={imgProduct}
              className='b7'
              alt="img" />
          )
        }
      </div>
      <div className="svg">
        <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 490 870">
          <path fill={`${dataPost.colors[2]}`}  d="m-.45-.94l-.5,873.94,491.45-1.5L491.55-1.94-.45-.94Zm435.95,702.44H62.5V186.5h373v515Z"/>
          <polygon fill={`${dataPost.colors[0]}`}  points="435.5 628.06 435.5 701.5 340.5 701.5 340.5 871.06 490.5 871.06 490.5 628.06 435.5 628.06"/>
          <g>
            <circle fill={`${dataPost.colors[1]}`} cx="17.51" cy="464.85" r="3.35"/>
            <circle fill={`${dataPost.colors[1]}`} cx="46.58" cy="464.85" r="3.35"/>
            <circle fill={`${dataPost.colors[1]}`} cx="17.51" cy="492.44" r="3.35"/>
            <circle fill={`${dataPost.colors[1]}`} cx="46.58" cy="492.44" r="3.35"/>
            <circle fill={`${dataPost.colors[1]}`} cx="17.51" cy="520.02" r="3.35"/>
            <circle fill={`${dataPost.colors[1]}`} cx="46.58" cy="520.02" r="3.35"/>
            <circle fill={`${dataPost.colors[1]}`} cx="17.51" cy="547.61" r="3.35"/>
            <circle fill={`${dataPost.colors[1]}`} cx="46.58" cy="547.61" r="3.35"/>
            <circle fill={`${dataPost.colors[1]}`} cx="17.51" cy="575.19" r="3.35"/>
            <circle fill={`${dataPost.colors[1]}`} cx="46.58" cy="575.19" r="3.35"/>
            <circle fill={`${dataPost.colors[1]}`} cx="17.51" cy="602.78" r="3.35"/>
            <circle fill={`${dataPost.colors[1]}`} cx="46.58" cy="602.78" r="3.35"/>
            <circle fill={`${dataPost.colors[1]}`} cx="17.51" cy="630.36" r="3.35"/>
            <circle fill={`${dataPost.colors[1]}`} cx="46.58" cy="630.36" r="3.35"/>
            <circle fill={`${dataPost.colors[1]}`} cx="17.51" cy="657.95" r="3.35"/>
            <circle fill={`${dataPost.colors[1]}`} cx="46.58" cy="657.95" r="3.35"/>
            <circle fill={`${dataPost.colors[1]}`} cx="17.51" cy="685.53" r="3.35"/>
            <circle fill={`${dataPost.colors[1]}`} cx="46.58" cy="685.53" r="3.35"/>
          </g>
          <polygon fill={`${dataPost.colors[0]}`}  points="62.5 186.5 216.5 186.5 216.5 -1.5 -.5 -1.5 -.5 306.5 62.5 306.5 62.5 186.5"/>
        </svg>
       
      </div>

      <img
        src={imgPostLogo}
        alt="logo" className="logo text bl"
        style={{left: '13%', bottom: '11%'}}
      />

      {
        dataPost.text[0] && (
          <div
            className="text tl text-uppercase  px-2 f-12 clamp-2"
            style={{color: dataPost.colors[1], left: '11%' ,
            fontSize: '33px', top: '7%', width: ' 78%', lineHeight: '36px'
          }}
          >{dataPost.text[0]}</div>
        )
      }
      {
        dataPost.text[1] && (
          <div
            className="w-content text f-4 br"
            style={{color: dataPost.colors[1],
              fontSize:'33px', right: '11%', bottom: '11%'
            }}
          >{convertAmount(dataPost.text[1])}</div>
        )
      }
      {
        dataPost.text[2] && (
          <div
            className="f-4 text bc clamp-2 text-center"
            style={{color: dataPost.colors[1], fontSize: '17px',
              bottom: '2%', width: '85%', lineHeight: '20px'
            }}
          >{dataPost.text[2]}</div>
        )
      }
   
    
    </div>
  );
};

export default State5;
