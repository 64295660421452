import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { resetAllPost} from '../../../../redux/reducer/post'
import Post1 from './allPost/Post1'
import FormPost from './FormPost'
import Post2 from './allPost/Post2'
import Post3 from './allPost/Post3'
import Post4 from './allPost/Post4'
import Post5 from './allPost/Post5'
import Post6 from './allPost/Post6'
import Post7 from './allPost/Post7'
import Post8 from './allPost/Post8'
import Post9 from './allPost/Post9'
import Post10 from './allPost/Post10'
import Post11 from './allPost/Post11'
import Post12 from './allPost/Post12'
import Post13 from './allPost/Post13'
import Post14 from './allPost/Post14'
import Post15 from './allPost/Post15'
import Post16 from './allPost/Post16'
import Post17 from './allPost/Post17'
import Post18 from './allPost/Post18'
import Post19 from './allPost/Post19'
import Post20 from './allPost/Post20'
import Post21 from './allPost/Post21'
import Post22 from './allPost/Post22'
import Post23 from './allPost/Post23'
import Post24 from './allPost/Post24'

const EditorPanel = () => {

  const {indexPost} = useSelector(s => s.post)
  
  const dispatch = useDispatch()
  const handleBack = ()=> dispatch(resetAllPost())

  return (
    <section className="panelEditor">
      <div className="container-fluid mb-5 mb-md-0 ">
        <div className="row">
          <div className="col-12 justify-content-center d-flex">
            <button className='btn-central mt-5 mt-md-4' onClick={handleBack}>Cerrar editor de Post</button>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-6 align-self-center">
            <FormPost />
          </div>
          <div className="col-md-6 align-self-center order-first order-md-last">
            <div className="d-flex justify-content-center">
              <div className="viewPost">
                {indexPost === 0 && <Post1 />}
                {indexPost === 1 && <Post2 />}
                {indexPost === 2 && <Post3 />}
                {indexPost === 3 && <Post4 />}
                {indexPost === 4 && <Post5 />}
                {indexPost === 5 && <Post6 />}
                {indexPost === 6 && <Post7 />}
                {indexPost === 7 && <Post8 />}
                {indexPost === 8 && <Post9 />}
                {indexPost === 9 && <Post10 />}
                {indexPost === 10 && <Post11 />}
                {indexPost === 11 && <Post12 />}
                {indexPost === 12 && <Post13 />}
                {indexPost === 13 && <Post14 />}
                {indexPost === 14 && <Post15 />}
                {indexPost === 15 && <Post16 />}
                {indexPost === 16 && <Post17 />}
                {indexPost === 17 && <Post18 />}
                {indexPost === 18 && <Post19 />}
                {indexPost === 19 && <Post20 />}
                {indexPost === 20 && <Post21 />}
                {indexPost === 21 && <Post22 />}
                {indexPost === 22 && <Post23 />}
                {indexPost === 23 && <Post24 />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default EditorPanel