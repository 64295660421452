import React from 'react'
import { useSelector } from 'react-redux';
import { convertAmount } from '../../../../tools/format';


const State20 = () => {

  const {dataPost, imgPostLogo, imgProduct} = useSelector(s => s.post)
  

  return (
    <div className="poster">
      <div className="img" style={{
        left: '4.5%', top: '2%', width: '91%', height: '76%'
      }}>
        {
          imgProduct && (
            <img
              src={imgProduct}
              className='b7'
              alt="img" />
          )
        }
      </div>
      <div className="svg">
        <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 490 870">
          <path fill={`${dataPost.colors[1]}`}  d="m0,0v870h490V0H0Zm465.5,674H24.5V21h441v653Z"/>
          <g>
            <rect fill={`${dataPost.colors[0]}`}  x="91" y="0" width="308" height="21"/>
            <rect fill={`${dataPost.colors[0]}`}  x="91" y="674" width="308" height="196"/>
          </g>
          <polygon fill={`${dataPost.colors[0]}`}  points="247 452 397 452 397 631 399 631 399 450 247 450 247 452"/>
          <g>
            <polygon fill={`${dataPost.colors[0]}`}  points="443.47 764.55 425.82 782.2 428.9 785.28 443.47 770.72 458.04 785.28 461.12 782.2 443.47 764.55"/>
            <polygon fill={`${dataPost.colors[0]}`}  points="443.47 790.55 425.82 808.2 428.9 811.28 443.47 796.72 458.04 811.28 461.12 808.2 443.47 790.55"/>
            <polygon fill={`${dataPost.colors[0]}`}  points="443.47 816.55 425.82 834.2 428.9 837.28 443.47 822.72 458.04 837.28 461.12 834.2 443.47 816.55"/>
          </g>
          <rect fill={`${dataPost.colors[2]}`}  x="24.55" y="484" width="346.45" height="357"/>
        </svg>
      </div>

      <img
        src={imgPostLogo}
        alt="logo" className="logo text tr"
        style={{margin: '7%'}}
      />

      {
        dataPost.text[0] && (
          <div
            className="text tl px-2 f-14 clamp-3"
            style={{color: dataPost.colors[0], lineHeight:'32px',
            fontSize: '28px', left: '8%', top: '57%', width: '65%'
          }}
          >{dataPost.text[0]}</div>
        )
      }
      {
        dataPost.text[1] && (
          <div
            className="text f-4 bl"
            style={{color: dataPost.colors[3], fontSize: '35px',
            left: '10%', bottom: '10%'
            }}
          >{convertAmount(dataPost.text[1])}</div>
        )
      }
      {
        dataPost.text[2] && (
          <div
            className="text f-4 bl text-uppercase text-truncate"
            style={{color: dataPost.colors[3], fontSize: '15px',
            left: '10%', bottom: '6%', width: '62%'
            }}
          >{dataPost.text[2]}</div>
        )
      }
    </div>
  );
};

export default State20;
