import React from 'react'
import { useSelector } from 'react-redux';
import { convertAmount } from '../../../../tools/format';



const State3 = () => {

  const {dataPost, imgPostLogo, imgProduct} = useSelector(s => s.post)
  

  return (
    <div className="poster">
      <div className="img">
        {
          imgProduct && (
            <img
              src={imgProduct}
              className='b7'
              alt="img" />
          )
        }
        <div className="overlay" style={{background: dataPost.colors[1], opacity: .1}}/>
      </div>
      <div className="svg">
        <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 490 870">
          <rect fill={`${dataPost.colors[2]}`}  x="43" y="446.44" width="404" height="156"/>
          <path fill={`${dataPost.colors[0]}`}  d="m490,712.59c-1.82-.1-3.65-.15-5.5-.15-56.61,0-102.5,45.89-102.5,102.5,0,20.26,5.88,39.15,16.03,55.06h91.97v-157.41Z"/>
          <path fill={`${dataPost.colors[2]}`}  d="m490,724.62c-1.82-.11-3.65-.17-5.5-.17-49.98,0-90.5,40.52-90.5,90.5,0,20.72,6.97,39.8,18.68,55.06h77.32v-145.38Z"/>
          <path fill={`${dataPost.colors[2]}`}  d="m0,108.86c7.42,2.33,15.31,3.58,23.5,3.58,43.35,0,78.5-35.15,78.5-78.5,0-12.16-2.77-23.67-7.7-33.94H0v108.86Z"/>
          <g>
            <polygon fill={`${dataPost.colors[1]}`}  points="443.51 777.78 432.34 788.95 434.29 790.9 443.51 781.68 452.74 790.9 454.69 788.95 443.51 777.78"/>
            <g>
              <path fill={`${dataPost.colors[1]}`} d="m410.06,813.02c-.32,0-.56-.08-.73-.25-.17-.17-.25-.41-.25-.73v-13.43c0-.32.08-.56.25-.73s.41-.25.73-.25h4.21c2.49,0,4.41.66,5.77,1.97,1.35,1.31,2.03,3.22,2.03,5.72,0,1.25-.17,2.36-.51,3.31-.34.95-.85,1.76-1.52,2.41-.67.66-1.48,1.15-2.45,1.49-.96.34-2.07.5-3.32.5h-4.21Zm.81-1.53h3.28c1.02,0,1.9-.13,2.65-.39.75-.26,1.38-.65,1.88-1.16.5-.51.88-1.15,1.12-1.93.25-.78.37-1.68.37-2.7,0-2.05-.5-3.59-1.51-4.62-1-1.03-2.51-1.54-4.52-1.54h-3.28v12.34Z"/>
              <path fill={`${dataPost.colors[1]}`} d="m429.83,813.21c-1.12,0-2.09-.22-2.89-.67-.81-.44-1.43-1.07-1.88-1.89-.44-.82-.67-1.79-.67-2.93s.22-2.07.66-2.89c.44-.82,1.04-1.47,1.8-1.93.76-.47,1.65-.7,2.65-.7.71,0,1.35.12,1.92.36.57.24,1.05.59,1.45,1.04.4.45.71,1,.92,1.64.21.64.32,1.36.32,2.16,0,.23-.07.4-.2.51s-.32.16-.57.16h-7.6v-1.16h7.19l-.35.28c0-.79-.12-1.45-.35-2-.23-.55-.57-.96-1.02-1.26-.44-.29-1-.44-1.67-.44-.74,0-1.37.17-1.89.51-.52.34-.91.82-1.17,1.42-.26.6-.39,1.31-.39,2.11v.13c0,1.34.32,2.36.97,3.06.65.7,1.57,1.05,2.76,1.05.49,0,.99-.07,1.47-.2.49-.13.96-.35,1.43-.66.2-.13.39-.19.56-.19.17,0,.3.06.4.15.1.09.17.21.21.35.04.14.02.29-.04.45-.07.16-.19.3-.38.41-.48.35-1.05.62-1.7.81-.66.19-1.3.28-1.94.28Z"/>
              <path fill={`${dataPost.colors[1]}`} d="m440.24,813.21c-.66,0-1.31-.08-1.98-.24-.66-.16-1.28-.44-1.85-.83-.15-.1-.25-.22-.31-.36-.06-.14-.08-.28-.05-.41.02-.14.08-.26.17-.36.09-.1.21-.16.35-.19.14-.02.29.01.47.1.57.35,1.12.59,1.65.73.53.14,1.06.21,1.58.21.83,0,1.46-.15,1.88-.46.42-.31.63-.72.63-1.24,0-.41-.14-.73-.42-.97-.28-.24-.71-.43-1.31-.56l-1.99-.44c-.92-.19-1.6-.52-2.04-.98-.44-.47-.67-1.07-.67-1.81,0-.66.17-1.22.51-1.69.34-.47.83-.84,1.45-1.1.63-.26,1.35-.39,2.18-.39.64,0,1.25.08,1.82.25.57.17,1.1.43,1.56.8.15.1.24.22.29.36.05.14.06.28.02.41-.04.14-.11.26-.21.35-.1.09-.23.15-.37.16-.15.01-.3-.03-.46-.13-.44-.29-.88-.51-1.33-.64-.45-.14-.9-.21-1.33-.21-.82,0-1.43.16-1.86.48-.42.32-.63.74-.63,1.27,0,.41.13.74.39,1,.26.26.67.45,1.22.57l1.99.42c.95.2,1.66.53,2.13.97.47.44.71,1.04.71,1.8,0,.96-.39,1.73-1.16,2.29-.77.57-1.8.85-3.08.85Z"/>
              <path fill={`${dataPost.colors[1]}`} d="m450.4,813.21c-1.05,0-1.85-.32-2.4-.96-.55-.64-.83-1.56-.83-2.75v-11.14c0-.29.07-.51.22-.67.15-.15.36-.23.66-.23.28,0,.49.08.66.23.16.15.24.37.24.67v11.01c0,.79.16,1.37.47,1.76.31.39.78.58,1.39.58.13,0,.25,0,.35-.02.1-.01.2-.02.31-.02.16-.01.27.02.34.11.07.09.1.26.1.52s-.06.46-.17.59c-.12.13-.31.22-.57.26-.12.01-.24.03-.37.04-.13.01-.26.02-.37.02Z"/>
              <path fill={`${dataPost.colors[1]}`} d="m454.51,799.85c-.36,0-.65-.1-.85-.29-.2-.2-.31-.46-.31-.8s.1-.61.31-.8c.2-.18.49-.27.85-.27s.67.09.86.27c.2.18.29.45.29.8s-.1.6-.29.8c-.2.2-.48.29-.86.29Zm0,13.28c-.29,0-.51-.08-.66-.25-.15-.17-.22-.4-.22-.71v-8.93c0-.31.07-.54.22-.7.15-.16.36-.24.66-.24.28,0,.49.08.66.24.16.16.24.39.24.7v8.93c0,.31-.08.54-.23.71s-.37.25-.67.25Z"/>
              <path fill={`${dataPost.colors[1]}`} d="m458.9,813.02c-.19,0-.34-.04-.46-.13-.12-.09-.2-.2-.24-.35-.04-.15-.04-.31,0-.48.04-.17.14-.34.28-.5l6.16-8.23v.46h-5.83c-.23,0-.41-.06-.54-.19-.12-.12-.19-.29-.19-.51s.06-.39.19-.5c.12-.12.3-.17.54-.17h6.7c.22,0,.4.04.53.13.14.09.23.2.27.34.04.14.05.29.01.46-.04.17-.12.33-.25.49l-6.27,8.32v-.5h6.14c.48,0,.72.23.72.68,0,.22-.06.39-.19.51-.12.12-.3.19-.54.19h-7.05Z"/>
              <path fill={`${dataPost.colors[1]}`} d="m472.04,813.21c-.73,0-1.38-.14-1.95-.43-.58-.28-1.03-.67-1.36-1.17-.34-.49-.5-1.05-.5-1.66,0-.79.2-1.41.6-1.86.4-.45,1.06-.77,1.99-.96.92-.19,2.19-.28,3.79-.28h.98v1.16h-.96c-1.18,0-2.11.05-2.78.16s-1.15.29-1.42.56c-.27.26-.4.63-.4,1.11,0,.6.21,1.08.62,1.46.41.38.98.57,1.69.57.58,0,1.1-.14,1.54-.41.44-.28.79-.66,1.05-1.14.25-.48.38-1.03.38-1.66v-2.49c0-.9-.18-1.55-.55-1.95-.36-.4-.96-.6-1.79-.6-.51,0-1.02.07-1.53.2-.51.13-1.05.34-1.62.63-.2.1-.38.13-.52.1-.15-.04-.26-.11-.35-.23-.09-.12-.14-.25-.15-.4-.01-.15.02-.3.1-.45.08-.15.21-.26.38-.35.64-.32,1.27-.55,1.9-.7.63-.15,1.22-.22,1.79-.22.92,0,1.67.15,2.27.45.6.3,1.04.75,1.33,1.35.29.6.44,1.38.44,2.33v5.92c0,.29-.07.52-.21.68-.14.16-.34.24-.6.24-.28,0-.49-.08-.63-.24-.15-.16-.22-.39-.22-.68v-1.7h.2c-.12.55-.34,1.03-.67,1.42-.33.39-.73.7-1.21.92-.48.22-1.02.33-1.62.33Z"/>
              <path fill={`${dataPost.colors[1]}`} d="m410.63,827.42c-.18,0-.31-.05-.39-.14-.09-.1-.13-.23-.13-.41v-8.35c0-.17.04-.31.13-.4.09-.09.22-.14.39-.14.17,0,.3.05.39.14.1.09.14.23.14.4v3.85h-.17c.18-.51.48-.89.9-1.15.42-.26.9-.39,1.44-.39.51,0,.93.09,1.27.28.34.18.59.46.76.83.17.37.25.84.25,1.41v3.52c0,.18-.05.31-.14.41-.09.1-.22.14-.39.14-.18,0-.31-.05-.4-.14-.09-.1-.14-.23-.14-.41v-3.45c0-.6-.12-1.03-.35-1.31-.23-.28-.6-.41-1.11-.41-.59,0-1.05.18-1.4.55-.35.36-.52.85-.52,1.45v3.18c0,.37-.18.55-.54.55Z"/>
              <path fill={`${dataPost.colors[1]}`} d="m419.58,827.44c-.44,0-.83-.09-1.18-.26-.35-.17-.62-.4-.82-.7-.2-.3-.3-.63-.3-1,0-.47.12-.84.36-1.12.24-.27.64-.46,1.2-.58.56-.11,1.32-.17,2.28-.17h.59v.7h-.58c-.71,0-1.27.03-1.67.1s-.69.18-.85.33c-.16.16-.24.38-.24.67,0,.36.12.65.37.88.25.23.59.34,1.02.34.35,0,.66-.08.93-.25.27-.17.48-.39.63-.68.15-.29.23-.62.23-1v-1.5c0-.54-.11-.93-.33-1.18-.22-.24-.58-.36-1.08-.36-.31,0-.61.04-.92.12-.31.08-.63.21-.97.38-.12.06-.23.08-.32.06-.09-.02-.16-.07-.21-.14-.05-.07-.08-.15-.09-.24,0-.09.01-.18.06-.27.05-.09.12-.16.23-.21.39-.19.77-.33,1.14-.42.38-.09.74-.13,1.08-.13.55,0,1.01.09,1.37.27.36.18.63.45.8.81.18.36.26.83.26,1.4v3.56c0,.18-.04.31-.12.41-.08.1-.2.14-.36.14-.17,0-.29-.05-.38-.14-.09-.1-.13-.23-.13-.41v-1.02h.12c-.07.33-.2.62-.4.85-.2.24-.44.42-.73.55-.29.13-.61.2-.97.2Z"/>
              <path fill={`${dataPost.colors[1]}`} d="m427.23,827.44c-.64,0-1.19-.14-1.65-.42-.46-.28-.82-.67-1.07-1.18-.25-.5-.37-1.09-.37-1.75,0-.51.07-.96.21-1.37.14-.4.35-.75.62-1.03.27-.28.6-.5.98-.65.38-.15.81-.22,1.29-.22.32,0,.64.05.99.14.34.1.65.26.93.5.09.06.14.14.17.22.03.09.03.17,0,.26-.03.08-.07.15-.13.21-.06.06-.14.09-.23.1-.09,0-.19-.02-.28-.09-.23-.18-.46-.3-.7-.37-.24-.07-.46-.11-.68-.11-.34,0-.64.05-.89.16-.25.11-.47.26-.64.47-.18.2-.31.45-.4.75s-.14.64-.14,1.04c0,.76.18,1.36.54,1.81.36.44.87.66,1.54.66.22,0,.44-.03.68-.11.23-.07.47-.19.7-.37.1-.07.19-.1.28-.09.09,0,.16.04.22.1.06.06.1.13.12.22.02.09.02.17,0,.26-.03.08-.08.16-.17.22-.28.23-.59.39-.92.49-.33.1-.66.15-.97.15Z"/>
              <path fill={`${dataPost.colors[1]}`} d="m431.13,819.41c-.22,0-.39-.06-.51-.18-.12-.12-.18-.28-.18-.48s.06-.37.18-.48c.12-.11.29-.16.51-.16s.4.05.52.16c.12.11.18.27.18.48s-.06.36-.18.48c-.12.12-.29.18-.52.18Zm0,7.99c-.18,0-.31-.05-.39-.15-.09-.1-.13-.24-.13-.43v-5.37c0-.18.04-.32.13-.42.09-.1.22-.14.39-.14.17,0,.3.05.39.14.1.1.14.24.14.42v5.37c0,.18-.05.33-.14.43s-.23.15-.4.15Z"/>
              <path fill={`${dataPost.colors[1]}`} d="m435.66,827.44c-.44,0-.83-.09-1.18-.26-.35-.17-.62-.4-.82-.7-.2-.3-.3-.63-.3-1,0-.47.12-.84.36-1.12.24-.27.64-.46,1.2-.58.56-.11,1.32-.17,2.28-.17h.59v.7h-.58c-.71,0-1.27.03-1.67.1s-.69.18-.85.33c-.16.16-.24.38-.24.67,0,.36.12.65.37.88.25.23.59.34,1.02.34.35,0,.66-.08.93-.25.27-.17.48-.39.63-.68.15-.29.23-.62.23-1v-1.5c0-.54-.11-.93-.33-1.18-.22-.24-.58-.36-1.08-.36-.31,0-.61.04-.92.12-.31.08-.63.21-.97.38-.12.06-.23.08-.32.06-.09-.02-.16-.07-.21-.14-.05-.07-.08-.15-.09-.24,0-.09.01-.18.06-.27.05-.09.12-.16.23-.21.39-.19.77-.33,1.14-.42.38-.09.74-.13,1.08-.13.55,0,1.01.09,1.37.27.36.18.63.45.8.81.18.36.26.83.26,1.4v3.56c0,.18-.04.31-.12.41-.08.1-.2.14-.36.14-.17,0-.29-.05-.38-.14-.09-.1-.13-.23-.13-.41v-1.02h.12c-.07.33-.2.62-.4.85-.2.24-.44.42-.73.55-.29.13-.61.2-.97.2Z"/>
              <path fill={`${dataPost.colors[1]}`} d="m446.01,827.44c-.44,0-.83-.09-1.18-.26-.35-.17-.62-.4-.82-.7-.2-.3-.3-.63-.3-1,0-.47.12-.84.36-1.12.24-.27.64-.46,1.2-.58.56-.11,1.32-.17,2.28-.17h.59v.7h-.58c-.71,0-1.27.03-1.67.1s-.69.18-.85.33c-.16.16-.24.38-.24.67,0,.36.12.65.37.88.25.23.59.34,1.02.34.35,0,.66-.08.93-.25.27-.17.48-.39.63-.68.15-.29.23-.62.23-1v-1.5c0-.54-.11-.93-.33-1.18-.22-.24-.58-.36-1.08-.36-.31,0-.61.04-.92.12-.31.08-.63.21-.97.38-.12.06-.23.08-.32.06-.09-.02-.16-.07-.21-.14-.05-.07-.08-.15-.09-.24,0-.09.01-.18.06-.27.05-.09.12-.16.23-.21.39-.19.77-.33,1.14-.42.38-.09.74-.13,1.08-.13.55,0,1.01.09,1.37.27.36.18.63.45.8.81.18.36.26.83.26,1.4v3.56c0,.18-.04.31-.12.41-.08.1-.2.14-.36.14-.17,0-.29-.05-.38-.14-.09-.1-.13-.23-.13-.41v-1.02h.12c-.07.33-.2.62-.4.85-.2.24-.44.42-.73.55-.29.13-.61.2-.97.2Z"/>
              <path fill={`${dataPost.colors[1]}`} d="m451.5,827.42c-.18,0-.31-.05-.4-.14-.09-.1-.14-.23-.14-.41v-5.48c0-.18.04-.31.13-.4.09-.09.21-.14.38-.14s.3.05.39.14c.09.09.14.23.14.4v1.06h-.13c.14-.52.41-.91.8-1.2.39-.28.88-.43,1.46-.45.13,0,.24.02.32.09.08.07.12.18.13.33,0,.15-.03.27-.11.35-.08.09-.2.14-.37.16l-.21.03c-.6.05-1.05.24-1.37.57-.32.33-.48.78-.48,1.35v3.18c0,.18-.05.31-.14.41-.09.1-.23.14-.4.14Z"/>
              <path fill={`${dataPost.colors[1]}`} d="m456.2,827.42c-.18,0-.31-.05-.4-.14-.09-.1-.14-.23-.14-.41v-5.48c0-.18.04-.31.13-.4.09-.09.21-.14.38-.14s.3.05.39.14c.09.09.14.23.14.4v1.06h-.13c.14-.52.41-.91.8-1.2.39-.28.88-.43,1.46-.45.13,0,.24.02.32.09.08.07.12.18.13.33,0,.15-.03.27-.11.35-.08.09-.2.14-.37.16l-.21.03c-.6.05-1.05.24-1.37.57-.32.33-.48.78-.48,1.35v3.18c0,.18-.05.31-.14.41-.09.1-.23.14-.4.14Z"/>
              <path fill={`${dataPost.colors[1]}`} d="m460.89,819.41c-.22,0-.39-.06-.51-.18-.12-.12-.18-.28-.18-.48s.06-.37.18-.48c.12-.11.29-.16.51-.16s.4.05.52.16c.12.11.18.27.18.48s-.06.36-.18.48c-.12.12-.29.18-.52.18Zm0,7.99c-.18,0-.31-.05-.39-.15-.09-.1-.13-.24-.13-.43v-5.37c0-.18.04-.32.13-.42.09-.1.22-.14.39-.14.17,0,.3.05.39.14.1.1.14.24.14.42v5.37c0,.18-.05.33-.14.43s-.23.15-.4.15Z"/>
              <path fill={`${dataPost.colors[1]}`} d="m466.64,827.44c-.58,0-1.07-.15-1.49-.45-.42-.3-.69-.7-.83-1.2l.14-.14v1.21c0,.18-.05.31-.14.41-.09.1-.22.14-.39.14-.18,0-.31-.05-.39-.14-.09-.1-.13-.23-.13-.41v-8.35c0-.17.04-.31.13-.4.09-.09.22-.14.39-.14.17,0,.3.05.39.14.1.09.14.23.14.4v3.91h-.16c.15-.49.43-.88.84-1.17.41-.29.91-.43,1.48-.43s1.08.13,1.5.4c.42.27.75.65.98,1.14.23.49.35,1.08.35,1.77s-.12,1.26-.35,1.75c-.23.49-.56.88-.99,1.15-.42.27-.92.41-1.49.41Zm-.22-.85c.4,0,.75-.1,1.04-.29.29-.19.52-.47.68-.84.16-.37.24-.81.24-1.33,0-.8-.18-1.41-.53-1.83s-.83-.63-1.44-.63c-.39,0-.74.09-1.04.28-.3.19-.53.46-.69.83-.16.36-.24.81-.24,1.35,0,.79.18,1.39.54,1.82.36.42.84.64,1.43.64Z"/>
              <path fill={`${dataPost.colors[1]}`} d="m473.08,827.44c-.44,0-.83-.09-1.18-.26-.35-.17-.62-.4-.82-.7-.2-.3-.3-.63-.3-1,0-.47.12-.84.36-1.12.24-.27.64-.46,1.2-.58.56-.11,1.32-.17,2.28-.17h.59v.7h-.58c-.71,0-1.27.03-1.67.1s-.69.18-.85.33c-.16.16-.24.38-.24.67,0,.36.12.65.37.88.25.23.59.34,1.02.34.35,0,.66-.08.93-.25.27-.17.48-.39.63-.68.15-.29.23-.62.23-1v-1.5c0-.54-.11-.93-.33-1.18-.22-.24-.58-.36-1.08-.36-.31,0-.61.04-.92.12-.31.08-.63.21-.97.38-.12.06-.23.08-.32.06-.09-.02-.16-.07-.21-.14-.05-.07-.08-.15-.09-.24,0-.09.01-.18.06-.27.05-.09.12-.16.23-.21.39-.19.77-.33,1.14-.42.38-.09.74-.13,1.08-.13.55,0,1.01.09,1.37.27.36.18.63.45.8.81.18.36.26.83.26,1.4v3.56c0,.18-.04.31-.12.41-.08.1-.2.14-.36.14-.17,0-.29-.05-.38-.14-.09-.1-.13-.23-.13-.41v-1.02h.12c-.07.33-.2.62-.4.85-.2.24-.44.42-.73.55-.29.13-.61.2-.97.2Z"/>
            </g>
          </g>
        </svg>
       
      </div>

      <img
        src={imgPostLogo}
        alt="logo" className="logo text"
        style={{ bottom: ' 39%'}}
      />

      {
        dataPost.text[0] && (
          <div
            className="text py-1 px-4 f-14 text-center clamp-3"
            style={{color: dataPost.colors[2], fontSize: '30px', lineHeight: '34px',
            top: '27%', textShadow: `0 0 10px ${dataPost.colors[1]}`
          }}
          >{dataPost.text[0]}</div>
        )
      }
      {
        dataPost.text[1] && (
          <div
            className="w-content text px-3 f-4 bl"
            style={{color: dataPost.colors[2], letterSpacing: '4px',
              background: dataPost.colors[1], fontSize:'22px',
              margin: '8%'
              
            }}
          >{convertAmount(dataPost.text[1])}</div>
        )
      }
      {
        dataPost.text[2] && (
          <div
            className="f-4 text tc text-center px-3 py-1 text-truncate text-uppercase"
            style={{color: dataPost.colors[2], fontSize: '15px',
              background: dataPost.colors[1] , top: '20%'
            }}
          >{dataPost.text[2]}</div>
        )
      }
      {
        dataPost.text[3] && (
          <div
            className="text text-center px-2 f-4 clamp-2"
            style={{color: dataPost.colors[1], fontSize: '13px',  lineHeight: ' 15px',
            width: ' 80%', bottom: '32.5%'
          }}
          >{dataPost.text[3]}</div>
        )
      }
      {
        dataPost.text[4] && (
          <div
            className="w-content text px-3 f-4 bl"
            style={{color: dataPost.colors[2], letterSpacing: '4px',
              background: dataPost.colors[0], fontSize:'18px',
              left: '8%', bottom: '9.5%'
              
            }}
          >{dataPost.text[4]}</div>
        )
      }
    
    </div>
  );
};

export default State3;
