import React from 'react'
import { useSelector } from 'react-redux';
import { convertAmount } from '../../../../tools/format';


const State21 = () => {

  const {dataPost, imgPostLogo, imgProduct} = useSelector(s => s.post)
  

  return (
    <div className="poster">
      <div className="img" style={{
        left: '17px', top: '17px', width: ' 455px', height: '835px'
      }}>
        {
          imgProduct && (
            <img
              src={imgProduct}
              className='b7'
              alt="img" />
          )
        }
        <div className="overlay" style={{background: dataPost.colors[2], opacity: .4}}/>
      </div>
      <div className="svg">
        <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 490 870">
          <path fill={`${dataPost.colors[1]}`}  d="m0,.06v870.94h490V.06H0Zm471,851H18V19.11h453v831.95Z"/>
          <g>
            <path fill={`${dataPost.colors[0]}`}  d="m372,1c0,69.48-56.52,126-126,126S120,70.48,120,1c0-.33,0-.67.01-1h-2c0,.33-.01.67-.01,1,0,70.58,57.42,128,128,128S374,71.58,374,1c0-.33,0-.67-.01-1h-2c0,.33.01.67.01,1Z"/>
            <path fill={`${dataPost.colors[0]}`}  d="m144,1c0,56.33,45.67,102,102,102S348,57.33,348,1c0-.33,0-.67-.01-1h-203.96c0,.33-.02.66-.02,1Z"/>
          </g>
          <g>
            <path fill={`${dataPost.colors[0]}`}  d="m120,869.56c0-69.48,56.52-126,126-126s126,56.52,126,126c0,.33,0,.67-.01,1h2c0-.33.01-.67.01-1,0-70.58-57.42-128-128-128s-128,57.42-128,128c0,.33,0,.67.01,1h2c0-.33-.01-.67-.01-1Z"/>
            <path fill={`${dataPost.colors[0]}`}  d="m348,869.56c0-56.33-45.67-102-102-102s-102,45.67-102,102c0,.33,0,.67.01,1h203.96c0-.33.02-.66.02-1Z"/>
          </g>
        </svg>
      </div>

      <img
        src={imgPostLogo}
        alt="logo" className="logo text tc"
        style={{top:'2%'}}
      />

      {
        dataPost.text[0] && (
          <div
            className="text ct px-2 f-13 text-center clamp-2"
            style={{color: dataPost.colors[0], top: '40%', fontSize: '41px',
              textShadow: `0 0 10px ${dataPost.colors[2]}`, lineHeight: '45px',
              width: '87%'
            
          }}
          >{dataPost.text[0]}</div>
        )
      }
      {
        dataPost.text[1] && (
          <div
            className="w-content text px-3 f-4 bc"
            style={{color: dataPost.colors[2], bottom: '2%', fontSize: '25px'
            }}
          >{convertAmount(dataPost.text[1])}</div>
        )
      }
      {
        dataPost.text[2] && (
          <div
            className="text ct px-2 f-4 text-center text-truncate"
            style={{color: dataPost.colors[0], top: '26%', fontSize: '20px',
              textShadow: `0 0 10px ${dataPost.colors[2]}`, width: '80%'
          }}
          >{dataPost.text[2]}</div>
        )
      }
      {
        dataPost.text[3] && (
          <div
            className="text ct px-2 f-4 text-center clamp-2"
            style={{color: dataPost.colors[0], top: '65%', fontSize: '18px',
              textShadow: `0 0 10px ${dataPost.colors[2]}`, lineHeight: '25px',
              width: '80%'
            
          }}
          >{dataPost.text[3]}</div>
        )
      }
    
    </div>
  );
};

export default State21;
