import React from 'react'
import { useSelector } from 'react-redux';
import { convertAmount } from '../../../../tools/format';


const Post4 = () => {

  const {dataPost, imgPostLogo, imgProduct} = useSelector(s => s.post)
  
  
  return (
    <div className="poster">
      <div className="img" style={{width: '86.6%', height: '77.6%'}}>
        {
          imgProduct && (
            <img
              src={imgProduct}
              className='b7'
              alt="img" />
          )
        }
      </div>
      <div className="svg">
        <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">
         
          <path fill={`${dataPost.colors[0]}`}   d="m885,0v568.79c0,123.83-100.38,224.21-224.21,224.21H0v231h1024V0h-139Z"/>
          <path fill={`${dataPost.colors[1]}`} d="m660.79,793H0v53h660.79c123.83,0,224.21-100.38,224.21-224.21v-53c0,123.83-100.38,224.21-224.21,224.21Z"/>
          <path fill={`${dataPost.colors[1]}`} d="m0,211.88c21.82,12.18,46.96,19.12,73.72,19.12,83.69,0,151.54-67.85,151.54-151.54,0-29.14-8.23-56.35-22.49-79.46H0v211.88Z"/>
          <polygon fill={`${dataPost.colors[0]}`} points="43.72 42.26 43.72 164.51 46.72 164.51 46.72 45.26 155.97 45.26 155.97 42.26 43.72 42.26"/>
          <path fill={`${dataPost.colors[1]}`} d="m1024,339h-254.24c-13.49,0-26.25,5.03-34.73,13.67l-73.28,74.76c-14.44,14.73-14.44,35.78,0,50.51l73.21,74.7c8.52,8.69,21.34,13.74,34.89,13.74h254.13v-227.39Z"/>
          <polygon fill={`${dataPost.colors[3]}`} points="1024 1024 1024 872 924 900.52 824 872 824 1024 1024 1024"/>
        </svg>
      </div>

      <img
        src={imgPostLogo}
        alt="logo" className="logo text"
        style={{ right: '1.5%', bottom: '1.5%'}}
      />

      {
        dataPost.text[0] && (
          <div
            className="text py-1 px-2 f-2 text-truncate"
            style={{color: dataPost.colors[3], left:'2%',
            fontSize: '25px', bottom: '4%', width:'77%'}}
          >{dataPost.text[0]}</div>
        )
      }
      {
        dataPost.text[1] && (
          <div
            className="w-content text-end text f-2"
            style={{color: dataPost.colors[2],
              fontSize:'27px',
              right:'3%', top: '43%'
            }}
          >{convertAmount(dataPost.text[1])}</div>
        )
      }
      {
        dataPost.text[2] && (
          <div
            className="f-4 text-uppercase text  clamp-3"
            style={{color: dataPost.colors[0], fontSize: '12px',
              left: '6%', top: '6%', width:' 17%',
              lineHeight: '13px'
            }}
          >{dataPost.text[2]}</div>
        )
      }
      {
        dataPost.text[3] && (
          <div
            className="text f-3 text-truncate text-end text-uppercase"
            style={{color: dataPost.colors[2], fontSize: '18px',
            top: '38%', right: '3%', width: '27%'
          }}
          >{dataPost.text[3]}</div>
        )
      }
      {
        dataPost.text[4] && (
          <div
            className="text tr py-1 px-2 f-3 text-truncate"
            style={{color: dataPost.colors[3], fontSize: '12px',
              border: `1px solid ${dataPost.colors[3]}`, maxWidth:' 70%'
            }}
          >{dataPost.text[4]}</div>
        )
      }
    
    </div>
  );
};

export default Post4;
