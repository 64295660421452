import React, { useEffect } from 'react'
import Dashboard from './dashboard/Dashboard'
import {setUserId } from '../../redux/reducer/admin'
import { useDispatch, useSelector } from 'react-redux'
import LoadAdmin from './dashboard/LoadAdmin'


const AdminPage = ({userID}) => {

  const dispatch = useDispatch(null)
  const {userID: superAdmin} = useSelector(state => state.log)

  useEffect(()=>{
    dispatch(setUserId(userID))
  },[userID,dispatch])

  


  return (
    <>
      {
        superAdmin ? <Dashboard /> : <LoadAdmin />
      }
    </>
  )
}

export default AdminPage