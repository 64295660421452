import React from 'react'
import { useSelector } from 'react-redux';
import { convertAmount } from '../../../../tools/format';


const Post2 = () => {

  const {dataPost, imgPostLogo, imgProduct} = useSelector(s => s.post)
  

  return (
    <div className="poster">
      <div className="img">
        {
          imgProduct && (
            <img
              src={imgProduct}
              className='b7'
              alt="img" />
          )
        }
        <div className="overlay" style={{background: dataPost.colors[2], opacity: .2}}/>
      </div>
      <div className="svg">
        <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">
          <polygon fill={dataPost.colors[3]} points="940.25 604.75 937.25 604.75 937.25 932.25 611.75 932.25 611.75 935.25 940.25 935.25 940.25 604.75"/>
          <polygon fill={dataPost.colors[3]}  points="83.75 414.25 86.75 414.25 86.75 86.75 412.25 86.75 412.25 83.75 83.75 83.75 83.75 414.25"/>
          <path fill={dataPost.colors[3]} d="m120.25,882.9c-1.24,0-2.41,0-3.57,0-1.08,0-1.42-.35-1.42-1.43,0-13.53,0-27.05,0-40.58,0-6.97,5.99-12.65,12.95-12.3.8.04,1.17.42,1.2,1.22,0,.1,0,.21,0,.31,0,28.28,0,56.57,0,84.85,0,.96-.16,1.87-.67,2.7-1.06,1.73-3.11,2.55-5.11,2.02-1.91-.5-3.29-2.2-3.38-4.19-.02-.33,0-.67,0-1,0-10.24,0-20.49,0-30.73,0-.27,0-.53,0-.87Zm6.96-2.18v-49.93c-1.32.01-2.54.29-3.68.84-4.02,1.94-6.1,5.17-6.11,9.63-.02,12.94,0,25.88,0,38.82,0,.2.02.4.03.63h9.76Zm-4.8,9.21v.79c0,7.98,0,15.97,0,23.95,0,.31,0,.63.04.94.14.98.69,1.64,1.63,1.95,1.65.54,3.16-.64,3.16-2.49,0-8.17,0-16.34,0-24.52,0-.2-.02-.4-.03-.62h-4.81Zm4.81-7.02c-1.53,0-3.02,0-4.52.02-.1,0-.27.32-.28.49-.02,1.25,0,2.5-.02,3.75,0,.42.11.6.57.58.62-.03,1.25,0,1.88,0,.78,0,1.57,0,2.37,0v-4.84Z"/>
          <path fill={dataPost.colors[3]}  d="m177.82,889c0-8.67,0-17.35,0-26.02,0-.41-.11-.69-.43-.96-2.37-2.08-3.78-4.77-4.64-7.75-1.84-6.37-1.6-12.64,1.14-18.73.8-1.77,1.9-3.34,3.35-4.65,3.44-3.11,7.87-3.06,11.23.13,2.39,2.28,3.78,5.14,4.58,8.29,1.6,6.33,1.25,12.52-1.64,18.45-.8,1.65-1.9,3.09-3.27,4.32-.22.2-.37.6-.37.91-.02,17.25-.01,34.49-.02,51.74,0,2.64-1.76,4.72-4.28,5.07-2.95.41-5.6-1.85-5.64-4.83-.02-1.44,0-2.88,0-4.33,0-7.21,0-14.42,0-21.63Zm2.15-.61c0,8.51,0,17.02,0,25.52,0,.36-.01.71,0,1.07.11,1.74,1.62,2.94,3.34,2.64,1.38-.24,2.28-1.42,2.28-3.02,0-16.87,0-33.74,0-50.61,0-.67-.06-1.35.02-2,.05-.39.25-.87.54-1.1,1.26-.99,2.31-2.15,3.02-3.56,3.49-6.98,3.54-14.06.38-21.16-.78-1.76-1.95-3.27-3.57-4.36-2.14-1.45-4.59-1.38-6.66.18-1.58,1.19-2.7,2.74-3.49,4.52-2.63,5.95-2.75,12.03-.68,18.15.79,2.35,2.01,4.47,4.01,6.02.57.44.81.92.8,1.64-.02,8.7-.01,17.39-.01,26.09Z"/>
          <path fill={dataPost.colors[3]}  d="m154.61,888.46c0-8.84,0-17.69.02-26.53,0-.51.17-1.08.43-1.52.98-1.66,1.95-3.32,3.05-4.89,2.06-2.92,2.56-6.1,2.13-9.59-.65-5.31-1.11-10.64-1.65-15.97-.06-.55.07-1,.6-1.26.75-.35,1.46.12,1.56,1.06.14,1.29.26,2.58.39,3.87.45,4.39.87,8.78,1.35,13.16.36,3.28-.28,6.31-2.06,9.1-1.1,1.73-2.19,3.46-3.25,5.22-.23.39-.39.88-.39,1.33-.02,17.5-.02,35,0,52.5,0,1.36-.33,2.58-1.31,3.54-1.41,1.37-3.08,1.76-4.91,1.03-1.77-.7-2.72-2.08-2.86-3.99-.02-.23,0-.46,0-.69,0-17.46,0-34.91-.02-52.37,0-.45-.16-.95-.39-1.33-1.09-1.81-2.23-3.59-3.35-5.38-1.75-2.79-2.32-5.81-1.96-9.07.61-5.61,1.16-11.23,1.73-16.84.05-.51.17-1.01.71-1.13.33-.08.76-.02,1.05.14.46.25.44.76.39,1.23-.32,3.14-.64,6.28-.95,9.42-.28,2.77-.58,5.53-.83,8.3-.21,2.39.35,4.61,1.62,6.65,1.24,2.01,2.52,4,3.74,6.02.24.4.4.93.41,1.4.02,17.54.02,35.08.01,52.62,0,.31,0,.63.01.94.08,1.3,1.12,2.27,2.4,2.26,1.24,0,2.26-.98,2.35-2.25.02-.29,0-.59,0-.88,0-8.7,0-17.39,0-26.09Z"/>
          <path fill={dataPost.colors[3]} d="m149.34,845.59h1.8c0-.24,0-.44,0-.64,0-4.91,0-9.82,0-14.74,0-.19,0-.38.01-.56.07-.64.52-1.07,1.11-1.05.57.02.99.43,1.05,1.05.02.21,0,.42,0,.63,0,4.83,0,9.66,0,14.48,0,.27,0,.53,0,.84h1.8c0-.28,0-.53,0-.77,0-4.83,0-9.66,0-14.48,0-.19,0-.38,0-.56.03-.69.45-1.16,1.05-1.17.63-.02,1.1.46,1.1,1.18,0,2.91,0,5.81,0,8.72,0,2.65,0,5.31,0,7.96,0,.96-.37,1.33-1.32,1.34-2.49,0-4.97,0-7.46,0-.93,0-1.3-.37-1.3-1.28,0-5.54,0-11.08,0-16.62,0-.83.43-1.32,1.11-1.3.64.02,1.04.51,1.05,1.31,0,4.97,0,9.95,0,14.92,0,.24,0,.49,0,.77Z"/>
          <rect fill={dataPost.colors[0]} x="282" y="581" width="460" height="56"/>
          <polygon fill={dataPost.colors[3]}  points="612 1026 612 874 512 902.52 412 874 412 1026 612 1026"/>
        </svg>
      </div>

      <img
        src={imgPostLogo} className='logo text'
        alt="logo" style={{bottom: '1.5%'}}
      />

      {
        dataPost.text[0] && (
          <div
            className="text text-end px-1 tr f-4  clamp-2"
            style={{color: dataPost.colors[3], textTransform: 'uppercase',
            maxWidth: ' 210px', lineHeight: '34px', right: '7%', top: '7%',
            fontSize: '33px', textShadow: `0 0 10px ${dataPost.colors[2]}`  }}
          >{dataPost.text[0]}</div>
        )
      }
      {
        dataPost.text[1] && (
          <div
            className="text f-2 "
            style={{color: dataPost.colors[1],fontSize:'43px',
              textShadow: `0px 0px 10px ${dataPost.colors[2]}`, marginTop: '19%'
            }}
          >{convertAmount(dataPost.text[1])}</div>
        )
      }
      {
        dataPost.text[2] && (
          <div
            className="f-2 text text-center text-truncate px-1"
            style={{color: dataPost.colors[3], fontSize: '33px',
              textShadow: `0 0 5px ${dataPost.colors[2]}` , marginTop:'-4%',
              maxWidth: '90%'
            }}
          >{dataPost.text[2]}</div>
        )
      }
      
      {
        dataPost.text[3] && (
          <div
            className="text bc py-1 px-2 f-3 text-truncate"
            style={{color: dataPost.colors[3], fontSize: '16px', maxWidth:'80%',
              marginBottom:'23%', border: `2px solid ${dataPost.colors[3]}`
            }}
          >{dataPost.text[3]}</div>
        )
      }
    
    </div>
  );
};

export default Post2;
