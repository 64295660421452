import { db } from "../../../../firebase/credentials";
import { collection, onSnapshot } from "firebase/firestore";

const getUserRegistered = (callback) => {
  const querySnapshot = collection(db, "register");
  
  return onSnapshot(querySnapshot, (snapshot) => {
    const allData = [];
    snapshot.forEach((doc) => allData.push({ id: doc.id, ...doc.data() }));
    callback(allData) 
  });
};

export default getUserRegistered;
