import React from 'react'
import l11 from '../../img/brand/l_11.webp'
import l12 from '../../img/brand/l_12.webp'
import { setShowDemo } from '../../redux/reducer/interactions'
import { useDispatch } from 'react-redux'
import sudo from '../../firebase/update/sudo'

const Footer = () => {

  const dispatch = useDispatch()
  const handleOpenDemo = ()=> dispatch(setShowDemo(true))
  const handleRegister = ()=> sudo('register')

  return (
    <footer className="landingFooter">
      <img src={l11} alt="" className='img-fluid pa l11'/>
      <img src={l12} alt="" className='img-fluid pa l12'/>
      <button
        className="btnLine"
        onClick={handleOpenDemo}
      >VER DEMOS</button>
      <button
        className="btnRegister"
        data-bs-toggle="modal"
        data-bs-target="#modalRegisterL"
        onClick={handleRegister}
      >REGÍSTRATE</button>
    </footer>
  )
}

export default Footer