import React, { useState } from 'react'
import Cropper from 'react-easy-crop'
import defalutImage from '../../../../img/dashboard/upload_product.svg'
import { createCanvas } from 'canvas'
import { useDispatch, useSelector } from 'react-redux'
import { setImgPostLogo, setImgPostProduct, setNewLogo } from '../../../../redux/reducer/post'



const CropImages = () => {

  const {dataPost, isLogoCropped} = useSelector(s => s.post)

  let coord = { x: 0, y: 0 }
  const [crop, setCrop] = useState(coord)
  const [zoom, setZoom] = useState(1)
  const [visible, setVisible] = useState(false)
  const [viewImage, setViewImage] = useState('')
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const dispatch = useDispatch()



  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }
  

  const handleClose = ()=>{
    setViewImage('')
    setVisible(false)
  }


  const handleChange = (event) => {
    const file = event.target.files[0]
    const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png']

    if (!allowedTypes.includes(file.type)) {
      alert('Solo se permiten archivos .jpeg, .jpg y .png.')
      return false
    }

    const reader = new FileReader()
    reader.onload = () => {
      const base64String = reader.result.split(',')[1]
      setViewImage(base64String)
    }
    reader.readAsDataURL(file)
    setVisible(true)
  }



  const handleCrop = async() => {

    const canvas = createCanvas(croppedAreaPixels.width, croppedAreaPixels.height)
    const ctx = canvas.getContext('2d')
  
    const image = new Image()
    image.src = `data:image/png;base64,${viewImage}`
    
    ctx.drawImage(
      image,
      croppedAreaPixels.x,
      croppedAreaPixels.y,
      croppedAreaPixels.width,
      croppedAreaPixels.height,
      0,
      0,
      croppedAreaPixels.width,
      croppedAreaPixels.height
    )
  
    const croppedImage = canvas.toDataURL('image/png')
    const optimizedImg = await optimizeImage(croppedImage, isLogoCropped ? 600 : dataPost.crop.width,isLogoCropped ? 300 :dataPost.crop.height)
      
    isLogoCropped ?
      (
        <>
          {dispatch(setImgPostLogo(optimizedImg))}
          {dispatch(setNewLogo(true))}
        </>
      )
      :dispatch(setImgPostProduct(optimizedImg))

    handleClose()
  }

  const optimizeImage = (base64String, maxWidth, maxHeight) => {
    return new Promise((resolve) => {
      const image = new Image()
      image.src = base64String
  
      image.onload = () => {
        const canvas = createCanvas(maxWidth, maxHeight)
        const ctx = canvas.getContext('2d')
  
        const aspectRatio = image.width / image.height
        let newWidth = image.width
        let newHeight = image.height
  
        if (newWidth > maxWidth) {
          newWidth = maxWidth
          newHeight = newWidth / aspectRatio
        }
  
        if (newHeight > maxHeight) {
          newHeight = maxHeight
          newWidth = newHeight * aspectRatio
        }
  
        canvas.width = newWidth
        canvas.height = newHeight
  
        ctx.drawImage(image, 0, 0, newWidth, newHeight)
  
        const optimizedBase64 = canvas.toDataURL('image/png')
        resolve(optimizedBase64)
      }
    })
  }
  
  


  

  return (
    <>
      <div className="modal fade modalDashboard" id="modalPost" tabIndex="-1" aria-labelledby="modalPost" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body p-3 p-md-4">
              
              {
                !visible && (
                  <>
                    <div className="uploadLogo">
                      <input
                        autoComplete="off"
                        type="file"
                        accept=".jpg, .jpeg, .png"
                        onChange={handleChange}
                      />
                      <img src={defalutImage} alt="default" className='img-fluid'/>
                    </div>
                  </>
                )
              }
              {
                visible && (
                  <>
                    <div className="crop-container">
                      {
                        isLogoCropped ? (
                          <Cropper
                            image={`data:image/png;base64,${viewImage}`}
                            crop={crop}
                            zoom={zoom}
                            aspect={2/1}
                            onCropChange={setCrop}
                            onCropComplete={onCropComplete}
                            onZoomChange={setZoom}
                          />
                        ):
                        (
                          <Cropper
                            image={`data:image/png;base64,${viewImage}`}
                            crop={crop}
                            zoom={zoom}
                            aspect={dataPost.crop.x/dataPost.crop.y}
                            onCropChange={setCrop}
                            onCropComplete={onCropComplete}
                            onZoomChange={setZoom}
                          />
                        )
                      }
                      
                    </div>
                    <div className="controls">
                      <span>Zoom de la imagen</span>
                      <input
                        autoComplete="off"
                        type="range"
                        value={zoom}
                        min={1}
                        max={6}
                        step={0.01}
                        aria-labelledby="Zoom"
                        onChange={(e) => {
                          setZoom(e.target.value)
                        }}
                        className="zoom-range"
                      />
                  </div>
                  </>
                )
              }
            </div>
            <div className="row justify-content-center my-4">
              <div className="col-md-5 col-6">
                {
                  !visible ? 
                    <button
                      type='button'
                      className='btn-green-dark w-100'
                      data-bs-dismiss="modal"
                      onClick={ handleClose}
                    >Cerrar</button>
                    :
                    <button
                      type='button'
                      className='btn-green-dark w-100'
                      data-bs-dismiss="modal"
                      onClick={ (x,y) => handleCrop(x,y)}
                    >Recortar</button>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CropImages