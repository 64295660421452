import React from "react";
import InfoGreen from "../../InfoGreen";
import { useDispatch, useSelector } from "react-redux";
import { setMinDeliveryPrice, setPriceDelivery } from "../../../../../redux/reducer/menu";

const Delivery = ({ errors, register }) => {
  const dispatch = useDispatch()
  const {priceDelivery, minDeliveryPrice} = useSelector(s => s.menu)

  return (
    <div className="col-12">
      <div className="row justify-content-center">
        <div className="col-md-12 mt-5">
          <div className="row">
            <div className="col-md-6">
              <h5 className="text-center text-apple mb-3">Valor de tu domicilio</h5>
              <div className="inputDash">
                <input
                  type="tel"
                  autoComplete="off"
                  value={priceDelivery || ''}
                  placeholder="Valor fijo de domicilio"
                  {...register("deliveryPrice", {
                    required: false,
                    maxLength: {value: 6,message: "Revisa el valor de tu domicilio"},
                    pattern: {value: /^\d+$/,message: 'Este campo solo admite números'},
                    onChange: (e) => {
                      dispatch(setPriceDelivery(e.target.value));
                    }
                  })}
                />
              </div>
              {errors.deliveryPrice && (
                <small className="errorDash">{errors.deliveryPrice.message}</small>
              )}
              <InfoGreen
                bg={true}
                text="Puedes definir un valor de domicilio, este valor es fijo y se sumará al total del pedido."
              />
            </div>
            <div className="col-md-6">
              <h5 className="text-center text-apple mb-3 mt-4 mt-md-0">
                Monto mínimo del domicilio
              </h5>
              <div className="inputDash">
                <input
                  type="tel"
                  autoComplete="off"
                  value={minDeliveryPrice || ''}
                  placeholder="Valor fijo de domicilio"
                  {...register("minDelivery", {
                    required: false,
                    maxLength: { value: 6, message: "Revisa el valor" },
                    pattern: {value: /^\d+$/,message: 'Este campo solo admite números'},
                    onChange: (e) => {
                      dispatch(setMinDeliveryPrice(e.target.value))
                    },
                  })}
                />
              </div>
              {errors.minDelivery && (
                <small className="errorDash">{errors.minDelivery.message}</small>
              )}
              <InfoGreen
                bg={true}
                text="Establece un monto mínimo de pedido. Esta configuración aplica para pedidos a domicilio y punto de venta."
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Delivery;
