import React from 'react'
import { useSelector } from 'react-redux';
import { convertAmount } from '../../../../tools/format';


const Post21 = () => {

  const {dataPost, imgPostLogo, imgProduct} = useSelector(s => s.post)
  

  return (
    <div className="poster">
      <div className="img" style={{
        width: '67%', height: ' 67%', top:'24%', left: '16.5%'
      }}>
        {
          imgProduct && (
            <img
              src={imgProduct}
              className='b7'
              alt="img" />
          )
        }
      </div>
      <div className="svg">
        <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">
          <path fill={`${dataPost.colors[2]}`}  d="m0,0v587h175.01c.27-186.17,151.26-337,337.49-337s337.22,150.83,337.49,337h174.01V0H0Z"/>
          <path fill={`${dataPost.colors[1]}`}  d="m849.99,587c0,.17,0,.33,0,.5,0,186.4-151.1,337.5-337.5,337.5s-337.5-151.1-337.5-337.5c0-.17,0-.33,0-.5H0v437h1024v-437h-174.01Z"/>
          <path fill={`${dataPost.colors[0]}`}  d="m164,0v587h11.01c.27-186.17,151.26-337,337.49-337s337.22,150.83,337.49,337h10.01V0H164Z"/>
          <rect fill={`${dataPost.colors[3]}`}  x="404.75" y="1" width="214.5" height="122"/>
          <g>
            <rect fill={`${dataPost.colors[3]}`}  y="43" width="347" height="4"/>
            <rect fill={`${dataPost.colors[3]}`}  y="68" width="306" height="4"/>
          </g>
          <g>
            <rect fill={`${dataPost.colors[3]}`}  x="677" y="43" width="347" height="4" transform="translate(1701 90) rotate(-180)"/>
            <rect fill={`${dataPost.colors[3]}`}  x="718" y="68" width="306" height="4" transform="translate(1742 140) rotate(-180)"/>
          </g>
        </svg>
      </div>

      <img
        src={imgPostLogo}
        alt="logo" className="logo text tc"
        style={{top: '1.4%'}}
      />

      {
        dataPost.text[0] && (
          <div
            className="text tc px-2 f-9 text-center text-truncate"
            style={{color: dataPost.colors[3], marginTop: '12.5%', 
            fontSize: '23px', width: ' 100%'
          }}
          >{dataPost.text[0]}</div>
        )
      }
      {
        dataPost.text[1] && (
          <div
            className="w-content text px-3 f-3 bc"
            style={{color: dataPost.colors[3], bottom:'17%',
              background: `${dataPost.colors[0]}`, fontSize: '24px'
            }}
          >{convertAmount(dataPost.text[1])}</div>
        )
      }
      {
        dataPost.text[2] && (
          <div
            className="f-3 text tc text-truncate text-center text-uppercase"
            style={{color: dataPost.colors[1], fontSize: '11px',
              top: '20%', width: '100%'
            }}
          >{dataPost.text[2]}</div>
        )
      }
      {
        dataPost.text[3] && (
          <div
            className="text bc py-1 px-2 f-3 text-uppercase text-center text-truncate"
            style={{color: dataPost.colors[2], fontSize: '13px',
             marginBottom:'2%', width: '100%'
          }}
          >{dataPost.text[3]}</div>
        )
      }
    </div>
  );
};

export default Post21;
