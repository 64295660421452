import React from 'react'

const TableList = ({name,tel,address,neighborhood, id, onEdit, onDelete, newOrder}) => {
  return (
      <div className="col-12">
        <div className="row text-center border-botton-greenDark py-1 text-white">
          <div className="col-md-3 col-12 align-self-center"><span>{name || 'No asignado'}</span></div>
          <div className="col-md-2 col-6 align-self-center"><span>{tel || 'No asignado'}</span></div>
          <div className="col-md-3 col-6 align-self-center"><span>{address || 'No asignado'}</span></div>
          <div className="col-md-2 col-12 align-self-center"><span>{neighborhood || 'No asignado'}</span></div>
          <div className="col-md-2 col-12 align-self-center actionsListDash">
            <button
              className='newOrder'
              type="button"
              onClick={newOrder}
              data-bs-toggle="modal"
              data-bs-target="#modalCreateOrderFromAdmin"
            >Crear pedido</button>
            <div className="dropdown">
              <button 
                className="dropdown-toggle btnDots"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false" 
              />
              <ul className="dropdown-menu listDrop">
                <button data-ref={id} onClick={onEdit}>Editar</button>
                <button
                  data-ref={id}
                  onClick={onDelete}
                  data-bs-toggle="modal"
                  data-bs-target="#deleteOneClient"
                >Eliminar</button>
              </ul>
            </div>
          </div>
        </div>
      </div>
  )
}

export default TableList