import React from 'react'
import CatalogDash from './catalog/CatalogDash'
import emptyList from '../../../../img/dashboard/empy_list.svg'
import InfoGreen from '../InfoGreen'
import { useSelector } from 'react-redux'

const Catalog = () => {

  const {listProducts} = useSelector(state => state.menu)
  
  

  return (
    <>
      <section className="py-0 py-md-4 mb-5 mb-md-0">
        {
          listProducts.length === 0 ?
            <EmptyProduct />
            :
            <CatalogDash />
        }
      </section>
    </>
  )
}

export default Catalog


const EmptyProduct = ()=>{
  return(
    <>
      <div className="row justify-content-center">
        <div className="col-md-5">
          <div className="catalogProductEmpty">
            <InfoGreen
              bg={true}
              text='En esta sección podrás gestionar todos tus productos, recuerda crear una categoría, luego crea tu primer producto para habilitar todas las funciones aquí. ¡Te esperamos!'
            />
            <img src={emptyList} alt="vacío" className='img-fluid mt-3'/>
          </div>
        </div>
      </div>
    </>
  )
}