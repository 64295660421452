import React, { useEffect, useState } from 'react'

const ProductsReport = ({orderReports}) => {

  const [ranking, setRanking] = useState({})

  useEffect(()=>{


    if(orderReports){
      
      const productCount = {}

      for (const key in orderReports) {
        const order = orderReports[key].order
        order.forEach(item => {
          const title = item.title
          const category = item.category
          if (productCount[title]) {
            productCount[title].count += item.count
          } else {
            productCount[title] = { count: item.count, category: category }
          }
        })
      }

      const productArray = Object.keys(productCount).map(title => {
        return { title: title, count: productCount[title].count, category: productCount[title].category }
      })

      productArray.sort((a, b) => {
        if (b.count === a.count) {
          return a.title.localeCompare(b.title)
        }
        return b.count - a.count
      })

      const topProducts = productArray.slice(0, 5)
      setRanking(topProducts)
    }
  

  },[orderReports])
  

  return (
    <div className="bg-b1 p-3 rounded-lg mb-3">
      <strong className="text-apple mb-3 d-block text-center text-md-start">Ranking #5 productos más solicitados</strong>
      {
        Object.values(ranking).map((el,index) =>(
          <div className="cardRaking" key={index}>
            <span>#{index+1}</span>
            <div>
              <p>{el.title}</p>
              <small>{el.category}</small>
            </div>
            <strong>x{el.count}</strong>
          </div>
        ))
      }  
    </div>
  )
}

export default ProductsReport
