import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setActiveOrder } from '../../../../redux/reducer/user'
import { Link } from 'react-router-dom'

const FooderAd = () => {

  const {order, menu, admin} = useSelector(state => state.user)
  const dispatch = useDispatch()

  const handleToogleAside = ()=> {
    dispatch(setActiveOrder())
  }

  return (
    <>
      <div className="footerOrder">
        <div className="row justify-content-center">
          {
            admin?.u === 3 ? null : (
              <div className="col-6 px-0">
                <Link
                  to='/'
                  target='_blank'
                  className={`fooderAd`}
                >
                  <svg className='icon' id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 302 302">
                    <path fill={menu?.g?.[3]} d="m283,183.21c-.71-4.29-5-7.15-8.58-3.57h0c-3.57,2.86-7.86,2.14-11.43-.71-2.86-2.14-4.29-5-5-8.58,0-1.43-.71-2.86-.71-3.57-.71-2.14-2.14-3.57-4.29-3.57s-4.29,1.43-5,3.57c-.71,1.43-.71,2.86-.71,4.29.71,5.72,2.14,11.43,5.72,15.72.71.71.71,1.43,0,1.43-3.57,3.57-7.15,7.15-10.72,10.72-5.72,5.72-11.43,10.72-17.87,15.72,2.86-30.01-47.17-28.59-53.6-15.72-7.15,14.29-27.87,43.59-28.59,44.31-5.72,0-10.72,0-15.72-.71-7.15-.71-13.58-2.14-20.72-4.29-12.86-3.57-25.73-8.58-37.88-15.01-12.86-6.43-24.3-14.29-35.73-23.58l-4.29-4.29h0q0,.71.71,1.43c1.43,2.14,2.86,5,4.29,7.15,6.43,8.58,13.58,15.01,22.15,21.44,15.01,11.43,31.44,20.72,50.02,26.44,12.86,4.29,25.73,6.43,39.3,7.15,8.58,0,17.15-.71,25.01-2.14,16.44-2.86,31.44-9.29,45.74-17.87,10.72-7.15,20.72-15.01,30.01-24.3,6.43-6.43,12.15-13.58,17.87-20.72h.71c5.72,1.43,11.43.71,16.44-2.86,2.14-1.43,2.86-4.29,2.86-7.86Z"/>
                    <path fill={menu?.g?.[1]} d="m84.33,48.86c-31.44,0-57.17,25.73-57.17,57.17s25.73,57.17,57.17,57.17,57.17-25.73,57.17-57.17-25.73-57.17-57.17-57.17Zm28.59,71.46c-7.86,0-14.29-6.43-14.29-14.29s6.43-14.29,14.29-14.29,14.29,6.43,14.29,14.29-6.43,14.29-14.29,14.29Z"/>
                    <path fill={menu?.g?.[1]} d="m205.82,48.86c-31.44,0-57.17,25.73-57.17,57.17s25.73,57.17,57.17,57.17,57.17-25.73,57.17-57.17-25.73-57.17-57.17-57.17Zm28.59,71.46c-7.86,0-14.29-6.43-14.29-14.29s6.43-14.29,14.29-14.29,14.29,6.43,14.29,14.29-6.43,14.29-14.29,14.29Z"/>
                  </svg>
                  <span>¡Quiero mi menú!</span>
                </Link>  
              </div>
            )
          }
          
          <div className="col-6 px-0">
            <button
              className='OrderBtn'
              type='button'
              onClick={handleToogleAside}
            >      
              <strong>Ver pedido</strong>
              <div>{order?.length}</div>
            </button> 
          </div>
        </div>
      </div>
    </>
  )
}

export default FooderAd