import React from 'react'

const LoadAdmin = () => {
  return (
    <div className="loadAdmin ">
      <div className="spinnerAdmin">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        </div>
    </div>
  )
}

export default LoadAdmin