import React from 'react'
import { useSelector } from 'react-redux'


const MainProducts = ({img, title, onClick, category, isFavorite}) => {

  const {menu} = useSelector(state => state.user)

  return (
    <button
      type='button'
      className='mainCards'
      onClick={onClick}
      data-bs-toggle="modal"
      data-bs-target="#showMProduct"
      data-enableswipe='true'
    >
      <div className="label">{category || 'NA'}</div>
      <div className="content" data-enableswipe='true'>
        <img
          src={img}
          alt={title}
          className='img-fluid'
        />
        {
          isFavorite && (
            <svg id="Capa_1" className='icon' data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 225 225" fill={`${menu?.g?.[2]}`}>
              <path d="m114,198.53c-23.76-22.9-47.56-45.75-71.24-68.72-5.85-5.68-11.89-11.32-16.82-17.76-13.53-17.69-15.79-37.17-6.01-57.2,9.65-19.76,26.3-29.67,48.15-29.85,13.44-.11,25.38,4.89,35.18,14.29,3.1,2.98,5.79,6.39,9.36,10.39,3.79-4.18,6.52-7.82,9.87-10.78,15.51-13.71,33.4-17.73,52.9-10.86,19.47,6.86,31.09,21.19,34.75,41.5,3.39,18.77-2.5,34.83-15.71,48.27-26.29,26.74-52.67,53.39-79.01,80.07-.81.83-1.68,1.6-2.53,2.39.37-.58.74-1.15,1.11-1.73Z"/>
            </svg>
          )
        }
        <div className="overlay">
          <h6 className='m-0 text-truncate'>{title}</h6>
          <div className="plus" />
        </div>
      </div>
    </button>
  )
}

export default MainProducts

