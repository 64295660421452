import React from 'react'
import { useSelector } from 'react-redux';
import { convertAmount } from '../../../../tools/format';


const State11 = () => {

  const {dataPost, imgPostLogo, imgProduct} = useSelector(s => s.post)
  

  return (
    <div className="poster">
      <div className="img" style={{
        top: '2.2%', width: '66%', height: '95.5%'
      }}>
        {
          imgProduct && (
            <img
              src={imgProduct}
              className='b7'
              alt="img" />
          )
        }
        <div className="overlay" style={{background: dataPost.colors[0], opacity: .2}}/>
      </div>
      <div className="svg">
        <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 490 870">
          <defs>
            <style>
              {`
              .ggt555 {
                opacity: .36;
              }
              `}
            </style>
          </defs>
          <polygon fill={`${dataPost.colors[0]}`} className="cls-1" points="0 0 0 24.5 319 24.5 319 845.5 0 845.5 0 870 490 870 490 0 0 0"/>
          <g  className="ggt555">
            <polygon fill={`${dataPost.colors[3]}`} className="cls-4" points="459.97 845.95 320 845.95 320 845.05 459.07 845.05 459.07 24.95 320 24.95 320 24.05 459.97 24.05 459.97 845.95"/>
          </g>
          <g  className="ggt555">
            <rect fill={`${dataPost.colors[3]}`} className="cls-4" x="341" y="198.94" width="1" height="276.48"/>
          </g>
          <g>
            <polygon fill={`${dataPost.colors[1]}`} className="cls-2" points="372.71 508.04 375.11 510.44 386.47 499.09 397.83 510.44 400.23 508.04 386.47 494.28 372.71 508.04"/>
            <polygon fill={`${dataPost.colors[1]}`} className="cls-2" points="372.71 526.04 375.11 528.44 386.47 517.09 397.83 528.44 400.23 526.04 386.47 512.28 372.71 526.04"/>
            <path fill={`${dataPost.colors[1]}`} className="cls-2" d="m386,475.44c-20.95,0-38,17.05-38,38s17.05,38,38,38,38-17.05,38-38-17.05-38-38-38Zm0,73c-19.3,0-35-15.7-35-35s15.7-35,35-35,35,15.7,35,35-15.7,35-35,35Z"/>
          </g>
          <rect fill={`${dataPost.colors[2]}`} className="cls-3" x="319" y="766.44" width="141" height="80"/>
          <rect fill={`${dataPost.colors[2]}`} className="cls-3" x="182" y="614.44" width="308" height="2"/>
          <rect fill={`${dataPost.colors[2]}`} className="cls-3" x="182" y="733.44" width="308" height="2"/>
        </svg>

        
      </div>

      <img
        src={imgPostLogo}
        alt="logo" className="logo text br"
        style={{right: '9%', bottom: '3.6%'}}
      />

      {
        dataPost.text[0] && (
          <div
            className="text tr  py-1 px-2 f-14 clamp-2 text-end"
            style={{color: dataPost.colors[3], top: '5%', width: '80%' , right: '11%',
            fontSize: '30px', textShadow: `0 0 7px ${dataPost.colors[0]}`, lineHeight: '38px'
          }}
          >{dataPost.text[0]}</div>
        )
      }
      {
        dataPost.text[1] && (
          <div
            className="w-content text px-3 f-14 br"
            style={{color: dataPost.colors[3], bottom: '18%',
              fontSize:'40px', marginRight: '6%', letterSpacing: '4px',
              textShadow: `0 0 7px ${dataPost.colors[0]}`
            }}
          >{convertAmount(dataPost.text[1])}</div>
        )
      }
    </div>
  );
};

export default State11;
