import { db } from "../../../../../firebase/credentials"
import { doc, updateDoc } from "firebase/firestore"



const updateStep1 = async(userID, data)=>{


  const ref = doc(db,'admin', userID)
  try {
    await updateDoc(ref, {
      a: data.userName,
      j: data.bName,
      i: data.inputFile,
      l: [data.streetType, data.op1, data.op2, data.op3, data.city, data.neigborhood, data.description]
    })
    return true
  } catch (error) {
    return false
  }

}

export default updateStep1