
import React from 'react'
import fooder from '../../../../img/vector/logo_fooder.svg'
import locked from '../../../../img/dashboard/locked.svg'
import { Link } from 'react-router-dom'

const DontExist = ({idMenu}) => {
  return (
    <>
      <section className="lockedMenu">
        <div className="content">
          <div className="row justify-content-center">
            <div className="col-md-6">
              <img src={fooder} alt="fooder" className='fooder img-fluid'/>
            </div>
          </div>
          <div>
            <img src={locked} alt="locked" className='locked'/>
            <p className='m-0'>Este menú <span className='text-orange'> /{idMenu}</span> no existe, comprueba la ruta de acceso nuevamente.</p>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-6">
              <Link to='/' className='btnGreen'>Ir al Home</Link>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default DontExist
