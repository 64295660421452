const firstCat = [
  {name:'Platos a la carta'},
  {name:'Desayunos'},
  {name:'Pizzas'},
  {name:'Postres'},
  {name:'Sopas'},
  {name:'Hamburguesas'},
  {name:'Para 2 personas'},
  {name:'Gaseosas'},
]

export default firstCat