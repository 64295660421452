import React from 'react'
import { useSelector } from 'react-redux';
import { convertAmount } from '../../../../tools/format';


const Post20 = () => {

  const {dataPost, imgPostLogo, imgProduct} = useSelector(s => s.post)
  

  return (
    <div className="poster">
      <div className="img" style={{
        width: '98.5%', height: '66%', top:'17%', left: '2%'
      }}>
        {
          imgProduct && (
            <img
              src={imgProduct}
              className='b7'
              alt="img" />
          )
        }
        <div className="overlay" style={{background: dataPost.colors[2], opacity: .1}}/>
      </div>
      <div className="svg">
        <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">
          <polygon fill={`${dataPost.colors[2]}`}  points="1023.96 180 1023.96 0 -.04 0 -.04 1024 1023.96 1024 1023.96 844 24.96 844 24.96 180 1023.96 180"/>
          <rect fill={`${dataPost.colors[0]}`}  x="24.96" width="502" height="181"/>
          <polygon fill={`${dataPost.colors[1]}`}  points="24.96 844 24.96 180 -.04 180 -.04 1024 529.96 1024 529.96 844 24.96 844"/>
          <rect fill={`${dataPost.colors[1]}`}  x="950.96" width="73" height="145"/>
          <g>
            <rect fill={`${dataPost.colors[0]}`}  x="736.96" width="4" height="79"/>
            <rect fill={`${dataPost.colors[0]}`}  x="752.96" width="4" height="79"/>
            <rect fill={`${dataPost.colors[0]}`}  x="768.96" width="4" height="79"/>
            <rect fill={`${dataPost.colors[0]}`}  x="784.96" width="4" height="79"/>
            <rect fill={`${dataPost.colors[0]}`}  x="800.96" width="4" height="79"/>
            <rect fill={`${dataPost.colors[0]}`}  x="816.96" width="4" height="79"/>
            <rect fill={`${dataPost.colors[0]}`}  x="832.96" width="4" height="79"/>
            <rect fill={`${dataPost.colors[0]}`}  x="848.96" width="4" height="79"/>
            <rect fill={`${dataPost.colors[0]}`}  x="864.96" width="4" height="79"/>
          </g>
        </svg>
      </div>

      <img
        src={imgPostLogo}
        alt="logo" className="logo text bc"
        style={{marginBottom: ' 4%', right:'28%'

        }}
      />

      {
        dataPost.text[0] && (
          <div
            className="text tl px-1 f-9 clamp-2"
            style={{color: dataPost.colors[2], width: '46%',
            fontSize: '20px', marginTop: '4%', marginLeft: '4%', lineHeight: '24px'
          }}
          >{dataPost.text[0]}</div>
        )
      }
      {
        dataPost.text[1] && (
          <div
            className="w-content text px-3 f-9 br"
            style={{color: dataPost.colors[0],
              fontSize:'19px', marginBottom: '5.5%'
            }}
          >{convertAmount(dataPost.text[1])}</div>
        )
      }
      {
        dataPost.text[2] && (
          <div
            className="f-9 text bl clamp-3 text-end"
            style={{color: dataPost.colors[0], fontSize: '13px',
              width: '47%', lineHeight: ' 18px', left: '3%', bottom: '2%'
            }}
          >{dataPost.text[2]}</div>
        )
      }
    </div>
  );
};

export default Post20;
