import { createSlice } from "@reduxjs/toolkit"

const initialState ={
  order: [],
}
export const orderLinkSlice = createSlice({
  name: 'orderLink',
  initialState,
  reducers: {
    getDataOrder: (state,action) =>{
      state.order = action.payload
    },
    
  }
})

export const {
  getDataOrder
} = orderLinkSlice.actions

export default orderLinkSlice.reducer



