import React, { useState } from 'react'
import Login from './Login'
import Panel from './Panel'

const SuperAdmin = () => {

  const [isLogin, setIsLogin] = useState(false)
  const [visible, setVisible] = useState(false)
  

  const typingPass = e =>{

    if(e.target.value === process.env.REACT_APP_SUDOADMIN){
      setVisible(true)
    }else{
      setVisible(false)
    }
    
  }

  const confirmPass = ()=> setIsLogin(true)

  return (
    <>
      {isLogin ? <Panel />  :  <Login typingPass={typingPass} confirmPass={confirmPass} visible={visible}/>}
    </>
  )
}

export default SuperAdmin