import React from 'react'
import { useSelector } from 'react-redux';
import { convertAmount } from '../../../../tools/format';


const State16 = () => {

  const {dataPost, imgPostLogo, imgProduct} = useSelector(s => s.post)
  

  return (
    <div className="poster">
      <div className="img" style={{
        left: '10%', width: '80%', height: '73%'
      }}>
        {
          imgProduct && (
            <img
              src={imgProduct}
              className='b7'
              alt="img" />
          )
        }
      </div>
      <div className="svg">
        <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 490 870">
          <path fill={`${dataPost.colors[0]}`}  d="m438.5,389.5v45.53c0,106.98-86.63,193.71-193.5,193.71h0c-106.87,0-193.5-86.73-193.5-193.71V.06H0v870.94h490v-423.54c-17.17-19.32-34.33-38.64-51.5-57.96Z"/>
          <path fill={`${dataPost.colors[0]}`}  d="m146,.06v28.3c0,54.68,44.32,99,99,99h0c54.68,0,99-44.32,99-99V.06h-198Z"/>
          <path fill={`${dataPost.colors[1]}`}  d="m438.5.06v389.44c17.17,19.32,34.33,38.64,51.5,57.96V.06h-51.5Z"/>
        </svg>
      </div>

      <img
        src={imgPostLogo}
        alt="logo" className="logo text tc"
        style={{top:'2.4%'}}
      />

      {
        dataPost.text[0] && (
          <div
            className="text tc  px-2 f-13 clamp-2 text-center py-0"
            style={{color: dataPost.colors[2], 
            fontSize: '36px', lineHeight: ' 42px',
            width: '90%', top: '74%', padding: '7px 0'
          }}
          >{dataPost.text[0]}</div>
        )
      }
      {
        dataPost.text[1] && (
          <div
            className="w-content text px-3 f-4 bc"
            style={{color: dataPost.colors[2],
              fontSize:'31px', bottom: '4%'
            }}
          >{convertAmount(dataPost.text[1])}</div>
        )
      }
    </div>
  );
};

export default State16;
