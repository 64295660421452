import React, { useEffect } from 'react'
import Layout from '../../components/Layout'
import Header from './Header'
import Hero from './Hero'
import Benefits from './Benefits'
import CallToAction from './CallToAction'
import Comments from './Comments'
import Footer from './Footer'
import ModalLogin from './ModalLogin'
import ModalRegister from './ModalRegister'
import Loading from './Loading'
import Demos from './Demos'
import sudo from '../../firebase/update/sudo'

const Landing = () => {

  useEffect(() => {

    const visitor = localStorage.getItem('fooderVisitor');
    
    if (!visitor) {
      localStorage.setItem('fooderVisitor', 'true')
      sudo('visit')
    }
  }, [])

  return (
    <>
      <div className="landingPage">
        <Layout title='FOODER - Digitaliza tus comidas'>
          <Header />
          <Hero />
          <Benefits />
          <CallToAction />
          <Comments />
          <Footer />
        </Layout>
        <ModalLogin />
        <ModalRegister />
      </div>
      <Loading />
      <Demos />
    </>
  )
}

export default Landing