import React from "react";
import QRCode from "qrcode.react";
import { useSelector } from "react-redux";

const OrderPaper = () => {
  const { phone, businessName, address, email, logoMenu, ticket, indicative } = useSelector(s => s.log)
  


  return (
    <>
      <div className="printArea">
      <div className="text-center">
        {ticket?.[6] && (
          <>
            {logoMenu && logoMenu.length > 0 && (
              <>
                <div className="d-flex justify-content-center">
                  <img src={logoMenu} alt="logo" className="logoOrder" />
                </div>
              </>
            )}
          </>
        )}
        <strong className="d-block text-uppercase ">{businessName}</strong>
        {ticket?.[0]?.length > 0 && (
          <strong className="d-block text-uppercase mb-3 text-legal">
            NIT.: {ticket[0]}
          </strong>
        )}
      </div>
      <hr />
      <strong className="d-block text-center">CLIENTE</strong>
      <div className="row">
        <div className="col-12">
          <strong>Nombre del cliente</strong>
        </div>
        <div className="col-12">
          <p>Tel/Cel.: 1234567890</p>
        </div>
        <div className="col-12">
          <p>Método de pago: Efectivo</p>
        </div>
        <div className="col-12">
          <p>OP: 1234567890</p>
        </div>
        <div className="col-12">
          <p>Expedido: dd/mm/aaaa | hora: 00:00:00</p>
        </div>
      </div>
      <hr />
      <strong className="d-block text-center mb-2">PRODUCTOS</strong>
      <div className="row">
        <div className="col-2 text-center">
          <strong className="d-block mb-2">Cant.</strong>
        </div>
        <div className="col-6">
          <strong className="d-block mb-2">Descripción</strong>
        </div>
        <div className="col-4 text-end">
          <strong className="d-block mb-2">Valor</strong>
        </div>

        <div className="d-flex justify-content-center">
          <table>
            <tbody>
              <tr>
                <td>1</td>
                <td>
                  Nombre de tu producto 1 | detalle de opción única | detalle
                  opción múltiple
                </td>
                <td>$10.000</td>
              </tr>
              <tr>
                <td>2</td>
                <td>
                  Nombre de tu producto 2 | detalle de opción única | detalle
                  opción múltiple
                </td>
                <td>$40.000</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <hr />
      <strong className="d-block text-center mb-3">
        RESUMEN DE COSTOS
      </strong>
      <div className="row justify-content-end">
        <div className="col-md-7 text-end px-0">
          <p className="mb-1">Subtotal</p>
          <p className="mb-1">Valor domicilio</p>
          <p className="mb-1">Total descuentos</p>

          <p>Total a pagar</p>
        </div>
        <div className="col-md-5 text-end">
          <p className="mb-1">$50.000</p>
          <p className="mb-1">$0</p>
          <p className="mb-1">$0</p>
          <p>
            <strong>$50.000</strong>
          </p>
        </div>
      </div>
      <hr />
      {ticket?.[2]?.length > 0 && (
        <>
          <small className="text-legal" style={{ textAlign: "justify" }}>
            {ticket[2]}
          </small>
          <hr />
        </>
      )}
      {ticket && (
        <>
          <p className="text-center">
            {ticket?.[3] && (
              <>
                Dir.:{address[0]} {address[1]} # {address[2]} - {address[3]}{" "}
                | {address[4]}, {address[5]}
              </>
            )}{" "}
            {ticket?.[4] && (
              <>
                <span>Tel.: {phone}</span>
              </>
            )}
          </p>
        </>
      )}
      {ticket?.[5] && (
        <>
          <p className="text-center">Email: {email}</p>
          <hr />
        </>
      )}

      <p className="text-center mt-2">
        Desarrollado por Fooder - Digitaliza tus comidas +57 312 559 9832
      </p>
      <hr />
      {ticket?.[7] && (
        <>
          <div className="d-flex justify-content-center mb-2 mt-3">
            <QRCode
              value={`https://fooderapp.co/menu/${indicative}${phone}`}
              className="qrCode mb-1"
              size={130}
            />
          </div>
          <small className="d-block text-center text-legal">
            Para futuras compras visita nuestro sitio web
          </small>
        </>
      )}
      {ticket?.[1]?.length > 0 && (
        <>
          <strong className="d-block text-center mt-2">{ticket[1]}</strong>
        </>
      )}
    </div>
    </>
  );
};

export default OrderPaper;
