import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { sendEmailToChangePassword } from '../firebase/credentials'
import emailSent from '../img/dashboard/email_sent.svg'

const ModalChangePassword = () => {

  const {email, name} = useSelector(s => s.log)
  const [isSent, setIsSent] = useState(false)


  const handleSendMail = async()=>{
    try {
      await sendEmailToChangePassword(email)
      setIsSent(true)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <div className="modal fade modalDashboard" id="modalChangePassword" /* data-bs-backdrop="static" */ data-bs-keyboard="false" tabIndex="-1" aria-labelledby="modalChangePasswordLabel" aria-hidden="true">
        <div className="modal-dialog  modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <div className="d-flex justify-content-between align-items-center w-100">
                <h1 className="m-0 fs-5 text-apple">Cambio de contraseña</h1>
                <button
                  type="button"
                  className="btn-green-dark"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  id='closeModalMistake'
                >Cerrar</button>
              </div>
            </div>
            <div className="modal-body text-center">
            
              {
                isSent ? (
                  <>
                    <img src={emailSent} alt="icono" style={{width: '90px'}} />
                    <div className="row justify-content-center">
                      <div className="col-md-9">
                        <h6 className="text-white py-3">¡Solicitud de cambio de contraseña hecho, revisa tu correo {email}!</h6>
                        <p className="text-apple">El envío del correo puede tardar hasta 10 minutos, no olvides realizar tu bandeja principal o correos SPAM</p>
                      </div>
                      <div className="col-md-6 col-8 mt-3">
                        <button
                          className='btn-line-dark w-100'
                          type='button'
                          data-bs-dismiss="modal"
                        >Cerrar</button>
                      </div>
                    </div>
                  </>
                ):
                (
                  <>
                    <div className="p-4">
                      <h5 className="text-white mb-4">Hola <strong className="text-apple h5">{name}</strong>, ¿que tal todo?</h5>
                      <p>Parece que quieres cambiar tu contraseña actual. Enviaremos un correo a <strong className="text-white">{email}</strong>, este correo contiene un enlace para que actualices tu contraseña.</p>
                      <h6 className="text-white py-4">Confirmar cambio de contraseña</h6>
                      <div className="row justify-content-center">
                        <div className="col-md-6">
                          <button className='btn-line-dark w-100' type='button' data-bs-dismiss="modal">No, en otro momento</button>
                        </div>
                        <div className="col-md-6 mt-3 mt-md-0">
                          <button
                            className='btn-green-dark w-100'
                            type='button'
                            onClick={handleSendMail}
                          >Sí, enviar correo</button>
                        </div>
                      </div>
                    </div>
                  </>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ModalChangePassword