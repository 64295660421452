import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addOrder, addToFavoriteStorage, setComment, setCounter, setPriceMultiple, setPriceOne, setProductSelected, setResetPriceMultiple } from '../../../../redux/reducer/user'
import { convertAmount } from '../../../tools/format'
import { useForm } from 'react-hook-form'

const ModalSelectedProduct = () => {

  const {productSelected, counter, priceOne, priceMultiple, menu, favoriteStorage, admin, orderSent} = useSelector(state => state.user)
  const dispatch = useDispatch()
  const [totalMultiple, setTotalMultiple] = useState(0)
  const [existFavorite, setExistFavorite] = useState(false)
  const [totalPrice, setTotalPrice] = useState(0)


  const {register, handleSubmit, formState: {isValid }, watch, reset, trigger} = useForm({
    mode: 'onChange'
  })


  useEffect(()=>{
    if(productSelected){
      let res = favoriteStorage.findIndex(el => el === productSelected?.c)
      if(res === -1){
        setExistFavorite(false)
      }else{
        setExistFavorite(true)
      }
      if(!productSelected?.j?.active && !productSelected?.i?.active){
        trigger()
      }
    }
  },[favoriteStorage, productSelected, trigger])

  
  const btnClose = useRef(null)
  const multicheck = useRef(null)


  const defineValueMultiple = (priceMultiple)=>{
    if(priceMultiple.length > 0){
      let allprices = []
      priceMultiple.forEach(el => allprices.push(el.value))
      let partialMultiple = allprices.reduce((prev, acc)=>{
        return prev + acc
      })
      setTotalMultiple(partialMultiple)
    }else{
      setTotalMultiple(0)
    }
  }


  useEffect(()=>{
    defineValueMultiple(priceMultiple)
  },[priceMultiple])



  const defineTotalPrice = (productSelected,priceOne,totalMultiple,counter)=>{
    if(productSelected){
      let productPrice = productSelected.f[1] === null ? productSelected.f[0]: productSelected.f[1]
      let result = (productPrice + priceOne + totalMultiple)*counter
      setTotalPrice(result)
    }
  }
  
  useEffect(()=>{
    defineTotalPrice(productSelected,priceOne,totalMultiple,counter)
  },[productSelected,priceOne,totalMultiple,counter])


  

  const closeModal = ()=>{
    setTimeout(()=>{
      dispatch(setProductSelected(null))
      dispatch(setCounter(1))
      dispatch(setPriceOne(0))
      dispatch(setComment(''))
      dispatch(setResetPriceMultiple())
      reset()
    }, 200)
  }
  
  const handleMinus = ()=>{
    if(counter === 1){
      btnClose.current.click()
      dispatch(setCounter(1))
      dispatch(setPriceOne(0))
      dispatch(setComment(''))
      dispatch(setResetPriceMultiple())
      reset()
    }else{
      dispatch(setCounter(counter -1))
    }
  }

  const addToFavorite = id => {
    dispatch(addToFavoriteStorage(id))
  }


  const onSubmit = (data) =>{

    const multipleFormat = ()=>{
      if (priceMultiple?.length === 0) {
        return "";  
      } else if (priceMultiple?.length === 1) {
        return priceMultiple?.[0]?.title;
      } else {
        const formattedTitles = priceMultiple.map(obj => obj?.title);
        return formattedTitles.slice(0, -1).join(', ') + ' y ' + formattedTitles[priceMultiple?.length - 1];
      }
    }

    let obj ={
      id: productSelected?.c,
      title: productSelected?.e,
      img: productSelected?.d,
      category: productSelected?.g,
      price: totalPrice/counter,
      count: counter,
      multipleTitle: priceMultiple === undefined ? []: multipleFormat(),
      oneOption: data.oneOption === undefined || data.oneOption === false ? '': data.oneOption ,
      comments: data.comments === undefined ? '' : data.comments,
    }
    dispatch(addOrder(obj))
    dispatch(setCounter(1))
    dispatch(setPriceOne(0))
    dispatch(setComment(''))
    dispatch(setResetPriceMultiple())
    reset()

  }

  const handleSendProduct = () =>{
    handleSubmit(onSubmit)()
    if(multicheck.current){
      Object.values(multicheck.current.children).forEach(el =>{
        el.classList.remove('disabledCheck')
      })
    }
  }



  return (
    <>
      <div className="modal fade" id="showMProduct" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="showMProductLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" >
          <form className="modal-content" onSubmit={handleSubmit(onSubmit)}>
            <div className="modal-header">
              <h1 className="modal-title fs-5 text-truncate" id="showMProductLabel">
                <span className="cat">{productSelected && productSelected?.g}</span>
                {productSelected && productSelected?.e}</h1>
              <button
                type="button"
                className="btn-close"
                 data-bs-dismiss="modal" aria-label="Close"
                onClick={closeModal}
                ref={btnClose}
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className='px-0 px-md-3 col-md-5'>
                  <div className="row justify-content-center">
                    <div className="col-12">
                      <div className="imgP-Modal mb-4 mb-md-3">
                        <img src={productSelected && productSelected?.d} alt={productSelected && productSelected?.e} className='img-fluid  w-100'/>
                      </div>
                    </div>
                    <div className="col-md-12 col-10">
                      <div className="row">
                        <div className="col-10">
                          <div className="prices">
                            {
                              productSelected && productSelected?.f?.[1] === null ? (
                                <>
                                  <strong className="after">{convertAmount(productSelected && productSelected.f[0])}</strong>
                                </>
                              ):
                              (
                                <>
                                  <span className="before">Antes <span>{convertAmount(productSelected && productSelected.f[0])}</span></span>
                                  <strong className="after">{convertAmount(productSelected && productSelected.f[1])}</strong>
                                </>
                              )
                            }
                          </div>
                        </div>
                        <div className="col-2">
                          <div className="d-flex align-items-center justify-content-end">
                            
                            {productSelected && productSelected?.k && (
                              <svg id="Capa_1" className='icon' data-name="Capa 1" fill={`${menu?.g?.[3]}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
                                <path d="m32.62,11.06c-.53-.05-.99-.14-1.45-.12-.29.01-.61.15-.87.32-.64.42-1.16.96-1.52,1.64-.1.2-.13.38-.04.58.15.32.16.65.13.99,0,.07.01.18.06.22.63.53.75,1.22.67,1.98-.1.91-.52,1.7-.99,2.47-1.36,2.16-3.14,3.91-5.2,5.39-1.69,1.21-3.49,2.21-5.49,2.78-3.51,1-6.85.5-10.03-1.21-2.46-1.32-3.99-3.4-4.84-6.02-.13-.4,0-.76.32-.98.32-.22.69-.2,1.03.06.98.75,2,1.45,3.17,1.87,2.72.96,5.28.61,7.67-.99.75-.5,1.28-1.21,1.71-1.98.48-.85.92-1.73,1.38-2.6.55-1.04,1.18-2.04,2.05-2.85.69-.64,1.46-1.14,2.35-1.44.91-.31,1.82-.34,2.67.19.04.02.09.04.13.04.34.02.69.02,1.03.05.16.01.24-.04.32-.17.57-.96,1.3-1.79,2.24-2.42.75-.5,1.58-.77,2.49-.67,1.02.11,1.58.92,1.33,1.94-.08.33-.22.65-.32.92ZM4.41,20.58s0,.01,0,.03c.01.04.02.07.04.11.84,1.93,2.12,3.45,4,4.44,3.6,1.9,7.27,2.12,11,.42,2.52-1.14,4.69-2.77,6.56-4.81.75-.82,1.42-1.72,1.97-2.69.28-.5.51-1.03.56-1.61.03-.32,0-.62-.23-.87-.46.24-.72.3-1.15.22-.74-.15-1.42-.44-1.93-1.01-.68-.75-1.1-1.59-.67-2.65-.31-.12-.64-.13-.96-.07-.89.16-1.63.62-2.31,1.2-.82.71-1.4,1.61-1.9,2.55-.52.97-1.01,1.95-1.56,2.9-.51.9-1.17,1.7-2.05,2.27-3.11,1.99-6.34,2.19-9.66.57-.59-.29-1.13-.66-1.7-.99Zm27.47-10.8c.04-.32-.07-.47-.4-.5-.09,0-.17,0-.26,0-.79.03-1.44.39-2.02.9-.58.51-1.08,1.1-1.45,1.78-.25.46-.61.66-1.12.59-.23-.03-.47-.05-.7-.06-.26-.01-.48.2-.46.46,0,.14.05.28.1.41.28.73.85,1.12,1.57,1.33.15.04.43.07.47,0,.11-.19.13-.43.17-.66,0-.05-.03-.1-.05-.15-.2-.51-.16-1.01.09-1.5.48-.9,1.17-1.59,2.01-2.13.24-.16.54-.28.82-.33.39-.08.8-.09,1.23-.13Z"/>
                                <path d="m10.7,23.98c2.54-.2,4.78-1.13,6.61-2.95.84-.84,1.4-1.88,1.93-2.94.39-.77.75-1.55,1.18-2.3.46-.82,1.06-1.54,1.78-2.15.12-.1.28-.17.44-.19.22-.03.43.14.5.35.08.23.04.45-.15.6-1.24.93-2.04,2.19-2.78,3.52-.47.83-.99,1.64-1.55,2.41-1.12,1.55-2.69,2.46-4.46,3.09-1.04.37-2.1.58-3.2.59-.06,0-.13,0-.19,0-.05,0-.09-.02-.11-.02Z"/>
                              </svg>
                            )}
                            
                            {
                              menu?.p?.length > 0 && (
                                <>
                                  <button type='button' className='favorite' onClick={()=> addToFavorite(productSelected?.c)}>
                                    {
                                      existFavorite ? (
                                        <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 225 225" fill={`${menu?.g?.[0]}`}>
                                          <path d="m114,198.53c-23.76-22.9-47.56-45.75-71.24-68.72-5.85-5.68-11.89-11.32-16.82-17.76-13.53-17.69-15.79-37.17-6.01-57.2,9.65-19.76,26.3-29.67,48.15-29.85,13.44-.11,25.38,4.89,35.18,14.29,3.1,2.98,5.79,6.39,9.36,10.39,3.79-4.18,6.52-7.82,9.87-10.78,15.51-13.71,33.4-17.73,52.9-10.86,19.47,6.86,31.09,21.19,34.75,41.5,3.39,18.77-2.5,34.83-15.71,48.27-26.29,26.74-52.67,53.39-79.01,80.07-.81.83-1.68,1.6-2.53,2.39.37-.58.74-1.15,1.11-1.73Z"/>
                                        </svg>
                                      ):
                                      (
                                        <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 225 225" fill={`${menu?.g?.[3]}`}>
                                          <path d="m114,198.53c-23.76-22.9-47.56-45.75-71.24-68.72-5.85-5.68-11.89-11.32-16.82-17.76-13.53-17.69-15.79-37.17-6.01-57.2,9.65-19.76,26.3-29.67,48.15-29.85,13.44-.11,25.38,4.89,35.18,14.29,3.1,2.98,5.79,6.39,9.36,10.39,3.79-4.18,6.52-7.82,9.87-10.78,15.51-13.71,33.4-17.73,52.9-10.86,19.47,6.86,31.09,21.19,34.75,41.5,3.39,18.77-2.5,34.83-15.71,48.27-26.29,26.74-52.67,53.39-79.01,80.07-.81.83-1.68,1.6-2.53,2.39.37-.58.74-1.15,1.11-1.73Zm-.34-19.14c-.52.39.05.06.5-.39,24.12-24.42,48.31-48.78,72.31-73.32,6.76-6.91,10.11-15.61,10.58-25.23.81-16.45-8.86-31.95-23.69-38.39-14.89-6.46-31.1-3.14-43.16,8.78-5.89,5.82-11.86,11.57-17.2,16.78-5.93-5.6-12.03-11.27-18.03-17.04-9.18-8.82-20.04-13.19-32.73-11.3-15.7,2.34-26.58,11.37-31.74,26.26-5.37,15.51-1.81,29.59,9.55,41.42,10.27,10.69,20.69,21.25,31.28,31.63,13.91,13.64,28.05,27.05,42.33,40.8Z"/>
                                        </svg>
                                      )
                                    }
                                  </button>
                                </>
                              )
                            }
                          </div>
                        </div>
                      </div>
                      
                      <p className='mb-5 mb-md-3'>{productSelected && productSelected?.h}</p>
                    </div>
                    
                  </div>
                </div>
                <div className="col-md-7">
                  {
                    productSelected && (
                      <>
                        {
                          productSelected?.i?.active && (
                            <div className="region mb-0 mb-md-3">
                              <div className='row justify-content-center'>
                                <div className="col-md-12 col-11">
                                  <div className="row">
                                    <div className="col-8 align-self-center">
                                      <span>Elige un item</span>
                                    </div>
                                    <div className="col-4 text-end">
                                      <span className={`label ${productSelected.i?.req ? 'req': 'op'}`}>{productSelected.i?.req ? 'Requerido' : 'Opcional'}</span>
                                    </div>
                                    <div className="col-12 text-center">
                                      <h6 className='my-4 my-md-3'>{productSelected.i?.title}</h6>
                                    </div>
                                    <div className="col-12">
                                      {productSelected.i?.op?.length > 0 && productSelected.i?.op?.map((option,index)=> (
                                        <div className="p-relative d-flex align-items-center justify-content-between" key={index}>
                                          <label className="groupMenu">
                                            <span>{option?.title}</span>
                                            <div>
                                              <input
                                                type="radio"
                                                value={option?.title}
                                                data-oneoption={option?.value}
                                                {...register(`oneOption`, {
                                                  required: productSelected?.i?.req,
                                                  onChange: e =>{
                                                    let value = parseInt(e.target.getAttribute('data-oneoption'))
                                                    dispatch(setPriceOne(value))
                                                  }
                                                })}
                                              />
                                              <strong className="price">{option?.value === 0 ? '': `+ ${convertAmount(option?.value)}`}</strong>
                                            </div>
                                          </label>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        }
                      </>
                    )
                  }
                  {
                    productSelected && (
                      <>
                        {
                          productSelected?.j?.active && (
                            <div className="region mb-0 mb-md-3">
                              <div className='row justify-content-center'>
                                <div className="col-md-12 col-11">
                                  <div className="row">
                                    <div className="col-8 align-self-center">
                                      <span>Elige hasta {productSelected.j.max} items</span>
                                    </div>
                                    <div className="col-4 text-end">
                                      <span className={`label ${productSelected.j.req ? 'req': 'op'}`}>{productSelected.j.req ? 'Requerido' : 'Opcional'}</span>
                                    </div>
                                    <div className="col-12 text-center">
                                      <div className="d-flex justify-content-center align-items-center">
                                        <h6 className='my-4 my-md-3 me-2'>{productSelected.j.title} </h6>
                                      </div>
                                    </div>
                                    <div className="col-12" ref={multicheck}>
                                      {productSelected.j.op.length > 0 && productSelected.j.op.map((option,index)=> (
                                        <div className="p-relative d-flex align-items-center justify-content-between" key={index}>
                                          <label className="groupMenuCheck">
                                            <span>{option.title}</span>
                                            <div>
                                              <input
                                                type="checkbox"
                                                value={index}
                                                {...register('multiple', {
                                                  required: productSelected.j.req,
                                                  onChange: e =>{
                                                    let ref = watch('multiple').length
                                                    if(ref >= productSelected.j.max){
                                                      Object.values(multicheck.current.children).forEach(el =>{
                                                        if(el.children[0].children[1].children[0].checked === false){
                                                          el.classList.add('disabledCheck')
                                                        }
                                                      })
                                                    }else{
                                                      Object.values(multicheck.current.children).forEach(el =>{
                                                        el.classList.remove('disabledCheck')
                                                      })
                                                    }
                                                    dispatch(setPriceMultiple(parseInt(e.target.value)))
                                                  }
                                                })}
                                              />
                                              <strong className="price">{option.value === 0 ? '': `+ ${convertAmount(option.value)}`}</strong>
                                            </div>
                                          </label>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        }
                      </>
                    )
                  }
                  {
                    productSelected && (
                      <>
                        {productSelected?.l && (
                          <>
                            <div className="row justify-content-center mb-5 mt-3 mt-md-0">
                              <div className="col-md-12 col-11">
                                <div className=" rounded text-bg1 mt-3">
                                  <textarea
                                    placeholder='Agregar una nota'
                                    defaultValue=''
                                    cols="30"
                                    rows="2"
                                    maxLength={120}
                                    className='modalComments'
                                    {...register('comments', {
                                      required: false,
                                      onChange: e =>{
                                        dispatch(setComment(e.target.value))
                                      }
                                    })} 
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    )
                  }
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <div className="d-flex justify-content-between justify-content-md-end w-100">
                <div className="counterProduct">
                  <button onClick={e => handleMinus(e)} type='button' className='minus'>-</button>
                  <span>{counter}</span>
                  <button onClick={()=> dispatch(setCounter(counter + 1))} type='button' className='plus'>+</button>
                </div>


                <button
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleSendProduct}
                  type='button'
                  className={`addModal d-flex d-md-none ${isValid ? '' : 'disabled'}`}
                >
                  <strong>Agregar + {convertAmount(productSelected && totalPrice)}</strong>
                </button>
                
                <button
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleSendProduct}
                  type='button'
                  className={`addModal d-none d-md-flex ${isValid ? '' : 'disabled'}`}
                >
                    <strong>Agregar y seguir comprando</strong>
                </button>

                {
                  menu?.p?.length > 0 && (
                    <>
                      {
                        orderSent === '' && (
                          <>    
                            <button
                              onClick={handleSendProduct}
                              type='button'
                              className={`lineBtnModal d-none d-md-flex ${isValid ? '' : 'disabled'}`}
                              data-bs-toggle="modal"
                              data-bs-target="#checkoutModal"
                            >
                              <strong>Agregar {convertAmount(productSelected && totalPrice)} y finalizar compra</strong>
                            </button>
                          </>
                        )
                      }
                    </>
                  )
                }

              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default ModalSelectedProduct