import React from 'react'
import { useSelector } from 'react-redux';
import { convertAmount } from '../../../../tools/format';


const Post1 = () => {

  const {dataPost, imgPostLogo, imgProduct} = useSelector(s => s.post)
  

  return (
    <div className="poster">
      <div className="img">
        {
          imgProduct && (
            <img
              src={imgProduct}
              className='b7'
              alt="img" />
          )
        }
        <div className="overlay" style={{background: dataPost.colors[2], opacity: .1}}/>
      </div>
      <div className="svg">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1024 1024"
          className="svg-class"
        >
          
          <path fill={`${dataPost.colors[1]}`} d="m446,809s-201,111-336,121c-48.06,3.56-83.84,4.58-110,4.43v89.57h1024V248.43L446,809Z"/>
          <path fill={`${dataPost.colors[0]}`}   d="m1024,174.49L484.78,713.72c-46.41,46.41-96.39,84.64-148.55,113.62-41.57,23.1-84.65,40.43-128.05,51.52-72.5,18.52-122.07,13.27-124.63,12.98l-2.28-.33H0v77h76.41c4.65.47,14.4,1.24,28.41,1.24,26.27,0,67.49-2.7,117.93-15.15,50.35-12.42,100.18-32.08,148.11-58.41,59.43-32.65,116.08-75.72,168.37-128.01l484.78-484.78v-108.89Z"/>
          <polygon fill={`${dataPost.colors[3]}`}   points="56.5 387 59.5 387 59.5 59.5 385 59.5 385 56.5 56.5 56.5 56.5 387"/>
          <polygon fill={`${dataPost.colors[3]}`}   points="966 639.5 963 639.5 963 967 637.5 967 637.5 970 966 970 966 639.5"/>
          <polygon fill={`${dataPost.colors[3]}`}   points="781 0 781 152 881 123.48 981 152 981 0 781 0"/>
        </svg>
      </div>

      <img
        src={imgPostLogo}
        alt="logo" className="text logo"
        style={{right: '6%', top: '1.4%'}}
      />

      {
        dataPost.text[0] && (
          <div
            className="text cr  py-1 px-2 f-3 text-truncate"
            style={{color: dataPost.colors[3], marginTop: '-32%', maxWidth: '80%',
            fontSize: '15px', marginRight: '5%', background: dataPost.colors[2] }}
          >{dataPost.text[0]}</div>
        )
      }
      {
        dataPost.text[1] && (
          <div
            className="w-content text px-3 f-2 cr"
            style={{color: dataPost.colors[1],
              background: dataPost.colors[3], fontSize:'30px',
              marginTop:' -16%', marginRight: '5%', maxWidth: '80%',
              textShadow: `2px 2px 0px ${dataPost.colors[0]}`
            }}
          >{convertAmount(dataPost.text[1])}</div>
        )
      }
      {
        dataPost.text[2] && (
          <div
            className="f-2 text bl text-truncate"
            style={{color: dataPost.colors[3], marginLeft:'5%', fontSize: '33px', width: '90%',
              marginBottom: '35%', textShadow: `0 0 10px ${dataPost.colors[2]}` 
            }}
          >{dataPost.text[2]}</div>
        )
      }
      {
        dataPost.text[3] && (
          <div
            className="text bl py-1 px-2 f-3 text-truncate"
            style={{color: dataPost.colors[3], fontSize: '16px', maxWidth: '85%',
            marginLeft:'5%', marginBottom:'25%', background: dataPost.colors[2]}}
          >{dataPost.text[3]}</div>
        )
      }
      {
        dataPost.text[4] && (
          <div
            className="text br py-1 px-2 f-3 text-truncate"
            style={{color: dataPost.colors[3], fontSize: '13px', maxWidth: '87%', 
              marginBottom:'8%', marginRight: '8%', border: `1px solid ${dataPost.colors[3]}`
            }}
          >{dataPost.text[4]}</div>
        )
      }
    
    </div>
  );
};

export default Post1;
