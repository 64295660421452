import React from 'react'
import { useSelector } from 'react-redux'

const LoadingForm = () => {

  const {stateLoading} = useSelector(s => s.ux)

  return (
    <>
      <div className={`isSubmiting ${stateLoading ? 'active': ''}`}>
        <div className="spinnerAdmin">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        </div>
      </div>
    </>
  )
}

export default LoadingForm