import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setUserToDelete } from '../../../redux/reducer/sudo'
import deleteUserFooder from './fx/deleteUserFooder'
import { setIsError, setStateNotification, setTextNotificate } from '../../../redux/reducer/interactions'

const ModalDeleteFooderUser = () => {

  const {userToDelete} = useSelector(s => s.sudo)
  const dispatch = useDispatch()
  
  console.log(userToDelete)

  const handleClose  = () => dispatch(setUserToDelete({}))

  const deleteUser = async() => {

    
    const response = await deleteUserFooder(userToDelete?.id, userToDelete?.k, userToDelete?.f)

    if(response){
      dispatch(setTextNotificate('Nuevo usuario agregado  para CMS Fooder'))
      dispatch(setStateNotification(true))
      dispatch(setIsError(false)) 
    }else{
      dispatch(setTextNotificate('El usuario no pudo ser creado.'))
      dispatch(setStateNotification(true))
      dispatch(setIsError(true)) 
    }
  }



  return (
    <div
      className="modal modalDashboard fade"
      data-bs-backdrop="static" 
      data-bs-keyboard="false"
      id="ModalDeleteFooderUser" 
      tabIndex="-1" 
      aria-labelledby="ModalDeleteFooderUserLabel" 
      aria-hidden="true"
    >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="p-3 p-md-5 text-center text-white">
                <h4 className='text-apple mb-3'>¿Estás seguro de eliminar a {userToDelete?.a || 'NA'}?</h4>
                <h6 className="text-white mb-5">Este cliente cuenta con {userToDelete?.c} créditos</h6>
                <div className="row justify-content-center">
                  <div className="col-md-6">
                    <button
                      className='btn-line-apple w-100 m-0'
                      data-bs-dismiss="modal"
                      onClick={handleClose}
                    >No, cerrar</button>
                  </div>
                  <div className="col-md-6 order-first order-md-last mb-4 mb-md-0">
                    <button
                      className='btn-orange w-100 m-0'
                      data-bs-dismiss="modal"
                      onClick={deleteUser}
                    >SÍ, BORRAR USUARIO</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}

export default ModalDeleteFooderUser