import React from 'react'
import { useSelector } from 'react-redux'

const Loading = () => {

  const {loadLanding} = useSelector(s => s.ux)


  return (
    <div className={`loadLanding ${loadLanding ? 'active': ''}`}>
      <div />
    </div>
  )
}

export default Loading