import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import {setIsError, setStateLoading, setStateNotification, setTextNotificate} from "../../../../../redux/reducer/interactions"
import { setNewSchedule } from "../../../../../redux/reducer/menu"
import Delivery from "./Delivery"
import AvalibleMenu from "./AvalibleMenu"
import updateScheduleModule from '../fx/updateScheduleModule'



const Schedule = () => {
  const { custumerSchedule, priceDelivery } = useSelector((state) => state.menu)
  const { userID } = useSelector((state) => state.log)
  const dispatch = useDispatch()

  const {register, handleSubmit, formState: { errors, isDirty }, clearErrors, setValue, trigger, watch} = useForm({
    mode: "onChange",
  })

  const handleSelectAll = () => {
    const start = watch(`start_0`)
    const end = watch(`end_0`)

    custumerSchedule.forEach((item, index) => {
      setValue(`checked_${index}`, true);
      setValue(`start_${index}`, start);
      setValue(`end_${index}`, end);
    })

    dispatch(setNewSchedule({index: 0, type: 4, value: null}))
    
    clearErrors()
    custumerSchedule.forEach((_, index) => {
      trigger(`start_${index}`)
      trigger(`end_${index}`)
    })
  }


  const submitSchedule = async (data) => {

    const days = [
      {a: data.checked_0, b: data.start_0, c: data.end_0},
      {a: data.checked_1, b: data.start_1, c: data.end_1},
      {a: data.checked_2, b: data.start_2, c: data.end_2},
      {a: data.checked_3, b: data.start_3, c: data.end_3},
      {a: data.checked_4, b: data.start_4, c: data.end_4},
      {a: data.checked_5, b: data.start_5, c: data.end_5},
      {a: data.checked_6, b: data.start_6, c: data.end_6},
    ] 

    const {deliveryPrice,minDelivery,disabledAccount} = data
    const sendObject = {
      week: days,
      deliveryPrice: deliveryPrice.length === 0 ? 0 : parseInt(deliveryPrice),
      minDelivery: minDelivery.length === 0 ? 0 : parseInt(minDelivery),
      disabledAccount
    }

   
    if (isDirty) {
      dispatch(setStateLoading(true))
      const response = await updateScheduleModule(userID,sendObject)
      if(response){
        dispatch(setTextNotificate('¡Tu módulo de domicilios y horarios ha sido actualizado!'))
        dispatch(setStateNotification(true))
        dispatch(setIsError(false))
      }else{
        dispatch(setTextNotificate('Ha ocurrido un problema con la actualización de los datos, inténtalo nuevamente.'))
        dispatch(setStateNotification(true))
        dispatch(setIsError(true))
      }
      dispatch(setStateLoading(false))
    }
  }



  return (
    <>
      <div className="py-0 pb-md-4">
        <div className="row justify-content-center">
          <div className="col-12">
            <form onSubmit={handleSubmit(submitSchedule)}>
              <div className="row mb-5 mb-md-0">
                <Delivery errors={errors} register={register} />
                <AvalibleMenu register={register} />
                <div className="bg-b1 p-4 rounded-lg text-white mb-5 mb-md-0">
                  <div className="row">
                    <div className="d-flex align-items-center justify-content-between mb-3">
                      <h6 className="m-0 text-apple">Habilita tus horarios de atención</h6>
                      <span className="optionalLabel">Opcional</span>
                    </div>

                    {custumerSchedule.map((item, index) => {

                      const days = ['Lunes','Martes','Miércoles','Jueves','Viernes','Sábado','Domingo']

                      return (
                        <div className="col-md-4 px-0 px-md-3" key={index}>
                          <div className="p-1">
                            <div className="daySchedule">
                              <div className="row">
                                <div className="col-md-12 align-self-center">
                                  <label className="pr">
                                    <input
                                      type="checkbox"
                                      checked={item.a}
                                      {...register(`checked_${index}`,{
                                        required: {value: item.a},
                                        onChange: e => dispatch(setNewSchedule({index, type: 1, value: e.target.checked}))
                                      })}
                                    />
                                    <span>
                                      {days[index]}
                                      {!item.a && <span className="text-green">Habilitar</span>}
                                    </span>
                                  </label>
                                </div>
                                <div className="col-12">
                                  <div className={`row ${item.a ? "" : "disabledField"}`}>
                                    <div className="col-md-12 align-self-center">
                                      <small className="d-block mt-2 mb-1 text-center">
                                        Hora inicial {item.a ? "*" : ""}
                                      </small>
                                      <input
                                        type="time"
                                        className="inputTime"
                                        value={item.b || ''}
                                        {...register(`start_${index}`, {
                                          required: {value: item.a,message: "Hora requerida"},
                                          onChange: e => dispatch(setNewSchedule({index, type: 2, value: e.target.value}))
                                        })}
                                      />
                                      {errors[`start_${index}`] && (
                                        <small className="errorDash justify-content-center w-100">
                                          {errors[`start_${index}`].message}
                                        </small>
                                      )}
                                    </div>
                                    <div className="col-md-12 align-self-center">
                                      <small className="d-block mt-2 mb-1 text-center">
                                        Hora final{item.a ? "*" : ""}
                                      </small>
                                      <input
                                        type="time"
                                        className="inputTime"
                                        value={item.c || ''}
                                        {...register(`end_${index}`, {
                                          required: {value: item.a,message: "Hora requerida"},
                                          onChange: e => dispatch(setNewSchedule({index, type: 3, value: e.target.value}))
                                        })}
                                      />
                                      {errors[`end_${index}`] && (
                                        <small className="errorDash justify-content-center w-100">
                                          {errors[`end_${index}`].message}
                                        </small>
                                      )}
                                    </div>
                                    {
                                      index === 0 && (
                                        <div className="col-12 mt-3">
                                          <button 
                                            type="button"
                                            className="btnGreen w-100"
                                            onClick={()=> handleSelectAll(index)}
                                          >Mismo todos los días</button>
                                        </div>
                                      )
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>

                <div className="footerSettingsDash">
                  <button type="submit" className="btnSave">
                    Guardar
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default Schedule
