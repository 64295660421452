import React from 'react'
import { useSelector } from 'react-redux';
import { convertAmount } from '../../../../tools/format';


const State7 = () => {

  const {dataPost, imgPostLogo, imgProduct} = useSelector(s => s.post)
  

  return (
    <div className="poster">
      <div className="img" style={{
        width: '64%', height: '62%', top: '39%', left:'15%'
      }}>
        {
          imgProduct && (
            <img
              src={imgProduct}
              className='b7'
              alt="img" />
          )
        }
      </div>
      <div className="svg">
        <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 490 870">
          <path fill={`${dataPost.colors[0]}`}  d="m0,.44v870h77v-367.5c0-85.33,69.17-154.5,154.5-154.5h0c85.33,0,154.5,69.17,154.5,154.5v367.5h104V.44H0Z"/>
          <path fill={`${dataPost.colors[3]}`}  d="m240.5,327.44c-91.69,0-166.28,74.59-166.28,166.28v376.72h2v-376.72c0-90.59,73.7-164.28,164.28-164.28s164.28,73.7,164.28,164.28v376.72h2v-376.72c0-91.69-74.59-166.28-166.28-166.28Z"/>
          <path fill={`${dataPost.colors[1]}`}  d="m444.83,514.32c-20.05,0-36.6,14.98-39.06,34.36-2.46-19.38-19.01-34.36-39.06-34.36,20.05,0,36.6-14.98,39.06-34.36,2.46,19.38,19.01,34.36,39.06,34.36Z"/>
          <path fill={`${dataPost.colors[1]}`}  d="m104,607.32c-15,0-27.38,11.21-29.23,25.71-1.84-14.5-14.23-25.71-29.23-25.71,15,0,27.38-11.21,29.23-25.71,1.84,14.5,14.23,25.71,29.23,25.71Z"/>
          <circle fill={`${dataPost.colors[1]}`}  cx="161" cy="347.44" r="9"/>
          <circle fill={`${dataPost.colors[1]}`}  cx="406" cy="822.44" r="9"/>
          <path fill={`${dataPost.colors[2]}`}  d="m490,0h-176.95l-2.37,8.86c-3.61,13.46.24,27.82,10.09,37.67l103.7,103.7c9.85,9.85,24.21,13.7,37.67,10.09l27.86-7.47V0Z"/>
          <path fill={`${dataPost.colors[2]}`}  d="m18.54,281.54c-4.94-4.94-11.71-7.44-18.54-7.01v211.94l48.36-12.96c8.32-2.23,14.81-8.73,17.04-17.04l23.46-87.55c2.23-8.32-.15-17.19-6.24-23.28l-64.09-64.09Z"/>
        </svg>
      </div>

      <img
        src={imgPostLogo}
        alt="logo" className="logo text tc"
        style={{top:'3%'}}
      />

      {
        dataPost.text[0] && (
          <div
            className="text tc text-center px-2 f-7 clamp-2"
            style={{color: dataPost.colors[3], top: '14%',
            fontSize: '33px', width: '80%', lineHeight: '39px'
          }}
          >{dataPost.text[0]}</div>
        )
      }
      {
        dataPost.text[1] && (
          <div
            className="clamp-2 bc text-center text px-3 f-10"
            style={{color: dataPost.colors[0], fontSize:'32px', bottom: '4%', marginLeft: '-3%' ,
              letterSpacing: '3px', textShadow: `0 0 6px ${dataPost.colors[3]}`
              
            }}
          >{convertAmount(dataPost.text[1])}</div>
        )
      }
      {
        dataPost.text[2] && (
          <div
            className="f-4 text tc clamp-2 text-center"
            style={{color: dataPost.colors[3], fontSize: '22px',
              top:'27.5%', width: '80%', lineHeight: ' 26px'
            }}
          >{dataPost.text[2]}</div>
        )
      }
    </div>
  );
};

export default State7;
