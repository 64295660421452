import { initializeApp } from "firebase/app";
import { getAuth, sendPasswordResetEmail, fetchSignInMethodsForEmail } from "firebase/auth";
import { getFirestore, onSnapshot, doc, getDoc, setDoc } from 'firebase/firestore';
import { getStorage, ref, deleteObject, getDownloadURL, uploadString } from 'firebase/storage';
import { getFunctions, httpsCallable } from 'firebase/functions';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID
};

// Firebase init 
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const functions = getFunctions(app); // Añadir configuración de Cloud Functions

export default app;

//functions//---------------------------------//---------------------------------

//get any info database
function getInfoCollection(userId, collectionName, callback) {
  if(userId && collectionName){
    const docRef = doc(db, collectionName, userId);
    const unsubscribe =  onSnapshot(docRef, (doc) => {
      if (doc.exists()) {
        callback(doc.data());
      } else {
        callback(null);
      }
    });
    return unsubscribe;
  }
}

//validate register
const isUserRegistered = async (email) => {
  try {
    const methods = await fetchSignInMethodsForEmail(auth, email);
    console.log('registrado');
    return methods.length > 0;
  } catch (error) {
    console.log('no registrado');
    console.error(error);
    return false;
  }
}

// set product img into logo folder
const undateProductImage = async (userID, imagenBase64, fileName) => {
  const productRef = ref(storage, `product/${userID}/${fileName}`);
  try {
    await uploadString(productRef, imagenBase64, 'data_url');
    const url = await getDownloadURL(productRef);
    return { success: true, message: 'Imagen subida con éxito', url };
  } catch (error) {
    return { success: false, message: 'Error al subir la imagen' };
  }
};

//add new product
const addProduct = async (userID, datos) => {
  try {
    const userDocRef = doc(db, process.env.REACT_APP_DATAMENU, userID);
    const userDocSnap = await getDoc(userDocRef);
    if (userDocSnap.exists()) {
      const userData = userDocSnap.data();
      userData.p.push(datos);
      await setDoc(userDocRef, userData);
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error(error);
    return false;
  }
}

//delete product image
const deleteImageProduct = async (userId, idImage) => {
  try {
    const storageRef = ref(storage, `product/${userId}/${idImage}`);
    await deleteObject(storageRef);
    return true;
  } catch (error) {
    console.error('Error al eliminar la imagen:', error);
    return false;
  }
};

//send email to change password
const sendEmailToChangePassword = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
  } catch (error) {
    console.error('Error al enviar el correo electrónico para cambio de contraseña:', error);
    throw error;
  }
}

//send order with dynamic link
const addOrderLink = async (country, phone, data) => {
  try {
    const orderId = data.op;
    const ref = doc(db, 'orderLink', `${country}${phone}`);
    const docData = {
      [orderId]: data 
    };
    await setDoc(ref, docData, { merge: true });
    return {
      success: true,
    };
  } catch (error) {
    return {
      success: false,
      error
    };
  }
}

// Función para llamar a sendAWelcomeEmail
const sendAWelcomeEmail = async (data) => {
  const sendWelcomeEmailFunction = httpsCallable(functions, 'sendAWelcomeEmail');
  const {country, email, name, tel} = data
  const obj = {
    name: name || 'Usuario',
    email: email || 'Correo no definido',
    country: country || '57',
    tel: tel || '',
  }
  try {
    const result = await sendWelcomeEmailFunction(obj);
    console.log('Function result:', result.data);
  } catch (error) {
    console.error('Error calling function:', error);
  }
}

const encriptPayment = async (data) => {
  const encript = httpsCallable(functions, 'encriptPayment');
  try {
    const result = await encript(data);
    return {success: true, hash: result.data}
  } catch (error) {
    return {success: false, error}
  }
}

// Función para llamar a mailCredits
const mailCredits = async (data) => {
  const sendWelcomeEmailFunction = httpsCallable(functions, 'mailCredits');
  const {credits, email, name} = data
  const obj = {
    name: name || 'Usuario',
    email: email || 'Correo no definido',
    credits: credits || 0,
  }
  try {
    const result = await sendWelcomeEmailFunction(obj);
    console.log('Function result:', result.data);
  } catch (error) {
    console.error('Error calling function:', error);
  }
}



export {
  auth,
  db,
  isUserRegistered,
  getInfoCollection,
  undateProductImage,
  addProduct,
  deleteImageProduct,
  sendEmailToChangePassword,
  addOrderLink,
  sendAWelcomeEmail,
  encriptPayment,
  mailCredits,
}