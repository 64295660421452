import { db } from "../../../../firebase/credentials";
import { collection, onSnapshot } from "firebase/firestore";

const getMyCustumers = (callback) => {
  const querySnapshot = collection(db, "admin");
  
  return onSnapshot(querySnapshot, (snapshot) => {
    const allData = [];
    snapshot.forEach((doc) => allData.push({ id: doc.id, ...doc.data() }));
    callback(allData) 
  })
}

export default getMyCustumers;
