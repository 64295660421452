import React from 'react'
import MainFreeTheme from '../../freetemplate/MainFreeTheme'
import BgMenu from './BgMenu'
import EditCategory from './EditCategory'

const IceCreamI = () => {


  return (
    <>
      <MainFreeTheme
        bgMenu={<BgMenu />}
        editCategory={<EditCategory />}
        twoColumns={false}
      />
    </>
  )
}

export default IceCreamI