import { db } from "../../../../../firebase/credentials"
import { doc, updateDoc } from "firebase/firestore"



const updateScheduleModule = async(userID, data)=>{

  const {week,deliveryPrice,minDelivery,disabledAccount} = data


  const ref = doc(db,'digitalmenu', userID)
  try {
    await updateDoc(ref, {
      a: disabledAccount,
      b: week,
      r: deliveryPrice,
      u: minDelivery
    })
    return true
  } catch (error) {
    return false
  }

}

export default updateScheduleModule