import React from 'react'
import InfoGreen from '../InfoGreen'
import { useSelector } from 'react-redux'

const WhatsAppTheme = ({numberWSP,errors}) => {

  const {country} = useSelector(s => s.menu)


  return (
    <>
      <div className="bg-b1 p-3 rounded-lg wspMenuC">
        <div className="d-flex align-items-center justify-content-between">
          <p className="text-bold text-apple m-0 pe-2">Número de WhatsApp al que lleguará el pedido de confirmación</p>
          <span className='requiredLabel'>Requerido</span>
        </div>
        <div className="row mt-3">
          <div className="col-md-6 align-self-center">
            <div className="d-flex align-items-center">
              <span>+{country}</span>
              <div className="inputDash bg-b2 w-100 ms-3 my-0">
                <input
                  type="tel"
                  autoComplete="off"
                  className='bg-b2'
                  placeholder='Número de WhatsApp'
                  {...numberWSP}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 align-self-center">
            <InfoGreen
              bg={false}
              text='Digita tu número sin espacios, máximo 10 dígitos'
            />  
          </div>
          {
            errors.numberWSP && (
              <div className="col-12">
                <small className='errorDash'>{errors.numberWSP.message}</small>
              </div>
            )
          }
        </div>
      </div>
    </>
  )
}

export default WhatsAppTheme