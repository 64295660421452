import { db } from "../../../../firebase/credentials";
import { collection, onSnapshot } from "firebase/firestore";


const getErrors = (callback)=>{


  const querySnapshot = collection(db, "allReports");
  
  return onSnapshot(querySnapshot, (snapshot) => {
    const allData = [];
    snapshot.forEach((doc) => {
      console.log(doc.id)
      let newData = {...doc.data()}
      newData.collection = doc.id
      allData.push(newData)
    });
    callback(allData) 
  })
  
}


export default getErrors