import React from 'react'
import logo1 from "../../img/dashboard/fooder_dashboard.svg"
import icon1 from "../../img/vector/logo_fooder_short.svg"

const LateralSudo = () => {

  const listLinks = [
    {title: 'Mis usuarios', class:'personal', fx: false, active: true, disabled: false},
    {title: 'Solicitudes', class:'novedades', fx: false, active: false, disabled: false},
    {title: 'Errores', class:'state', fx: false, active: false, disabled: false},
  ]

  return (
    <>
      <div className='logoDash'>
        <img src={logo1} alt="Logo Fooder" className="img-fluid logo1" />
        <img src={icon1} alt="Logo Fooder" className="img-fluid logo2" />
      </div>

      <ul className="nav nav-tabs tabLateral" role="tablist">
        {
          listLinks.map((el, index) =>(
            <li className={`nav-item ${el.disabled ? 'disabled': ''}`} role="presentation" key={index}>
              <button
                className={`nav-link ${el.active ? 'active': ''} ${el.class}`}
                id={`${el.class}-tab`}
                data-bs-toggle={el.disabled ? '': 'tab'}
                data-bs-target={el.disabled ? null : `#${el.class}-tab-pane`}
                type="button"
                role="tab"
                aria-controls={`#${el.class}-tab-pane`}
                aria-selected="true"
              >
                <span>{el.title}</span>
              </button>
            </li>
          ))
        }
        
      </ul>
    
    </>
  )
}

export default LateralSudo