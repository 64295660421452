import { createSlice } from "@reduxjs/toolkit"

export const sudoSlice = createSlice({
  name: 'sudo',
  initialState: {
    listUsers: [],
    listErrors: [],
    dataRequest: {},
    mycustomers: [],
    filterClient: null,
    userToDelete: {},
    note: {visible: false, text: ''},
    userIssue: null,
  },
  reducers: {
    getAllUsers: (state, action) =>{
      state.listUsers = action.payload
    },
    updateIssue: (state, action) =>{
      state.userIssue = action.payload
    },
    getAllErrors: (state, action) =>{
      state.listErrors = action.payload
    },
    setAllCustomers: (state, action) =>{
      state.mycustomers = action.payload
    },
    setDataRequest: (state, action) =>{
      state.dataRequest = action.payload
    },
    setFilterClient: (state, action) =>{
      state.filterClient = action.payload
    },
    setUserToDelete: (state, action) =>{
      state.userToDelete = action.payload
    },
    updateNotification: (state, action) =>{
      state.note = action.payload
    },

  }
})

export const {
  getAllUsers,getAllErrors,setDataRequest,setAllCustomers,
  setFilterClient,setUserToDelete,updateNotification,
  updateIssue,
} = sudoSlice.actions

export default sudoSlice.reducer
