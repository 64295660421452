import React from 'react'
import { useSelector } from 'react-redux';
import { convertAmount } from '../../../../tools/format';


const State12 = () => {

  const {dataPost, imgPostLogo, imgProduct} = useSelector(s => s.post)
  

  return (
    <div className="poster">
      <div className="img" style={{
        top: '7%', width: '100%', height:'77%'
      }}>
        {
          imgProduct && (
            <img
              src={imgProduct}
              className='b7'
              alt="img" />
          )
        }
        <div className="overlay" style={{background: dataPost.colors[3], opacity: .1}}/>
      </div>
      <div className="svg">
        <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 490 870">
          <defs>
            <style>
              {`
              .gggde {
                opacity: .1;
              }


              .yyute {
                opacity: .52;
              }
              `}
            </style>
          </defs>
          <path fill={`${dataPost.colors[0]}`} d="m222.17,67.44c23.1,0,41.83,18.73,41.83,41.83v161.34c0,23.1,18.73,41.83,41.83,41.83h184.17V0H0v67.44h222.17Z"/>
          <path fill={`${dataPost.colors[0]}`} d="m261.83,724.44c-23.1,0-41.83-18.73-41.83-41.83v-62.34c0-23.1-18.73-41.83-41.83-41.83H0v291.56h490v-145.56h-228.17Z"/>
          <path fill={`${dataPost.colors[1]}`} className="gggde" d="m490,291.73h-192.88c-26.27,0-47.64-21.37-47.64-47.64V58.1c0-26.9-21.89-48.79-48.79-48.79H0v1.15h200.69c26.27,0,47.64,21.37,47.64,47.64v185.99c0,26.9,21.89,48.79,48.79,48.79h192.88v-1.15Z"/>
          <path fill={`${dataPost.colors[1]}`} className="gggde" d="m204.32,687.65v-72.78c0-27.2-22.13-49.33-49.33-49.33H0v1h154.99c26.65,0,48.33,21.68,48.33,48.33v72.78c0,27.2,22.13,49.33,49.33,49.33h237.36v-1h-237.36c-26.65,0-48.33-21.68-48.33-48.33Z"/>
          <g  className="yyute">
            <polygon fill={`${dataPost.colors[1]}`}  points="89.71 646.04 92.11 648.44 103.47 637.09 114.83 648.44 117.23 646.04 103.47 632.28 89.71 646.04"/>
            <polygon fill={`${dataPost.colors[1]}`}  points="89.71 664.04 92.11 666.44 103.47 655.09 114.83 666.44 117.23 664.04 103.47 650.28 89.71 664.04"/>
            <path fill={`${dataPost.colors[1]}`}  d="m103,613.44c-20.95,0-38,17.05-38,38s17.05,38,38,38,38-17.05,38-38-17.05-38-38-38Zm0,73c-19.3,0-35-15.7-35-35s15.7-35,35-35,35,15.7,35,35-15.7,35-35,35Z"/>
          </g>
          <path fill={`${dataPost.colors[1]}`}  d="m490,189.44h-127.5c-24.58,0-44.5,19.92-44.5,44.5h0c0,24.58,19.92,44.5,44.5,44.5h127.5v-89Z"/>
          <g>
            <path fill={`${dataPost.colors[1]}`}  d="m382,559.17v-39.29l19.64,19.64-19.64,19.64Zm.4-38.33v37.37l18.68-18.68-18.68-18.68Z"/>
            <path fill={`${dataPost.colors[1]}`}  d="m382,625.34v-39.29l19.64,19.64-19.64,19.64Zm.4-38.33v37.37l18.68-18.68-18.68-18.68Z"/>
            <path fill={`${dataPost.colors[1]}`}  d="m423.36,592.25v-39.29l19.64,19.64-19.64,19.64Zm.4-38.33v37.37l18.68-18.68-18.68-18.68Z"/>
            <path fill={`${dataPost.colors[1]}`}  d="m423.36,660.49v-39.29l19.64,19.64-19.64,19.64Zm.4-38.33v37.37l18.68-18.68-18.68-18.68Z"/>
            <path fill={`${dataPost.colors[1]}`}  d="m382,691.51v-39.29l19.64,19.64-19.64,19.64Zm.4-38.33v37.37l18.68-18.68-18.68-18.68Z"/>
          </g>
        </svg>
      </div>

      <img
        src={imgPostLogo}
        alt="logo" className="logo text tr"
        style={{right: '4.6%', top: '23.2%'}}
      />

      {
        dataPost.text[0] && (
          <div
            className="text tr  py-1 px-2 f-2 clamp-2 text-end"
            style={{color: dataPost.colors[1], lineHeight: '37px', right: '3%',
            fontSize: '34px', textShadow: `0 0 10px ${dataPost.colors[2]}`, top: '5%',
            width: '65%'
          }}
          >{dataPost.text[0]}</div>
        )
      }
      {
        dataPost.text[1] && (
          <div
            className="w-content text px-3 f-4 bl text-center"
            style={{color: dataPost.colors[1],
              fontSize:'25px', bottom: '13%', width: '45%'
            }}
          >{convertAmount(dataPost.text[1])}</div>
        )
      }
      {
        dataPost.text[2] && (
          <div
            className="f-4 text-center text bc clamp-2"
            style={{color: dataPost.colors[1], fontSize: '18px',
              width: '85%', lineHeight:'23px', bottom: '3%'
            }}
          >{dataPost.text[2]}</div>
        )
      }
    </div>
  );
};

export default State12;
