import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  productSelected: null,
  counter: 1,
  priceOne: 0,
  priceMultiple: [],
  comment: '',
  activeMenu: false,
  showAboutCompany: false,
  favoriteStorage: [],
  order: [],
  activeOrder: false,
  clientAddress: [],
  showTYC: false,
  orderSent: '',
  inKitchen: false,
  idOrderSelected: null,
  orderProducts: [],
  dataClient: {},
  modifyOrder: false,
  infoToPrint: null,
  orderMethods: {}
}

export const orderingSlice = createSlice({
  name: 'ordering',
  initialState,
  reducers: {
    resetOrderInDash: (state) =>{ 
      state.productSelected = null
      state.counter = 1
      state.priceOne = 0
      state.priceMultiple = []
      state.comment = ''
      state.dataClient = {}
      state.order = []
      state.idOrderSelected = null
      state.orderMethods = {}
    },
    setProductSelected: (state,action) =>{
      state.productSelected = action.payload
    },
    setOrderMethods: (state,action) =>{
      state.orderMethods = action.payload
    },
    setInfoToPrint: (state,action) =>{
      state.infoToPrint = action.payload
    },
    setDataClient: (state,action) =>{
      state.dataClient = action.payload
    },
    setModifyOrder: (state,action) =>{
      state.modifyOrder = action.payload
    },
    setOrderProducts: (state,action) =>{
      state.orderProducts = action.payload
    },
    setCounter: (state,action) =>{
      state.counter = action.payload
    },
    setComment: (state,action) =>{
      state.comment = action.payload
    },
    setPriceOne: (state,action) =>{
      state.priceOne = action.payload
    },
    setResetPriceMultiple: (state) =>{
      state.priceMultiple = []
    },
    setPriceMultiple: (state,action) =>{
      let list = [...state.productSelected.j.op]
      let refmultiple = [...state.priceMultiple]
      let ref = state.priceMultiple.filter(el => el.title === list[action.payload].title)
      if(ref.length === 0){
        state.priceMultiple.push(list[action.payload])
      }else{
        let obj = refmultiple.findIndex(el => el.title === list[action.payload].title)
        state.priceMultiple.splice(obj,1)
      }
    },
    setActiveMenu: (state) =>{
      if(state.activeMenu){
        state.activeMenu = false
      }else{
        state.activeMenu = true
      }
    },
    setActiveTYC: (state) =>{
      if(state.showTYC){
        state.showTYC = false
      }else{
        state.showTYC = true
      }
    },
    setActiveOrder: (state) =>{
      if(state.activeOrder){
        state.activeOrder = false
      }else{
        state.activeOrder = true
      }
    },
    setShowAboutCompany : (state) =>{
      if(!state.showAboutCompany){
        state.showAboutCompany = true
      }
    },
    setCloseAboutCompany: (state) =>{
      state.showAboutCompany = false
    },
    addOrder: (state, action) =>{
      state.order.push(action.payload)
    },
    customizeOrder: (state, action) =>{
      let copy = JSON.parse(JSON.stringify(state.order))
      if(action.payload.type === 'minus'){
        if(copy[action.payload.index].count > 1){
          copy[action.payload.index].count -= 1
          state.order = copy
        }else{
          copy.splice(action.payload.index, 1)
          state.order = copy
        }
      }
      if(action.payload.type === 'add'){
        copy[action.payload.index].count += 1
        state.order = copy
      }
    },
    setCleanOrder : (state) =>{
      state.order = []
    },
    setIdOrderSelected : (state, action) =>{
      state.idOrderSelected = action.payload
    },
    onloadState: (state) =>{
      state.activeOrder = false
      state.activeMenu = false
      state.showAboutCompany = false
    },
    setOrderSent: (state, action) =>{
      state.orderSent = action.payload
    },
    setInKitchen: (state, action) =>{
      state.inKitchen = action.payload
    },
  }
})
export const {
  setProductSelected, setCounter,setPriceOne,setPriceMultiple,
  setComment,setResetPriceMultiple,setActiveMenu,setShowAboutCompany,
  setCloseAboutCompany,addOrder, setInfoToPrint,
  customizeOrder, setCleanOrder,setActiveOrder,
  setActiveTYC, onloadState, setOrderMethods,
  setOrderSent,setInKitchen, resetOrderInDash, setIdOrderSelected, setOrderProducts,
  setDataClient, setModifyOrder,
} = orderingSlice.actions

export default orderingSlice.reducer



