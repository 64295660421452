import { query, where, collection, getDocs} from 'firebase/firestore'
import {db} from '../../../firebase/credentials'



const searchMenu = async (phone) => {

  try {
    const q = query(collection(db, 'mycustomers'), where('c', '==', phone))
    const querySnapshot = await getDocs(q)

    const results = []

    querySnapshot.forEach((doc) => {
      let ref = doc.data()
      results.push(ref)
    })
    return results
  } catch (error) {
    console.log('Error al confirmar menú', error.message)
    throw error
  }
}

export default searchMenu