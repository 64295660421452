import React from 'react'
import l6 from '../../img/brand/l_6.webp'
import l7 from '../../img/brand/l_7.webp'
import l8 from '../../img/brand/l_8.webp'
import l9 from '../../img/brand/l_9.webp'
import adminPage from '../../img/brand/adminpage.webp'
import sudo from '../../firebase/update/sudo'

const CallToAction = () => {

  const handleRegister = ()=> sudo('register')

  return (
    <section className="ladingBenefits">
      <img src={l6} alt="" className='img-fluid pa l6'/>
      <img src={l7} alt="" className='img-fluid pa l7'/>
      <img src={l8} alt="" className='img-fluid pa l8'/>
      <img src={l9} alt="" className='img-fluid pa l9'/>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-9 col-md-12">
            <h2 className='text-center mb-4 mb-md-3'>¡COMIENZA TU PRUEBA GRATUITA!</h2>
          </div>
          <div className="col-md-5 text-center col-10">
            <p>Te obsequiamos 50 Créditos Fooder que equivalen a <strong>50 pedidos generados en tu menú</strong>  dentro de nuestro plan “Emprendedor”. ¡Comienza tu experiencia con nosotros!</p>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-3 col-9 my-4 ">
            <button
              className='btnRegister w-100'
              data-bs-toggle="modal"
              data-bs-target="#modalRegisterL"
              onClick={handleRegister}
            >REGÍSTRATE</button>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-10 col-md-4 mb-3 mb-md-0 text-center">
            <img src={adminPage} alt="Administrador FOODER"  className='img-fluid'/>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CallToAction