import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setSelectToDelete } from '../../../../../redux/reducer/menu'
import { deleteImageProduct } from '../../../../../firebase/credentials'
import { setIsError, setStateLoading, setStateNotification, setTextNotificate } from '../../../../../redux/reducer/interactions'
import updateOnlyOnePiece from '../../../../../firebase/update/updateOnlyOnePiece'


const ModalDeleteProduct = () => {

  const {userID} = useSelector(state => state.log)
  const {listProducts, selectToDelete, listCategories} = useSelector(state => state.menu)
  const dispatch = useDispatch()
  const product = listProducts.filter(el => el.c === selectToDelete)
  
  const handleCloseDeleteModal = ()=> dispatch(setSelectToDelete(''))

  const handleDeleted = async()=>{
    let index = listProducts.findIndex(el => el.c === selectToDelete)
    let newState = listProducts.map(product => JSON.parse(JSON.stringify(product)))
    newState.splice(index,1)
    let list = listCategories.map(product => JSON.parse(JSON.stringify(product)))

    const newList = list.map(item => {
      if (item.name === product[0].g) {
        return {...item,count: item.count - 1}
      }
      return item
    })

    dispatch(setStateLoading(true))
    try {
      await updateOnlyOnePiece(userID, process.env.REACT_APP_DATAMENU,'p', newState)
      await updateOnlyOnePiece(userID, process.env.REACT_APP_DATAMENU,'n', newList)
      await deleteImageProduct(userID, product[0].c)    
      dispatch(setStateLoading(false))
      dispatch(setTextNotificate(`¡El producto "${product[0].e}" ha sido eliminado!`))
      dispatch(setStateNotification(true))
      dispatch(setIsError(false))  
    } catch (error) {
      dispatch(setStateLoading(false))
      dispatch(setTextNotificate('Ha ocurrido un error, inténtalo mas tarde'))
      dispatch(setStateNotification(true))
      dispatch(setIsError(true))  
    }
    dispatch(setSelectToDelete(''))
  }

  return (
    <>
      <div className="modal fade modalDashboard" id="ModalDeleteProduct" tabIndex="-1" aria-labelledby="ModalDeleteProduct" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body p-3 p-md-4 my-4">
              <div className="row justify-content-center">
                <div className="col-md-10">
                  <h4 className="text-white text-center m-0">¿Estás seguro de eliminar el producto <span className='text-apple'>{product[0] && product[0].e}</span> ?</h4>
                </div>
                <div className="col-9 my-4">
                  {product[0] && <img src={product[0].d} alt="product" className='img-fluid rounded-3 w-100' />}
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-md-6 col-9">
                  <button
                  className='btn-line-apple mt-4 mt-md-0'
                  type='button'
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleCloseDeleteModal}
                >No</button>
                </div>
                <div className="col-md-6 col-9 order-first order-md-last mt-4 mt-md-0">
                  <button
                    className='btn-orange m-0'
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={handleDeleted}
                  >Sí, Eliminar</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ModalDeleteProduct



