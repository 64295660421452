import React from 'react'

const EditCategory = () => {
  return (
    <section className="contentEditCategory">
      
    </section>
  )
}

export default EditCategory