import React from 'react'
import { useSelector } from 'react-redux'



const MyMap = ({swichMap,isMap}) => {

  const {address} = useSelector(state => state.log)
  

  return (
    <>
      <div className="bg-b1 rounded-lg p-3">
        <div className="d-flex align-items-center justify-content-between">
          <p className="text-bold text-apple m-0">¿Deseas incluir un mapa?</p>
          <span className='optionalLabel'>Opcional</span>
        </div>
        <div className="text-center">
          <p className='my-3 my-md-0'>Activar</p>
          <div className="form-check form-switch justify-content-center">
            <input
              className="form-check-input "
              type="checkbox"
              role="switch"
              {...swichMap}
            />
          </div>
        </div>
        {
          isMap && (
            <>
              <div className="row mt-4">
                <div className="col-12 text-center">
                  <p>Ahora tus clientes podrán visualizar el mapa de tu negocio. Si este mapa no visualiza correctamente, debes ir a "Configuración" para ajustar todo lo necesario.</p>
                </div>
              </div>    
              <div className="mapouter">
                <div className="gmap_canvas">
                  <iframe
                    className="gmap_iframe"
                    width="100%"
                    frameBorder="0"
                    scrolling="no"
                    marginHeight="0"
                    title='Mapa'
                    marginWidth="0"
                    src={`https://maps.google.com/maps?width=600&height=400&hl=en&q= ${address[0]} ${address[1]} ${address[2]} - ${address[3]} ${address[4]} &t=&z=15&ie=UTF8&iwloc=B&output=embed`} 
                  />
                </div>
              </div>
            </>
          )
        }
      </div>
    </>
  )
}

export default MyMap


