import React, { useEffect, useState } from 'react'
import {productsTest} from '../../dummi/source-products'
import MainProducts from './MainProducts'
import Slider from 'react-slick'
import { useDispatch, useSelector } from 'react-redux'
import { setProductSelected } from '../../../../redux/reducer/user'
import icon from '../../../../img/vector/icon_restaurant.svg'



const MainSlider = () => {

  const { menu, favoriteStorage} = useSelector(state => state.user)
  const [active, setActive] = useState(false)
  const [isAdd, setIsAdd] = useState(true)
  const [partial, setPartial] = useState(false)
  const [printProduct, setPrintProduct] = useState([])
  const [dataProduct, setDataProduct] = useState([])
  const dispatch = useDispatch()

  useEffect(()=>{
    if(menu){
      if(menu?.p?.length === 0){
        setDataProduct(productsTest)
      }else{
        setDataProduct(menu?.p)
      }
    }
  },[menu])




  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    swipeToSlide: true,
    speed: 150,
    slidesToShow: 3,
    slidesToScroll: 1,
    className: 'mainProducts',
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          centerMode: true,
          centerPadding: '30px',
        }
      }
    ]
  }

  useEffect(() => {
    if (menu) {
      if (menu?.p?.length === 0) {
        setActive(true)
        setPartial(false)
        setPrintProduct(productsTest)
      } else {
        const count = menu?.p ? menu?.p?.filter(el => (el?.a && el?.b)) : []
        setPrintProduct(menu?.p)
        if (count.length === 0) {
          setActive(false)
          setPartial(false)
        } else {
          if (count.length === 1 || count.length === 2) {
            setActive(false)
            setPartial(true)
          } else {
            setActive(true)
            setPartial(false)
          }
        }
      }
    }
  }, [menu])


  const selectProduct = id =>{
    let reference = dataProduct.find(el => el.c === id)
    dispatch(setProductSelected(reference))
  }


  const handleaAdvertisement = ()=> setIsAdd(false)

  return (
    <>
    {
      menu?.p?.length === 0 && (
        <div>
          {
            isAdd && (
              <section>
                <div className="container">
                  <div className="addEmptyProducts">
                    <img src={icon} alt="" />
                    <p className="m-0">Presentamos unos productos y categorías de prueba para que visualices el alcance de nuestro <strong>MENÚ DIGITAL FOODER.</strong>  Estos productos serán removidos una vez crees tu primer producto.</p>
                    <button type='button' onClick={handleaAdvertisement}/>
                  </div>
                </div>
              </section>
            )
          }
        </div>
      )
    }
    <div className='bg-mainSlider'>
      {
        partial && (
          <div className="px-4">
            <div className='row'>
              {
                printProduct?.map((el, index) => {
                  if(el?.a && el?.b){
                    return (
                      <div key={`mainP-${index}`} className='col-md-5 mt-3 mt-md-0'>
                        <MainProducts
                          title={el?.e}
                          img={el?.d}
                          category={el?.g}
                          onClick={() => selectProduct(el?.c)}
                        />
                      </div>
                    )
                  }
                  return null
                }
                )
              }
            </div>
          </div>
        )
      }
      {
        active && (
          <>
            <div
              className="contentMainSlider"
            >
              <Slider {...settings}>
              {
                printProduct?.map((el, index) => {
                  let isFavorite = false
                  let res = favoriteStorage.findIndex(item => item === el?.c)

                  if (res === -1) {
                    isFavorite = false
                  } else {
                    isFavorite = true
                  }

                  if (el?.a && el?.b) {
                    return (
                      <div key={`mainP-${index}`} className='p-1 d-flex align-items-center justify-content-center'>
                        <MainProducts
                          title={el?.e}
                          img={el?.d}
                          category={el?.g}
                          onClick={() => selectProduct(el?.c)}
                          isFavorite={isFavorite}
                        />
                      </div>
                    )
                  }
                  return null
                })
              }
              </Slider>
            </div>
          </>
        )
      }
    </div>
    </>
  )
}

export default MainSlider
