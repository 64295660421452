import React from 'react'
import { useSelector } from 'react-redux'

const Global = ({orderReports}) => {

  const {visitCounter} = useSelector(s => s.log)


  return (
    <div className="bg-b1 p-3 rounded-lg mb-3">
      <strong className="text-apple d-block mb-3 text-center text-md-start">Desempeño de tu menú digital</strong>
      <div className="statistic">
        <table>
          <tbody>
            <tr>
              <td>Total de pedidos</td>
              <td className="text-apple">{Object.values(orderReports).length}</td>
            </tr>
            <tr>
              <td>Visitas a tu menú</td>
              <td className="text-apple">{visitCounter}</td>
            </tr>
            <tr>
              <td>Conversión Órdenes vs Visitas</td>
              <td className="text-apple">{`${((Object.values(orderReports).length * 100)/visitCounter).toFixed(1) }%`}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Global