import { signInWithEmailAndPassword } from "firebase/auth"
import { auth } from "../../../firebase/credentials"

const handleLogin = async(email, password) =>{

  try {
    await signInWithEmailAndPassword(auth, email,password)
    return {response: true, error: null}
  } catch (error) {
    return {response: false, error: error}
  }

}

export default handleLogin