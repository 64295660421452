import { db } from "../credentials"
import { doc, updateDoc } from "firebase/firestore"

const checkVideo = async (userID, array, index)=>{
  const ref = doc(db, 'admin', userID)

  let copy = [...array]
  copy[index] = true
  
  await updateDoc(ref, {
    v: copy
  })

}

export default checkVideo