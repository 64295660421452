import React from 'react'
import Viewer from './Viewer'

const ModalViewProduct = () => {
  return (
    <>
      <div className="modal fade modalDashboard" id="modalViewProduct" tabIndex="-1" aria-labelledby="modalViewProduct" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <div className="modal-dialog modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-body p-3 p-md-4">
              <Viewer />
              <div className="py-4 my-5"></div>
              <div className="footerModalProduct">
                <button
                  type='button'
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >Cerrar visualización</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ModalViewProduct


