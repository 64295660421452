import React from 'react'
import logo from '../../img/vector/logo_fooder.svg'

const Policy = () => {
  return (
    <>
      <header className="sticky-top bg-white shadow p-3 text-center">
        <img src={logo} alt="" style={{width: '120px'}}/>
      </header>
      <main className='bg-light'>
        <section>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8 py-5 col-11">
                <div className="text-center mb-5">
                  <h2 className='mb-5'>Políticas de tratamiento de datos personales</h2>
                  <hr />
                </div>

                <p>En <strong>Fooder</strong>, nos comprometemos a proteger la privacidad y la seguridad de los datos personales de nuestros usuarios. En cumplimiento con la normativa vigente en Colombia sobre la protección de datos personales, queremos informarte cómo tratamos la información que nos proporcionas al usar nuestra plataforma.</p>

                <h5 className='mt-5'>1. Responsable del Tratamiento de los Datos</h5>
                <p>Fooder, identificado bajo la razón social [Nombre Legal de la Empresa], con domicilio en [Dirección], es responsable del tratamiento de los datos personales recopilados a través de nuestro sitio web y plataforma.</p>

                <h5 className='mt-5'>2. Finalidad del Tratamiento de los Datos</h5>
                <p>Los datos personales que recopilamos se utilizan con los siguientes propósitos:</p>
                <ul>
                  <li><strong>Registro y autenticación:</strong> Para crear y administrar tu cuenta de usuario en la plataforma.</li>
                  <li><strong>Gestión de pedidos:</strong> Para facilitar la creación, seguimiento y entrega de los pedidos realizados a través de los menús digitales.</li>
                  <li><strong>Procesamiento de pagos:</strong> Para gestionar los pagos en línea de los pedidos, en los casos que aplique.</li>
                  <li><strong>Comunicación:</strong> Para enviarte notificaciones sobre el estado de tus pedidos, actualizaciones importantes, promociones y novedades relacionadas con Fooder.</li>
                  <li><strong>Mejora del servicio:</strong> Para analizar cómo se utiliza nuestra plataforma y mejorar la experiencia de usuario.</li>
                </ul>

                <h5 className='mt-5'>3. Datos Recopilados</h5>
                <p>Podemos recopilar los siguientes tipos de datos:</p>
                <ul>
                  <li><strong>Datos personales básicos:</strong> como nombre, dirección de correo electrónico, número de teléfono y otros datos que proporcionas al registrarte.</li>
                  <li><strong>Datos de pago:</strong> como la información de tu tarjeta de crédito o débito cuando procesas pagos a través de la plataforma.</li>
                  <li><strong>Datos de uso:</strong> como tu dirección IP, tipo de navegador, y otras métricas relacionadas con tu interacción en la plataforma.</li>
                </ul>

                <h5 className='mt-5'>4. Almacenamiento y Seguridad de los Datos</h5>
                <p>En <strong>Fooder</strong> tomamos medidas técnicas y organizativas para garantizar la seguridad de tus datos personales, incluyendo la encriptación y el uso de servidores seguros proporcionados por Firebase, nuestra plataforma de backend. Solo personal autorizado tiene acceso a esta información, y se implementan políticas de control de acceso estrictas.</p>

                <h5 className='mt-5'>5. Compartición de Datos</h5>
                <p><strong>Fooder</strong> no comparte, vende ni alquila datos personales a terceros no autorizados. Sin embargo, podemos compartir información con los siguientes terceros cuando sea necesario para la prestación del servicio:</p>
                <ul>
                  <li><strong>Procesadores de pago:</strong> como Bold, para gestionar los pagos en línea de manera segura.</li>
                  <li><strong>Proveedores de servicios:</strong> como Firebase, para la gestión y el almacenamiento de datos en la nube.</li>
                </ul>

                <h5 className='mt-5'>6. Derechos de los Usuarios</h5>
                <p>Como usuario de Fooder, tienes derecho a:</p>
                <ul>
                  <li><strong>Acceder</strong> a los datos personales que hemos recopilado sobre ti.</li>
                  <li><strong>Solicitar correcciones</strong> a cualquier dato inexacto o incompleto.</li>
                  <li><strong>Solicitar la eliminación</strong> de tus datos personales cuando ya no sean necesarios para los fines para los cuales fueron recolectados.</li>
                  <li><strong>Revocar el consentimiento</strong> para el tratamiento de tus datos en cualquier momento, sin efectos retroactivos.</li>
                </ul>
                <p>Para ejercer estos derechos, puedes contactarnos en ventas@fooderapp.co o llamando a 3125599832.</p>

                <h5 className='mt-5'>7. Uso de Cookies</h5>
                <p>En nuestra plataforma utilizamos cookies para mejorar tu experiencia de navegación y para entender cómo interactúas con nuestros servicios. Puedes configurar tu navegador para rechazar las cookies, pero esto podría afectar la funcionalidad de algunas características de la plataforma.</p>

                <h5 className='mt-5'>8. Actualizaciones a esta Política</h5>
                <p><strong>Fooder</strong> se reserva el derecho de actualizar esta política de tratamiento de datos personales en cualquier momento, en conformidad con las leyes aplicables. Te notificaremos sobre cualquier cambio importante mediante el envío de una notificación a tu correo electrónico o a través de nuestra plataforma.</p>

                <div className="text-center my-4 pt-5">
                  <p><strong>Última actualización:</strong> 14 de octubre de 2024</p>
                  <small>Al continuar utilizando <strong>Fooder</strong>, usted acepta haber leído y entendido nuestras políticas de tratamiento de datos personales.</small>
                </div>


              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}

export default Policy