import React from 'react'
import { useSelector } from 'react-redux'

const Chilli = ({registerChilli}) => {

  const {chilli} = useSelector(s => s.product)

  return (
    <>
      <div className="bg-b1 p-3 rounded-lg">
        <div className="d-flex align-items-center justify-content-between">
          <p className="text-bold text-apple m-0">¿Esta comida contiene picante?</p>
          <span className='optionalLabel'>Opcional</span>
        </div>
        <div className="d-flex justify-content-center">
          <div className="toggle-border">
            <input
              type="checkbox"
              role="switch"
              id="check-chilli"
              checked={chilli}
              {...registerChilli}
            />
            <label htmlFor='check-chilli'>
              <div className="handle"></div>
            </label>
          </div>
        </div>
       
      
      </div>
    </>
  )
}

export default Chilli