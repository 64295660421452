import React, { useEffect, useState } from 'react'
import HeadPost from './HeadPost'
import { useSelector } from 'react-redux'
import ContentPost from './ContentPost'
import EditorPanel from './EditorPanel'
import NoAvalible from './NoAvalible'


const Design = () => {

  const {activeEditor} = useSelector(s => s.post)
  const [isIphone, setIsIphone] = useState(false)

  useEffect(()=>{
    function isIphone() {
      const userAgent = window.navigator.userAgent.toLowerCase()
      return /iphone/.test(userAgent)
    }
    
    if (isIphone()) {
      setIsIphone(true)
    } else {
      setIsIphone(false)
    }
  },[])

  return (
    <>
      <section className="contentPost">
        {
          isIphone ? <NoAvalible />:
          (
            <>
              <HeadPost/>
              {activeEditor ? <EditorPanel /> : <ContentPost />}
            </>
          )
        }
      </section>
    </>
  )
}

export default Design



