import React from 'react'
import { useSelector } from 'react-redux';
import { convertAmount } from '../../../../tools/format';


const Post12 = () => {

  const {dataPost, imgPostLogo, imgProduct} = useSelector(s => s.post)
  

  return (
    <div className="poster">
      <div className="img" style={{ width: '68%', height: '84%', left: '16%'}}>
        {
          imgProduct && (
            <img
              src={imgProduct}
              className='b7'
              alt="img" />
          )
        }
      </div>
      <div className="svg">
        <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1023.75 1024">
          <path fill={`${dataPost.colors[2]}`}  d="m851,0v811.4c0,21.87-17.73,39.6-39.6,39.6H212.6c-21.87,0-39.6-17.73-39.6-39.6V0H0v1024h1024V0h-173Z"/>
          <rect fill={`${dataPost.colors[1]}`}  x="851.3" width="174.7" height="593"/>
          <circle fill={`${dataPost.colors[1]}`}  cx="74.43" cy="71.43" r="14.43"/>
          <circle fill={`${dataPost.colors[1]}`}  cx="149" cy="71.43" r="14.43"/>
          <circle fill={`${dataPost.colors[1]}`}  cx="223.57" cy="71.43" r="14.43"/>
          <circle fill={`${dataPost.colors[1]}`}  cx="74.43" cy="138.78" r="14.43"/>
          <circle fill={`${dataPost.colors[1]}`}  cx="149" cy="138.78" r="14.43"/>
          <circle fill={`${dataPost.colors[1]}`}  cx="223.57" cy="138.78" r="14.43"/>
          <circle fill={`${dataPost.colors[1]}`}  cx="74.43" cy="206.14" r="14.43"/>
          <circle fill={`${dataPost.colors[1]}`}  cx="149" cy="206.14" r="14.43"/>
          <circle fill={`${dataPost.colors[1]}`}  cx="223.57" cy="206.14" r="14.43"/>
          <circle fill={`${dataPost.colors[2]}`}  cx="735.43" cy="754.43" r="14.43"/>
          <circle fill={`${dataPost.colors[2]}`}  cx="810" cy="754.43" r="14.43"/>
          <circle fill={`${dataPost.colors[2]}`}  cx="735.43" cy="821.78" r="14.43"/>
          <circle fill={`${dataPost.colors[2]}`}  cx="810" cy="821.78" r="14.43"/>
          <path fill={`${dataPost.colors[0]}`}  d="m85,716c-29.31,0-57.78,3.6-85,10.37v297.63h433.39c-21.2-173.57-169.09-308-348.39-308Z"/>
          <path fill={`${dataPost.colors[2]}`}  d="m809.59,709.49c-.83,0-1.49-.67-1.49-1.49V41.98h-256.1c-.83,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49h259.09v669.01c0,.83-.67,1.49-1.49,1.49Z"/>
          <path fill={`${dataPost.colors[2]}`}  d="m223.57,709.49c-.83,0-1.49-.67-1.49-1.49v-441.86c0-.83.67-1.49,1.49-1.49s1.49.67,1.49,1.49v441.86c0,.83-.67,1.49-1.49,1.49Z"/>
          <path fill={`${dataPost.colors[2]}`}  d="m685.5,822.99h-315.04c-.83,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49h315.04c.83,0,1.49.67,1.49,1.49s-.67,1.49-1.49,1.49Z"/>
        </svg>
      </div>

      <img
        src={imgPostLogo}
        alt="logo" className="logo text bl"
        style={{
          marginBottom: '17%', marginLeft: '4%'
        }}
      />
      {
        dataPost.text[0] && (
          <div
            className="text br px-2 f-3 text-uppercase text-end text-truncate"
            style={{color: dataPost.colors[1],  lineHeight: '15px',
            fontSize: '14px', marginRight: '3%', width: '52%',
            marginBottom: '6.5%'
          }}
          >{dataPost.text[0]}</div>
        )
      }
      {
        dataPost.text[1] && (
          <div
            className="w-content text px-3 f-2 bl"
            style={{color: dataPost.colors[1],
              fontSize:'26px', left: ' 8px', bottom: '3%'
            }}
          >{convertAmount(dataPost.text[1])}</div>
        )
      }
      {
        dataPost.text[2] && (
          <div
            className="f-3 text bl text-truncate"
            style={{color: dataPost.colors[1], fontSize: '15px',
              marginLeft:'5%', marginBottom: '10.5%',
              width: '33%'
            }}
          >{dataPost.text[2]}</div>
        )
      }
    </div>
  );
};

export default Post12;
