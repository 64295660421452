import React from 'react'
import coin from '../../../../img/dashboard/coin.svg'

const CreditModal = () => {
  return (
    <>
      <div className="modal fade modalDashboard" id="infoModal" tabIndex="-1" data-bs-backdrop="static" aria-labelledby="infoModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body p-4 p-md-5">
              <h5 className='text-apple mb-3 d-flex align-items-center'>
                <img src={coin} alt="coin" className='coin me-2'/>
                <span>¿Qué es un crédito FOODER?</span>
              </h5>
              <p>Un <span className="text-white">CRÉDITO FOODER</span> representa una solicitud por parte del usuario desde tu menú digital, una vez tu cliente realiza todo el pedido se te descontará 1 crédito.</p>
              <p>Por otra parte, cada día de licencia tiene un valor de 5 créditos.</p>
              <div className="bg-b1 p-3 rounded-3">
                <strong className="text-apple d-block mb-3">Ejemplo:</strong>
                <ul>
                  <li>45 usuarios solicitaron tus platos desde el menú digital (45 créditos)</li>
                  <li>1 día calendario (5 créditos)</li>
                </ul>
                <p>Para este ejercicio tu actividad diaria representa <span className='credits-text'>50 Créditos Fooder</span></p>
                <p className="mb-0">¿...y cuánto cuesta 1 crédito FOODER? <span className='credits-text'>$100 COP</span></p>
                <h6 className="text-center mt-4 text-apple">¡Solamente pagas por lo que consumes!</h6>
              </div>
              <div className="row justify-content-center mt-4">
                <div className="col-md-5 col-9">
                  <button
                    type="button"
                    className="w-100 btn-green-dark"
                    data-bs-dismiss="modal"
                  >
                    ¡Entendido!
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default CreditModal