import React, { useEffect, useState } from 'react'

import { useSelector } from 'react-redux'
import { convertAmount } from '../../../../tools/format'

const ViewProduct = () => {

  
  const {productSelectedID} = useSelector(state => state.product)
  const {listProducts} = useSelector(state => state.menu)
  const [product, setProduct] = useState([])

  useEffect(()=>{
    let ref = listProducts.filter(el => el.c === productSelectedID)
    setProduct(ref)
  },[listProducts,productSelectedID])

 

  return (
    <>
      {
        product.length > 0 && (
          <>
            <div className="cardViewer">
              <span className="h">Visualización del producto</span>
              <div className="contentCardProduct">
                <div className="img">
                  {product[0].k && <span />}
                  <img src={product[0].d} alt="viwe product" className='img-fluid w-100'/>
                  <div className="label">{product[0].g}</div>
                </div>
                <div className="content">
                  <strong className="titleP">{product[0].e}</strong>
                  <p>{product[0].h}</p>
                  <div className="text-center">
                    {
                      product[0].f[1] > 0 ? (
                        <>
                          <span className="before">
                            <span>Antes</span>
                            <span className="price">{convertAmount(product[0].f[0])}</span>
                          </span>
                          <div className="after">{convertAmount(product[0].f[1])}</div>
                        </>
                      ):
                      (
                        <>
                          <div className="after">{convertAmount(product[0].f[0])}</div>
                        </>
                      )
                    }
                  </div>
                </div>
                {
                  product[0].i.active && (
                    <>
                    <hr/>
                    <div className="d-flex align-items-center justify-content-between mb-2">
                      <small className='text-gray2'>Opción única</small>
                      {
                        product[0].i.req ? 
                          <span className="label-orange">Obligatorio</span>
                          : 
                          <span className="label-apple">Opcional</span>
                      }
                    </div>
                    {product[0].i.title.length !== 0 && <strong className="d-block text-center my-3">{product[0].i.title}</strong>}
                    {product[0].i.op.length > 0 && product[0].i.op.map((option,index)=> (
                      <div className="p-relative d-flex align-items-center justify-content-between" key={index}>
                        <label className="groupOneOptionLabel bg-white">
                          <span>{option.title}</span>
                          <div>
                            <strong className="price">{option.value === 0 ? '': `+ ${convertAmount(option.value)}`}</strong>
                            <input autoComplete="off" type="radio" name="oneOptionExample"/>
                          </div>
                        </label>
                      </div>
                    ))}
                    </>
                  )
                }
                {
                  product[0].j.active && (
                    <>
                    <hr className='mt-4'/>
                    <div className="d-flex align-items-center justify-content-between mb-2">
                      <small className='text-gray2'>Opción múltiple</small>
                      {
                        product[0].j.req ? 
                          <span className="label-orange">Obligatorio</span>
                          : 
                          <span className="label-apple">Opcional</span>
                      }
                    </div>
                    {product[0].j.title.length !== 0 && <strong className="d-block text-center mt-3">{product[0].j.title}</strong>}
                    <small className="text-center text-orange-dark d-block mb-3">{`( Elige un máximo de ${product[0].j.max === 0 ? product[0].j.op.length : product[0].j.max} opciones )`}</small>
                    <div id="contentMultipleView">
                      {product[0].j.op.length > 0 && product[0].j.op.map((option,index)=> (
                        <div className="p-relative d-flex align-items-center justify-content-between" key={index}>
                          <label className="groupOneOptionLabel bg-white">
                            <span>{option.title}</span>
                            <div>
                              <strong className="price">{option.value === 0 ? '': `+ ${convertAmount(option.value)}`}</strong>
                              <input autoComplete="off" type="checkbox" name="exampleMultipleOption"/>
                            </div>
                          </label>
                        </div>
                      ))}
                    </div>
                    
                    </>
                  )
                }  
                {
                  product[0].l && (
                    <>
                      <hr className='mt-4'/>
                      <div className=" rounded text-bg1 mt-3">
                        <p className="fw-bold mb-2">Comentarios adicionales</p>
                        <textarea name="" placeholder='Agregar una nota' defaultValue='' cols="30" rows="2" className='w-100 m-0 p-3 rounded-3 text-bg1'/>
                      </div>
                    </>
                  )
                }    
              </div>
            </div>
          </>
        )

      }
    </>
  )
}

export default ViewProduct