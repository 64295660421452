import React from 'react'
import { useSelector } from 'react-redux';
import { convertAmount } from '../../../../tools/format';


const Post22 = () => {

  const {dataPost, imgPostLogo, imgProduct} = useSelector(s => s.post)
  

  return (
    <div className="poster">
      <div className="img">
        {
          imgProduct && (
            <img
              src={imgProduct}
              className='b7'
              alt="img" />
          )
        }
        <div className="overlay" style={{
          background: dataPost.colors[0], opacity: .3,
          width: '450px', height: '40px', left: '87px', top: '473px'
        }}/>
      </div>
      <div className="svg">
        <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">
          <path fill={`${dataPost.colors[1]}`}  d="m949.68,949.68H74.32V74.32h875.37v875.37Zm-801-74.37h726.63V296H148.68v579.32Z"/>
          <path fill={`${dataPost.colors[1]}`}  d="m1008,1008H16V16h992v992Zm-990-2h988V18H18v988Z"/>
        </svg>
        
      </div>

      <img
        src={imgPostLogo}
        alt="logo" className="logo text tc"
        style={{top: '10%'}}
      />

      {
        dataPost.text[0] && (
          <div
            className="text tc text-center px-2 f-7 text-truncate"
            style={{color: dataPost.colors[0], top: '20%',
            fontSize: '24px', width: '86%'
          }}
          >{dataPost.text[0]}</div>
        )
      }
      {
        dataPost.text[1] && (
          <div
            className="w-content text text-center px-3 f-3 bc"
            style={{color: dataPost.colors[1], textShadow: ` 0 0 10px ${dataPost.colors[0]}` ,
              fontSize:'23px', bottom: '14%', letterSpacing: ' 10px', width: '71%', 
            }}
          >{convertAmount(dataPost.text[1])}</div>
        )
      }
      {
        dataPost.text[2] && (
          <div
            className="f-3 text-uppercase text bc text-truncate text-center"
            style={{color: dataPost.colors[0], fontSize: '11px',
              bottom: '9%', width: ' 85%'
            }}
          >{dataPost.text[2]}</div>
        )
      }
    </div>
  );
};

export default Post22;
