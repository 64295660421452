import React from 'react'
import infoGreen from '../../../img/dashboard/info_green.svg'

const InfoGreen = ({text, bg}) => {
  return (
    <>
      <div className={`${bg ? 'bg-b1': 'bg-b2'} rounded-lg my-2 p-3 d-flex`}>
        <img src={infoGreen} alt="" className='img-fluid mr-3 icon'/>
        <p className="m-0"><small className='d-block ps-2' style={{fontSize: '14px', color: 'white'}}>{text}</small></p>
      </div>
    </>
  )
}

export default InfoGreen