import React from "react";
import { useSelector } from "react-redux";

const AboutUsMenu = () => {
  const { menu } = useSelector((state) => state.user)


  return (
    <>
      <div className=" content">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-11">
              {
                menu?.j && menu?.j?.length >0 && (
                  <div className="whoIAm">
                    <div className="row">
                      <div className="col-md-3">
                        <h3 className="mb-4 mb-md-0">¿QUIÉNES SOMOS?</h3>
                      </div>
                      <div className="col-md-9">
                        <div className="ps-4">
                          <p className="m-0">
                            {menu?.j}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }
              <div className="row justify-content-end mt-4">
                {
                  menu?.k && menu?.k?.length>0 && (
                    <div className="col-md-6 text-end mb-4 mb-md-0">
                      <div className="pe-0 pe-md-4">
                        <h5>MISIÓN</h5>
                        <hr />
                        <p>
                          {menu?.k}
                        </p>
                      </div>
                    </div>
                  )
                }
                {
                  menu?.l?.length>0 && (
                    <div className="col-md-6 text-end pb-4 pb-md-0">
                      <div className="ps-4">
                        <h5>VISIÓN</h5>
                        <hr />
                        <p>
                          {menu?.l}
                        </p>
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUsMenu;
