import React from 'react'
import { useSelector } from 'react-redux';
import { convertAmount } from '../../../../tools/format';


const State13 = () => {

  const {dataPost, imgPostLogo, imgProduct} = useSelector(s => s.post)
  

  return (
    <div className="poster">
      <div className="img" style={{
        left: '13%', top: '18%', width: '74%', height: '58%'
      }}>
        {
          imgProduct && (
            <img
              src={imgProduct}
              className='b7'
              alt="img" />
          )
        }
      </div>
      <div className="svg">
        <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 490 870">
          <path fill={`${dataPost.colors[1]}`}  d="m0,0v870h490V0H0Zm424,658H66V168h358v490Z"/>
          <polygon fill={`${dataPost.colors[2]}`}  points="436.5 555 436.5 670.5 53.5 670.5 53.5 555 0 555 0 870 490 870 490 555 436.5 555"/>
          <g>
            <polygon fill={`${dataPost.colors[0]}`}  points="239.26 710.23 240.3 711.26 245.2 706.36 250.1 711.26 251.14 710.23 245.2 704.29 239.26 710.23"/>
            <polygon fill={`${dataPost.colors[0]}`}  points="239.26 717.99 240.3 719.03 245.2 714.13 250.1 719.03 251.14 717.99 245.2 712.05 239.26 717.99"/>
            <path fill={`${dataPost.colors[0]}`}  d="m245,696.16c-9.04,0-16.4,7.36-16.4,16.4s7.36,16.4,16.4,16.4,16.4-7.36,16.4-16.4-7.36-16.4-16.4-16.4Zm0,31.5c-8.33,0-15.1-6.77-15.1-15.1s6.77-15.1,15.1-15.1,15.1,6.77,15.1,15.1-6.77,15.1-15.1,15.1Z"/>
          </g>
          <path fill={`${dataPost.colors[0]}`}  d="m478,859.06H12V10.06h466v849Zm-465-1h464V11.06H13v847Z"/>
        </svg>
      </div>

      <img
        src={imgPostLogo}
        alt="logo" className="logo text tc"
        style={{top:'4%'}}
      />

      {
        dataPost.text[0] && (
          <div
            className="text bc  py-1 px-2 f-2 clamp-2 text-center"
            style={{color: dataPost.colors[0], 
            fontSize: '29px', bottom: '25%', width: '77%', lineHeight: '31px',
            textShadow: `0 0 10px ${dataPost.colors[2]}`
          }}
          >{dataPost.text[0]}</div>
        )
      }
      {
        dataPost.text[1] && (
          <div
            className="w-content text px-3 f-4 bc"
            style={{color: dataPost.colors[0],
              fontSize:'30px', letterSpacing: '3px', bottom: '3%'
              
              
            }}
          >{convertAmount(dataPost.text[1])}</div>
        )
      }
      {
        dataPost.text[2] && (
          <div
            className="f-4 text-uppercase text tc text-truncate"
            style={{color: dataPost.colors[2], fontSize: '15px',
              top: '13.5%', letterSpacing: '4px'
            }}
          >{dataPost.text[2]}</div>
        )
      }
      {
        dataPost.text[3] && (
          <div
            className="text bc py-1 px-5 f-3 text-truncate text-center"
            style={{color: dataPost.colors[0], fontSize: '17px',
              bottom: '10%', width:'90%'
          }}
          >{dataPost.text[3]}</div>
        )
      }
    </div>
  );
};

export default State13;
