import React from 'react'
import MainFreeTheme from '../../freetemplate/MainFreeTheme'
import BgMenu from './BgMenu'

const FastFood = () => {
  return (
    <MainFreeTheme
      bgMenu={<BgMenu />}
    />
  )
}

export default FastFood