import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateIssue } from '../../../redux/reducer/sudo'
import { setIsError, setStateNotification, setTextNotificate } from '../../../redux/reducer/interactions'
import solveErrors from './fx/solveErrors'

const ModalSolveIssue = () => {

  const {userIssue} = useSelector(s => s.sudo)
  const dispatch = useDispatch()
  

  const handleClose  = () => {
    dispatch(updateIssue([]))
  }

  const deleteUser = async() => {

    const response = await solveErrors(userIssue?.collection)

    if(response){
      dispatch(setTextNotificate('¡Caso resuelto!'))
      dispatch(setStateNotification(true))
      dispatch(setIsError(false)) 
    }else{
      dispatch(setTextNotificate('Upps, no pudo se marcado como un caso resuelto, inténtalo nuevamente'))
      dispatch(setStateNotification(true))
      dispatch(setIsError(true)) 
    }
  }



  return (
    <div
      className="modal modalDashboard fade"
      data-bs-backdrop="static" 
      data-bs-keyboard="false"
      id="ModalSolveIssue" 
      tabIndex="-1" 
      aria-labelledby="ModalSolveIssueLabel" 
      aria-hidden="true"
    >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="p-3 p-md-5 text-center text-white">
                <h5 className='mb-4'>¿Ya está resuelto el problema del usuario <span className='text-apple'>{userIssue?.email || 'NA'}</span>?</h5>
                <div className="row justify-content-center">
                  <div className="col-md-6">
                    <button
                      className='btn-orange w-100 m-0'
                      data-bs-dismiss="modal"
                      onClick={handleClose}
                    >No, cerrar</button>
                  </div>
                  <div className="col-md-6 order-first order-md-last mb-4 mb-md-0">
                    <button
                      className='btn-line-apple w-100 m-0'
                      data-bs-dismiss="modal"
                      onClick={deleteUser}
                    >SÍ, RESUELTO</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}

export default ModalSolveIssue