
const totalCalculate = (el) =>{
  let copy = JSON.parse(JSON.stringify(el?.order))
  let total = copy.reduce((accumulator, item) => {
    let itemValue = item.price * item.count
    return accumulator + itemValue
  }, 0)
  if(el?.payment?.delivery){
    let partial = total
    total = partial + el?.payment?.deliveryPrice
  }
  const finalPrice = total - el?.payment?.discount
  return finalPrice
}

export default totalCalculate