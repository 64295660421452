import React from 'react'
import dismissStep from '../change-password/fx/dismissStep'
import { useSelector } from 'react-redux'

const WelcomeVideo = () => {

  const {userID} = useSelector(s => s.log)

  const handleStep = async ()=> await dismissStep(userID,2)

  return (
    <section className="welcomevideo">
      <div className="content">
        <div className="row justify-content-center">
          <div className="col-12 mb-4">
            <div className="iframeVideo">

            </div>
          </div>
          <div className="col-md-4">
            <button className="btnPurple w-100" onClick={handleStep}>Continuar</button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WelcomeVideo
