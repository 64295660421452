import React from 'react'
import score from '../../img/vector/score.svg'

const CardComments = ({title, comment, name, key}) => {
  return (
    <div className="cardComments" key={key}>
      <img src={score} alt="score" className='img-fluid'/>
      <h5>{`"${title || 'Título'}"`}</h5>
      <p>{comment || 'Comentario'}</p>
      <strong>{name || 'Nombre'}</strong>
    </div>
  )
}

export default CardComments