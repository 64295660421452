import React from 'react'
import { useSelector } from 'react-redux';
import { convertAmount } from '../../../../tools/format';


const State22 = () => {

  const {dataPost, imgPostLogo, imgProduct} = useSelector(s => s.post)
  

  return (
    <div className="poster">
      <div className="img">
        {
          imgProduct && (
            <img
              src={imgProduct}
              className='b7'
              alt="img" />
          )
        }
      </div>
      <div className="svg">
        <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 490 870">
          <path fill={`${dataPost.colors[1]}`}  d="m413,0H0v870h490V0h-77ZM67.63,837.84c-25.81,0-46.81-21-46.81-46.81V78.97c0-25.81,21-46.81,46.81-46.81h9.37v17.24h-9.37c-16.33,0-29.56,13.24-29.56,29.56v712.07c0,16.33,13.24,29.56,29.56,29.56h8.92v17.24h-8.92Zm322.4-174.84H100.97c-13.79,0-24.97-11.18-24.97-24.97V174.97c0-13.79,11.18-24.97,24.97-24.97h289.06c13.79,0,24.97,11.18,24.97,24.97v463.06c0,13.79-11.18,24.97-24.97,24.97Zm79.14,128.03c0,25.81-21,46.81-46.81,46.81h-9.81v-17.24h9.81c16.33,0,29.56-13.24,29.56-29.56V78.97c0-16.33-13.24-29.56-29.56-29.56h-9.37v-17.24h9.37c25.81,0,46.81,21,46.81,46.81v712.07Z"/>
          <polygon fill={`${dataPost.colors[0]}`}  points="245 821.5 227.35 839.15 230.43 842.23 245 827.66 259.57 842.23 262.65 839.15 245 821.5"/>
        </svg>
      </div>

      <img
        src={imgPostLogo}
        alt="logo" className="logo text tc"
        style={{top: '3%'}}
      />

      {
        dataPost.text[0] && (
          <div
            className="text tc  py-1 px-2 text-center f-7 text-truncate"
            style={{color: dataPost.colors[0], fontSize: '20px',
            top: '10.7%', width: '86%'
          }}
          >{dataPost.text[0]}</div>
        )
      }
      {
        dataPost.text[1] && (
          <div
            className="w-content text px-3 f-4 bc"
            style={{color: dataPost.colors[0], fontSize: '25px',
            letterSpacing: '3px', bottom: '7%'
              
            }}
          >{convertAmount(dataPost.text[1])}</div>
        )
      }
      {
        dataPost.text[2] && (
          <div
            className="clamp-2 text text-center px-3 f-4 tc text-uppercase"
            style={{color: dataPost.colors[0], fontSize: '16px', lineHeight: '22px',
              top: '79%', width: '80%'
            }}
          >{dataPost.text[2]}</div>
        )
      }
      {/* {
        dataPost.text[3] && (
          <div
            className="text bl py-1 px-2 f-3 text-truncate"
            style={{color: dataPost.colors[3], fontSize: '25px',
            marginLeft:'32px', marginBottom:'130px', background: dataPost.colors[2]}}
          >{dataPost.text[3]}</div>
        )
      } */}
      {/* {
        dataPost.text[4] && (
          <div
            className="text br py-1 px-2 f-3 text-truncate"
            style={{color: dataPost.colors[3], fontSize: '17px',
              marginBottom:'50px', marginRight: '50px', border: `2px solid ${dataPost.colors[3]}`
            }}
          >{dataPost.text[4]}</div>
        )
      } */}
    
    </div>
  );
};

export default State22;
