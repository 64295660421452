import React from 'react'
import { useSelector } from 'react-redux';
import { convertAmount } from '../../../../tools/format';


const Post14 = () => {

  const {dataPost, imgPostLogo, imgProduct} = useSelector(s => s.post)
  

  return (
    <div className="poster">
      <div className="img">
        {
          imgProduct && (
            <img
              src={imgProduct}
              className='b7'
              alt="img" />
          )
        }
        
      </div>
      <div className="svg">
        <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">
          <g>
            <path fill={`${dataPost.colors[1]}`}  d="m372,884C214.75,919.11,67.67,800.16,0,733.72v290.28h908.54c-88.31-114.31-348.13-182.06-536.54-140Z"/>
            <path fill={`${dataPost.colors[1]}`}  d="m0,0v305.19c170.25,131.49,454,76.81,544.84-36.15,56.5-70.25,102.16-152.05,222.16-164.05,79.57-7.96,118.68-66.47,136.01-105H0Z"/>
          </g>
          <path fill={`${dataPost.colors[2]}`}  d="m841.44,0c-2.53,2.81-5.44,5.78-8.83,8.92-40.27,37.27-113.46,67.13-167.78,85.61-82.65,28.13-117.09,68.88-150.4,108.29-21.68,25.65-44.1,52.17-79.6,74.46-39.77,24.96-92.4,42.05-160.91,52.23-61.29,9.11-118.11,9.64-168.9,1.57-38.71-6.15-74.01-17.29-105.02-33.12v1.13c30.99,15.74,66.21,26.83,104.81,32.97,23.91,3.8,49.15,5.7,75.57,5.7,29.82,0,61.13-2.42,93.69-7.26,68.65-10.21,121.4-27.34,161.29-52.38,35.64-22.37,58.11-48.95,79.83-74.66,33.22-39.31,67.58-79.95,149.96-107.99,54.5-18.55,127.95-48.52,168.3-85.98,3.61-3.35,6.69-6.52,9.34-9.49h-1.36Z"/>
          <circle fill={`${dataPost.colors[2]}`}  cx="191" cy="385" r="125"/>
          <g>
            <polygon fill={`${dataPost.colors[2]}`}  points="936 74 953.25 92.17 970.5 74 936 74"/>
            <polygon fill={`${dataPost.colors[2]}`}  points="936 119.28 953.25 137.45 970.5 119.28 936 119.28"/>
            <polygon fill={`${dataPost.colors[2]}`}  points="936 164.55 953.25 182.72 970.5 164.55 936 164.55"/>
            <polygon fill={`${dataPost.colors[2]}`}  points="936 209.83 953.25 228 970.5 209.83 936 209.83"/>
          </g>
        </svg>
      </div>

      <img
        src={imgPostLogo}
        alt="logo" className="logo text cl"
        style={{
          marginLeft: '11%', top:'33%'
        }}
      />

      {
        dataPost.text[0] && (
          <div
            className="text tl  px-1 f-1 text-truncate"
            style={{color: dataPost.colors[2], width: '60%',
            fontSize: '36px', left:'2%' ,
            textShadow: `1px 1px 0px ${dataPost.colors[0]}`
          }}
          >{dataPost.text[0]}</div>
        )
      }
      {
        dataPost.text[1] && (
          <div
            className="w-content text px-3 f-4 tl"
            style={{color: dataPost.colors[2],
              background: dataPost.colors[3], fontSize:'42px',
              marginTop:' 11%', marginLeft: '0%',
              textShadow: `2px 2px 0px ${dataPost.colors[0]}`
            }}
          >{convertAmount(dataPost.text[1])}</div>
        )
      }
      {
        dataPost.text[2] && (
          <div
            className="f-3 text-uppercase text bl clamp-2"
            style={{color: dataPost.colors[2], marginLeft: '3%',
              marginBottom:'2.5%', maxWidth: '70%', fontSize: '13px'
            }}
          >{dataPost.text[2]}</div>
        )
      }
    
    </div>
  );
};

export default Post14;
