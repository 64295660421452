import React from "react";
import Categories from "./Categories";
import CreateProduct from "./CreateProduct";
import Catalog from "./Catalog";
import { useDispatch, useSelector } from "react-redux";
import { setIsEdit } from "../../../../redux/reducer/interactions";
import { setResetProductValues, setIDProductSelected } from "../../../../redux/reducer/product";

const Product = () => {

  const {step} = useSelector(state => state.log)
  const {isEdit} = useSelector(state => state.ux)
  const dispatch = useDispatch()

  const handleBack = ()=>{
    dispatch(setIsEdit(false))
    document.querySelector('#catalogP-tab').click()
    dispatch(setResetProductValues())
    dispatch(setIDProductSelected(''))
  }


  return (
    <>
      <section className="py-2 py-md-4 productSectionD">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className={`col-md-12 mb-0 mt-4 mt-md-0 ${!isEdit ? 'd-none' : 'd-block'}`}>
              <button
                className="btn-back"
                onClick={handleBack}
              >Salir del editor</button>
            </div>
            <div className="col-md-6 text-center px-0">
              <div className="p-3">
                <ul className={`nav nav-tabs tabsOptionsDash ${isEdit ? 'd-none':'d-flex'}`} role="tablist">
                  <li className={`nav-item ${step === 5 ? 'disabled': ''}`} role="presentation">
                    <button
                      className="nav-link active"
                      id="categoryP-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#categoryP-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="categoryP-tab-pane"
                      aria-selected="true"
                    >
                      Categorías
                    </button>
                  </li>
                  <li className={`nav-item ${step === 4 ? 'disabled': ''}`} role="presentation">
                    <button
                      className="nav-link"
                      id="productsP-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#productsP-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="productsP-tab-pane"
                      aria-selected="false"
                    >
                      Producto
                    </button>
                  </li>
                  <li className={`nav-item ${(step === 4 || step === 5) ? 'disabled': ''}`} role="presentation">
                    <button
                      className="nav-link"
                      id="catalogP-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#catalogP-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="catalogP-tab-pane"
                      aria-selected="false"
                    >
                      Catálogo
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12">
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="categoryP-tab-pane"
                  role="tabpanel"
                  aria-labelledby="categoryP-tab"
                  tabIndex="0"
                >
                  <Categories />
                </div>
                <div
                  className="tab-pane fade"
                  id="productsP-tab-pane"
                  role="tabpanel"
                  aria-labelledby="productsP-tab"
                  tabIndex="0"
                >
                  <CreateProduct />
                </div>
                <div
                  className="tab-pane fade"
                  id="catalogP-tab-pane"
                  role="tabpanel"
                  aria-labelledby="catalogP-tab"
                  tabIndex="0"
                >
                  <Catalog />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Product;


