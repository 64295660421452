import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import updateOnlyOnePiece from '../../../firebase/update/updateOnlyOnePiece'
import { setIsError, setStateLoading, setStateNotification, setTextNotificate } from '../../../redux/reducer/interactions'
import {setActiveMenu} from '../../../redux/reducer/menu'

const CloseAdvertisement = () => {

  const {userID} = useSelector(s => s.log)
  const dispatch = useDispatch()


  const handleActiveMenu = async()=>{
    dispatch(setStateLoading(true))
    await updateOnlyOnePiece(userID, process.env.REACT_APP_DATAMENU, 'a', true)
    dispatch(setTextNotificate('¡Tu menú digital ha sido activado!'))
    dispatch(setStateNotification(true))
    dispatch(setIsError(false))
    dispatch(setStateLoading(false))
    dispatch(setActiveMenu(true))
    
  }

  return (
    <section className="closedAdvertisement">
      <div>
        <h5 className='mb-4'>Tu menú actualmente está deshabilitado</h5>
        <button className='btnWhite' onClick={handleActiveMenu}>Activar menú digital</button>
      </div>
    </section>
  )
}

export default CloseAdvertisement