import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { convertAmount } from '../../../tools/format'
import {setProductSelected} from '../../../../redux/reducer/ordering'

const ListProducts = ({twoColumns, chooseDetails}) => {

  const {listCategories, listProducts} = useSelector(state => state.menu)
  const dispatch = useDispatch()

  const showModalProduct =(id)=>{
    let reference = listProducts.find(el => el.c === id)
    dispatch(setProductSelected(reference))
    chooseDetails()
    document.querySelector('.ocShow').scrollTo(0,0)
  }


  return (
    <>
      <div className="row justify-content-center">
        <div className="col-11 pb-4 pb-md-5 px-0">
        {listCategories?.map((cat) => {


          const matchingProducts = listProducts.filter((product) => product?.g === cat?.name)
          const activeProducts = matchingProducts.filter((product) => product?.a === true)
          

          return (
            <div className="row refPrintProducts" key={cat?.name} data-ref={cat?.name} >
              <div className="col-12">
                <div className="headCategory">
                  <h6 className='m-0'>{cat?.name}</h6>
                  <div />
                </div>
              </div>
              {
                activeProducts.map( el => {
                  return(
                    <div className={`${twoColumns ? 'col-md-6 mb-3': 'col-md-4  col-6 mb-3'}`} key={el?.c}>
                      <button
                        className="productList"
                        onClick={()=> showModalProduct(el?.c)}
                      >
                        <div className="img">
                          <img src={el?.d} alt={el?.e} />
                        </div>
                        <div className="content">
                          <div className="top">
                            <h6 className="text-truncate">{el?.e}</h6>
                            <p>{el?.h}</p>
                            <div className="prices">
                              {
                                el?.f?.[1] === null ? (
                                  <>
                                    <strong className="after">{convertAmount(el?.f?.[0])}</strong>
                                  </>
                                ):
                                (
                                  <>
                                    <span className="before">Antes <span>{convertAmount(el?.f?.[0])}</span></span>
                                    <strong className="after">{convertAmount(el?.f?.[1])}</strong>
                                  </>
                                )
                              }
                            </div>
                          </div>
                          <div className="bottom">
                            <div className="add">
                              <span>Agregar</span>
                              {
                                el?.k && (
                                  <svg id="Capa_1" className='icon' data-name="Capa 1" fill='white' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
                                    <path d="m32.62,11.06c-.53-.05-.99-.14-1.45-.12-.29.01-.61.15-.87.32-.64.42-1.16.96-1.52,1.64-.1.2-.13.38-.04.58.15.32.16.65.13.99,0,.07.01.18.06.22.63.53.75,1.22.67,1.98-.1.91-.52,1.7-.99,2.47-1.36,2.16-3.14,3.91-5.2,5.39-1.69,1.21-3.49,2.21-5.49,2.78-3.51,1-6.85.5-10.03-1.21-2.46-1.32-3.99-3.4-4.84-6.02-.13-.4,0-.76.32-.98.32-.22.69-.2,1.03.06.98.75,2,1.45,3.17,1.87,2.72.96,5.28.61,7.67-.99.75-.5,1.28-1.21,1.71-1.98.48-.85.92-1.73,1.38-2.6.55-1.04,1.18-2.04,2.05-2.85.69-.64,1.46-1.14,2.35-1.44.91-.31,1.82-.34,2.67.19.04.02.09.04.13.04.34.02.69.02,1.03.05.16.01.24-.04.32-.17.57-.96,1.3-1.79,2.24-2.42.75-.5,1.58-.77,2.49-.67,1.02.11,1.58.92,1.33,1.94-.08.33-.22.65-.32.92ZM4.41,20.58s0,.01,0,.03c.01.04.02.07.04.11.84,1.93,2.12,3.45,4,4.44,3.6,1.9,7.27,2.12,11,.42,2.52-1.14,4.69-2.77,6.56-4.81.75-.82,1.42-1.72,1.97-2.69.28-.5.51-1.03.56-1.61.03-.32,0-.62-.23-.87-.46.24-.72.3-1.15.22-.74-.15-1.42-.44-1.93-1.01-.68-.75-1.1-1.59-.67-2.65-.31-.12-.64-.13-.96-.07-.89.16-1.63.62-2.31,1.2-.82.71-1.4,1.61-1.9,2.55-.52.97-1.01,1.95-1.56,2.9-.51.9-1.17,1.7-2.05,2.27-3.11,1.99-6.34,2.19-9.66.57-.59-.29-1.13-.66-1.7-.99Zm27.47-10.8c.04-.32-.07-.47-.4-.5-.09,0-.17,0-.26,0-.79.03-1.44.39-2.02.9-.58.51-1.08,1.1-1.45,1.78-.25.46-.61.66-1.12.59-.23-.03-.47-.05-.7-.06-.26-.01-.48.2-.46.46,0,.14.05.28.1.41.28.73.85,1.12,1.57,1.33.15.04.43.07.47,0,.11-.19.13-.43.17-.66,0-.05-.03-.1-.05-.15-.2-.51-.16-1.01.09-1.5.48-.9,1.17-1.59,2.01-2.13.24-.16.54-.28.82-.33.39-.08.8-.09,1.23-.13Z"/>
                                    <path d="m10.7,23.98c2.54-.2,4.78-1.13,6.61-2.95.84-.84,1.4-1.88,1.93-2.94.39-.77.75-1.55,1.18-2.3.46-.82,1.06-1.54,1.78-2.15.12-.1.28-.17.44-.19.22-.03.43.14.5.35.08.23.04.45-.15.6-1.24.93-2.04,2.19-2.78,3.52-.47.83-.99,1.64-1.55,2.41-1.12,1.55-2.69,2.46-4.46,3.09-1.04.37-2.1.58-3.2.59-.06,0-.13,0-.19,0-.05,0-.09-.02-.11-.02Z"/>
                                  </svg>
                                )
                              }
                            </div>
                          </div>
                        </div>
                      </button>
                    </div>
                  )
                })
              }
            </div>
          );
        })}
        </div>
      </div>
    </>
  )
}

export default ListProducts