import React from 'react'

const HeaderSudo = () => {
  return (
    <>
      <header className='headerDashboard'>
        <span>Super Admin Gato</span>
      </header>
      <header className="headerMobile">
        <span>Super Admin Gato</span>
      </header>
    </>
  )
}

export default HeaderSudo