import React from 'react'
import banner from '../../img/brand/banner_landing.webp'
import bannersm from '../../img/brand/banner_landing_sm.webp'
import l1 from '../../img/brand/l_1.webp'
import l2 from '../../img/brand/l_2.webp'
import l3 from '../../img/brand/l_3.webp'
import l4 from '../../img/brand/l_4.webp'
import { useDispatch } from 'react-redux'
import { setShowDemo } from '../../redux/reducer/interactions'
import sudo from '../../firebase/update/sudo'

const Hero = () => {

  const dispatch = useDispatch()
  const handleOpenDemo = ()=> dispatch(setShowDemo(true))
  const handleRegister = ()=> sudo('register')

  return (
    <section className="hero">
      <img src={l1} alt="" className="img-fluid pa l1 float"/>
      <img src={l2} alt="" className="img-fluid pa l2"/>
      <img src={l3} alt="" className="img-fluid pa l3"/>
      <img src={l4} alt="" className="img-fluid pa l4"/>
      <div className="content">
        <div className="container">
          <div className="text-center">
            <h1 className='mb-2 mb-md-3'>EL MEJOR <span>MENÚ DIGITAL</span> DEL MERCADO</h1>
            <h4 className='mb-0 mb-md-5 d-none d-md-block'>Recibe pedidos con $0 de inversión inicial</h4>
            <p className='mb-4 d-block d-md-none'>Recibe pedidos con $0 de inversión inicial</p>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-9 col-11">
              <img src={banner} alt="Demos Menú Digital" className='img-fluid w-100 d-none d-md-block' />
              <img src={bannersm} alt="Demos Menú Digital" className='img-fluid w-100 d-block d-md-none' />
            </div>
            <div className="col-8 col-md-12">
              <div className="btns">
                <button
                  className='btnLine'
                  onClick={handleOpenDemo}
                >VER DEMOS</button>
                <button
                  className='btnRegister'
                  data-bs-toggle="modal"
                  data-bs-target="#modalRegisterL"
                  onClick={handleRegister}
                >REGÍSTRATE</button>
              </div>
            </div>
            <div className="col-12">
              <p className="m-0 text-center">No necesitas inscribir tu tarjeta crédito o débito</p>
            </div>

          </div>

        </div>
      </div>
    </section>
  )
}

export default Hero