import React from 'react'
import { Link } from 'react-router-dom'

const TableClientsFooder = ({name,tel,address,credits, deleteUser, step, email, country }) => {
  return (
      <div className="col-12">
        <div className="row text-center border-botton-greenDark py-1 text-white">
          <div className="col-md-3 col-12 align-self-center">
            <span>{name || 'No asignado'}</span>
            <div><span>{email || 'No asignado'}</span></div>
          </div>
          <div className="col-md-2 col-6 align-self-center">
            <div className="d-flex align-items-center justify-content-between">
              <span>{`(+${country}) ${tel}` || 'No asignado'}</span>
              <Link
                to={`/menu/${country}${tel}`}
                target="_blank"
                rel="noopener noreferrer"
                className='linkMenu' />
          
            </div>
          </div>
          <div className="col-md-3 col-6 align-self-center"><span>{address || 'No asignado'}</span></div>
          <div className="col-md-2 col-12 align-self-center"><span>{credits || 'No asignado'}</span></div>
          <div className="col-md-1 col-12 align-self-center"><span>{step || 0}</span></div>
          <div className="col-md-1 col-12 align-self-center actionsListDash">
            <div className="dropdown">
              <button 
                className="dropdown-toggle btnDots"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false" 
              />
              <ul className="dropdown-menu listDrop">
                <button
                  data-bs-toggle="modal"
                  data-bs-target="#ModalDeleteFooderUser"
                  onClick={deleteUser}
                >Eliminar Usuario</button>
                {/* <button data-ref={id} >Bloquear menú digital</button> */}
                {/* <button data-ref={id} >Modificar créditos</button> */}
                {/* <button data-ref={id} >Crear nueva sucursal</button> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
  )
}

export default TableClientsFooder