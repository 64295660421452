import React from 'react'
import { useSelector } from 'react-redux';
import { convertAmount } from '../../../../tools/format';


const Post3 = () => {

  const {dataPost, imgPostLogo, imgProduct} = useSelector(s => s.post)
  

  return (
    <div className="poster">
      <div className="img">
        {
          imgProduct && (
            <img
              src={imgProduct}
              className='b7'
              alt="img" />
          )
        }
      </div>
      <div className="svg">
        <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">
          <polygon  fill={`${dataPost.colors[0]}`} points="603.62 89.49 649 334 1024 1024 1024 0 719.05 .31 603.62 89.49"/>
          <polygon  fill={`${dataPost.colors[2]}`} points="649 334 1024 875.11 1024 1024 1011.43 1024 649 334"/>
          <polygon fill={`${dataPost.colors[4]}`}  points="967.25 59.75 845 59.75 845 62.75 964.25 62.75 964.25 172 967.25 172 967.25 59.75"/>
          <polygon fill={`${dataPost.colors[4]}`}  points="80 952 202.25 952 202.25 949 83 949 83 839.75 80 839.75 80 952"/>
          <rect x="603.62" y="630" width="420.38" height="178" fill={`${dataPost.colors[3]}`}/>
          <polygon  fill={`${dataPost.colors[1]}`} points="830.06 .36 649 334 0 6.71 0 0 830.06 .36"/>
          <polygon fill={`${dataPost.colors[4]}`}  points="137 0 137 152 237 123.48 337 152 337 0 137 0"/>
        </svg>
      </div>

      <img
        src={imgPostLogo}
        alt="logo" className="logo text "
        style={{left: '15.5%', top: '1.6%'}}
      />

      {
        dataPost.text[0] && (
          <div
            className="text text-end  py-1 px-1 f-2 clamp-2"
            style={{color: dataPost.colors[4], lineHeight: ' 32px',
            textShadow: `0px 0px 8px ${dataPost.colors[3]}`, 
            fontSize: '30px', maxWidth: '57%', right: '8%', top: '7%'}}
          >{dataPost.text[0]}</div>
        )
      }
      {
        dataPost.text[1] && (
          <div
            className="text px-3 f-2 me-3 text-center"
            style={{color: dataPost.colors[2], bottom: '21%',
              fontSize:'32px', right: '-3%',
              width: '41%'
            }}
          >{convertAmount(dataPost.text[1])}</div>
        )
      }
      {
        dataPost.text[2] && (
          <div
            className="f-4 text-end text-uppercase text clamp-3 "
            style={{color: dataPost.colors[4], fontSize: '14px', top: '33%',
              width: '27%', lineHeight: '16px', right: '5%'
            }}
          >{dataPost.text[2]}</div>
        )
      }
      {
        dataPost.text[3] && (
          <div
            className="text f-3 text-truncate text-center "
            style={{color: dataPost.colors[4], fontSize: '17px',
            right:'0%', bottom: '31%', width: '41%'
          }}
          >{dataPost.text[3]}</div>
        )
      }
    </div>
  );
};

export default Post3;
