import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setDataRequest } from '../../../redux/reducer/sudo'
import updateStateRequest from './fx/updateStateRequest'
import { setIsError, setStateNotification, setTextNotificate } from '../../../redux/reducer/interactions'

const ModalDeleteRequest = () => {

  const {dataRequest} = useSelector(s => s.sudo)
  const dispatch = useDispatch()


  const handleReset = ()=> dispatch(setDataRequest({}))


  const updateState = async()=>{

    const response = await updateStateRequest(dataRequest?.tel)
    if(response){
      dispatch(setTextNotificate('Esta solicitud ha sido rechazada satisfactoriamente'))
      dispatch(setStateNotification(true))
      dispatch(setIsError(false)) 
    }else{
      dispatch(setTextNotificate('Ha ocurrido un error al eliminar esta solicitud'))
      dispatch(setStateNotification(true))
      dispatch(setIsError(true)) 
    }

  }

  return (
    <div
      className="modal modalDashboard fade"
      data-bs-backdrop="static" 
      data-bs-keyboard="false"
      id="modalConfirmRequest" 
      tabIndex="-1" 
      aria-labelledby="modalConfirmRequestLabel" 
      aria-hidden="true"
    >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="p-3 p-md-5 text-center text-white">
                <h4 className='text-apple mb-4'>¿Estás seguro de eliminar esta solicitud?</h4>
                <p> Nombre: {dataRequest?.name || 'NA'}</p>
                <p> Celular: {dataRequest?.tel || 'NA'}</p>
                <p> Email: {dataRequest?.email || 'NA'}</p>
                <p className='mb-5 '>Fecha de solicitud: {dataRequest?.date || 'NA'}</p>
                
                
                
                <div className="row justify-content-center">
                  <div className="col-md-6">
                    <button
                      className='btn-line-apple w-100 m-0'
                      data-bs-dismiss="modal"
                      onClick={handleReset}
                    >No, cerrar</button>
                  </div>
                  <div className="col-md-6 order-first order-md-last mb-4 mb-md-0">
                    <button
                      className='btn-orange w-100 m-0'
                      data-bs-dismiss="modal"
                      onClick={updateState}
                    >Sí, rechazar solicitud</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}

export default ModalDeleteRequest