import React from 'react'
import e1 from '../../../../img/dashboard/post/e1.webp'
import e2 from '../../../../img/dashboard/post/e2.webp'
import e3 from '../../../../img/dashboard/post/e3.webp'
import e4 from '../../../../img/dashboard/post/e4.webp'
import e5 from '../../../../img/dashboard/post/e5.webp'
import e6 from '../../../../img/dashboard/post/e6.webp'
import e7 from '../../../../img/dashboard/post/e7.webp'
import e8 from '../../../../img/dashboard/post/e8.webp'
import e9 from '../../../../img/dashboard/post/e9.webp'
import e10 from '../../../../img/dashboard/post/e10.webp'
import e11 from '../../../../img/dashboard/post/e11.webp'
import e12 from '../../../../img/dashboard/post/e12.webp'
import e13 from '../../../../img/dashboard/post/e13.webp'
import e14 from '../../../../img/dashboard/post/e14.webp'
import e15 from '../../../../img/dashboard/post/e15.webp'
import e16 from '../../../../img/dashboard/post/e16.webp'
import e17 from '../../../../img/dashboard/post/e17.webp'
import e18 from '../../../../img/dashboard/post/e18.webp'
import e19 from '../../../../img/dashboard/post/e19.webp'
import e20 from '../../../../img/dashboard/post/e20.webp'
import e21 from '../../../../img/dashboard/post/e21.webp'
import e22 from '../../../../img/dashboard/post/e22.webp'
import e23 from '../../../../img/dashboard/post/e23.webp'
import e24 from '../../../../img/dashboard/post/e24.webp'

import { useDispatch } from 'react-redux'
import { setDataPost, setIndexPost, toggleActiveEditor, updateStep } from '../../../../redux/reducer/post'

const ContentState = () => {

  const dispatch = useDispatch()

  const dataSegment = [
    {id: 'state1', image: e1 , colors:['#375349','#eac376','#372e15','#f2efcf'],
      text: ['Nombre del producto', 30000, 'Aquí puedes agregar cualquier información adicional', 'Pídelo ahora'],
      crop: {width: 492, height: 674, x: 1, y: 1.35}},
    {id: 'state2', image: e2 , colors:['#e6e6e6','#c9a6d9','#000000'],
      text: ['Nombre del producto', 23000, 'Aquí puedes agregar cualquier información adicional','Pídelo ahora'],
      crop: {width: 420, height: 575, x: .73, y: 1}},
    {id: 'state3', image: e3 , colors:['#37aea6','#3a3a4b','#eae7dc'],
      text: ['Nombre del producto', 17000, 'Pídelo ahora!','Aquí puedes agregar cualquier información adicional', 'valor entrada'],
      crop: {width: 490, height: 870, x: .564, y: 1}},
    {id: 'state4', image: e4 , colors:['#d0aeb7','#000000','#fcfcfc'],
      text: ['Nombre del producto', 14800, 'Aquí puedes agregar cualquier información adicional'],
      crop: {width: 435, height: 600, x: .72, y: 1}},
    {id: 'state5', image: e5 , colors:['#9ef5e3','#000000','#dedace'],
      text: ['Nombre del producto', 22500, 'Aquí puedes agregar cualquier información adicional'],
      crop: {width: 373, height: 515, x: .725, y: 1}},
    {id: 'state6', image: e6 , colors:['#f5eedc','#fd8c30','#eee2c6','#6d5023'],
      text: ['Nombre del producto', 22500,'Aquí puedes agregar cualquier información adicional'],
      crop: {width: 285, height: 460, x: .62, y: 1}},
    {id: 'state7', image: e7 , colors:['#ffffff','#ff4242','#f0f0f0', '#624c1e'],
      text: ['Nombre del producto', 25700, 'Aquí puedes agregar cualquier información adicional'],
      crop: {width: 310, height: 523, x: .595, y: 1}},
    {id: 'state8', image: e8 , colors:['#ffffff','#ffa700', '#000000'],
      text: ['Nombre del producto', 15200, 'Pídelo ahora', 'Aquí puedes agregar cualquier información adicional'],
      crop: {width: 419, height: 538, x: .778, y: 1}},
    {id: 'state9', image: e9 , colors:['#e0e0e0','#d4ea61', '#000000'],
      text: ['Nombre del producto', 23000, 'Aquí puedes agregar cualquier información adicional'],
      crop: {width: 279, height: 575, x: .486, y: 1}},
    {id: 'state10', image: e10 , colors:['#6a907e','#fbfada', '#5d4028'],
      text: ['Nombre del producto', 13500],
      crop: {width: 490, height: 870, x: .564, y: 1}},
    {id: 'state11', image: e11 , colors:['#2d2541','#787b7d', '#ffffff', '#fbdaac'],
      text: ['Nombre del producto', 13500],
      crop: {width: 320, height: 820, x: .389, y: 1}},
    {id: 'state12', image: e12 , colors:['#6d7c6a','#f8f9eb', '#333d2e'],
      text: ['Nombre del producto', 13500, 'Aquí puedes agregar cualquier información adicional'],
      crop: {width: 490, height: 661, x: .74, y: 1}},
    {id: 'state13', image: e13 , colors:['#f5e9bd','#e9e2e2', '#4f4136', '#000000'],
      text: ['Nombre del producto', 13500, 'Menú especial', 'pídelo por solo'],
      crop: {width: 360, height: 490, x: .73, y: 1}},
    {id: 'state14', image: e14 , colors:['#d4d8b0','#2d444e', '#ffe6bd'],
      text: ['Nombre del producto', 13500 , 'Aquí puedes agregar cualquier información adicional'],
      crop: {width: 490, height: 534, x: .92, y: 1}},
    {id: 'state15', image: e15 , colors:['#fff5e0','#616855', '#000000'],
      text: ['Nombre del producto', 13500, 'Pídelo ahora', 'por sólo', 'Aquí puedes agregar cualquier información adicional' ],
      crop: {width: 490, height: 500, x: 1, y: 0.972}},
    {id: 'state16', image: e16 , colors:['#f4f2f0','#c69c53', '#362e26'],
      text: ['Nombre del producto', 13500 ],
      crop: {width: 387, height: 628, x: .616, y: 1}},
    {id: 'state17', image: e17 , colors:['#e8e8e8','#f5b2b2', '#404040'],
      text: ['Nombre del producto', 13500, 'Aquí puedes agregar cualquier información adicional' ],
      crop: {width: 330, height: 498, x: 0.662, y: 1}},
    {id: 'state18', image: e18 , colors:['#bc3860','#f9edd2', '#58745c'],
      text: ['Nombre del producto', 13500, 'Aquí puedes agregar cualquier información adicional' ],
      crop: {width: 346, height: 508, x: 0.681, y: 1}},
    {id: 'state19', image: e19 , colors:['#e7e2d0','#dda95f','#464a30'],
      text: ['Nombre del producto', 13500, '¡Gran promoción!' ],
      crop: {width: 458, height: 568, x: 0.8, y: 1}},
    {id: 'state20', image: e20 , colors:['#ffffff','#484a45','#545e50','#f7a78d'],
      text: ['Nombre del producto', 13500, '¡Pídelo ahora!' ],
      crop: {width: 441, height: 653, x: .675, y: 1}},
    {id: 'state21', image: e21 , colors:['#e6e6e6','#e5f9b9', '#000000'],
      text: ['Nombre del producto', 13500, ' ¡Pídelo ahora!', 'Aquí puedes agregar cualquier información adicional' ],
      crop: {width: 453, height: 831, x: .545, y: 1}},
    {id: 'state22', image: e22 , colors:['#000000','#f7f7f7'],
      text: ['Nombre del producto', 13500, 'Aquí puedes agregar cualquier información adicional' ],
      crop: {width: 490, height: 870, x: .564, y: 1}},
    {id: 'state23', image: e23 , colors:['#c1d2fb','#636569'],
      text: ['Nombre del producto', 13500, 'Aquí puedes agregar cualquier información adicional', '¡Pídelo ahora!' ],
      crop: {width: 600, height: 600, x: 1, y: 1}},
    {id: 'state24', image: e24 , colors:['#b8ffe7','#efd9bd','#000000'],
    text: ['Nombre del producto', 13500, '¡Gran pormoción!', ' Aquí puedes agregar cualquier información adicional' ],
    crop: {width: 490, height: 870, x: .564, y: 1}},
  ]  


  const selectTypePost = index => {
    dispatch(setDataPost(dataSegment[index]))
    dispatch(setIndexPost(index))
    dispatch(toggleActiveEditor())
    dispatch(updateStep(2))
  }

  return (
    <div className="contentDesign">
      <div className="container-fluid py-2 mt-2 mt-md-0">
        <div className="row mt-4 mt-md-0 mb-5 mb-md-0">
          {
            dataSegment.map((el, i) => (
              <div
                className="col-md-2 col-6 p-0 mb-2"
                key={el.id}
              >
                <button onClick={()=> selectTypePost(i)}>
                  <img src={el.image} alt="state" className='img-fluid'/>
                </button>
              </div>    
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default ContentState