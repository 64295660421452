import React from 'react'
import { useSelector } from 'react-redux';
import { convertAmount } from '../../../../tools/format';


const Post18 = () => {

  const {dataPost, imgPostLogo, imgProduct} = useSelector(s => s.post)
  

  return (
    <div className="poster">
      <div className="img" style={{width:'63%', height: '92%', top: '4.5%', left: '32%'}}>
        {
          imgProduct && (
            <img
              src={imgProduct}
              className='b7'
              alt="img" />
          )
        }
        <div className="overlay" style={{background: dataPost.colors[1], opacity: .1}}/>
      </div>
      <div className="svg">
        <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">
          <polygon fill={`${dataPost.colors[0]}`}  points="331 971 331 49 379 49 379 0 0 0 0 1024 379 1024 379 971 331 971"/>
          <polygon fill={`${dataPost.colors[1]}`}  points="378 0 378 49 970 49 970 971 378 971 378 1024 1024 1024 1024 0 378 0"/>
          <rect fill={`${dataPost.colors[2]}`}  x="587" y="880" width="223" height="144"/>
        </svg>
      </div>

      <img
        src={imgPostLogo}
        alt="logo" className="logo text br"
        style={{right: '23.8%', bottom: '2.5%'}}
      />

      {
        dataPost.text[0] && (
          <div
            className="text px-2 cl f-1 text-center text-truncate"
            style={{color: dataPost.colors[3], width: '100%', transform: 'rotate(-90deg)',
            fontSize: '38px', marginLeft: '-30%', 
          }}
          >{dataPost.text[0]}</div>
        )
      }
      {
        dataPost.text[2] && (
          <div
            className="text px-2 cl f-3 text-center text-truncate"
            style={{color: dataPost.colors[2], width: '100%', transform: 'rotate(-90deg)',
            fontSize: '20px', marginLeft: '-39%', opacity: .5
          }}
          >{dataPost.text[2]}</div>
        )
      }
      {
        dataPost.text[1] && (
          <div
            className="w-content text px-3 f-2 cr border"
            style={{color: dataPost.colors[0],
              background: dataPost.colors[3], fontSize:'30px',
              marginTop:' -45px',
              textShadow: `2px 2px 0px ${dataPost.colors[1]}`
            }}
          >{convertAmount(dataPost.text[1])}</div>
        )
      }
    </div>
  );
};

export default Post18;
