import React from 'react'
//img
import imageExample from '../../../../../img/dashboard/img_example.svg'
import { useSelector } from 'react-redux'
import { convertAmount } from '../../../../tools/format'

const Viewer = () => {

  const {updateProductoFile, title, description,category,price,discount,uniqueoption,multipleoption,chilli,comments} = useSelector(state => state.product)

  


  return (
    <>
      <div className="cardViewer">
        <span className="h mt-3">Visualización del producto</span>
        <div className="contentCardProduct">
          <div className="img">
            {chilli && <span />}
            {
              updateProductoFile.length === 0 ?
                <img src={imageExample} alt="viwe product" className='img-fluid w-100'/>
                :
                <img src={updateProductoFile} alt="viwe product" className='img-fluid w-100'/>
            }
            {category.length !== 0 && <div className="label">{category}</div>}
          </div>
          <div className="content">
            <strong className="titleP">{title.length === 0 ? 'Título de tu producto': title}</strong>
            <p>{description.length === 0 ? 'Aquí necesitas referenciar los ingredientes de tu producto. Recuerda especificar la información que necesita ver tu cliente.': description}</p>
            <div className="text-center">
              {
                discount > 0 ? (
                  <>
                    <span className="before">
                      <span>Antes</span>
                      <span className="price">{convertAmount(price)}</span>
                    </span>
                    <div className="after">{convertAmount(discount)}</div>
                  </>
                ):
                (
                  <>
                    <div className="after">{convertAmount(price)}</div>
                  </>
                )
              }
            </div>
          </div>
          {
            uniqueoption.active && (
              <>
              <hr/>
              <div className="d-flex align-items-center justify-content-between mb-2">
                <small className='text-gray2'>Opción única</small>
                {
                  uniqueoption.req ? 
                    <span className="label-orange">Obligatorio</span>
                    : 
                    <span className="label-apple">Opcional</span>
                }
              </div>
              {uniqueoption.title.length !== 0 && <strong className="d-block text-center my-3">{uniqueoption.title}</strong>}
              {uniqueoption.op.length > 0 && uniqueoption.op.map((option,index)=> (
                <div className="p-relative d-flex align-items-center justify-content-between" key={index}>
                  <label className="groupOneOptionLabel bg-white">
                    <span>{option.title}</span>
                    <div>
                      <strong className="price">{option.value === 0 ? '': `+ ${convertAmount(option.value)}`}</strong>
                      <input type="radio" name="oneOptionExample"/>
                    </div>
                  </label>
                </div>
              ))}
              </>
            )
          }
          {
            multipleoption.active && (
              <>
              <hr className='mt-4'/>
              <div className="d-flex align-items-center justify-content-between mb-2">
                <small className='text-gray2'>Opción múltiple</small>
                {
                  multipleoption.req ? 
                    <span className="label-orange">Obligatorio</span>
                    : 
                    <span className="label-apple">Opcional</span>
                }
              </div>
              {multipleoption.title.length !== 0 && <strong className="d-block text-center mt-3">{multipleoption.title}</strong>}
              <small className="text-center text-orange-dark d-block mb-3">{`( Elige un máximo de ${multipleoption.max === 0 ? multipleoption.op.length : multipleoption.max} opciones )`}</small>
              <div id="contentMultipleView">
                {multipleoption.op.length > 0 && multipleoption.op.map((option,index)=> (
                  <div className="p-relative d-flex align-items-center justify-content-between" key={index}>
                    <label className="groupOneOptionLabel bg-white">
                      <span>{option.title}</span>
                      <div>
                        <strong className="price">{option.value === 0 ? '': `+ ${convertAmount(option.value)}`}</strong>
                        <input type="checkbox" name="exampleMultipleOption"/>
                      </div>
                    </label>
                  </div>
                ))}
              </div>
              
              </>
            )
          }  
          {
            comments && (
              <>
                <hr className='mt-4'/>
                <div className=" rounded text-bg1 mt-3">
                  <p className="fw-bold mb-2">Comentarios adicionales</p>
                  <textarea name="" placeholder='Agregar una nota' defaultValue='' cols="30" rows="2" className='w-100 m-0 p-3 rounded-3 text-bg1'/>
                </div>
              </>
            )
          }    
        </div>
      </div>
    </>
  )
}

export default Viewer