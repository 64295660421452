import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateNotification } from '../../redux/reducer/sudo'

const Messages = () => {

  const {note} = useSelector(s => s.sudo)
  const dispatch = useDispatch()
  
  const closeMessage = ()=> dispatch(updateNotification({visible: false, text: ''}))

  return (
    <>
    {
      note.visible && (  
        <div className="bg-b1 text-white p-4 text-center border mb-4">
          <p>{note.text}</p>
          <div className="row justify-content-center">
            <div className="col-md-4">
              <button
                onClick={closeMessage}
                className='btn-orange'
              >CERRAR AVISO</button>
            </div>
          </div>
        </div>
      )
    }
    </>
  )
}

export default Messages