import React from 'react'
import { useSelector } from 'react-redux';
import { convertAmount } from '../../../../tools/format';


const State6 = () => {

  const {dataPost, imgPostLogo, imgProduct} = useSelector(s => s.post)
  

  return (
    <div className="poster">
      <div className="img" style={{
        left: '21%', top: '21%', width: '58%', height: '55%'
      }}>
        {
          imgProduct && (
            <img
              src={imgProduct}
              className='b7'
              alt="img" />
          )
        }
      </div>
      <div className="svg">
        <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 490 870">
          <defs>
            <style>
              {`
              .ghjuikytjy {
                opacity: .2;
              }
              `}
            </style>
          </defs>
          <path fill={`${dataPost.colors[0]}`}  d="m0,0v870h490V0H0Zm386,541.24c0,62.27-50.48,112.76-112.76,112.76H104v-361.21c0-54.01,43.78-97.79,97.79-97.79h184.21v346.24Z"/>
          <path fill={`${dataPost.colors[2]}`}  d="m490,0h-176.95l-2.37,8.86c-3.61,13.46.24,27.82,10.09,37.67l103.7,103.7c9.85,9.85,24.21,13.7,37.67,10.09l27.86-7.47V0Z"/>
          <path fill={`${dataPost.colors[2]}`}  d="m18.54,417.1c-4.94-4.94-11.71-7.44-18.54-7.01v211.94l48.36-12.96c8.32-2.23,14.81-8.73,17.04-17.04l23.46-87.55c2.23-8.32-.15-17.19-6.24-23.28l-64.09-64.09Z"/>
          <path fill={`${dataPost.colors[3]}`}  d="m275.09,681.5H85.68v-403.14c0-60.58,49.29-109.86,109.86-109.86h206.08v386.47c0,69.77-56.76,126.53-126.53,126.53Zm-187.42-2h187.42c68.67,0,124.53-55.86,124.53-124.53V170.5h-204.08c-59.48,0-107.86,48.39-107.86,107.86v401.14Z"/>
          <path fill={`${dataPost.colors[1]}`}  d="m125.39,625.64c-20.05,0-36.6,14.98-39.06,34.36-2.46-19.38-19.01-34.36-39.06-34.36,20.05,0,36.6-14.98,39.06-34.36,2.46,19.38,19.01,34.36,39.06,34.36Z"/>
          <path fill={`${dataPost.colors[1]}`}  d="m439.83,244.87c-20.05,0-36.6,14.98-39.06,34.36-2.46-19.38-19.01-34.36-39.06-34.36,20.05,0,36.6-14.98,39.06-34.36,2.46,19.38,19.01,34.36,39.06,34.36Z"/>
          <circle fill={`${dataPost.colors[1]}`}  cx="380" cy="623" r="9"/>
          <circle fill={`${dataPost.colors[1]}`}  cx="119" cy="202" r="9"/>
          <g className="ghjuikytjy">
            <path fill={`${dataPost.colors[3]}`}  d="m450.38,530.97v-12.97h-28.38v28.38h10.54v12.97h28.38v-28.38h-10.54Zm-26.76,13.78v-25.14h25.14v11.35h-16.22v13.78h-8.92Zm25.14-12.16v12.16h-14.59v-12.16h14.59Zm10.54,25.14h-25.14v-11.35h16.22v-13.78h8.92v25.14Z"/>
            <path fill={`${dataPost.colors[3]}`}  d="m450.38,566.65h-28.38v28.38h10.54v12.97h28.38v-28.38h-10.54v-12.97Zm-26.76,26.76v-25.14h25.14v11.35h-16.22v13.78h-8.92Zm25.14-12.16v12.16h-14.59v-12.16h14.59Zm10.54,0v25.14h-25.14v-11.35h16.22v-13.78h8.92Z"/>
          </g>
          <g className="ghjuikytjy">
            <path fill={`${dataPost.colors[3]}`}  d="m56.38,214.97v-12.97h-28.38v28.38h10.54v12.97h28.38v-28.38h-10.54Zm-26.76,13.78v-25.14h25.14v11.35h-16.22v13.78h-8.92Zm25.14-12.16v12.16h-14.59v-12.16h14.59Zm10.54,25.14h-25.14v-11.35h16.22v-13.78h8.92v25.14Z"/>
            <path fill={`${dataPost.colors[3]}`}  d="m56.38,250.65h-28.38v28.38h10.54v12.97h28.38v-28.38h-10.54v-12.97Zm-26.76,26.76v-25.14h25.14v11.35h-16.22v13.78h-8.92Zm25.14-12.16v12.16h-14.59v-12.16h14.59Zm10.54,0v25.14h-25.14v-11.35h16.22v-13.78h8.92Z"/>
          </g>
        </svg>
        
      </div>

      <img
        src={imgPostLogo}
        alt="logo" className="logo text tc "
        style={{top: '3%'}}
      />

      {
        dataPost.text[0] && (
          <div
            className="text tc py-1 f-13 text-center text-truncate"
            style={{color: dataPost.colors[3], width: '100%',
            fontSize: '29px', top:'11%',
          }}
          >{dataPost.text[0]}</div>
        )
      }
      {
        dataPost.text[1] && (
          <div
            className="w-content text px-3 f-7 text-center bc"
            style={{color: dataPost.colors[3], fontSize: ' 31px', bottom: '12%'
              
            }}
          >{convertAmount(dataPost.text[1])}</div>
        )
      }
      {
        dataPost.text[2] && (
          <div
            className="f-4 text-center text bc clamp-2"
            style={{color: dataPost.colors[3], fontSize: '17px',
              width: '80%', lineHeight: ' 19px', bottom: '4%'
            }}
          >{dataPost.text[2]}</div>
        )
      }
  
    
    </div>
  );
};

export default State6;
