import React from 'react'
import { useDispatch } from 'react-redux'
import { setCleanOrder } from '../../../../redux/reducer/user'

const ModalConfirmDeleted = () => {

  const dispatch = useDispatch()

  const handleDeleteOrder = ()=>  dispatch(setCleanOrder())

  return (
    <div className="modal fade modalSM" id="confirmDeleted" tabIndex="-1" aria-labelledby="confirmDeletedLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content text-center">
          <h2 className='mb-4' >Oops!</h2>
          <p>¿Deseas limpiar tu carrito?</p>
          <div className="row justify-content-center mt-3">
            <div className="col-md-6 col-9">
              <button
                type="button"
                className="btnModalCancel"
                data-bs-dismiss="modal"
              >Cancelar</button>
            </div>
            <div className="col-md-6 col-9 order-first order-md-last mb-4 mb-md-0">
              <button
                type='button'
                className='btnModalConfirm'
                data-bs-dismiss="modal"
                onClick={handleDeleteOrder}
              ><strong>Borrar todo</strong></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModalConfirmDeleted