import React from 'react'
import ViewProduct from './ViewProduct'
import { useDispatch } from 'react-redux'
import { setIDProductSelected } from '../../../../../redux/reducer/product'

const ModalViewCatalog = () => {

  const dispatch = useDispatch()

  const handleClose = ()=> dispatch(setIDProductSelected(''))

  return (
    <>
      <div className="modal fade modalDashboard" id="ModalViewCatalog" tabIndex="-1" aria-labelledby="ModalViewCatalog" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body p-3 p-md-4">
              <ViewProduct />
              <div className="py-4 my-5"></div>
              <div className="footerModalProduct">
                <button
                  type='button'
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleClose}
                >Cerrar visualización</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ModalViewCatalog



