import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setFilterClient, setUserToDelete } from '../../../redux/reducer/sudo';
import EmptyClient from '../../admin/dashboard/comp-contactlist/EmptyClient';
import Slider from 'react-slick';
import TableClientsFooder from './TableClientsFooder';

const FooderCustomers = () => {
  const { mycustomers, filterClient } = useSelector((s) => s.sudo);
  const slideClient = useRef(null);
  const dispatch = useDispatch();
  const countPagination = 100;

  const handleFilter = (e) => {
    dispatch(setFilterClient(e.target.value));
  };

  const handleCleanFilter = () => {
    dispatch(setFilterClient(''));
  };

  const chunkArray = (array, size) => {
    const chunkedArr = [];
    for (let i = 0; i < array.length; i += size) {
      chunkedArr.push(array.slice(i, i + size));
    }
    return chunkedArr;
  };

  const chunkedCustomerList = chunkArray(mycustomers, countPagination);
  const matchingClient = mycustomers?.filter((el) => el?.a?.toLowerCase().includes(filterClient?.toLowerCase()));

  const slideNext = () => {
    slideClient.current.slickNext();
  };
  const slidePrev = () => {
    slideClient.current.slickPrev();
  };

  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: 'slideClient',
  };

  const deleteUser = (data) => dispatch(setUserToDelete(data));

  return (
    <>
      <section className="p-3">
        <h5 className="text-center text-apple mb-4 mt-2">Mis clientes {`(${mycustomers?.length})`}</h5>
        {mycustomers?.length === 0 ? (
          <EmptyClient />
        ) : (
          <>
            <div className="bg-b1 rounded-lg py-3">
              <div className="row">
                <div className="col-md-6 align-self-center mb-3 mb-md-0">
                  <div className="inputDash m-0 px-3">
                    <input
                      type="text"
                      autoComplete="off"
                      placeholder="Buscar por nombre"
                      className="bg-b2 rounded px-3"
                      value={filterClient || ''}
                      onChange={(e) => handleFilter(e)}
                    />
                  </div>
                </div>
                <div className="col-md-6 align-self-center px-3 px-md-0">
                  <div className="row mx-0">
                    {filterClient || filterClient?.length === 0 ? (
                      <>
                        <div className="col-md-5">
                          <button
                            className="btn-line-apple excel"
                            /* onClick={downloadExcel} */
                          >
                            Descargar Excel
                          </button>
                        </div>
                        <div className="col-md-3 col-6 mt-4 mt-md-0">
                          <button
                            className={`btn-line-apple ${mycustomers?.length > countPagination ? '' : 'disabled'}`}
                            onClick={slidePrev}
                          >{`< Anterior`}</button>
                        </div>
                        <div className="col-md-3 col-6 mt-4 mt-md-0">
                          <button
                            className={`btn-line-apple ${mycustomers?.length > countPagination ? '' : 'disabled'}`}
                            onClick={slideNext}
                          >{`Siguiente >`}</button>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="col-md-5">
                          <button className="btn-line-apple" onClick={handleCleanFilter}>
                            Limpiar búsqueda
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-b1 p-3 rounded my-3 text-apple d-none d-md-block">
              <div className="row text-center">
                <div className="col-md-3">
                  <span>Nombre /email</span>
                </div>
                <div className="col-md-2">
                  <span>Celular</span>
                </div>
                <div className="col-md-3">
                  <span>Dirección</span>
                </div>
                <div className="col-md-2">
                  <span>Créditos</span>
                </div>
                <div className="col-md-1">
                  <span>Paso</span>
                </div>
                <div className="col-md-1">
                  <span>---</span>
                </div>
              </div>
            </div>
            {filterClient?.length === 0 ? (
              <Slider {...settings} ref={slideClient}>
                {chunkedCustomerList?.map((chunk, index) => (
                  <div key={index} className="px-3">
                    {chunk.map((el) => (
                      <TableClientsFooder
                        key={el.id}
                        id={el.id}
                        name={el.a}
                        country={el.k}
                        email={el.e}
                        tel={el.f}
                        address={`${el.l[0]} ${el.l[1]} # ${el.l[2]} - ${el.l[3]}, ${el.l[5]}, ${el.l[6]}`}
                        credits={el.c}
                        step={el.d}
                        deleteUser={() => deleteUser(el)}
                      />
                    ))}
                  </div>
                ))}
              </Slider>
            ) : (
              <>
                {matchingClient?.length === 0 ? (
                  <div className="rounded-3 bg-b1 p-4 text-center text-white my-3">
                    No hay nombres que coincidan con tu criterio de búsqueda.
                  </div>
                ) : (
                  matchingClient?.map((el) => (
                    <TableClientsFooder
                      key={el.id}
                      id={el.id}
                      name={el.a}
                      tel={el.f}
                      address={`${el.l[0]} ${el.l[1]} # ${el.l[2]} - ${el.l[3]}, ${el.l[5]}, ${el.l[6]}`}
                      credits={el.c}
                      step={el.d}
                      deleteUser={() => deleteUser(el)}
                    />
                  ))
                )}
              </>
            )}
            {filterClient?.length === 0 && (
              <div className="mt-5">
                <div className="row justify-content-center">
                  <div className="col-md-3 col-6">
                    <button
                      className={`btn-line-apple ${mycustomers?.length > countPagination ? '' : 'disabled'}`}
                      onClick={slidePrev}
                    >{`< Anterior`}</button>
                  </div>
                  <div className="col-md-3 col-6">
                    <button
                      className={`btn-line-apple ${mycustomers?.length > countPagination ? '' : 'disabled'}`}
                      onClick={slideNext}
                    >{`Siguiente >`}</button>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </section>
    </>
  );
};

export default FooderCustomers;
