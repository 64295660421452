import React from 'react'
import { useSelector } from 'react-redux';
import { convertAmount } from '../../../../tools/format';


const Post6 = () => {

  const {dataPost, imgPostLogo, imgProduct} = useSelector(s => s.post)
  

  return (
    <div className="poster">
      <div className="img" style={{width: '66%'}}>
        {
          imgProduct && (
            <img
              src={imgProduct}
              className='b7'
              alt="img" />
          )
        }
      </div>
      <div className="svg">
        <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">
          <rect fill={`${dataPost.colors[0]}`}  x="676" y=".12" width="348" height="1024"/>
          <path fill={`${dataPost.colors[1]}`}  d="m974.14,895.88c-3.24,0-5.86,2.63-5.86,5.86v122.37h11.73v-122.37c0-3.24-2.63-5.86-5.86-5.86Z"/>
          <path fill={`${dataPost.colors[1]}`}  d="m948.73,1024.12v-91.1c0-3.24-2.63-5.86-5.86-5.86h0c-3.24,0-5.86,2.63-5.86,5.86v91.1h11.73Z"/>
          <path fill={`${dataPost.colors[0]}`}  d="m637.14,497.12c-3.24,0-5.86-2.63-5.86-5.86V-.12s11.73,0,11.73,0v491.37c0,3.24-2.63,5.86-5.86,5.86Z"/>
          <path fill={`${dataPost.colors[0]}`}  d="m611.73-.12v460.1c0,3.24-2.63,5.86-5.86,5.86h0c-3.24,0-5.86-2.63-5.86-5.86V-.12s11.73,0,11.73,0Z"/>
        </svg>
      </div>

      <img
        src={imgPostLogo}
        alt="logo" className="logo text"
        style={{right: '15%', bottom: '3%'}}
      />

      {
        dataPost.text[0] && (
          <div
            className="text text-uppercase text-end   px-3 py-2 f-6 text-truncate"
            style={{color: dataPost.colors[2], background: dataPost.colors[1],
            fontSize: '23px', top: '7%', lineHeight: '27px', right: '0%', maxWidth: '100%'
          }}
          >{dataPost.text[0]}</div>
        )
      }
      {
        dataPost.text[1] && (
          <div
            className="w-content text px-3 f-5"
            style={{color: dataPost.colors[2],
              fontSize:'25px',
              top:'30%', right: '0%',
              textShadow: `2px 2px 0px ${dataPost.colors[1]}`
            }}
          >{convertAmount(dataPost.text[1])}</div>
        )
      }
      {
        dataPost.text[2] && (
          <div
            className="f-4 text-uppercase text  clamp-2 px-2  text-end"
            style={{color: dataPost.colors[2], fontSize: '13px', maxWidth: '32%',
              bottom: '18%', background: dataPost.colors[1], lineHeight: '20px',
              right: '0%'
            }}
          >{dataPost.text[2]}</div>
        )
      }
      {
        dataPost.text[3] && (
          <div
            className="text text-end tr px-2 f-5 clamp-2"
            style={{color: dataPost.colors[1], fontSize: '13px',
              top: '18.5%', right: '2%', maxWidth: ' 32%',
              lineHeight: '14px'
            }}
          >{dataPost.text[3]}</div>
        )
      }
      {
        dataPost.text[4] && (
          <div
            className="text cr text-end py-1 px-2 f-3  clamp-4"
            style={{color: dataPost.colors[2], fontSize: '14px',
            width: '31%', right: '2%', top: '46%'
              
            }}
          >{dataPost.text[4]}</div>
        )
      }
    
    </div>
  );
};

export default Post6;
