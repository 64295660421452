import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setDataDeleteOrder, setIsError, setStateLoading, setStateNotification, setTextNotificate } from '../../../../redux/reducer/interactions'
import setStateOrder from '../../../../firebase/update/stateKitchen'

const ModalConfirmDeleteOrder = () => {

  const { dataDeleteOrder }= useSelector(s => s.ux)
  const { phone, indicative }= useSelector(s => s.log)
  const dispatch = useDispatch()


  const handleReset = ()=> dispatch(setDataDeleteOrder(null))

  const updateState = async()=>{
    dispatch(setStateLoading(true))
    
    const response = await setStateOrder(indicative, phone,dataDeleteOrder?.order, 5)
    if(!response){
      dispatch(setTextNotificate('Ha ocurrido un error, inténtalo mas tarde'))
      dispatch(setStateNotification(true))
      dispatch(setIsError(true)) 
    }
    
    dispatch(setStateLoading(false))
    handleReset()
  }


  return (
    <>
      <div className="modal modalDashboard fade" data-bs-backdrop="static" id="confirmDeleteOrder" tabIndex="-1" aria-labelledby="confirmDeleteOrderLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="p-3 p-md-5 text-center text-white">
                <h4 className='text-apple'>Confirmar cancelación de pedido</h4>
                <h6 className='text-uppercase'>OP: {dataDeleteOrder?.order}</h6>
                <p className="my-4">Recuerda notificarle al cliente acerca de tu decisión</p>
                <div className="row justify-content-center">
                  <div className="col-12">
                    <div className="d-block d-md-flex pt-2 justify-content-center w-100 mb-4 contactMSect">
                      <a rel="noopener noreferrer" href={`https://api.whatsapp.com/send?phone=${indicative}${dataDeleteOrder?.phone}`} className="btnSmall wspIcon" target="_blank">Enviar mensaje</a>
                      <a rel="noopener noreferrer" href={`tel:+${indicative}${dataDeleteOrder?.phone}`} className="btnSmall ms-2 callClient" target="_blank">Llamar</a>
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-md-6">
                    <button
                      className='btn-line-apple w-100 m-0'
                      data-bs-dismiss="modal"
                      onClick={handleReset}
                    >No, cerrar</button>
                  </div>
                  <div className="col-md-6 order-first order-md-last mb-4 mb-md-0">
                    <button
                      className='btn-orange w-100 m-0'
                      data-bs-dismiss="modal"
                      onClick={updateState}
                    >Sí, rechazar pedido</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ModalConfirmDeleteOrder
