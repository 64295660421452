import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  updateProductoFile: '',
  title: '',
  description: '',
  category: '',
  price: null,
  discount: null,
  uniqueoption:{
    active: false,
    req: false,
    title: '',
    op:[] 
  },
  multipleoption:{
    active: false,
    req: false,
    title: '',
    op:[],
    max: ''
  },
  chilli: false,
  comments: false,
  productSelectedID: 0,
}

export const logSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setUpdateLogoFile: (state,action) =>{
      state.updateProductoFile = action.payload
    },
    setNewTitle: (state,action) =>{
      state.title = action.payload
    },
    setCategoryProductView: (state,action) =>{
      state.category = action.payload
    },
    setNewDescription: (state,action) =>{
      state.description = action.payload
    },
    setProductPrice: (state,action) =>{
      state.price = action.payload
    },
    setProductDiscount: (state,action) =>{
      state.discount = action.payload
    },
    setUniqueOption: (state,action) =>{
      const {type, value} = action.payload
      return {
        ...state,
        uniqueoption: {
          ...state.uniqueoption,
          [type]: value
        }
      }
    },
    setAddNewOnlyOption: (state, action) =>{
      state.uniqueoption.op.push(action.payload)
    },
    setDeleteOnlyOption:(state,action) =>{
      state.uniqueoption.op = action.payload
    },
    setMultipleOption: (state,action) =>{
      const {type, value} = action.payload
      return {
        ...state,
        multipleoption: {
          ...state.multipleoption,
          [type]: value
        }
      }
    },
    setAddNewMultipleOption: (state, action) =>{
      state.multipleoption.op.push(action.payload)
    },
    setDeleteMultipleOption:(state,action) =>{
      state.multipleoption.op = action.payload
    },
    setChilli:(state,action) =>{
      state.chilli = action.payload
    },
    setComments:(state,action) =>{
      state.comments = action.payload
    },
    setInitialStateUniqueoption:(state,action) =>{
      state.uniqueoption = action.payload
    },
    setInitialStateMultipleoption:(state,action) =>{
      state.multipleoption = action.payload
    },
    setIDProductSelected:(state,action) =>{
      state.productSelectedID = action.payload
    },
    setResetProductValues: (state) =>{
      state.updateProductoFile= ''
      state.title= ''
      state.description= ''
      state.category= ''
      state.price= null
      state.discount= null
      state.uniqueoption={
        active: false,
        req: false,
        title: '',
        op:[] 
      }
      state.multipleoption={
        active: false,
        req: false,
        title: '',
        op:[],
        max: ''
      }
      state.chilli= false
      state.comments= false
      state.productSelectedID= 0
    }
  }
})

export const {
  setUpdateLogoFile, setNewTitle,setNewDescription,setCategoryProductView,
  setProductPrice,setProductDiscount,setUniqueOption,setAddNewOnlyOption,
  setDeleteOnlyOption,setMultipleOption,setAddNewMultipleOption,setDeleteMultipleOption,
  setChilli,setComments,setInitialStateUniqueoption,setInitialStateMultipleoption,
  setIDProductSelected, setResetProductValues

} = logSlice.actions

export default logSlice.reducer




export const setEditViewer = (ref) => dispatch =>{
  let {
    d: link, //link
    e: title, //título
    f: price,
    g: category , //categoría
    h: description, //descripción
    i: uniqueoption,
    j: multipleoption,
    k: chilli, // chilli
    l: comments, // comentarios
  } = ref

  
  
  dispatch(setUpdateLogoFile(link))
  dispatch(setNewTitle(title))
  dispatch(setCategoryProductView(category))
  dispatch(setNewDescription(description))
  dispatch(setProductPrice(price[0]))
  dispatch(setProductDiscount(price[1]))
  dispatch(setInitialStateUniqueoption(uniqueoption))
  dispatch(setInitialStateMultipleoption(multipleoption))
  dispatch(setChilli(chilli))
  dispatch(setComments(comments))
}





