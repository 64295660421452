import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { resetAllPost} from '../../../../redux/reducer/post'
import FormState from './FormState'
import State1 from './allPost/State1'
import State2 from './allPost/State2'
import State3 from './allPost/State3'
import State4 from './allPost/State4'
import State5 from './allPost/State5'
import State6 from './allPost/State6'
import State7 from './allPost/State7'
import State8 from './allPost/State8'
import State9 from './allPost/State9'
import State10 from './allPost/State10'
import State11 from './allPost/State11'
import State12 from './allPost/State12'
import State13 from './allPost/State13'
import State14 from './allPost/State14'
import State15 from './allPost/State15'
import State16 from './allPost/State16'
import State17 from './allPost/State17'
import State18 from './allPost/State18'
import State19 from './allPost/State19'
import State20 from './allPost/State20'
import State21 from './allPost/State21'
import State22 from './allPost/State22'
import State23 from './allPost/State23'
import State24 from './allPost/State24'


const EditorState = () => {

  const {indexPost} = useSelector(s => s.post)
  
  const dispatch = useDispatch()
  const handleBack = ()=> dispatch(resetAllPost())

  return (
    <section className="panelEditor">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 justify-content-center d-flex">
            <button className='btn-central mt-5 mt-md-4' onClick={handleBack}>Cerrar editor de Estados</button>
          </div>
        </div>
        <div className="row t-state">
          <div className="col-md-7 align-self-center">
            <FormState />
          </div>
          <div className="col-md-5 align-self-center order-first order-md-last">
            <div className="statePost">
              {indexPost === 0 && <State1 />}
              {indexPost === 1 && <State2 />}
              {indexPost === 2 && <State3 />}
              {indexPost === 3 && <State4 />}
              {indexPost === 4 && <State5 />}
              {indexPost === 5 && <State6 />}
              {indexPost === 6 && <State7 />}
              {indexPost === 7 && <State8 />}
              {indexPost === 8 && <State9 />}
              {indexPost === 9 && <State10 />}
              {indexPost === 10 && <State11 />}
              {indexPost === 11 && <State12 />}
              {indexPost === 12 && <State13 />}
              {indexPost === 13 && <State14 />}
              {indexPost === 14 && <State15 />}
              {indexPost === 15 && <State16 />}
              {indexPost === 16 && <State17 />}
              {indexPost === 17 && <State18 />}
              {indexPost === 18 && <State19 />}
              {indexPost === 19 && <State20 />}
              {indexPost === 20 && <State21 />}
              {indexPost === 21 && <State22 />}
              {indexPost === 22 && <State23 />}
              {indexPost === 23 && <State24 />}
       
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default EditorState