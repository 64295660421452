import React from 'react'

const EditCategory = () => {


  return (
    <div className="contentEditCategory">
      <svg id="Capa_1" className='fill-1' data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1632 54">
        
        <path className="fill-c4" d="m1625.65,0H0v20c0,18.78,15.22,34,34,34h0c18.78,0,34-15.22,34-34,0,18.78,15.22,34,34,34h0c18.78,0,34-15.22,34-34,0,18.78,15.22,34,34,34h0c18.78,0,34-15.22,34-34,0,18.78,15.22,34,34,34h0c18.78,0,34-15.22,34-34,0,18.78,15.22,34,34,34h0c18.78,0,34-15.22,34-34,0,18.78,15.22,34,34,34h0c18.78,0,34-15.22,34-34,0,18.78,15.22,34,34,34h0c18.78,0,34-15.22,34-34,0,18.78,15.22,34,34,34h0c18.78,0,34-15.22,34-34,0,18.78,15.22,34,34,34h0c18.78,0,34-15.22,34-34,0,18.78,15.22,34,34,34h0c18.78,0,34-15.22,34-34,0,18.78,15.22,34,34,34h0c18.78,0,34-15.22,34-34,0,18.78,15.22,34,34,34h0c18.78,0,34-15.22,34-34,0,18.78,15.22,34,34,34h0c18.78,0,34-15.22,34-34,0,18.78,15.22,34,34,34h0c18.78,0,34-15.22,34-34,0,18.78,15.22,34,34,34s34-15.22,34-34c0,18.78,15.22,34,34,34s34-15.22,34-34c0,18.78,15.22,34,34,34s34-15.22,34-34c0,18.78,15.22,34,34,34s34-15.22,34-34c0,18.78,15.22,34,34,34s34-15.22,34-34c0,18.78,15.22,34,34,34s34-15.22,34-34c0,18.78,15.22,34,34,34s34-15.22,34-34c0,18.78,15.22,34,34,34s34-15.22,34-34c0,18.78,15.22,34,34,34s34-15.22,34-34c0,18.78,15.22,34,34,34s34-15.22,34-34V0h-6.35Z"/>
        <g className="c-content">
          <path d="m0,0v20c0,18.78,15.22,34,34,34h0c18.78,0,34-15.22,34-34V0H0Z"/>
          <path className="cls-1" d="m34,47.5c15.25,0,27.65-12.41,27.65-27.65V0h-3.89v19.85c0,13.11-10.66,23.77-23.77,23.77s-23.77-10.66-23.77-23.77V0h-3.89v19.85c0,15.25,12.41,27.65,27.65,27.65Z"/>
          <path className="cls-1" d="m68,0v20c0,18.78,15.22,34,34,34h0c18.78,0,34-15.22,34-34V0h-68Z"/>
          <path d="m102,47.5c15.25,0,27.65-12.41,27.65-27.65V0h-3.89v19.85c0,13.11-10.66,23.77-23.77,23.77s-23.77-10.66-23.77-23.77V0h-3.89v19.85c0,15.25,12.41,27.65,27.65,27.65Z"/>
          <path d="m136,0v20c0,18.78,15.22,34,34,34h0c18.78,0,34-15.22,34-34V0h-68Z"/>
          <path className="cls-1" d="m170,47.5c15.25,0,27.65-12.41,27.65-27.65V0h-3.89v19.85c0,13.11-10.66,23.77-23.77,23.77s-23.77-10.66-23.77-23.77V0h-3.89v19.85c0,15.25,12.41,27.65,27.65,27.65Z"/>
          <path className="cls-1" d="m204,0v20c0,18.78,15.22,34,34,34h0c18.78,0,34-15.22,34-34V0h-68Z"/>
          <path d="m238,47.5c15.25,0,27.65-12.41,27.65-27.65V0h-3.89v19.85c0,13.11-10.66,23.77-23.77,23.77s-23.77-10.66-23.77-23.77V0h-3.89v19.85c0,15.25,12.41,27.65,27.65,27.65Z"/>
          <path d="m272,0v20c0,18.78,15.22,34,34,34h0c18.78,0,34-15.22,34-34V0h-68Z"/>
          <path className="cls-1" d="m306,47.5c15.25,0,27.65-12.41,27.65-27.65V0h-3.89v19.85c0,13.11-10.66,23.77-23.77,23.77s-23.77-10.66-23.77-23.77V0h-3.89v19.85c0,15.25,12.41,27.65,27.65,27.65Z"/>
          <path className="cls-1" d="m340,0v20c0,18.78,15.22,34,34,34h0c18.78,0,34-15.22,34-34V0h-68Z"/>
          <path d="m374,47.5c15.25,0,27.65-12.41,27.65-27.65V0h-3.89v19.85c0,13.11-10.66,23.77-23.77,23.77s-23.77-10.66-23.77-23.77V0h-3.89v19.85c0,15.25,12.41,27.65,27.65,27.65Z"/>
          <path d="m408,0v20c0,18.78,15.22,34,34,34h0c18.78,0,34-15.22,34-34V0h-68Z"/>
          <path className="cls-1" d="m442,47.5c15.25,0,27.65-12.41,27.65-27.65V0h-3.89v19.85c0,13.11-10.66,23.77-23.77,23.77s-23.77-10.66-23.77-23.77V0h-3.89v19.85c0,15.25,12.41,27.65,27.65,27.65Z"/>
          <path className="cls-1" d="m476,0v20c0,18.78,15.22,34,34,34h0c18.78,0,34-15.22,34-34V0h-68Z"/>
          <path d="m510,47.5c15.25,0,27.65-12.41,27.65-27.65V0h-3.89v19.85c0,13.11-10.66,23.77-23.77,23.77s-23.77-10.66-23.77-23.77V0h-3.89v19.85c0,15.25,12.41,27.65,27.65,27.65Z"/>
          <path d="m544,0v20c0,18.78,15.22,34,34,34h0c18.78,0,34-15.22,34-34V0h-68Z"/>
          <path className="cls-1" d="m578,47.5c15.25,0,27.65-12.41,27.65-27.65V0h-3.89v19.85c0,13.11-10.66,23.77-23.77,23.77s-23.77-10.66-23.77-23.77V0h-3.89v19.85c0,15.25,12.41,27.65,27.65,27.65Z"/>
          <path className="cls-1" d="m612,0v20c0,18.78,15.22,34,34,34h0c18.78,0,34-15.22,34-34V0h-68Z"/>
          <path d="m646,47.5c15.25,0,27.65-12.41,27.65-27.65V0h-3.89v19.85c0,13.11-10.66,23.77-23.77,23.77s-23.77-10.66-23.77-23.77V0h-3.89v19.85c0,15.25,12.41,27.65,27.65,27.65Z"/>
          <path d="m680,0v20c0,18.78,15.22,34,34,34h0c18.78,0,34-15.22,34-34V0h-68Z"/>
          <path className="cls-1" d="m714,47.5c15.25,0,27.65-12.41,27.65-27.65V0h-3.89v19.85c0,13.11-10.66,23.77-23.77,23.77s-23.77-10.66-23.77-23.77V0h-3.89v19.85c0,15.25,12.41,27.65,27.65,27.65Z"/>
          <path className="cls-1" d="m748,0v20c0,18.78,15.22,34,34,34h0c18.78,0,34-15.22,34-34V0h-68Z"/>
          <path d="m782,47.5c15.25,0,27.65-12.41,27.65-27.65V0h-3.89v19.85c0,13.11-10.66,23.77-23.77,23.77s-23.77-10.66-23.77-23.77V0h-3.89v19.85c0,15.25,12.41,27.65,27.65,27.65Z"/>
          <path d="m816,0v20c0,18.78,15.22,34,34,34h0c18.78,0,34-15.22,34-34V0h-68Z"/>
          <path className="cls-1" d="m850,47.5c15.25,0,27.65-12.41,27.65-27.65V0h-3.89v19.85c0,13.11-10.66,23.77-23.77,23.77s-23.77-10.66-23.77-23.77V0h-3.89v19.85c0,15.25,12.41,27.65,27.65,27.65Z"/>
          <path className="cls-1" d="m884,0v20c0,18.78,15.22,34,34,34h0c18.78,0,34-15.22,34-34V0h-68Z"/>
          <path d="m918,47.5c15.25,0,27.65-12.41,27.65-27.65V0h-3.89v19.85c0,13.11-10.66,23.77-23.77,23.77s-23.77-10.66-23.77-23.77V0h-3.89v19.85c0,15.25,12.41,27.65,27.65,27.65Z"/>
          <path d="m952,0v20c0,18.78,15.22,34,34,34h0c18.78,0,34-15.22,34-34V0h-68Z"/>
          <path className="cls-1" d="m986,47.5c15.25,0,27.65-12.41,27.65-27.65V0h-3.89v19.85c0,13.11-10.66,23.77-23.77,23.77s-23.77-10.66-23.77-23.77V0h-3.89v19.85c0,15.25,12.41,27.65,27.65,27.65Z"/>
          <path className="cls-1" d="m1020,0v20c0,18.78,15.22,34,34,34h0c18.78,0,34-15.22,34-34V0h-68Z"/>
          <path d="m1054,47.5c15.25,0,27.65-12.41,27.65-27.65V0h-3.89v19.85c0,13.11-10.66,23.77-23.77,23.77s-23.77-10.66-23.77-23.77V0h-3.89v19.85c0,15.25,12.41,27.65,27.65,27.65Z"/>
          <path d="m1088,0v20c0,18.78,15.22,34,34,34h0c18.78,0,34-15.22,34-34V0h-68Z"/>
          <path className="cls-1" d="m1122,47.5c15.25,0,27.65-12.41,27.65-27.65V0h-3.89v19.85c0,13.11-10.66,23.77-23.77,23.77s-23.77-10.66-23.77-23.77V0h-3.89v19.85c0,15.25,12.41,27.65,27.65,27.65Z"/>
          <path className="cls-1" d="m1156,0v20c0,18.78,15.22,34,34,34h0c18.78,0,34-15.22,34-34V0h-68Z"/>
          <path d="m1190,47.5c15.25,0,27.65-12.41,27.65-27.65V0h-3.89v19.85c0,13.11-10.66,23.77-23.77,23.77s-23.77-10.66-23.77-23.77V0h-3.89v19.85c0,15.25,12.41,27.65,27.65,27.65Z"/>
          <path d="m1224,0v20c0,18.78,15.22,34,34,34h0c18.78,0,34-15.22,34-34V0h-68Z"/>
          <path className="cls-1" d="m1258,47.5c15.25,0,27.65-12.41,27.65-27.65V0h-3.89v19.85c0,13.11-10.66,23.77-23.77,23.77s-23.77-10.66-23.77-23.77V0h-3.89v19.85c0,15.25,12.41,27.65,27.65,27.65Z"/>
          <path className="cls-1" d="m1292,0v20c0,18.78,15.22,34,34,34h0c18.78,0,34-15.22,34-34V0h-68Z"/>
          <path d="m1326,47.5c15.25,0,27.65-12.41,27.65-27.65V0h-3.89v19.85c0,13.11-10.66,23.77-23.77,23.77s-23.77-10.66-23.77-23.77V0h-3.89v19.85c0,15.25,12.41,27.65,27.65,27.65Z"/>
          <path d="m1360,0v20c0,18.78,15.22,34,34,34h0c18.78,0,34-15.22,34-34V0h-68Z"/>
          <path className="cls-1" d="m1394,47.5c15.25,0,27.65-12.41,27.65-27.65V0h-3.89v19.85c0,13.11-10.66,23.77-23.77,23.77s-23.77-10.66-23.77-23.77V0h-3.89v19.85c0,15.25,12.41,27.65,27.65,27.65Z"/>
          <path className="cls-1" d="m1428,0v20c0,18.78,15.22,34,34,34h0c18.78,0,34-15.22,34-34V0h-68Z"/>
          <path d="m1462,47.5c15.25,0,27.65-12.41,27.65-27.65V0h-3.89v19.85c0,13.11-10.66,23.77-23.77,23.77s-23.77-10.66-23.77-23.77V0h-3.89v19.85c0,15.25,12.41,27.65,27.65,27.65Z"/>
          <path d="m1496,0v20c0,18.78,15.22,34,34,34h0c18.78,0,34-15.22,34-34V0h-68Z"/>
          <path className="cls-1" d="m1530,47.5c15.25,0,27.65-12.41,27.65-27.65V0h-3.89v19.85c0,13.11-10.66,23.77-23.77,23.77s-23.77-10.66-23.77-23.77V0h-3.89v19.85c0,15.25,12.41,27.65,27.65,27.65Z"/>
          <path className="cls-1" d="m1564,0v20c0,18.78,15.22,34,34,34h0c18.78,0,34-15.22,34-34V0h-68Z"/>
          <path d="m1598,47.5c15.25,0,27.65-12.41,27.65-27.65V0h-3.89v19.85c0,13.11-10.66,23.77-23.77,23.77s-23.77-10.66-23.77-23.77V0h-3.89v19.85c0,15.25,12.41,27.65,27.65,27.65Z"/>
        </g>
      </svg>
    </div>
  )
}

export default EditCategory