import React from 'react'
import Slider from 'react-slick'
import CardComments from './CardComments'
import l10 from '../../img/brand/l_10.webp'

const Comments = () => {


  const settings = {
    dots: false,
    arrows: false,
    slidesToShow: 4,
    centerMode: true,
    autoplay: true,
    speed: 12500,
    autoplaySpeed: 0,
    cssEase: "linear",
    infinite: true,
    pauseOnHover: true,
    swipe: false,
    adaptiveHeight: false,
    className: 'slideComments',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        }
      },
    ]
  }

  const settings2 = {
    dots: false,
    arrows: false,
    slidesToShow: 3,
    centerMode: true,
    autoplay: true,
    speed: 15000,
    autoplaySpeed: 0,
    cssEase: "linear",
    infinite: true,
    pauseOnHover: true,
    swipe: false,
    rtl: true, 
    adaptiveHeight: false,
    className: 'slideComments',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.3,
        }
      },
    ]
  }

  const slide1 = [
    {title: 'Impulsa tus ventas con Fooder', comment: 'El cambio que necesitaba para mi restaurante! Con Fooder, mis clientes disfrutan de una experiencia única y ¡mis ventas han aumentado! 💥👍', name: 'Andres Calderón'},
    {title: 'Diseño dinámico con Fooder', comment: '¡Increíble! Con Fooder puedo cambiar el diseño del menú en segundos. ¡Mis clientes están encantados y yo también! 💪🍔', name: 'Pedro Umbarilla'},
    {title: 'Gestión fácil con Fooder', comment: 'Fooder me ha facilitado la vida como emprendedora. ¡Puedo gestionar mi menú desde cualquier lugar y mis clientes pueden hacer pedidos con solo unos clics! 🚀📱', name: 'Luisa Sánchez'},
    {title: 'Personalización perfecta con Fooder', comment: '¡Una joya para cualquier negocio! Con Fooder puedo personalizar mi menú según la temporada y guardar los productos favoritos de mis clientes. ¡Simplemente genial! ', name: 'Camilo Rodríguez'},
    {title: 'Mi negocio ahora destaca', comment: 'Con Fooder, mi negocio se destaca entre la competencia. ¡Los colores, las plantillas, todo es perfecto! Mis clientes adoran la facilidad de uso y yo adoro los resultados. ¡100% recomendado', name: 'María Fernanda Martínez'},
    {title: 'Eficiencia garantizada con Fooder', comment: '¡Lo mejor que he probado! Fooder no solo es económico, sino que también es fácil de usar y tiene todas las funciones que necesito para mi cafetería. ¡Mis clientes están encantados y yo también!', name: 'Andrés García'},
    {title: 'Simplicidad con Fooder', comment: 'Me salvó la vida! Con Fooder, puedo gestionar mi negocio de manera eficiente y atraer más clientes. ¡Es una herramienta increíblemente útil para cualquier restaurante', name: 'Laura Ospina'},
    {title: 'El aliado de mi restaurante', comment: 'Mi restaurante se ha convertido en el lugar favorito de mis clientes. ¡Es como tener un amigo que me ayuda a gestionar todo fácilmente! ', name: 'Ana María Ríos'},
    {title: 'Este es el toque mágico para mi negocio', comment: 'Ha cambiado por completo la forma en que administro mi negocio. Es como tener un mago que hace que todo sea más fácil y eficiente. ¡Mis clientes lo adoran tanto como yo', name: 'Felipe Herrera'}
  ]

  const slide2 = [
    {title: 'La sonrisa de mi restaurante', comment: 'Ha traído una sonrisa a mi rostro y a los de mis clientes. Con esta aplicación, todo es más simple, colorido y divertido. ¡Es como tener un cómplice que siempre me respalda!', name: 'Valentina Gutiérrez'},
    {title: 'El secreto de mi éxito', comment: 'Es mi secreto mejor guardado para el éxito de mi negocio. Me ayuda a destacar entre la multitud y a brindar una experiencia excepcional a mis clientes. ¡Es como tener un as bajo la manga en todo momento!', name: 'Andrea Duque'},
    {title: 'El ingrediente secreto de mi cocina', comment: 'Mi cocina cobra vida de una manera completamente nueva. Es como tener un ingrediente secreto que hace que todo sea más delicioso y memorable. ¡Mis clientes nunca han estado tan satisfechos!', name: 'Juan José López'},
    {title: 'Fooder, el compañero perfecto de mi negocio', comment: 'Es más que una herramienta, es mi compañero de confianza en el día a día de mi negocio. Me facilita la vida y me permite enfocarme en lo que realmente importa: mis clientes. ¡Es como tener un amigo leal que siempre está ahí para ayudar! 👫💼', name: 'Marcela González'},
    {title: 'Trae el toque personal de mi restaurante', comment: 'Puedo agregar mi propio toque personal a cada aspecto de mi restaurante. Es como tener un artista que me ayuda a crear una experiencia única para mis clientes. ¡Estoy encantado con los resultados!', name: 'Andrés Felipe Vargas'},
    {title: 'El motor de mi emprendimiento', comment: 'Fooder es el motor que impulsa mi negocio hacia el éxito. Me ayuda a mantenerme en movimiento, a adaptarme y a crecer. ¡Es como tener un motorista experto que siempre está listo para llevarme más lejos!', name: 'Laura Cárdenas'},
    {title: 'El ingrediente esencial de mi cocina', comment: 'Cada plato que sale de mi cocina lleva un toque especial. Es como tener un ingrediente esencial que hace que todo sea más delicioso y memorable. ¡Mis clientes siempre vuelven por más!', name: 'Miguel Ángel Castro'},
  ]

  return (
    <section className="py-5 bg-light2">
      <div className="container mb-5">
        <div className="row">
          <div className="container">
            <div className="col-12 mt-4">
              <div className="bg-tl">
                <div className="row">
                  <div className="col-md-6">
                    <div className="px-4">
                      <h5>¿POR QUÉ FOODER?</h5>
                      <hr />
                      <p className="m-0 ps-5">Damos la oportunidad de que cualquier emprendedor o empresario, tenga un menú digital con las funciones básicas de un sitio web que refleje el tamaño de sus sueños.</p>
                    </div>
                  </div>
                  <div className="col-md-6 mt-5 mt-md-0">
                    <div className="px-4">
                      <h5>¿POR QUÉ <br className='d-block d-md-none'/> SOMOS DIFERENTES?</h5>
                      <hr />
                      <p className="m-0 ps-5">Fooder funciona con un sistema de créditos bajo demanda, esto quiere decir que solo vas a pagar por lo que consumes. Puedes recargar tus créditos Fooder en cualquier momento según tu necesidad.</p>
                    </div>
                  </div>
                  <div className="col-12 text-center mt-4">
                    <img src={l10} alt="" className='img-fluid' style={{width: '80px'}}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <Slider {...settings}>
        {
          slide1.map((el,index) => (
            <CardComments
              key={index}
              title={el.title}
              comment={el.comment}
              name={el.name}/>
          ))
        }
      </Slider>
      <Slider {...settings2}>
        {
          slide2.map((el,index) => (
            <CardComments
              key={index}
              title={el.title}
              comment={el.comment}
              name={el.name}/>
          ))
        }
      </Slider>
    </section>
  )
}

export default Comments