import React from 'react'
import { useSelector } from 'react-redux';
import { convertAmount } from '../../../../tools/format';


const Post19 = () => {

  const {dataPost, imgPostLogo, imgProduct} = useSelector(s => s.post)
  

  return (
    <div className="poster">
      <div className="img" style={{width: '65%', height: '97%', top: ' 1.7%', left: ' 1.7%'}}>
        {
          imgProduct && (
            <img
              src={imgProduct}
              className='b7'
              alt="img" />
          )
        }
        <div className="overlay" style={{background: dataPost.colors[1], opacity: .1}}/>
      </div>
      <div className="svg">
        <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">
          <polygon fill={`${dataPost.colors[0]}`}  points="0 0 0 807 21 807 21 21 680 21 680 807 1024 807 1024 0 0 0"/>
          <polygon fill={`${dataPost.colors[2]}`}  points="680 807 680 1003 21 1003 21 807 0 807 0 1024 1024 1024 1024 807 680 807"/>
        </svg>
      </div>

      <img
        src={imgPostLogo}
        alt="logo" className="logo text br"
        style={{right: '9%', bottom: '10%'}}
      />

      {
        dataPost.text[0] && (
          <div
            className="text tr  px-2 f-9 clamp-2 text-end"
            style={{color: dataPost.colors[2], lineHeight: '38px', width: ' 60%', right: '2%',top: '6%' ,
            fontSize: '33px', textShadow: `0 0 10px ${ dataPost.colors[1]}` }}
          >{dataPost.text[0]}</div>
        )
      }
      {
        dataPost.text[1] && (
          <div
            className="w-content text text-center f-9 cr"
            style={{color: dataPost.colors[2], fontSize:'23px', minWidth: '33.7%',
              background: `${dataPost.colors[1]}`, marginTop: '-10%'
              
            }}
          >{convertAmount(dataPost.text[1])}</div>
        )
      }
      {
        dataPost.text[2] && (
          <div
            className="f-3 text br text-truncate text-center text-uppercase"
            style={{color: dataPost.colors[2], fontSize: '11px',
              background: `${dataPost.colors[1]}`, width: ' 33.7%',
              bottom: '2%', right: '0%',
            }}
          >{dataPost.text[2]}</div>
        )
      }
      {
        dataPost.text[3] && (
          <div
            className="text br f-3 clamp-4 text-uppercase text-end"
            style={{color: dataPost.colors[2], fontSize: '12px',
              right: '3%', bottom: '28%', width: '30%'
          }}
          >{dataPost.text[3]}</div>
        )
      }
    </div>
  );
};

export default Post19;
