import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

const CategoryList = ({ list, handleDeleteCategory,countOrder, countCategories }) => {
  const { attributes, listeners, transform, transition, setNodeRef } = useSortable({ id: list.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    touchAction: 'none',
  };

  return (
    <>
      <div className="categoryList" id={list.id} ref={setNodeRef} style={style}>
        <div className="info">
          <p className="m-0 text-white text-truncate">{list.name || ' No definido'}</p>
          <span className="text-apple">{list.count || 'No hay'} items</span>
        </div>
        <div className="options">
          <div className="dropdown">
          {
            list.count === 0 ? (
              <>
                <button className="dropdown-toggle btnDots" type="button" data-bs-toggle="dropdown" aria-expanded="false" />
                <ul className="dropdown-menu listDrop">
                  <button type="button" onClick={handleDeleteCategory}>Eliminar</button>
                </ul>
              </>
            ):null
          }
          </div>
          {
            countOrder <= 20 ? (
              <button type="button" className={`btn-move-dash ms-2 ${countCategories === 1 ? 'disabled':''}`} {...attributes} {...listeners} />
            ): null
          }
        </div>
      </div>
    </>
  );
};

export default CategoryList;
