import React from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { format } from 'date-fns'
import addNewReport from '../firebase/create/addNewReport'
import { setIsError, setStateLoading, setStateNotification, setTextNotificate } from '../redux/reducer/interactions'

const ModalReportMistake = () => {

  const {name, userID, email, phone, indicative} = useSelector(s => s.log)
  const dispatch = useDispatch()

  const {register, handleSubmit, formState: { errors }, reset } = useForm({
    mode: 'onChange'
  })


  const handleSendMistake = async(data) =>{
    
    const {typeMistake, descMistake} = data
    const date = new Date()
    dispatch(setStateLoading(true))

    const obj = {
      id: userID,
      type: typeMistake,
      message: descMistake,
      date: format(date, 'dd/MM/yyyy HH:mm'),
      country: indicative,
      tel: phone,
      email: email,
      solve: false,
    }
    try {
      await addNewReport(obj)
      dispatch(setTextNotificate('¡Tu reporte ha sido enviado!'))
      dispatch(setStateNotification(true))
      dispatch(setIsError(false))
      document.querySelector('#closeModalMistake').click()
      reset()
    } catch (error) {
      dispatch(setTextNotificate('¡Ha ocurrido un error al enviar el formulario, inténtalo nuevamente!'))
      dispatch(setStateNotification(true))
      dispatch(setIsError(true))
    }
    dispatch(setStateLoading(false))
  }

  return (
    <>
      <div className="modal fade modalDashboard" id="reportMistake" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="reportMistakeLabel" aria-hidden="true">
        <div className="modal-dialog  modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <div className="d-flex justify-content-end align-items-center w-100">
                <h1 className="m-0 fs-5 text-apple w-100">Reportar un problema</h1>
                <button
                  type="button"
                  className="btn-green-dark"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  id='closeModalMistake'
                >Cerrar</button>
              </div>
            </div>
            <div className="modal-body">
              <p className='text-white'>Hola <strong className='text-apple text-capitalize'>{name}</strong>, sentimos que se haya presentado algún tipo de error. A continuación describe el tipo de error que está presentando la plataforma y explica qué originó este tipo de problema.</p>
              <form onSubmit={handleSubmit(handleSendMistake)}  noValidate>
                <div className="row justify-content-center justify-content-md-end">
                  <div className="col-12">
                    <label className='text-apple mb-2'><small className="d-block">¿Que tipo de error te presentó la plataforma?</small></label>
                    <div className="inputDash selectDash">
                      <select
                        id='selectMistake'
                        {...register('typeMistake', {
                          required: {value: true, message: 'Necesitamos conocer que problema presentas'}
                        })}
                      >
                        <option value="">---- Seleccionar ----</option>
                        <option value="Me aparecen errores con letras rojas en mi pantalla">Me aparecen errores con letras rojas en mi pantalla</option>
                        <option value="No se ha realizado mi recarga de créditos">No se ha realizado mi recarga de créditos</option>
                        <option value="Mi menú digital no aparece disponible">Mi menú digital no aparece disponible</option>
                        <option value="No obtengo notificaciones vía WhatsApp y/o en la sección - Mi cocina">No obtengo notificaciones vía WhatsApp y/o en la sección "Mi cocina"</option>
                        <option value="Otro">Otro</option>
                      </select>
                    </div>
                    {errors.typeMistake && <small className='errorDash'>{errors.typeMistake.message}</small> }
                  </div>
                  <div className="col-12">
                    <label className='text-apple mb-2'><small className="d-block">Describe el problema</small></label>

                    <textarea
                      className='textareaDash'
                      rows="5"
                      {...register('descMistake', {
                        required: {value: true, message: 'Describe el problema'},
                        minLength: {value: 50, message: 'Mensaje demasiado corto'},
                        maxLength: {value: 2000, message: 'Mensaje demasiado largo'}
                      })}
                    />
                    {errors.descMistake && <small className='errorDash'>{errors.descMistake.message}</small> }
                  </div>
                  <div className="col-md-5 col-9 mt-3">
                    <button type='submit' className='btn-green-dark w-100'>Enviar reporte</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ModalReportMistake