import React from 'react'
import { useSelector } from 'react-redux';
import { convertAmount } from '../../../../tools/format';


const Post13 = () => {

  const {dataPost, imgPostLogo, imgProduct} = useSelector(s => s.post)
  

  return (
    <div className="poster">
      <div className="img">
        {
          imgProduct && (
            <img
              src={imgProduct}
              className='b7'
              alt="img" />
          )
        }
      </div>
      <div className="svg">
        <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">
          <defs>
            <style>
              {`
              .vfdjukdwsw {
                opacity: .35;
              }
              `}
            </style>
          </defs>
          <path fill={`${dataPost.colors[0]}`}  d="m1024-1.86H441.69c-10.91,38.39-11.71,81.83,6.31,129,68,178,165,80,309,204,54.17,46.65,157.61,71.08,267,68.33V-1.86Z"/>
          <path fill={`${dataPost.colors[2]}`}  d="m1024,364.27c-8.66,1.6-17.43,3.03-26.32,4.31-46.62,6.67-93.85,8.55-140.35,5.59-94-5.99-173.55-31.51-218.26-70.01-64.44-55.49-119.34-68.41-167.77-79.8-63.8-15.01-114.2-26.87-154.69-132.87-11.82-30.94-16.48-62.21-13.98-93.33h-2.01c-2.5,31.37,2.21,62.88,14.11,94.05,40.87,106.99,91.72,118.95,156.1,134.1,48.21,11.34,102.86,24.2,166.93,79.37,45.03,38.78,125.01,64.47,219.44,70.49,14.3.91,28.66,1.37,43.05,1.37,32.56,0,65.28-2.33,97.71-6.97,8.78-1.26,17.46-2.68,26.03-4.25v-2.03Z"/>
          <polygon fill={`${dataPost.colors[1]}`}  points="1024 1024 1024 426.9 433.93 1024 1024 1024"/>
          <g  className="vfdjukdwsw">
            <path fill={`${dataPost.colors[2]}`}  d="m997,995.14H27V25.14h970v970Zm-968-2h966V27.14H29v966Z"/>
          </g>
          <circle fill={`${dataPost.colors[1]}`}  cx="829" cy="371.14" r="33"/>
        </svg>
      </div>

      <img
        src={imgPostLogo}
        alt="logo" className="logo text br"
        style={{margin: '5%', bottom: '20%', right: '0%'}}
      />

      {
        dataPost.text[0] && (
          <div
            className="text tr clamp-2 px-2 f-2 text-uppercase text-end"
            style={{color: dataPost.colors[1], width: '50%', 
            fontSize: '24px', lineHeight: '26px', margin: '5%'
          }}
          >{dataPost.text[0]}</div>
        )
      }
      {
        dataPost.text[1] && (
          <div
            className="w-content text px-2  f-2 br"
            style={{color: 'transparent', fontSize:'37px', margin: '3%',
              WebkitTextStroke: `2px ${dataPost.colors[2]}`
            }}
          >{convertAmount(dataPost.text[1])}</div>
        )
      }
      {
        dataPost.text[2] && (
          <div
            className="f-3 text text-end br clamp-2"
            style={{color: dataPost.colors[2], fontSize: '15px',
              marginBottom: '15%', marginRight: '4%',
              width: '32%', lineHeight: '18px'
            }}
          >{dataPost.text[2]}</div>
        )
      }
      {
        dataPost.text[3] && (
          <div
            className="text text-end tr px-2 f-3 clamp-3 border"
            style={{color: dataPost.colors[1], fontSize: '15px', 
            width: '40%', lineHeight: '17px', marginRight: '5%', marginTop: '17%'
          }}
          >{dataPost.text[3]}</div>
        )
      }
    </div>
  );
};

export default Post13;
