import React, { useRef } from 'react'
import { useSelector } from 'react-redux'
import HeaderOrder from './HeaderOrder'
import ListProducts from './ListProducts'
import Slider from 'react-slick'
import DetailsProduct from './DetailsProduct'
import PendingOrder from './PendingOrder'


const OrderInMenu = () => {

  const {interiorMenu} = useSelector(s => s.ux)
  const {cuota} = useSelector(s => s.log)
  const carousel = useRef(null)
  
  const settings = {
    dots: false,
    slidesToShow: 1,
    arrows: false,
    infinite: false,
    swipe: false,
  }

  const chooseDetails = ()=>{
    if(carousel){
      carousel.current.slickNext()
    }
  }

  const returnSlide = ()=>{
    if(carousel){
      carousel.current.slickPrev()
    }
  }

  return (
    <section className={`orderInMenu ${interiorMenu ? 'active': ''} ${cuota === 2? 'corp': ''} ${cuota === 3 ? 'premium': ''}`}>
      <HeaderOrder />
      <div className="ocShow">
        <div className="container-fluid text-white">
          <div className="row">
            <div className="col-md-8 py-3">
              <Slider {...settings} ref={carousel}>
                <ListProducts
                  twoColumns={true}
                  chooseDetails={chooseDetails}
                />
                <DetailsProduct returnSlide={returnSlide}/>
              </Slider>
            </div>
            <div className="col-md-4 bg-b1 p-0 pAside">
              <div className="sticky-top py-5 py-md-2 mb-5 mb-md-0">
                <PendingOrder />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default OrderInMenu