import React from 'react'

const LoadingSudo = () => {
  return (
    <section className="text-center py-5">
      <h5 className="text-white">No hay registros disponibles</h5>
    </section>
  )
}

export default LoadingSudo