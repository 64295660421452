import { db } from "../../../../firebase/credentials"
import { doc, updateDoc } from "firebase/firestore"

const solveErrors = async(userId) =>{

  const reference = doc(db, 'allReports', userId)

  try {
    await updateDoc(reference, {
      solve: true
    })
    return true
  } catch (error) {
    return false
  }

}

export default solveErrors