import React from 'react'
import { useSelector } from 'react-redux';
import { convertAmount } from '../../../../tools/format';


const Post7 = () => {

  const {dataPost, imgPostLogo, imgProduct} = useSelector(s => s.post)
  

  return (
    <div className="poster">
      <div className="img" style={{width: '50%', left: '50%'}}>
        {
          imgProduct && (
            <img
              src={imgProduct}
              className='b7'
              alt="img" />
          )
        }
      </div>
      <div className="svg">
        <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">
          <rect fill={`${dataPost.colors[3]}`} x=".06" width="512" height="1024"/>
          <path fill={`${dataPost.colors[1]}`} d="m297.18,45.75c0,3.24-2.63,5.86-5.86,5.86H-.06s0-11.73,0-11.73h291.37c3.24,0,5.86,2.63,5.86,5.86Z"/>
          <path fill={`${dataPost.colors[1]}`} d="m-.06,71.16h260.1c3.24,0,5.86,2.63,5.86,5.86h0c0,3.24-2.63,5.86-5.86,5.86H-.06s0-11.73,0-11.73Z"/>
          <rect fill={`${dataPost.colors[0]}`} x="145.06" y="654" width="442" height="181" rx="90.5" ry="90.5"/>
          <path fill={`${dataPost.colors[1]}`} d="m27.56,654H1.06v181h26.5c49.98,0,90.5-40.52,90.5-90.5h0c0-49.98-40.52-90.5-90.5-90.5Z"/>
        </svg>
      </div>

      <img
        src={imgPostLogo}
        alt="logo" className="logo text bl"
        style={{left: '32%', bottom: '5%'}}
      />

      {
        dataPost.text[0] && (
          <div
            className="text tc py-1 px-3 f-5 text-center text-uppercase text-truncate "
            style={{color: dataPost.colors[2], top: '12%', maxWidth: '90%',
            fontSize: '25px', background: dataPost.colors[1], lineHeight:'40px'
           }}
          >{dataPost.text[0]}</div>
        )
      }
      {
        dataPost.text[1] && (
          <div
            className="w-content text text-center px-3 f-5 bl"
            style={{color: dataPost.colors[3], width: '43%',
              fontSize:'30px', bottom: '20%', left: '14%'
            }}
          >{convertAmount(dataPost.text[1])}</div>
        )
      }
      {
        dataPost.text[2] && (
          <div
            className="f-5 text cl clamp-2 text-end px-2"
            style={{color: dataPost.colors[0], fontSize: '17px', lineHeight: '20px',
            width: '48%', left: '2%', top: '24%'
            }}
          >{dataPost.text[2]}</div>
        )
      }
      {
        dataPost.text[3] && (
          <div
            className="text bl py-1 px-2 f-3 text-truncate text-center"
            style={{color: dataPost.colors[3], fontSize: '16px',
            left: '14%', bottom: '28%', width: '43%'
            
          }}
          >{dataPost.text[3]}</div>
        )
      }
      {
        dataPost.text[4] && (
          <div
            className="text cl py-1 px-2 f-3 clamp-4 text-end"
            style={{color: dataPost.colors[2], fontSize: '15px',
              left: '2%', width:' 48%', marginTop: '-20px'
            }}
          >{dataPost.text[4]}</div>
        )
      }
    
    </div>
  );
};

export default Post7;
