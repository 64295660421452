import React from 'react'
import { useSelector } from 'react-redux';
import { convertAmount } from '../../../../tools/format';


const State19 = () => {

  const {dataPost, imgPostLogo, imgProduct} = useSelector(s => s.post)
  

  return (
    <div className="poster">
      <div className="img" style={{
        left: '0%', top: '21%', width: '94%', height: '66%'
      }}>
        {
          imgProduct && (
            <img
              src={imgProduct}
              className='b7'
              alt="img" />
          )
        }
      </div>
      <div className="svg">
        <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 490 870">
          <path fill={`${dataPost.colors[0]}`}  d="m0,0v870h490V0H0Zm459,754H1V186h458v568Z"/>
          <polygon fill={`${dataPost.colors[1]}`}  points="459 544.95 459 754 1 754 1 617.26 0 617.42 0 870 490 870 490 540.05 459 544.95"/>
          <g>
            <circle fill={`${dataPost.colors[2]}`}  cx="455.99" cy="152.03" r="2.03"/>
            <circle fill={`${dataPost.colors[2]}`}  cx="455.99" cy="169.67" r="2.03"/>
            <circle fill={`${dataPost.colors[2]}`}  cx="439.26" cy="152.03" r="2.03"/>
            <circle fill={`${dataPost.colors[2]}`}  cx="439.26" cy="169.67" r="2.03"/>
            <circle fill={`${dataPost.colors[2]}`}  cx="422.53" cy="152.03" r="2.03"/>
            <circle fill={`${dataPost.colors[2]}`}  cx="422.53" cy="169.67" r="2.03"/>
            <circle fill={`${dataPost.colors[2]}`}  cx="405.8" cy="152.03" r="2.03"/>
            <circle fill={`${dataPost.colors[2]}`}  cx="405.8" cy="169.67" r="2.03"/>
            <circle fill={`${dataPost.colors[2]}`}  cx="389.07" cy="152.03" r="2.03"/>
            <circle fill={`${dataPost.colors[2]}`}  cx="389.07" cy="169.67" r="2.03"/>
            <circle fill={`${dataPost.colors[2]}`}  cx="372.34" cy="152.03" r="2.03"/>
            <circle fill={`${dataPost.colors[2]}`}  cx="372.34" cy="169.67" r="2.03"/>
            <circle fill={`${dataPost.colors[2]}`}  cx="355.6" cy="152.03" r="2.03"/>
            <circle fill={`${dataPost.colors[2]}`}  cx="355.6" cy="169.67" r="2.03"/>
            <circle fill={`${dataPost.colors[2]}`}  cx="338.87" cy="152.03" r="2.03"/>
            <circle fill={`${dataPost.colors[2]}`}  cx="338.87" cy="169.67" r="2.03"/>
            <circle fill={`${dataPost.colors[2]}`}  cx="322.14" cy="152.03" r="2.03"/>
            <circle fill={`${dataPost.colors[2]}`}  cx="322.14" cy="169.67" r="2.03"/>
          </g>
          <polygon fill={`${dataPost.colors[0]}`}  points="382.5 681.5 490 681.5 490 678.5 385.5 678.5 385.5 262.5 490 262.5 490 259.5 382.5 259.5 382.5 681.5"/>
          <polygon fill={`${dataPost.colors[2]}`}  points="490 801 30 801 30 0 26 0 26 805 490 805 490 801"/>
        </svg>
      </div>

      <img
        src={imgPostLogo}
        alt="logo" className="logo text tr"
        style={{margin: '5%'}}
      />

      {
        dataPost.text[0] && (
          <div
            className="text tl py-0 px-2 f-8 clamp-2"
            style={{color: dataPost.colors[2], 
            fontSize: '29px', left: '10%', top: '2.5%', width: '60%',
            lineHeight: '35px'
          }}
          >{dataPost.text[0]}</div>
        )
      }
      {
        dataPost.text[1] && (
          <div
            className="w-content text px-3 f-12 tl"
            style={{color: dataPost.colors[2],
              fontSize:'30px', left: '8%', letterSpacing: ' 4px', top: '14%'
              
            }}
          >{convertAmount(dataPost.text[1])}</div>
        )
      }
      {
        dataPost.text[2] && (
          <div
            className="f-12 text bc text-truncate text-uppercase px-3 py-1"
            style={{color: dataPost.colors[0], fontSize: '20px', maxWidth:'85%' ,
              marginBottom: '8.5%', background: dataPost.colors[2], letterSpacing: '2px'
            }}
          >{dataPost.text[2]}</div>
        )
      }
    </div>
  );
};

export default State19;
