import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setAlmostFinish } from '../../../../redux/reducer/menu'
import { setDataClient } from '../../../../redux/reducer/user'
import { convertAmount } from '../../../tools/format'

const AlmostFinised = () => {

  const {almostFinish} = useSelector(s => s.menu)
  const {admin, dataClient, menu} = useSelector(s => s.user)
  const [data, setData] = useState(null)
  const dispatch = useDispatch()

  const handleClose = ()=>{
    dispatch(setAlmostFinish(false))
    dispatch(setDataClient({}))
  }

  useEffect(()=>{
    setData(dataClient)
  },[dataClient])


  const message = `https://api.whatsapp.com/send?phone=${menu?.s}${menu?.h}&text=${encodeURIComponent(`Hola 🙂, soy *${data?.client?.name}*. \n\nConfirmo un pedido desde tu menú digtal 🧾  \n\n*Enlace de seguimiento del pedido:* \n\nhttps://fooderapp.co/order/${menu?.s}${admin?.f}/${data?.op} \n👆🏻👆🏻👆🏻👆🏻👆🏻👆🏻👆🏻\n\n💵 Método de pago: *${data?.payment?.method === 'cash'? 'Efectivo': data?.payment?.method}* \n📦 Método de entrega: *${data?.payment?.delivery === true ? 'Domicilio': 'Recoger en punto'}* \n🏠 Dirección: *${data?.payment?.delivery === true ? data?.client?.address : 'No aplica'}* Barrio: *${data?.client?.neighbor?.length === 0 ? 'No aplica': data?.client?.neighbor}* \n📑 Valor total del pedido: *${convertAmount(data?.payment?.total)}* \n\n-ˋˏ✄┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈ \n\n¡Gracias por elegirnos! Estamos aquí para tí ✨✨ ( ˘͈ ᵕ ˘͈♡) ✨✨`)}`



  return (
    <>
      <section className={`almostSent ${almostFinish ? 'active': ''}`}>
        <div className="content">
          <h3>Casi terminado...</h3>
          <p className='my-4'>Confirma tu pedido vía WhatsApp</p>
          <a href={message} onClick={handleClose} target='_blank' rel="noopener noreferrer">
            <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 196 196">
              <defs>
                <style>
                  {`
                  .cls-1 {
                    fill: #fff;
                  }
                  `}
                </style>
              </defs>
              <path className="cls-1" d="m92.43,8c3.75,0,7.5,0,11.25,0,.45.1.89.26,1.35.29,19.92,1.61,37.6,8.79,52.34,22.25,24.77,22.62,35.13,50.77,28.71,83.82-10.93,56.25-72.51,88.67-126.71,63.31-1.14-.54-2.17-.85-3.48-.54-11.28,2.62-22.58,5.15-33.86,7.74-4.32.99-8.61,2.08-12.91,3.13h-.7c.07-.46.11-.93.21-1.38,3.35-15.31,6.73-30.62,10.03-45.94.18-.82.02-1.87-.36-2.61-4.25-8.34-7.37-17.06-8.92-26.31-.51-3.05-.88-6.12-1.32-9.18,0-3.63,0-7.27,0-10.9.57-3.93,1-7.88,1.74-11.77C16.55,44.4,46.58,15.18,82.2,9.4c3.4-.55,6.82-.93,10.23-1.4ZM26.81,169.38c10.63-2.41,20.88-4.76,31.16-7.01.96-.21,2.22-.02,3.09.45,17.03,9.21,35.03,11.91,53.88,7.58,50.86-11.7,75.28-69.39,46.85-113.51-16.98-26.35-48.91-39.88-79.19-33.27-22.37,4.89-39.48,17.25-50.87,37.14-8.07,14.09-11.03,29.35-8.99,45.51,1.32,10.49,4.94,20.17,10.23,29.29.48.82.76,2.01.58,2.92-1.23,6.02-2.61,12-3.93,18-.93,4.21-1.83,8.43-2.8,12.91Z"/>
              <path className="cls-1" d="m71.76,57.98c3.63-.84,5.55,1.22,6.83,4.49,1.88,4.79,3.87,9.54,5.87,14.28.59,1.4.76,2.77-.13,4.01-1.5,2.07-3.04,4.12-4.73,6.04-1.98,2.24-2.1,2.79-.54,5.38,6.59,10.94,15.72,18.92,27.57,23.76,2.34.96,3.17.84,4.75-1.07,2.09-2.52,4.18-5.06,6.17-7.65,1.08-1.41,2.37-1.97,3.97-1.22,5.92,2.79,11.84,5.6,17.68,8.55.67.34,1.23,1.63,1.22,2.47-.07,5.55-1.55,10.38-6.44,13.86-6.41,4.55-13.21,4.96-20.58,3.2-19.21-4.58-33.93-15.55-45.32-31.37-3.94-5.47-7.95-10.9-9.91-17.48-2.74-9.22-1.13-17.44,5.63-24.52,2.17-2.27,4.72-3.14,7.97-2.73Z"/>
            </svg>
            <span>Enviar</span>
          </a>
        </div>
      </section>
    </>
  )
}

export default AlmostFinised