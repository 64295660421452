import React from 'react'
import MainFreeTheme from '../../freetemplate/MainFreeTheme'

const Galery = () => {
  return (
    <MainFreeTheme
      twoColumns={false}
    />
  )
}

export default Galery