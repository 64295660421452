import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setVideo } from "../../../redux/reducer/interactions";


const TutorialModal = () => {
  
  const {video} = useSelector(x => x.ux)
  const dispatch = useDispatch()
 
  const handleCancel = ()=> dispatch(setVideo(null))

  return (
    <div className="modal fade modalDashboard modalXXL" id="tutorialModal" tabIndex="-1" aria-labelledby="tutorialModal" aria-hidden="true" data-bs-backdrop="static">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header py-4 border-none">
            <button
              className="btnClose"
              data-bs-dismiss="modal"
              onClick={handleCancel}
            >Cerrar</button>
          </div>
          <div className="modal-body p-3 p-md-4">
            
            <div className="row">
              <div className="col-12">
                {
                  video && (
                    <div className="iframeVideo">
                      {video === 1 && 'VIDEO DE NOVEDADES'}
                      {video === 2 && 'VIDEO CONFIGURACIÓN'}
                      {video === 3 && 'VIDEO MI PLANTILLA'}
                      {video === 4 && 'VIDEO PRODUCTOS'}
                      {video === 5 && 'VIDEO COCINA'}
                      {video === 6 && 'VIDEO REPORTES'}
                    </div>
                  )
                }
              </div>
            </div>

            <div className="row justify-content-center mt-4">
              <div className="col-md-4 col-6">
                <a
                  href="https://"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="btn-tiktok"
                >Seguir en TikTok</a>
              </div>
              <div className="col-md-4 col-6">
                <button
                  className="btn-line-apple w-100"
                  data-bs-dismiss="modal"
                >Entendido</button>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default TutorialModal;
