import React from 'react'
import { setDataRequest, updateNotification } from '../../../redux/reducer/sudo'
import { useDispatch, useSelector } from 'react-redux'
import userIcon from '../../../img/dashboard/register_user.svg'
import handleCreateUser from './fx/createUser'
import { setIsError, setStateNotification, setTextNotificate } from '../../../redux/reducer/interactions'
import updateStateRequest from './fx/updateStateRequest'

const ModalCreateUser = () => {


  const {dataRequest} = useSelector(s => s.sudo)
  const dispatch = useDispatch()



  const handleReset = ()=> dispatch(setDataRequest({}))

  const createUser = async()=>{
    
    const response = await handleCreateUser(dataRequest)

    if(response){
      dispatch(setTextNotificate('Nuevo usuario agregado  para CMS Fooder'))
      dispatch(setStateNotification(true))
      dispatch(setIsError(false)) 
      // update state
      await updateStateRequest(dataRequest.tel)
    }else{
      dispatch(setTextNotificate('El usuario no pudo ser creado.'))
      dispatch(setStateNotification(true))
      dispatch(setIsError(true)) 
      dispatch(updateNotification({visible: true, text:'Este usuario ya había sido parte de Fooder pero fué eliminado, ó ESTÁ USANDO UN CORREO DE UN USUARIO EXISTENTE PARA REGISTRARSE. Para que esta solicitud pueda ser creada sin problemas, debe ser borrado el correo asociado en la autenticación de Firebase.'}))
    }

  }


  return (
    <div
      className="modal modalDashboard fade"
      data-bs-backdrop="static" 
      data-bs-keyboard="false"
      id="modalCreateUser" 
      tabIndex="-1" 
      aria-labelledby="modalCreateUserLabel" 
      aria-hidden="true"
    >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="p-3 p-md-5 text-center text-white">
                <div className="row justify-content-center">
                  <div className="col-md-4 col-5 mb-4">
                    <img src={userIcon} alt="" className='img-fluid'/>
                  </div>
                </div>
                <h4 className='text-apple mb-4'>Crear usuario nuevo</h4>
                <p> Nombre: {dataRequest?.name || 'NA'}</p>
                <p> Celular: {dataRequest?.tel || 'NA'}</p>
                <p> Email: {dataRequest?.email || 'NA'}</p>
                <p className='mb-5 '>Fecha de solicitud: {dataRequest?.date || 'NA'}</p>
                
                
                
                <div className="row justify-content-center">
                  <div className="col-md-6">
                    <button
                      className='btn-orange w-100 m-0'
                      data-bs-dismiss="modal"
                      onClick={handleReset}
                    >No, cerrar</button>
                  </div>
                  <div className="col-md-6 order-first order-md-last mb-4 mb-md-0">
                    <button
                      className='btn-line-apple w-100 m-0'
                      data-bs-dismiss="modal"
                      onClick={createUser}
                    >CREAR USUARIO</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}

export default ModalCreateUser