import React from 'react'
import { useSelector } from 'react-redux'

const ScheduleMenu = () => {

  const {menu} = useSelector(state => state.user)

  return (
    <>
      <div className="content">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-7 col-11">
              <p className="display-2 subtitle text-c4 text-center">HORARIO DE ATENCIÓN</p>
              <table className='tableMenu'>
                <thead>
                  <tr>
                    <th>DÍA</th>
                    <th>APERTURA</th>
                    <th>CIERRE</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    menu?.b?.map((el, index) =>{
                      const week = ["Lunes","Martes","Miércoles","Jueves","Viernes","Sábado","Domingo"]
                      return (
                        <tr key={index}>
                          <td>{week[index]}</td>
                          <td>{el.a ? el.b || '--': 'Cerrado'}</td>
                          <td>{el.a ? el.b || '--': 'Cerrado'}</td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ScheduleMenu