import React from 'react'

const ModalTYCDashboard = () => {
  return (
    <>
      <div className="modal fade modalDashboard" id="modalTYCDashboard" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="modalTYCDashboardLabel" aria-hidden="true">
        <div className="modal-dialog  modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header sticky-top bg-b1">
              <div className="d-flex justify-content-between align-items-center w-100">
                <h1 className="modal-title fs-5 text-apple">Términos y condiciones</h1>
                <button
                  type="button"
                  className="btn-green-dark"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >Cerrar</button>
              </div>
            </div>
            <div className="modal-body">
              <h6>título</h6>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aspernatur dolores debitis, ipsa consequuntur quibusdam odit quam expedita amet placeat deserunt dolorem? Dolores reprehenderit cupiditate nisi necessitatibus ea neque eos quidem!
              Delectus ab nam facilis soluta, quibusdam ipsum libero modi nostrum, cupiditate explicabo earum repudiandae quod aliquam dicta ea odit beatae voluptates iste tempora, voluptatibus vel. Minima, non facere. Expedita, rerum.
              Deserunt adipisci id libero? Magnam nostrum, asperiores tempore architecto alias sint labore deserunt. Pariatur, recusandae fugit porro aliquam, earum rem temporibus provident debitis nulla non praesentium quam animi soluta facere.
              Asperiores suscipit, quisquam sint in nulla consectetur corrupti commodi ipsa dolores itaque molestiae, consequuntur optio voluptatum cum ducimus dolorum rerum esse iusto neque. Voluptatem magnam et dignissimos fugit quis error!
              Temporibus voluptatibus dolor architecto eveniet mollitia officiis exercitationem. Quas, nisi totam sequi unde recusandae, quae ex quos reiciendis provident magni illo itaque porro ea veniam delectus natus, voluptatem maiores consectetur.
              Pariatur aliquam cum voluptates id labore possimus! Rem, eos nobis, quasi incidunt impedit dolorem molestiae quas sed perferendis sapiente facilis tempora explicabo, odio at aliquid accusantium. Modi asperiores exercitationem ea!
              Earum, exercitationem, est labore aliquid itaque officia recusandae delectus fugit quaerat illo obcaecati reprehenderit voluptatem similique rem commodi! Enim vitae velit ex nemo ducimus voluptates architecto blanditiis reprehenderit quam recusandae.
              Consequuntur asperiores facilis quos eum magni, vel, maxime earum consectetur hic non ducimus repellat, iste eos fuga. Deleniti corporis officia voluptatum architecto sed nesciunt harum ipsum quibusdam veritatis, ex quia.
              Voluptate molestias aliquid placeat! Aut distinctio fuga nobis nihil at illo atque magni minus. Id, facere officiis. Officiis placeat odit facilis minus numquam reiciendis, consectetur, fugit, rem neque voluptatem porro?
              Sapiente deleniti sit aliquid ratione eveniet rem, impedit tempore aliquam ad numquam voluptatum obcaecati vero soluta qui earum quos perspiciatis debitis error natus ipsam repudiandae optio. Ea eligendi eius quo.
              Porro dignissimos est nulla doloribus esse aut harum sapiente veritatis tenetur cupiditate fugit illo excepturi rem eum aliquam eaque veniam totam similique itaque inventore officia eligendi, earum pariatur enim? Unde.
              Quia facere, fuga unde cum laudantium expedita iusto deserunt earum praesentium laborum labore sit reiciendis tempore perferendis necessitatibus sint eius hic esse totam distinctio laboriosam! Mollitia vel officiis veritatis expedita.
              Architecto ex iusto at voluptatum eius qui cupiditate illum, incidunt eaque ipsum itaque vero doloremque veritatis necessitatibus autem laborum sunt delectus, asperiores modi commodi doloribus mollitia sit consequatur. Eum, nesciunt?
              Omnis autem esse aspernatur laborum voluptatum quibusdam voluptates dolor quaerat nulla et commodi cumque dolores, necessitatibus maxime velit reiciendis, libero at corrupti tempore mollitia? Nostrum laboriosam inventore magni omnis aliquid.
              Fuga aliquam accusamus ratione, minima reiciendis sint fugiat quae dicta, nostrum labore, assumenda perferendis tempora molestias? Numquam velit earum officiis impedit, laborum cumque cupiditate hic modi quibusdam vitae libero sapiente?
              Officiis, tempore. Numquam autem tempora iste, distinctio sit consectetur esse ab a quas reprehenderit libero illo corrupti illum odit accusantium nulla omnis non aperiam atque voluptatem consequuntur ducimus. Animi, quas.
              Dignissimos nam ad sapiente doloremque vero quam dolorem culpa consequatur amet, quos minus assumenda illo sunt similique id qui tenetur nisi saepe ab fugit eveniet doloribus. Esse recusandae repudiandae obcaecati?
              Inventore nihil error, iusto commodi doloremque repellat odio animi quia a, delectus ex sed, magnam labore voluptatem quisquam odit reiciendis illum id. Ipsa ut eius laboriosam harum blanditiis hic provident?
              Consectetur corrupti numquam ad? Dolores totam labore maxime facere cupiditate delectus debitis? Exercitationem inventore delectus quae laboriosam dolor temporibus dolores, consequuntur iste quo neque architecto sit necessitatibus eveniet atque possimus.
              Reiciendis eligendi a earum expedita quidem incidunt nam, debitis nesciunt atque rem, excepturi corporis. Placeat dolor reprehenderit odit dignissimos itaque tempora odio, quod facilis quidem vel nostrum autem aliquid ducimus?
              Corporis rem deserunt voluptas in pariatur. Excepturi nisi velit, suscipit quibusdam veritatis soluta quasi minus cum voluptates eius repellendus enim blanditiis non quae obcaecati recusandae reprehenderit totam, molestiae nobis. Repellat.
              Aliquam error facilis nemo deserunt autem vero quam pariatur aperiam obcaecati qui amet ullam saepe vel nulla ducimus hic, officiis blanditiis facere, ut dicta? Nostrum odit quo culpa dicta architecto.
              Facere suscipit pariatur exercitationem, tempora obcaecati consectetur voluptas cupiditate corrupti repudiandae a quasi, sed odit temporibus neque porro sint beatae illo quibusdam et ipsa id. Facilis assumenda exercitationem delectus hic.
              Non veniam ab architecto expedita. Ut, eius ullam nulla rerum autem, in maiores necessitatibus, aut voluptas magni mollitia perspiciatis culpa repudiandae tempora quo atque facilis perferendis? Voluptates aliquam sit vitae?
              Voluptates, distinctio excepturi. Recusandae, deserunt itaque. Veniam pariatur at inventore in atque. Voluptatibus consectetur adipisci impedit voluptatum officiis consequatur, explicabo sint nulla quasi vero fuga blanditiis repellendus aliquid enim cumque.
              Labore dolorum voluptate nulla repudiandae reprehenderit. Ut vel beatae saepe magni qui exercitationem voluptatum cumque modi. Explicabo libero eos, maiores ea, perferendis minus dolore ipsum nulla iste voluptatum deleniti numquam!
              Amet labore tempora perferendis beatae recusandae dolorum nobis velit minus debitis, veniam repellendus adipisci odit quas autem atque quaerat eius aliquid corrupti, eaque earum delectus! Aspernatur dolor quia saepe quibusdam.
              Ipsam excepturi aliquid, reiciendis possimus cupiditate eum laudantium praesentium sapiente necessitatibus odit facilis iste distinctio minus ipsa quas hic laboriosam, dolores porro, incidunt velit adipisci repellendus alias. Consequatur, dicta cum.
              Ut qui atque eius odit minus ipsum et repellendus, officiis sint? Dolorem nesciunt cum totam, corporis facilis excepturi consequuntur perspiciatis similique, maxime quisquam reprehenderit unde sunt autem deleniti temporibus deserunt.
              Error ut laborum natus reprehenderit, obcaecati nulla animi tempora assumenda. At quibusdam ex dolorum? Rem reprehenderit illum reiciendis similique consectetur, libero expedita sed minus commodi voluptatibus provident, ipsum, unde asperiores.
              Velit qui saepe dolore consectetur tenetur, vel mollitia maxime, est soluta incidunt eveniet? Saepe nostrum corrupti laborum possimus laboriosam corporis ipsa, repellendus voluptates officiis facilis eaque consequatur eos fuga placeat?
              Autem dolorem magni necessitatibus illo corrupti nobis repellendus quidem vel. Unde illo reprehenderit quia optio deserunt ut ex reiciendis, enim blanditiis similique quidem alias ad distinctio possimus culpa esse autem!
              Accusamus ab, earum est sed quisquam itaque corrupti quis ducimus nostrum cupiditate magni impedit nemo. At atque nesciunt, ad explicabo necessitatibus, sunt ea iure aperiam in itaque minus veritatis similique.
              Modi exercitationem deserunt laudantium ipsam odio sunt voluptas. Porro quo autem eos, accusantium iusto quibusdam vitae neque consequatur quasi, magnam, modi laborum ullam! Fugiat dolorum minima quisquam at praesentium sed.
              Possimus velit, voluptatum deleniti magni quibusdam magnam atque nisi corrupti, ad tempora molestiae saepe! Aspernatur vel sed corporis error itaque sequi incidunt, molestiae est tempore! Ducimus aperiam corporis ipsam quo?
              Eaque ducimus nam distinctio dolorem, mollitia dolor laudantium, id consequuntur exercitationem fugiat nihil cum impedit nostrum quaerat? Vero praesentium laudantium voluptatem eos, molestias accusamus accusantium minima incidunt quidem veniam architecto!
              Ipsam officiis non accusantium ex facere. Dolorum doloremque in voluptate aliquid beatae sunt quam non. Enim sequi nostrum consequuntur, quos eius libero laborum in quas? Eaque possimus impedit eveniet id.
              Autem officia illum, consectetur architecto vitae neque soluta dolorem aliquid aspernatur aperiam dolorum tenetur ratione labore dolores delectus aliquam enim, corrupti nesciunt animi cum, eius exercitationem maxime ut! At, eveniet!
              Nemo, neque odit amet magni autem laudantium repellat quae iusto porro dolorem perspiciatis laboriosam fuga nobis iste aperiam aliquam vel dicta harum libero praesentium at! Ad perspiciatis exercitationem delectus laboriosam.
              Odio sint perferendis ad quae, fugiat labore quod hic ipsum magni, reiciendis beatae commodi nostrum, ratione tempora? Non numquam alias cupiditate voluptatem ea, consequatur, nostrum, labore quos debitis nemo explicabo?
              Esse obcaecati fugit, deleniti aut sint numquam laudantium dolores dolore voluptates eum optio illum, velit fugiat perferendis? Culpa recusandae corrupti provident animi deserunt. Facilis provident suscipit, soluta numquam amet tempora.
              Quo voluptatem sequi consequatur optio praesentium, tempore ducimus, placeat excepturi odit minima rem nisi fuga beatae! Iste, itaque ipsum ipsa qui, voluptate quasi rem deleniti, dicta ex fuga laboriosam suscipit!
              Quod quidem voluptatibus quas possimus quia velit aspernatur error sequi veritatis, ratione et ipsa modi doloribus dolorem quaerat est suscipit eveniet sed cumque! Quis nemo illum ab fugiat, ut in?
              Atque dicta consequatur veritatis repellat ex soluta praesentium quidem ab non perspiciatis, odio sunt facere aut ullam aperiam tenetur. Autem sed reiciendis neque accusamus aliquam eos dolore, qui impedit dignissimos.
              Labore deserunt aliquid ut odit autem rem quis, natus explicabo facilis quasi tempora fugiat molestias dicta. Facere quidem suscipit repellendus doloribus. Nostrum, ullam esse dolor repellendus ex et cumque ipsum!
              Porro ipsam assumenda a voluptates unde molestias vero eaque iste eveniet aut maxime saepe corporis ipsa sed inventore animi, possimus laborum minus, quis beatae veritatis obcaecati! Nihil consequuntur ad eius!
              Tempora ullam tempore iste recusandae laborum delectus vitae corrupti facere expedita fugit harum aspernatur quasi amet veritatis molestias accusamus cum explicabo laboriosam, neque vel eos rerum. Voluptate labore aliquam perspiciatis!
              Perspiciatis dignissimos consequatur pariatur nemo. Aut esse illum aliquid doloribus ducimus rem quos ratione non ullam fugiat, optio corrupti excepturi reiciendis delectus laudantium quas molestias earum voluptatem officia ad quis?
              Rerum distinctio maxime fugiat harum dolorem odio suscipit minus, error perspiciatis molestiae quo laborum tempora, ullam tenetur ut consequatur atque sed quas officiis architecto placeat corrupti repudiandae esse dolor! Repellendus.
              Pariatur iure tempora obcaecati rem eos corrupti corporis quidem consequuntur explicabo, in nobis iste tempore perferendis repudiandae provident exercitationem dolore officiis ratione consequatur praesentium aliquid, enim aliquam modi? Quo, dolores.
              Molestias expedita cumque similique numquam excepturi, rem optio quos aperiam quidem animi sunt, neque temporibus sequi tenetur reprehenderit. Voluptatem nihil facere minima delectus ipsam maxime nobis magni eveniet? Quis, qui.
              Iste laudantium atque inventore qui officia. Odio facere laborum cupiditate nemo voluptatem voluptate animi, numquam quisquam sed vel laboriosam architecto omnis facilis modi nulla corrupti quae possimus! Necessitatibus, praesentium perspiciatis!
              Excepturi voluptatibus officiis eius natus ipsa explicabo aperiam repudiandae, facilis dicta. Delectus debitis neque aliquid, vero voluptatum ipsam doloribus, in aperiam temporibus, totam quod aspernatur quo iure cupiditate consequuntur autem!
              Consequatur quam est vero rem autem nesciunt officia molestias omnis assumenda non, quos error. Nostrum fugiat itaque provident id non quas, cumque nisi eius officia voluptas mollitia! Enim, sunt accusantium?
              Ex voluptas quae esse delectus quibusdam, doloribus provident eos sapiente maxime eveniet fugiat odio molestias vitae aliquid officia ipsam repudiandae corrupti dolor commodi in odit! Est iste molestias illo ipsam!
              Dignissimos, quasi nesciunt iure quibusdam ipsum culpa optio soluta veritatis nulla voluptatem reprehenderit itaque ducimus in. In, facere aliquid! Placeat accusamus odit tempora non dicta numquam mollitia illum necessitatibus quia.
              Placeat, voluptatibus vero nisi vel ea asperiores omnis accusantium! Ipsum, consequatur necessitatibus. Debitis dicta obcaecati repellendus quis vel, tempora harum quia aspernatur eveniet iusto nostrum veniam, aperiam magni id nulla!
              Accusamus voluptatibus natus soluta neque ullam aspernatur animi, adipisci facere consequatur dolorem? Voluptate illo dolores tenetur expedita. Quam, illo rerum. Vero omnis voluptas, cum cumque est animi tenetur laborum aut.
              Culpa debitis vero, ab numquam ipsa et cupiditate at minima quo voluptatum veritatis quia facilis suscipit reprehenderit saepe quod quae laudantium in perspiciatis repudiandae, modi ratione. Iste est dicta veritatis?
              Reprehenderit inventore impedit fugit numquam assumenda ipsam, maiores magni libero culpa a tempora velit delectus necessitatibus suscipit molestias reiciendis. Laboriosam dolores expedita inventore modi reprehenderit consequatur fugiat consectetur perspiciatis explicabo!
              Quibusdam pariatur necessitatibus minus, quod quidem quas saepe distinctio odit modi doloribus recusandae debitis, exercitationem, repellat maxime illum suscipit amet magnam aut non. Vel eaque officia nesciunt aliquid, qui molestias?
              Nemo asperiores incidunt quaerat aliquam adipisci magnam cupiditate earum repudiandae minima fugiat sunt blanditiis enim dolor, impedit consequuntur, iusto vel temporibus nam provident repellendus hic dolorum minus quibusdam beatae? Numquam!
              Dolorum nulla sapiente veniam deleniti. Maxime, explicabo, unde, incidunt excepturi voluptatibus minima itaque libero deserunt quos a illum consequatur ratione quis nulla similique quisquam. Sed ducimus fuga asperiores quaerat sequi!
              Delectus iure facilis, pariatur deserunt vero, aspernatur tenetur veritatis totam sequi cum perferendis repudiandae exercitationem ducimus voluptate harum, ex inventore quae id aut excepturi. Quis debitis possimus porro placeat dicta.
              Molestiae tempora, ipsa ex voluptatibus corporis accusamus molestias laborum vel eius, omnis modi, assumenda cum quos? Et, blanditiis, nulla odit itaque aliquam distinctio nesciunt nam accusantium laboriosam voluptates cumque inventore?
              Delectus aperiam cumque maiores officia sit, soluta minus laboriosam tenetur reiciendis minima, non dicta suscipit cupiditate. Eveniet dolorem beatae aliquam illum eaque maiores eligendi expedita odit mollitia. Doloremque, quasi doloribus.
              Eos deleniti dolor, debitis vitae rem minima reprehenderit velit maxime distinctio ad consectetur sed beatae, officia explicabo excepturi aperiam consequuntur fuga magni dignissimos hic? Consequuntur iusto unde ipsam nihil recusandae.
              Molestias sequi ea neque quasi voluptate facilis sint harum ducimus qui nobis, tempore animi impedit iure ipsum explicabo magni illum, delectus voluptas, omnis possimus? Nostrum cupiditate atque illo officia saepe?
              Non nihil delectus consequuntur ea facilis id a accusamus voluptatem odio? Eligendi distinctio cumque nihil repellendus. Nemo enim iusto atque vero ullam deleniti ipsa provident! Commodi eveniet vitae corporis exercitationem.
              Laboriosam est molestiae tempora distinctio dolores voluptates quas, ipsa illo iste deserunt nam non sequi unde dolore fuga repellendus sint? Ducimus dolore cupiditate et dolorem nesciunt beatae expedita voluptatibus velit!
              Cupiditate, nobis? Veritatis laudantium deserunt amet! Quas aliquid dolore nobis optio laudantium excepturi architecto pariatur illum dolorum possimus saepe, odit doloremque ad voluptates esse! Dolor eum reprehenderit consequuntur delectus aperiam!
              Pariatur, vel ab amet voluptates minima unde omnis dolore, eligendi tempore odit labore ex sit vero repudiandae natus a quaerat voluptatum alias ullam quod culpa! Corporis ducimus excepturi animi dolorum.
              Doloribus dicta maxime quod tempora distinctio sequi provident vel, ut odio at, eaque atque ea a numquam officia inventore reprehenderit? Dolorem nemo quam quidem recusandae atque voluptates iste fugit corporis?
              Quibusdam libero totam, vero officiis magni atque eos pariatur optio non earum omnis a, quisquam explicabo doloribus laboriosam voluptas quaerat nihil doloremque! Eius incidunt libero molestiae ad delectus perspiciatis. Ut.
              Eveniet sequi suscipit soluta libero minima adipisci, facere quod corporis ut incidunt, temporibus repellendus non harum doloremque ab? Eveniet aliquid repudiandae iusto eius dolorem fugit quos explicabo error temporibus voluptas?
              Quibusdam, quas nulla. Voluptates voluptatibus tempora eius quaerat, quam illum veniam velit temporibus cum commodi. Reprehenderit perferendis culpa odio porro nam illum eligendi cum ullam. Voluptas modi incidunt id doloribus.
              Sit dolore eius rerum velit voluptatum nobis, nam animi minima odio ut quibusdam soluta? Aut soluta vel non doloremque eligendi. Perspiciatis, perferendis fuga ipsa doloribus esse odio nemo dolores sint.
              Sequi quia voluptas ducimus alias odio ab ullam aperiam saepe. Consectetur, labore doloribus. Aperiam aspernatur dignissimos, doloribus dolorem delectus minus quod eos ea repellat molestias, id hic debitis enim aliquid?
              Sed, ad voluptate, ipsum iusto consequuntur recusandae culpa minus commodi fugiat error accusamus fuga nostrum accusantium aperiam blanditiis illum eius, reprehenderit nihil omnis praesentium sit doloribus ducimus molestiae temporibus! Autem?
              Incidunt assumenda temporibus eligendi ab reprehenderit quam exercitationem voluptatem repellat debitis dolorem mollitia molestias itaque adipisci eveniet nostrum consequuntur esse aliquam voluptatum quisquam enim illum minus aspernatur, ullam totam. Eum!
              Reiciendis laborum odio expedita pariatur, saepe dolores vero, temporibus id, odit repellat est suscipit eius. Reprehenderit, unde, laborum molestiae aut alias quo modi laboriosam sapiente impedit amet ex, perferendis vero.
              Excepturi, aliquam earum ut officia voluptate ratione itaque, commodi provident consectetur dolore totam voluptatum nostrum dolores deleniti fuga. Quod sed minima rerum ipsa similique. Ad mollitia inventore officiis possimus saepe?
              Assumenda, unde ratione! Ipsa excepturi omnis culpa iste, rerum eveniet enim molestiae deleniti ullam ut eligendi asperiores totam laboriosam quia cum sit error, beatae placeat blanditiis dolorum numquam praesentium mollitia.
              Ab rem atque iure nam vitae aut provident, eveniet est. Impedit et culpa accusamus magni inventore, voluptas recusandae est officiis quas aut, expedita natus dolores nulla nam deleniti repudiandae laborum.
              Maiores ut nam odio, rerum architecto earum corporis consequatur aliquam mollitia impedit nobis explicabo, pariatur magni, omnis asperiores ea ratione? Quidem, illum! Adipisci ipsam consectetur dolor ipsum, aperiam rerum placeat!
              Explicabo aut nihil nemo modi quidem iste sed iusto iure soluta! Labore doloribus reiciendis nulla aliquid laborum, obcaecati assumenda ullam odit iure commodi possimus ipsum, corrupti, nesciunt incidunt dignissimos at?
              Perferendis perspiciatis itaque minima nostrum, aspernatur aperiam corrupti quaerat unde dolorum nam fugiat nihil explicabo necessitatibus ipsam? Ratione, odio, sint totam perspiciatis repellat culpa delectus cumque vel laudantium, mollitia vero.
              Et, facilis quaerat reprehenderit autem soluta accusamus? Atque error, eius placeat molestias inventore fuga nulla tempora expedita, ea nemo ratione ullam maxime excepturi culpa laborum quia distinctio illum optio odit!
              Consequuntur quos sit porro molestiae, ducimus velit odio iure vitae, veniam sint repudiandae, aliquid corporis at pariatur esse culpa labore ex amet ea maiores ut. Odio laborum unde officia expedita!
              In atque laborum laudantium nobis asperiores consequatur nostrum necessitatibus voluptates quod porro? Pariatur reprehenderit quam neque, quos exercitationem similique consectetur atque officiis laboriosam quidem deleniti. Possimus provident quidem aliquid cupiditate?
              Tenetur minus eos rem debitis. Asperiores esse dolorem, fugit nobis culpa quas similique doloremque non ipsa molestias illum aut consequuntur! Rem soluta praesentium dicta quia consequuntur tempore ratione aliquam necessitatibus!
              Error, deleniti doloremque nesciunt maxime magnam beatae ab veniam rerum illum, eaque dolorem, vero tempora? Eum sed libero voluptatibus debitis voluptas voluptate dolor, iure impedit. Expedita qui tempore dolore laboriosam.
              Delectus minima animi modi, minus voluptatum autem, quis esse eos eum accusamus molestias veniam deleniti culpa ab numquam veritatis repudiandae dolor dolorum alias? Dignissimos labore mollitia atque minima minus? Voluptatum?
              Neque delectus voluptas inventore rem aliquid provident ipsa dolorem, quos nulla laborum ea quaerat repellat necessitatibus repellendus ex labore at beatae rerum, reprehenderit dignissimos? Iusto quia perspiciatis sunt! Qui, repellendus!
              At sint dolore sapiente magnam molestias et aut officia! Illo quod vitae architecto quam laudantium suscipit ipsa, esse, blanditiis optio minus dicta. Cumque, sunt vel accusamus iste quaerat voluptas? Labore!
              Sit a doloremque voluptas iure asperiores consequuntur adipisci consequatur sed at unde quibusdam cum accusantium amet iusto, magni nobis quos eveniet ea dolore provident delectus non veritatis! Natus, magni a?
              Quo quam sapiente commodi ad minus iusto, eveniet officiis nihil neque ratione officia ab nemo velit, natus, laborum aspernatur! Quos rem velit inventore tenetur a nemo alias corporis. Expedita, natus.
              Eius totam repellat, sunt voluptatum, quidem voluptate cum pariatur assumenda animi voluptatibus consequatur autem voluptatem necessitatibus fugiat? Laboriosam necessitatibus aperiam asperiores voluptatum maiores quidem sequi itaque eius atque? Nisi, nemo.
              Sunt aperiam ex, perspiciatis vel recusandae debitis sapiente enim tenetur temporibus magni dicta velit voluptates commodi voluptas itaque atque eligendi et qui doloribus nesciunt excepturi nisi sequi non. Esse, quas!
              Suscipit ullam voluptatem porro enim ipsam iste? Ipsam mollitia accusantium maiores nemo voluptates veritatis iusto unde eos deleniti explicabo at eius perspiciatis dignissimos repellat dolorem voluptatem, soluta quibusdam, blanditiis voluptate.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ModalTYCDashboard