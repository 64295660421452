import { db } from "../credentials"
import {updateDoc, doc} from 'firebase/firestore'



const addInOrder = async(country, number ,idOrderSelected, newOrder) => {
  
  const ref = doc(db, 'orderLink', `${country}${number}`)
  try {
    await updateDoc(ref, {
      [`${idOrderSelected}.order`]: newOrder
    } )
    return true
  } catch (error) {
    return false
  }
}

export default addInOrder