import React from 'react'
import { useSelector } from 'react-redux';
import { convertAmount } from '../../../../tools/format';


const State9 = () => {

  const {dataPost, imgPostLogo, imgProduct} = useSelector(s => s.post)
  

  return (
    <div className="poster">
      <div className="img" style={{
        left: '6.6%', top: '4%', width: '57.5%', height: '67%'
      }}>
        {
          imgProduct && (
            <img
              src={imgProduct}
              className='b7'
              alt="img" />
          )
        }
      </div>
      <div className="svg">
        <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 490 870">
          <path fill={`${dataPost.colors[0]}`}  d="m-.5,0v870h490V0H-.5Zm313,613H33.5V38h279v575Z"/>
          <circle fill={`${dataPost.colors[1]}`}  cx="79.5" cy="647" r="75"/>
          <g>
            <polygon fill={`${dataPost.colors[2]}`} points="79.91 602.74 63.69 618.95 66.52 621.79 79.91 608.4 93.29 621.79 96.13 618.95 79.91 602.74"/>
            <g>
              <path fill={`${dataPost.colors[2]}`} d="m31.37,653.87c-.46,0-.82-.12-1.06-.36-.24-.24-.36-.6-.36-1.06v-19.49c0-.46.12-.82.36-1.06s.6-.36,1.06-.36h6.12c3.61,0,6.4.95,8.37,2.85,1.97,1.9,2.95,4.67,2.95,8.3,0,1.82-.25,3.42-.74,4.8-.5,1.38-1.23,2.55-2.2,3.5-.97.95-2.15,1.67-3.55,2.16-1.39.49-3,.73-4.82.73h-6.12Zm1.17-2.22h4.75c1.48,0,2.76-.19,3.85-.57,1.09-.38,2-.94,2.73-1.68.73-.74,1.27-1.67,1.63-2.8.36-1.13.54-2.43.54-3.91,0-2.98-.73-5.21-2.19-6.7s-3.64-2.23-6.56-2.23h-4.75v17.91Z"/>
              <path fill={`${dataPost.colors[2]}`} d="m60.05,654.16c-1.63,0-3.03-.32-4.2-.97-1.17-.64-2.08-1.56-2.73-2.74-.64-1.18-.97-2.6-.97-4.25s.32-3.01.95-4.2c.63-1.19,1.51-2.13,2.61-2.8,1.11-.68,2.39-1.01,3.85-1.01,1.03,0,1.96.17,2.79.52.82.35,1.53.85,2.11,1.51.58.66,1.02,1.45,1.33,2.38.31.93.46,1.98.46,3.14,0,.34-.1.59-.29.74s-.46.24-.82.24h-11.03v-1.68h10.43l-.51.41c0-1.14-.17-2.11-.51-2.9-.34-.79-.83-1.4-1.47-1.82-.64-.42-1.45-.63-2.42-.63-1.08,0-1.99.25-2.74.74-.75.5-1.32,1.18-1.7,2.06-.38.88-.57,1.9-.57,3.06v.19c0,1.94.47,3.42,1.41,4.44.94,1.01,2.28,1.52,4.01,1.52.72,0,1.43-.1,2.14-.29.71-.19,1.4-.51,2.08-.95.3-.19.57-.28.81-.27.24.01.44.08.59.22.15.14.25.31.3.51.05.2.03.42-.06.65-.1.23-.28.43-.55.6-.7.51-1.52.9-2.47,1.17-.95.27-1.89.41-2.82.41Z"/>
              <path fill={`${dataPost.colors[2]}`} d="m75.16,654.16c-.95,0-1.91-.12-2.87-.35-.96-.23-1.85-.63-2.68-1.2-.21-.15-.36-.32-.44-.52-.08-.2-.11-.4-.08-.6.03-.2.12-.37.25-.52.14-.15.31-.24.51-.27.2-.03.43.02.68.14.82.51,1.62.86,2.39,1.06.77.2,1.54.3,2.3.3,1.2,0,2.11-.22,2.73-.67.61-.44.92-1.05.92-1.81,0-.59-.2-1.06-.6-1.41-.4-.35-1.04-.62-1.9-.81l-2.88-.63c-1.33-.27-2.32-.75-2.96-1.43-.64-.68-.97-1.55-.97-2.63,0-.95.25-1.77.74-2.46.5-.69,1.2-1.22,2.11-1.6.91-.38,1.96-.57,3.17-.57.93,0,1.81.12,2.65.36.83.24,1.59.63,2.27,1.16.21.15.35.32.43.52.07.2.08.4.03.6-.05.2-.15.37-.3.51-.15.14-.33.22-.54.24-.21.02-.43-.04-.67-.19-.63-.42-1.28-.73-1.93-.93-.66-.2-1.3-.3-1.93-.3-1.18,0-2.08.23-2.69.7-.61.47-.92,1.08-.92,1.84,0,.59.19,1.08.57,1.46.38.38.97.65,1.77.82l2.88.6c1.37.3,2.4.77,3.09,1.41.69.65,1.03,1.52,1.03,2.61,0,1.39-.56,2.5-1.68,3.33-1.12.82-2.61,1.24-4.47,1.24Z"/>
              <path fill={`${dataPost.colors[2]}`} d="m89.9,654.16c-1.52,0-2.68-.46-3.49-1.39-.8-.93-1.2-2.26-1.2-3.99v-16.16c0-.42.11-.74.32-.97.21-.22.53-.33.95-.33.4,0,.72.11.95.33.23.22.35.54.35.97v15.97c0,1.14.23,1.99.68,2.55.45.56,1.13.84,2.01.84.19,0,.36-.01.51-.03.15-.02.3-.03.44-.03.23-.02.4.03.49.16.1.13.14.38.14.76s-.08.67-.25.86c-.17.19-.44.32-.82.38-.17.02-.35.04-.54.06-.19.02-.37.03-.54.03Z"/>
              <path fill={`${dataPost.colors[2]}`} d="m95.86,634.76c-.53,0-.94-.14-1.24-.43-.3-.28-.44-.67-.44-1.16s.15-.89.44-1.16c.3-.26.71-.4,1.24-.4s.97.13,1.25.4c.29.26.43.65.43,1.16s-.14.87-.43,1.16c-.29.29-.7.43-1.25.43Zm0,19.27c-.42,0-.74-.12-.95-.36-.21-.24-.32-.59-.32-1.03v-12.96c0-.44.11-.78.32-1.01.21-.23.53-.35.95-.35.4,0,.72.12.95.35.23.23.35.57.35,1.01v12.96c0,.44-.11.79-.33,1.03s-.54.36-.97.36Z"/>
              <path fill={`${dataPost.colors[2]}`} d="m102.23,653.87c-.27,0-.5-.06-.67-.19-.17-.13-.29-.3-.35-.51-.06-.21-.06-.44,0-.7.06-.25.2-.5.41-.73l8.94-11.95v.67h-8.46c-.34,0-.6-.09-.78-.27-.18-.18-.27-.43-.27-.74s.09-.56.27-.73c.18-.17.44-.25.78-.25h9.73c.32,0,.58.06.78.19.2.13.33.29.4.49.06.2.07.42.02.67-.05.24-.17.48-.36.71l-9.1,12.07v-.73h8.91c.7,0,1.05.33,1.05.98,0,.32-.09.57-.27.75-.18.18-.44.27-.78.27h-10.24Z"/>
              <path fill={`${dataPost.colors[2]}`} d="m121.31,654.16c-1.06,0-2-.21-2.84-.62-.83-.41-1.49-.98-1.98-1.7-.49-.72-.73-1.52-.73-2.41,0-1.14.29-2.04.87-2.69.58-.65,1.54-1.12,2.88-1.39,1.34-.27,3.17-.41,5.5-.41h1.43v1.68h-1.39c-1.71,0-3.06.08-4.04.24s-1.67.43-2.06.81c-.39.38-.59.92-.59,1.62,0,.87.3,1.57.9,2.12.6.55,1.42.82,2.46.82.84,0,1.59-.2,2.23-.6.64-.4,1.15-.95,1.52-1.65.37-.7.55-1.5.55-2.41v-3.61c0-1.31-.26-2.26-.79-2.84-.53-.58-1.39-.87-2.6-.87-.74,0-1.48.1-2.22.29-.74.19-1.52.5-2.35.92-.3.15-.55.2-.76.14-.21-.05-.38-.16-.51-.33-.13-.17-.2-.36-.22-.59-.02-.22.03-.44.14-.65.12-.21.3-.38.55-.51.93-.46,1.85-.8,2.76-1.01.91-.21,1.77-.32,2.6-.32,1.33,0,2.43.22,3.3.65.87.43,1.51,1.09,1.93,1.96.42.88.63,2,.63,3.38v8.59c0,.42-.1.75-.3.98-.2.23-.49.35-.87.35-.4,0-.71-.12-.92-.35-.21-.23-.32-.56-.32-.98v-2.47h.29c-.17.8-.49,1.49-.97,2.06-.48.57-1.06,1.01-1.76,1.33-.7.32-1.48.48-2.35.48Z"/>
              <path fill={`${dataPost.colors[2]}`} d="m32.19,674.77c-.25,0-.44-.07-.57-.21-.13-.14-.19-.34-.19-.59v-12.12c0-.25.06-.45.19-.58.13-.13.32-.2.57-.2.24,0,.43.07.57.2.14.13.21.33.21.58v5.58h-.25c.27-.74.7-1.29,1.31-1.67.6-.37,1.3-.56,2.09-.56.74,0,1.35.13,1.84.4.49.27.85.67,1.1,1.21.24.54.36,1.22.36,2.05v5.11c0,.25-.07.45-.2.59-.13.14-.32.21-.56.21-.25,0-.45-.07-.58-.21-.13-.14-.2-.34-.2-.59v-5.01c0-.86-.17-1.5-.51-1.9-.34-.4-.87-.6-1.61-.6-.85,0-1.53.26-2.03.79-.5.53-.75,1.23-.75,2.11v4.61c0,.53-.26.8-.78.8Z"/>
              <path fill={`${dataPost.colors[2]}`} d="m45.17,674.81c-.64,0-1.2-.12-1.71-.37-.5-.25-.9-.59-1.19-1.02-.29-.43-.44-.91-.44-1.45,0-.69.17-1.23.52-1.62.35-.39.93-.67,1.73-.84.81-.16,1.91-.25,3.31-.25h.86v1.01h-.84c-1.03,0-1.84.05-2.43.14s-1,.26-1.24.49c-.24.23-.35.55-.35.97,0,.52.18.95.54,1.28.36.33.85.5,1.48.5.51,0,.96-.12,1.34-.36.39-.24.69-.57.91-.99.22-.42.33-.9.33-1.45v-2.17c0-.79-.16-1.36-.48-1.71-.32-.35-.84-.52-1.56-.52-.44,0-.89.06-1.33.17-.44.11-.91.3-1.41.55-.18.09-.33.12-.46.09-.13-.03-.23-.1-.3-.2-.08-.1-.12-.22-.13-.35-.01-.13.02-.26.09-.39.07-.13.18-.23.33-.31.56-.28,1.11-.48,1.66-.61.55-.13,1.07-.19,1.56-.19.8,0,1.46.13,1.98.39.52.26.91.65,1.16,1.18.25.53.38,1.2.38,2.03v5.16c0,.25-.06.45-.18.59-.12.14-.3.21-.52.21-.24,0-.43-.07-.55-.21-.13-.14-.19-.34-.19-.59v-1.49h.17c-.1.48-.3.9-.58,1.24-.29.34-.64.61-1.06.8-.42.19-.89.29-1.41.29Z"/>
              <path fill={`${dataPost.colors[2]}`} d="m56.28,674.81c-.93,0-1.73-.2-2.4-.61-.67-.41-1.19-.97-1.55-1.71-.36-.73-.54-1.58-.54-2.54,0-.74.1-1.4.3-1.98.2-.58.5-1.08.9-1.5.39-.41.87-.73,1.42-.94.55-.22,1.18-.32,1.88-.32.46,0,.93.07,1.43.21.5.14.95.38,1.35.72.13.09.21.2.25.32.04.13.04.25,0,.37-.04.12-.1.22-.19.31-.09.08-.2.13-.33.14-.13.01-.27-.03-.41-.13-.33-.25-.67-.43-1.01-.53-.34-.1-.67-.15-.99-.15-.5,0-.93.08-1.3.24-.37.16-.68.38-.93.68-.25.29-.45.65-.58,1.09s-.2.93-.2,1.51c0,1.11.26,1.98.78,2.62.52.64,1.26.96,2.23.96.32,0,.64-.05.98-.15.34-.1.68-.28,1.02-.53.14-.1.27-.15.4-.13.13.01.23.06.32.14.09.08.15.19.18.31.03.13.03.25,0,.37-.04.12-.12.23-.25.31-.41.33-.85.57-1.33.71-.48.15-.95.22-1.41.22Z"/>
              <path fill={`${dataPost.colors[2]}`} d="m61.94,663.15c-.32,0-.57-.09-.74-.26-.18-.17-.27-.4-.27-.7s.09-.54.27-.7c.18-.16.43-.24.74-.24s.58.08.75.24c.17.16.26.39.26.7s-.09.52-.26.7c-.17.17-.42.26-.75.26Zm0,11.59c-.25,0-.44-.07-.57-.22-.13-.15-.19-.35-.19-.62v-7.79c0-.27.06-.47.19-.61.13-.14.32-.21.57-.21.24,0,.43.07.57.21.14.14.21.34.21.61v7.79c0,.27-.07.47-.2.62s-.33.22-.58.22Z"/>
              <path fill={`${dataPost.colors[2]}`} d="m68.52,674.81c-.64,0-1.2-.12-1.71-.37-.5-.25-.9-.59-1.19-1.02-.29-.43-.44-.91-.44-1.45,0-.69.17-1.23.52-1.62.35-.39.93-.67,1.73-.84.81-.16,1.91-.25,3.31-.25h.86v1.01h-.84c-1.03,0-1.84.05-2.43.14s-1,.26-1.24.49c-.24.23-.35.55-.35.97,0,.52.18.95.54,1.28.36.33.85.5,1.48.5.51,0,.96-.12,1.34-.36.39-.24.69-.57.91-.99.22-.42.33-.9.33-1.45v-2.17c0-.79-.16-1.36-.48-1.71-.32-.35-.84-.52-1.56-.52-.44,0-.89.06-1.33.17-.44.11-.91.3-1.41.55-.18.09-.33.12-.46.09-.13-.03-.23-.1-.3-.2-.08-.1-.12-.22-.13-.35-.01-.13.02-.26.09-.39.07-.13.18-.23.33-.31.56-.28,1.11-.48,1.66-.61.55-.13,1.07-.19,1.56-.19.8,0,1.46.13,1.98.39.52.26.91.65,1.16,1.18.25.53.38,1.2.38,2.03v5.16c0,.25-.06.45-.18.59-.12.14-.3.21-.52.21-.24,0-.43-.07-.55-.21-.13-.14-.19-.34-.19-.59v-1.49h.17c-.1.48-.3.9-.58,1.24-.29.34-.64.61-1.06.8-.42.19-.89.29-1.41.29Z"/>
              <path fill={`${dataPost.colors[2]}`} d="m83.53,674.81c-.64,0-1.2-.12-1.71-.37-.5-.25-.9-.59-1.19-1.02-.29-.43-.44-.91-.44-1.45,0-.69.17-1.23.52-1.62.35-.39.93-.67,1.73-.84.81-.16,1.91-.25,3.31-.25h.86v1.01h-.84c-1.03,0-1.84.05-2.43.14s-1,.26-1.24.49c-.24.23-.35.55-.35.97,0,.52.18.95.54,1.28.36.33.85.5,1.48.5.51,0,.96-.12,1.34-.36.39-.24.69-.57.91-.99.22-.42.33-.9.33-1.45v-2.17c0-.79-.16-1.36-.48-1.71-.32-.35-.84-.52-1.56-.52-.44,0-.89.06-1.33.17-.44.11-.91.3-1.41.55-.18.09-.33.12-.46.09-.13-.03-.23-.1-.3-.2-.08-.1-.12-.22-.13-.35-.01-.13.02-.26.09-.39.07-.13.18-.23.33-.31.56-.28,1.11-.48,1.66-.61.55-.13,1.07-.19,1.56-.19.8,0,1.46.13,1.98.39.52.26.91.65,1.16,1.18.25.53.38,1.2.38,2.03v5.16c0,.25-.06.45-.18.59-.12.14-.3.21-.52.21-.24,0-.43-.07-.55-.21-.13-.14-.19-.34-.19-.59v-1.49h.17c-.1.48-.3.9-.58,1.24-.29.34-.64.61-1.06.8-.42.19-.89.29-1.41.29Z"/>
              <path fill={`${dataPost.colors[2]}`} d="m91.5,674.77c-.25,0-.45-.07-.58-.21-.13-.14-.2-.34-.2-.59v-7.95c0-.25.06-.45.19-.58.13-.13.31-.2.55-.2s.43.07.56.2c.13.13.2.33.2.58v1.54h-.19c.2-.75.59-1.33,1.16-1.73.57-.41,1.28-.62,2.12-.65.19-.01.34.03.46.12.11.1.18.26.19.49.01.22-.04.39-.15.51-.11.13-.29.2-.53.23l-.3.04c-.86.08-1.53.35-1.99.83-.46.48-.7,1.13-.7,1.95v4.61c0,.25-.07.45-.2.59-.13.14-.33.21-.58.21Z"/>
              <path fill={`${dataPost.colors[2]}`} d="m98.32,674.77c-.25,0-.45-.07-.58-.21-.13-.14-.2-.34-.2-.59v-7.95c0-.25.06-.45.19-.58.13-.13.31-.2.55-.2s.43.07.56.2c.13.13.2.33.2.58v1.54h-.19c.2-.75.59-1.33,1.16-1.73.57-.41,1.28-.62,2.12-.65.19-.01.34.03.46.12.11.1.18.26.19.49.01.22-.04.39-.15.51-.11.13-.29.2-.53.23l-.3.04c-.86.08-1.53.35-1.99.83-.46.48-.7,1.13-.7,1.95v4.61c0,.25-.07.45-.2.59-.13.14-.33.21-.58.21Z"/>
              <path fill={`${dataPost.colors[2]}`} d="m105.13,663.15c-.32,0-.57-.09-.74-.26-.18-.17-.27-.4-.27-.7s.09-.54.27-.7c.18-.16.43-.24.74-.24s.58.08.75.24c.17.16.26.39.26.7s-.09.52-.26.7c-.17.17-.42.26-.75.26Zm0,11.59c-.25,0-.44-.07-.57-.22-.13-.15-.19-.35-.19-.62v-7.79c0-.27.06-.47.19-.61.13-.14.32-.21.57-.21.24,0,.43.07.57.21.14.14.21.34.21.61v7.79c0,.27-.07.47-.2.62s-.33.22-.58.22Z"/>
              <path fill={`${dataPost.colors[2]}`} d="m113.47,674.81c-.84,0-1.56-.22-2.16-.65-.6-.43-1.01-1.01-1.21-1.73l.21-.21v1.75c0,.25-.07.45-.2.59-.13.14-.32.21-.56.21-.25,0-.44-.07-.57-.21-.13-.14-.19-.34-.19-.59v-12.12c0-.25.06-.45.19-.58.13-.13.32-.2.57-.2.24,0,.43.07.57.2.14.13.21.33.21.58v5.68h-.23c.22-.71.62-1.28,1.22-1.7.6-.42,1.31-.63,2.15-.63s1.56.19,2.17.58c.61.39,1.08.94,1.42,1.66.34.72.51,1.57.51,2.56s-.17,1.83-.51,2.54c-.34.72-.81,1.27-1.43,1.67-.62.39-1.34.59-2.16.59Zm-.32-1.24c.58,0,1.09-.14,1.52-.42.43-.28.76-.69.99-1.22.23-.53.35-1.18.35-1.92,0-1.16-.26-2.04-.77-2.65s-1.21-.91-2.09-.91c-.57,0-1.07.14-1.51.41-.43.27-.77.67-1,1.2-.24.53-.35,1.18-.35,1.95,0,1.14.26,2.02.78,2.64.52.62,1.21.92,2.08.92Z"/>
              <path fill={`${dataPost.colors[2]}`} d="m122.81,674.81c-.64,0-1.2-.12-1.71-.37-.5-.25-.9-.59-1.19-1.02-.29-.43-.44-.91-.44-1.45,0-.69.17-1.23.52-1.62.35-.39.93-.67,1.73-.84.81-.16,1.91-.25,3.31-.25h.86v1.01h-.84c-1.03,0-1.84.05-2.43.14s-1,.26-1.24.49c-.24.23-.35.55-.35.97,0,.52.18.95.54,1.28.36.33.85.5,1.48.5.51,0,.96-.12,1.34-.36.39-.24.69-.57.91-.99.22-.42.33-.9.33-1.45v-2.17c0-.79-.16-1.36-.48-1.71-.32-.35-.84-.52-1.56-.52-.44,0-.89.06-1.33.17-.44.11-.91.3-1.41.55-.18.09-.33.12-.46.09-.13-.03-.23-.1-.3-.2-.08-.1-.12-.22-.13-.35-.01-.13.02-.26.09-.39.07-.13.18-.23.33-.31.56-.28,1.11-.48,1.66-.61.55-.13,1.07-.19,1.56-.19.8,0,1.46.13,1.98.39.52.26.91.65,1.16,1.18.25.53.38,1.2.38,2.03v5.16c0,.25-.06.45-.18.59-.12.14-.3.21-.52.21-.24,0-.43-.07-.55-.21-.13-.14-.19-.34-.19-.59v-1.49h.17c-.1.48-.3.9-.58,1.24-.29.34-.64.61-1.06.8-.42.19-.89.29-1.41.29Z"/>
            </g>
          </g>
          <rect fill={`${dataPost.colors[1]}`}  x="440.5" y="311" width="48" height="325"/>
          <path fill={`${dataPost.colors[0]}`}  d="m413.5,571h-128c-30.88,0-56-25.12-56-56s25.12-56,56-56h128c30.88,0,56,25.12,56,56s-25.12,56-56,56Zm-128-110c-29.78,0-54,24.22-54,54s24.22,54,54,54h128c29.78,0,54-24.22,54-54s-24.22-54-54-54h-128Z"/>
        </svg>
      </div>

      <img
        src={imgPostLogo}
        alt="logo" className="logo text tr"
        style={{right: '7%', top: '23%'}}
      />

      {
        dataPost.text[0] && (
          <div
            className="text tr  py-1 pe-4 f-5 clamp-2 text-end"
            style={{color: dataPost.colors[2], top:'6%', maxWidth:'60%' ,
            fontSize: '30px', background: dataPost.colors[1], lineHeight: '35px'
          }}
          >{dataPost.text[0]}</div>
        )
      }

      {
        dataPost.text[1] && (
          <div
            className="w-content text px-3 f-3 br text-center"
            style={{color: dataPost.colors[2], fontSize: '36px', right: '0%', bottom: '18%',
              width: '67%'
            }}
          >{convertAmount(dataPost.text[1])}</div>
        )
      }
      {
        dataPost.text[2] && (
          <div
            className="f-3 text bc clamp-2 text-center text-uppercase"
            style={{color: dataPost.colors[2], fontSize: '15px', width: '80%',
            bottom: '6%'
            }}
          >{dataPost.text[2]}</div>
        )
      }
      
    
    </div>
  );
};

export default State9;
