import lpfnjkin from '../../../img/menu/test/lpfnjkin.webp'
import lpfnnne1 from '../../../img/menu/test/lpfnnne1.webp'
import lpfnuchn from '../../../img/menu/test/lpfnuchn.webp'
import lpfnzwy2 from '../../../img/menu/test/lpfnzwy2.webp'
import lpfo33s0 from '../../../img/menu/test/lpfo33s0.webp'
import lpfoa24h from '../../../img/menu/test/lpfoa24h.webp'
import lpfog27i from '../../../img/menu/test/lpfog27i.webp'
import lpfojnhf from '../../../img/menu/test/lpfojnhf.webp'
import lpfono9r from '../../../img/menu/test/lpfono9r.webp'
import lpfoqlwn from '../../../img/menu/test/lpfoqlwn.webp'
import lpfotd2c from '../../../img/menu/test/lpfotd2c.webp'
import lpfp1yes from '../../../img/menu/test/lpfp1yes.webp'
import lpfp3egk from '../../../img/menu/test/lpfp3egk.webp'
import lpfp5sgy from '../../../img/menu/test/lpfp5sgy.webp'
import lpfp82up from '../../../img/menu/test/lpfp82up.webp'
import lpfpb2i0 from '../../../img/menu/test/lpfpb2i0.webp'
import lpfpcu37 from '../../../img/menu/test/lpfpcu37.webp'
import lpfpg93h from '../../../img/menu/test/lpfpg93h.webp'
import lpfpk495 from '../../../img/menu/test/lpfpk495.webp'
import lpfpm45f from '../../../img/menu/test/lpfpm45f.webp'
import lpfpodha from '../../../img/menu/test/lpfpodha.webp'
import lpfr9xyw from '../../../img/menu/test/lpfr9xyw.webp'
import lpfrd7ou from '../../../img/menu/test/lpfrd7ou.webp'
import lpfrgrl3 from '../../../img/menu/test/lpfrgrl3.webp'
import lpfrk9p0 from '../../../img/menu/test/lpfrk9p0.webp'
import lpfropr7 from '../../../img/menu/test/lpfropr7.webp'
import lpfrsh2x from '../../../img/menu/test/lpfrsh2x.webp'
import lpft4iju from '../../../img/menu/test/lpft4iju.webp'
import lpft8v84 from '../../../img/menu/test/lpft8v84.webp'
import lpft14hd from '../../../img/menu/test/lpft14hd.webp'
import lpftc7vm from '../../../img/menu/test/lpftc7vm.webp'
import lpftdp9h from '../../../img/menu/test/lpftdp9h.webp'
import lpftepxt from '../../../img/menu/test/lpftepxt.webp'
import lpgbgusb from '../../../img/menu/test/lpgbgusb.webp'
import lpgbkj8n from '../../../img/menu/test/lpgbkj8n.webp'
import lpgbmypq from '../../../img/menu/test/lpgbmypq.webp'
import lpgbruyr from '../../../img/menu/test/lpgbruyr.webp'
import lpftfogd from '../../../img/menu/test/lpftfogd.webp'
import lpftghuj from '../../../img/menu/test/lpftghuj.webp'
import lpgb1lfu from '../../../img/menu/test/lpgb1lfu.webp'
import lpgb9yns from '../../../img/menu/test/lpgb9yns.webp'
import lpgb75hx from '../../../img/menu/test/lpgb75hx.webp'
import lpgbcdwj from '../../../img/menu/test/lpgbcdwj.webp'



export const productsTest = [
	{
		"j": {
			"req": false,
			"op": [],
			"max": "",
			"title": "",
			"active": false
		},
		"a": true,
		"b": false,
		"f": [
			45000,
			38500
		],
		"l": true,
		"h": "Esta es una descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato.",
		"d": lpfnjkin,
		"i": {
			"op": [
				{
					"value": 0,
					"title": "Bebida 1"
				},
				{
					"title": "Bebida 2",
					"value": 500
				},
				{
					"title": "Bebida 3",
					"value": 0
				}
			],
			"req": true,
			"title": "¿Qué bebida prefieres?",
			"active": true
		},
		"k": false,
		"c": "lpfnjkin",
		"g": "Platos a la carta",
		"e": "Carne asada Recipe de costilla asada"
	},
	{
		"f": [
			18500,
			null
		],
		"d": lpfnnne1,
		"h": "Esta es una descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato. descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato.",
		"i": {
			"active": true,
			"title": "¿Qué acompañamiento prefieres?",
			"req": false,
			"op": [
				{
					"title": "Sopa de menudencias",
					"value": 0
				},
				{
					"value": 0,
					"title": "Ensalada con huevo"
				},
				{
					"value": 1200,
					"title": "Papaya picada"
				}
			]
		},
		"c": "lpfnnne1",
		"j": {
			"title": "",
			"active": false,
			"req": false,
			"op": [],
			"max": ""
		},
		"b": true,
		"g": "Platos a la carta",
		"a": true,
		"l": true,
		"k": false,
		"e": "Pollo a la plancha"
	},
	{
		"i": {
			"req": false,
			"op": [],
			"title": "",
			"active": false
		},
		"d": lpfnuchn,
		"l": true,
		"k": false,
		"j": {
			"max": 3,
			"op": [
				{
					"title": "Chimichurri",
					"value": 500
				},
				{
					"title": "Tomate",
					"value": 0
				},
				{
					"title": "De la casa",
					"value": 0
				},
				{
					"title": "Guacamole",
					"value": 0
				},
				{
					"title": "Ají",
					"value": 0
				}
			],
			"req": false,
			"active": true,
			"title": "Aderezos"
		},
		"a": true,
		"e": "Churrasco - Chivo loco",
		"f": [
			35000,
			32000
		],
		"h": "Esta es una descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato. descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato.",
		"b": false,
		"c": "lpfnuchn",
		"g": "Platos a la carta"
	},
	{
		"i": {
			"active": true,
			"req": true,
			"op": [
				{
					"value": 0,
					"title": "Panelada"
				},
				{
					"value": 0,
					"title": "Jugo de mango"
				},
				{
					"title": "Jugo de mora",
					"value": 0
				},
				{
					"value": 1800,
					"title": "Coca-Cola 350ml"
				}
			],
			"title": "¿Que bebida quieres?"
		},
		"g": "Platos a la carta",
		"k": false,
		"a": true,
		"c": "lpfnzwy2",
		"b": false,
		"l": true,
		"j": {
			"op": [],
			"title": "",
			"req": false,
			"max": "",
			"active": false
		},
		"e": "Lomo de cerdo en salsa",
		"f": [
			23000,
			null
		],
		"d": lpfnzwy2,
		"h": "Esta es una descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato. descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato."
	},
	{
		"k": false,
		"j": {
			"active": true,
			"op": [
				{
					"value": 0,
					"title": "De tomate"
				},
				{
					"title": "Mayonesa",
					"value": 0
				},
				{
					"value": 0,
					"title": "Tártara"
				}
			],
			"max": 3,
			"req": false,
			"title": "¿Que tipo de salsa quieres?"
		},
		"c": "lpfo33s0",
		"h": "Esta es una descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato. descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato.",
		"b": false,
		"i": {
			"active": false,
			"req": false,
			"op": [],
			"title": ""
		},
		"g": "Platos a la carta",
		"e": "Arroz con pollo",
		"l": true,
		"d": lpfo33s0,
		"f": [
			15500,
			14700
		],
		"a": true
	},
	{
		"a": true,
		"k": false,
		"b": false,
		"c": "lpfoa24h",
		"h": "Esta es una descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato. descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato.",
		"e": "Mojarra frita",
		"f": [
			38000,
			35500
		],
		"j": {
			"title": "",
			"max": "",
			"active": false,
			"op": [],
			"req": false
		},
		"d": lpfoa24h,
		"i": {
			"active": true,
			"op": [
				{
					"value": 0,
					"title": "Jugo de mora en agua"
				},
				{
					"value": 500,
					"title": "Jugo de mora en leche"
				},
				{
					"title": "Jugo de maracuyá en agua",
					"value": 0
				},
				{
					"value": 500,
					"title": "Jugo de maracuyá en leche"
				},
				{
					"value": 1800,
					"title": "Coca-Cola"
				},
				{
					"value": 2500,
					"title": "Cerveza Corona"
				}
			],
			"req": true,
			"title": "Bebidas"
		},
		"l": true,
		"g": "Platos a la carta"
	},
	{
		"a": true,
		"g": "Desayunos",
		"c": "lpfog27i",
		"l": false,
		"b": false,
		"h": "Esta es una descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato. descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato.",
		"j": {
			"active": true,
			"req": false,
			"title": "¿Algún adicional?",
			"op": [
				{
					"value": 2500,
					"title": "Queso"
				},
				{
					"title": "Almojabana",
					"value": 1800
				},
				{
					"value": 600,
					"title": "2 Panes de hojaldre"
				}
			],
			"max": 3
		},
		"k": false,
		"f": [
			6500,
			null
		],
		"d": lpfog27i,
		"e": "Tamal tolimense",
		"i": {
			"active": true,
			"req": true,
			"title": "Acompañamiento",
			"op": [
				{
					"title": "Queso",
					"value": 0
				},
				{
					"title": "Almojabana",
					"value": 0
				},
				{
					"value": 0,
					"title": "3 panes hojaldrados"
				}
			]
		}
	},
	{
		"c": "lpfojnhf",
		"e": "Changua",
		"d": lpfojnhf,
		"f": [
			8600,
			null
		],
		"l": true,
		"g": "Desayunos",
		"a": true,
		"i": {
			"op": [
				{
					"value": 0,
					"title": "2 Tostadas de ajo"
				},
				{
					"value": 0,
					"title": "3 Galletas saltinas"
				}
			],
			"title": "Acompañamiento",
			"active": true,
			"req": true
		},
		"j": {
			"active": true,
			"title": "Adicionales",
			"req": false,
			"op": [
				{
					"value": 1000,
					"title": "2 Tostadas"
				},
				{
					"title": "1 Huevo ",
					"value": 700
				},
				{
					"title": "Naranjada",
					"value": 2000
				}
			],
			"max": 3
		},
		"h": "Esta es una descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato. descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato.",
		"b": false,
		"k": false
	},
	{
		"d": lpfono9r,
		"k": false,
		"l": true,
		"f": [
			7000,
			null
		],
		"j": {
			"title": "",
			"req": false,
			"max": "",
			"active": false,
			"op": []
		},
		"e": "Chocolate con queso",
		"h": "Esta es una descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato. descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato.",
		"g": "Desayunos",
		"i": {
			"req": true,
			"title": "Acompañamiento",
			"op": [
				{
					"value": 0,
					"title": "2 Tostadas"
				},
				{
					"value": 0,
					"title": "2 Pan rollo"
				}
			],
			"active": true
		},
		"a": true,
		"b": false,
		"c": "lpfono9r"
	},
	{
		"a": true,
		"d": lpfoqlwn,
		"k": true,
		"l": true,
		"c": "lpfoqlwn",
		"f": [
			16000,
			14500
		],
		"h": "Esta es una descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato. descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato.",
		"b": true,
		"j": {
			"req": false,
			"op": [],
			"active": false,
			"title": "",
			"max": ""
		},
		"i": {
			"title": "Acompañamiento",
			"req": true,
			"active": true,
			"op": [
				{
					"value": 0,
					"title": "Ensalada de aguacate"
				},
				{
					"title": "Guacamole",
					"value": 0
				},
				{
					"title": "1 Tostada",
					"value": 0
				}
			]
		},
		"e": "Burrito de huevo",
		"g": "Desayunos"
	},
	{
		"b": false,
		"c": "lpfotd2c",
		"a": true,
		"k": false,
		"l": true,
		"d": lpfotd2c,
		"h": "Esta es una descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato. descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato.",
		"g": "Desayunos",
		"e": "Huevos fritos ",
		"j": {
			"active": false,
			"title": "",
			"req": false,
			"max": "",
			"op": []
		},
		"f": [
			7000,
			null
		],
		"i": {
			"req": true,
			"op": [
				{
					"value": 0,
					"title": "Arroz blanco"
				},
				{
					"title": "Arroz con pollo",
					"value": 2000
				},
				{
					"title": "Tocineta",
					"value": 1500
				}
			],
			"active": true,
			"title": "Acompañamiento"
		}
	},
	{
		"e": "Torta de chocolate",
		"k": false,
		"l": true,
		"h": "Esta es una descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato. descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato.",
		"c": "lpfp1yes",
		"j": {
			"max": "",
			"title": "",
			"active": false,
			"req": false,
			"op": []
		},
		"g": "Postres",
		"b": true,
		"f": [
			45000,
			null
		],
		"a": true,
		"d": lpfp1yes,
		"i": {
			"active": false,
			"op": [],
			"req": false,
			"title": ""
		}
	},
	{
		"d": lpfp3egk,
		"b": false,
		"a": true,
		"e": "Postre de maracuyá",
		"l": true,
		"h": "Esta es una descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato. descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato.",
		"i": {
			"title": "",
			"active": false,
			"op": [],
			"req": false
		},
		"c": "lpfp3egk",
		"j": {
			"max": "",
			"req": false,
			"title": "",
			"op": [],
			"active": false
		},
		"g": "Postres",
		"k": false,
		"f": [
			4500,
			null
		]
	},
	{
		"d": lpfp5sgy,
		"j": {
			"active": false,
			"req": false,
			"max": "",
			"op": [],
			"title": ""
		},
		"c": "lpfp5sgy",
		"a": true,
		"b": false,
		"h": "Esta es una descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato. descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato.",
		"k": false,
		"l": true,
		"g": "Postres",
		"e": "Mora y galleta",
		"f": [
			46000,
			42000
		],
		"i": {
			"title": "",
			"active": false,
			"op": [],
			"req": false
		}
	},
	{
		"b": false,
		"e": "Copa de gelatina con fresa",
		"k": false,
		"c": "lpfp82up",
		"j": {
			"active": false,
			"op": [],
			"req": false,
			"title": "",
			"max": ""
		},
		"g": "Postres",
		"a": true,
		"i": {
			"active": false,
			"op": [],
			"title": "",
			"req": false
		},
		"d": lpfp82up,
		"f": [
			7500,
			null
		],
		"l": true,
		"h": "Esta es una descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato. descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato."
	},
	{
		"b": false,
		"g": "Postres",
		"a": true,
		"i": {
			"title": "",
			"op": [],
			"req": false,
			"active": false
		},
		"f": [
			3800,
			null
		],
		"c": "lpfpb2i0",
		"e": "Gelatina festival",
		"l": true,
		"k": false,
		"h": "Esta es una descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato. descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato.",
		"j": {
			"title": "",
			"max": "",
			"op": [],
			"req": false,
			"active": false
		},
		"d": lpfpb2i0,
	},
	{
		"d": lpfpcu37,
		"i": {
			"active": false,
			"req": false,
			"op": [],
			"title": ""
		},
		"b": false,
		"h": "Esta es una descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato. descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato.",
		"j": {
			"title": "",
			"max": "",
			"req": false,
			"op": [],
			"active": false
		},
		"k": false,
		"g": "Postres",
		"f": [
			45000,
			null
		],
		"c": "lpfpcu37",
		"a": true,
		"e": "Torta de tiramisu",
		"l": true
	},
	{
		"f": [
			3500,
			null
		],
		"j": {
			"title": "",
			"req": false,
			"active": false,
			"max": "",
			"op": []
		},
		"i": {
			"title": "",
			"req": false,
			"op": [],
			"active": false
		},
		"h": "Esta es una descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato. descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato.",
		"k": false,
		"d": lpfpg93h,
		"l": false,
		"e": "Coca-Cola en lata",
		"c": "lpfpg93h",
		"b": false,
		"a": true,
		"g": "Gaseosas"
	},
	{
		"a": true,
		"i": {
			"title": "",
			"req": false,
			"op": [],
			"active": false
		},
		"j": {
			"title": "",
			"max": "",
			"req": false,
			"active": false,
			"op": []
		},
		"h": "Esta es una descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato. descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato.",
		"e": "Manzana Postobón",
		"l": false,
		"g": "Gaseosas",
		"k": false,
		"b": false,
		"c": "lpfpk495",
		"f": [
			2800,
			null
		],
		"d": lpfpk495,
	},
	{
		"i": {
			"active": false,
			"title": "",
			"req": false,
			"op": []
		},
		"a": true,
		"h": "Esta es una descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato. descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato.",
		"k": false,
		"j": {
			"req": false,
			"active": false,
			"title": "",
			"op": [],
			"max": ""
		},
		"c": "lpfpm45f",
		"f": [
			2500,
			null
		],
		"g": "Gaseosas",
		"d": lpfpm45f,
		"b": false,
		"e": "Colombiana Postobón",
		"l": false
	},
	{
		"c": "lpfpodha",
		"b": false,
		"k": false,
		"h": "Esta es una descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato. descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato.",
		"d": lpfpodha,
		"i": {
			"op": [],
			"title": "",
			"req": false,
			"active": false
		},
		"g": "Gaseosas",
		"j": {
			"max": "",
			"op": [],
			"req": false,
			"title": "",
			"active": false
		},
		"e": "Pepsi",
		"l": false,
		"a": true,
		"f": [
			3500,
			null
		]
	},
	{
		"i": {
			"op": [
				{
					"value": 0,
					"title": "Colombiana 350ml"
				},
				{
					"title": "Manzana 350ml",
					"value": 0
				},
				{
					"title": "Bretaña 250ml",
					"value": 0
				}
			],
			"req": true,
			"active": true,
			"title": "¿Qué bebida quieres?"
		},
		"f": [
			35000,
			33000
		],
		"a": true,
		"g": "Hamburguesas",
		"b": false,
		"j": {
			"active": true,
			"max": 3,
			"req": false,
			"title": "Salsas",
			"op": [
				{
					"title": "De la casa",
					"value": 0
				},
				{
					"title": "De tomate",
					"value": 0
				},
				{
					"title": "Mayonesa",
					"value": 0
				},
				{
					"title": "Tártara",
					"value": 0
				}
			]
		},
		"d": lpfr9xyw,
		"e": "Doble carne",
		"l": true,
		"k": false,
		"c": "lpfr9xyw",
		"h": "Esta es una descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato. descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato."
	},
	{
		"a": true,
		"c": "lpfrd7ou",
		"g": "Hamburguesas",
		"f": [
			29000,
			27500
		],
		"b": false,
		"i": {
			"req": true,
			"active": true,
			"title": "¿Qué bebida quieres?",
			"op": [
				{
					"value": 1500,
					"title": "Coca-Cola"
				},
				{
					"value": 0,
					"title": "Manzana Postobón"
				},
				{
					"value": 0,
					"title": "Colombiana Postobón"
				},
				{
					"value": 0,
					"title": "Limonada natural"
				}
			]
		},
		"h": "Esta es una descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato. descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato.",
		"j": {
			"max": 2,
			"op": [
				{
					"title": "De la casa",
					"value": 0
				},
				{
					"value": 0,
					"title": "De ajo"
				},
				{
					"value": 0,
					"title": "Mayonesa"
				},
				{
					"title": "Tártara",
					"value": 0
				}
			],
			"req": false,
			"title": "Salsas",
			"active": true
		},
		"d": lpfrd7ou,
		"k": false,
		"e": "De la casa",
		"l": true
	},
	{
		"k": false,
		"l": true,
		"i": {
			"req": true,
			"op": [
				{
					"value": 0,
					"title": "Colombiana 350ml"
				},
				{
					"value": 0,
					"title": "Manzana 350ml"
				},
				{
					"value": 0,
					"title": "Uva 350ml"
				},
				{
					"title": "Coca-Cola 350ml",
					"value": 1600
				}
			],
			"active": true,
			"title": "Bebidas"
		},
		"h": "Esta es una descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato. descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato.",
		"f": [
			25000,
			22500
		],
		"c": "lpfrgrl3",
		"e": "De pollo",
		"g": "Hamburguesas",
		"j": {
			"max": 2,
			"active": true,
			"req": false,
			"title": "Salsas",
			"op": [
				{
					"title": "Mayonesa",
					"value": 0
				},
				{
					"value": 0,
					"title": "Tártara"
				},
				{
					"title": "De la casa",
					"value": 0
				}
			]
		},
		"a": true,
		"d": lpfrgrl3,
		"b": true
	},
	{
		"a": true,
		"j": {
			"op": [
				{
					"value": 2500,
					"title": "Chips de chocolate"
				},
				{
					"value": 1000,
					"title": "Porción adicional de papitas"
				},
				{
					"value": 5500,
					"title": "Malteada de fresa"
				}
			],
			"active": true,
			"title": "Golosinas",
			"max": 3,
			"req": false
		},
		"e": "Infantil",
		"h": "Esta es una descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato. descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato.",
		"d": lpfrk9p0,
		"f": [
			15000,
			13500
		],
		"b": false,
		"c": "lpfrk9p0",
		"l": true,
		"k": false,
		"i": {
			"op": [
				{
					"value": 0,
					"title": "Limonada natural"
				},
				{
					"title": "Ponymalta",
					"value": 1200
				},
				{
					"title": "Manzana Postobón 250ml",
					"value": 0
				},
				{
					"value": 0,
					"title": "Colombiana Postobón 250ml"
				}
			],
			"active": true,
			"title": "Bebidas",
			"req": true
		},
		"g": "Hamburguesas"
	},
	{
		"b": false,
		"l": true,
		"j": {
			"title": "Salsas",
			"req": false,
			"active": true,
			"op": [
				{
					"title": "De tomate",
					"value": 0
				},
				{
					"value": 0,
					"title": "Mayonesa"
				},
				{
					"value": 0,
					"title": "Tártara"
				},
				{
					"title": "De ajo",
					"value": 0
				}
			],
			"max": 2
		},
		"g": "Hamburguesas",
		"a": true,
		"k": false,
		"i": {
			"title": "Bebidas",
			"active": true,
			"op": [
				{
					"value": 0,
					"title": "Limonada natural"
				},
				{
					"title": "Cerveza Águila",
					"value": 2000
				},
				{
					"value": 800,
					"title": "Colombiana Postobón 350ml"
				},
				{
					"value": 800,
					"title": "Manzana Postobón 350ml"
				}
			],
			"req": true
		},
		"f": [
			27000,
			25000
		],
		"d": lpfropr7,
		"h": "Esta es una descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato. descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato.",
		"c": "lpfropr7",
		"e": "Vegana"
	},
	{
		"k": false,
		"b": false,
		"h": "Esta es una descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato. descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato.",
		"a": true,
		"d": lpfrsh2x,
		"l": true,
		"c": "lpfrsh2x",
		"j": {
			"title": "Salsas",
			"req": false,
			"active": true,
			"op": [
				{
					"title": "De tomate",
					"value": 0
				},
				{
					"value": 0,
					"title": "Mayonesa"
				},
				{
					"title": "Tártara",
					"value": 0
				}
			],
			"max": 2
		},
		"e": "La parrillada",
		"f": [
			33000,
			31500
		],
		"g": "Hamburguesas",
		"i": {
			"active": true,
			"title": "Bebidas",
			"op": [
				{
					"title": "Colombiana Postobón 350ml",
					"value": 0
				},
				{
					"title": "Manzana Postobón 350ml",
					"value": 0
				},
				{
					"title": "Uva Postobón 350ml",
					"value": 0
				},
				{
					"title": "Coca-Cola personal",
					"value": 1500
				}
			],
			"req": true
		}
	},
	{
		"b": false,
		"a": true,
		"k": false,
		"j": {
			"op": [],
			"req": false,
			"max": "",
			"title": "",
			"active": false
		},
		"i": {
			"op": [
				{
					"title": "Jarra de limonada 1 L",
					"value": 0
				},
				{
					"title": "Refajo",
					"value": 12000
				}
			],
			"req": true,
			"title": "Bebidas",
			"active": true
		},
		"f": [
			28000,
			null
		],
		"h": "Esta es una descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato. descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato.",
		"e": "1/2 Pollo broaster",
		"l": true,
		"g": "Para 2 personas",
		"c": "lpft14hd",
		"d": lpft14hd,
	},
	{
		"b": false,
		"l": true,
		"f": [
			24500,
			23000
		],
		"a": true,
		"h": "Esta es una descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato. descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato.",
		"d": lpft4iju,
		"e": "Salchipapas especial",
		"c": "lpft4iju",
		"i": {
			"title": "Bebidas",
			"active": false,
			"op": [],
			"req": false
		},
		"k": false,
		"g": "Para 2 personas",
		"j": {
			"req": true,
			"active": true,
			"title": "Bebidas ",
			"max": 2,
			"op": [
				{
					"value": 0,
					"title": "Colombiana 350ml"
				},
				{
					"value": 0,
					"title": "Manzana 350ml"
				},
				{
					"value": 0,
					"title": "Uva 350ml"
				},
				{
					"title": "Limonada natural",
					"value": 0
				}
			]
		}
	},
	{
		"a": true,
		"g": "Para 2 personas",
		"e": "Arroz chino",
		"h": "Esta es una descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato. descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato.",
		"f": [
			24000,
			null
		],
		"c": "lpft8v84",
		"j": {
			"max": 2,
			"active": true,
			"title": "Salsas",
			"op": [
				{
					"title": "Salsa negra",
					"value": 0
				},
				{
					"value": 0,
					"title": "Tomate"
				},
				{
					"value": 0,
					"title": "Mayonesa"
				}
			],
			"req": true
		},
		"d": lpft8v84,
		"i": {
			"active": true,
			"title": "Bebidas",
			"req": true,
			"op": [
				{
					"title": "Manzana 1.5 L",
					"value": 0
				},
				{
					"value": 0,
					"title": "Colombiana 1.5 L"
				},
				{
					"value": 0,
					"title": "Uva 1.5 L"
				},
				{
					"value": 2000,
					"title": "Coca-Cola 1.5 L"
				}
			]
		},
		"b": false,
		"k": true,
		"l": true
	},
	{
		"h": "Esta es una descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato. descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato.",
		"e": "Ajiaco",
		"c": "lpftc7vm",
		"l": true,
		"d": lpftc7vm,
		"b": true,
		"i": {
			"req": false,
			"active": false,
			"op": [],
			"title": ""
		},
		"a": true,
		"f": [
			12000,
			null
		],
		"k": false,
		"j": {
			"active": true,
			"req": false,
			"op": [
				{
					"title": "Adicional pollo desmechado",
					"value": 1500
				},
				{
					"title": "Arroz con pollo",
					"value": 4000
				},
				{
					"title": "Arroz blanco",
					"value": 1000
				}
			],
			"title": "Porciones",
			"max": 3
		},
		"g": "Sopas"
	},
	{
		"h": "Esta es una descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato. descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato.",
		"g": "Sopas",
		"l": true,
		"k": false,
		"j": {
			"title": "",
			"req": false,
			"active": false,
			"op": [],
			"max": ""
		},
		"e": "De lentejas",
		"i": {
			"active": false,
			"title": "",
			"req": false,
			"op": []
		},
		"f": [
			7000,
			6500
		],
		"d": lpftdp9h,
		"b": false,
		"c": "lpftdp9h",
		"a": true
	},
	{
		"k": false,
		"d": lpftepxt,
		"a": true,
		"l": true,
		"f": [
			12000,
			null
		],
		"b": false,
		"e": "De tomate",
		"g": "Sopas",
		"i": {
			"active": false,
			"op": [],
			"req": false,
			"title": ""
		},
		"c": "lpftepxt",
		"h": "Esta es una descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato. descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato.",
		"j": {
			"op": [],
			"max": "",
			"req": false,
			"title": "",
			"active": false
		}
	},
	{
		"k": false,
		"f": [
			8000,
			null
		],
		"c": "lpftfogd",
		"b": false,
		"l": true,
		"h": "Esta es una descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato. descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato.",
		"g": "Sopas",
		"d": lpftfogd,
		"j": {
			"max": "",
			"active": false,
			"op": [],
			"title": "",
			"req": false
		},
		"a": true,
		"i": {
			"active": false,
			"req": false,
			"title": "",
			"op": []
		},
		"e": "De cuchuco"
	},
	{
		"c": "lpftghuj",
		"b": false,
		"j": {
			"req": false,
			"op": [],
			"title": "",
			"active": false,
			"max": ""
		},
		"d": lpftghuj,
		"k": false,
		"i": {
			"req": false,
			"active": false,
			"op": [],
			"title": ""
		},
		"a": true,
		"h": "Esta es una descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato. descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato.",
		"f": [
			15000,
			null
		],
		"e": "De menudencias",
		"l": true,
		"g": "Sopas"
	},
	{
		"h": "Esta es una descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato. descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato.",
		"b": false,
		"f": [
			54000,
			null
		],
		"a": true,
		"e": "Pollo con champiñones",
		"g": "Pizzas",
		"k": false,
		"j": {
			"op": [],
			"title": "",
			"max": "",
			"req": false,
			"active": false
		},
		"l": true,
		"c": "lpgb1lfu",
		"d": lpgb1lfu,
		"i": {
			"req": true,
			"title": "Bebidas",
			"active": true,
			"op": [
				{
					"title": "Manzana 1.5 L",
					"value": 0
				},
				{
					"value": 0,
					"title": "Colombiana 1.5 L"
				},
				{
					"title": "Uva 1.5 L",
					"value": 0
				},
				{
					"title": "Coca-Cola 1.5 L",
					"value": 4000
				}
			]
		}
	},
	{
		"g": "Pizzas",
		"e": "Peperoni",
		"b": false,
		"j": {
			"req": false,
			"active": false,
			"title": "",
			"op": [],
			"max": ""
		},
		"h": "Esta es una descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato. descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato.",
		"k": false,
		"d": lpgb75hx,
		"l": true,
		"f": [
			51500,
			null
		],
		"i": {
			"title": "Bebidas",
			"active": true,
			"req": true,
			"op": [
				{
					"value": 0,
					"title": "Manzana 1.5 L"
				},
				{
					"value": 0,
					"title": "Colombiana 1.5 L"
				},
				{
					"value": 0,
					"title": "Uva 1.5 L"
				},
				{
					"title": "Coca-Cola 1.5 L",
					"value": 4000
				}
			]
		},
		"a": true,
		"c": "lpgb75hx"
	},
	{
		"e": "Napolitana",
		"i": {
			"op": [
				{
					"title": "Manzana Postobón 1.5L",
					"value": 0
				},
				{
					"value": 0,
					"title": "Colombiana Postobón 1.5L"
				},
				{
					"title": "Coca-Cola 1.5L",
					"value": 4000
				}
			],
			"title": "Bebidas",
			"active": true,
			"req": true
		},
		"g": "Pizzas",
		"a": true,
		"h": "Esta es una descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato. descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato.",
		"k": false,
		"c": "lpgb9yns",
		"d": lpgb9yns,
		"l": true,
		"f": [
			53000,
			51000
		],
		"b": false,
		"j": {
			"active": false,
			"req": false,
			"op": [],
			"title": "",
			"max": ""
		}
	},
	{
		"i": {
			"req": true,
			"title": "Bebidas",
			"op": [
				{
					"title": "Manzana Postobón 1.5L",
					"value": 0
				},
				{
					"value": 0,
					"title": "Colombiana Postobón 1.5L"
				},
				{
					"value": 4000,
					"title": "Coca-Cola 1.5L"
				}
			],
			"active": true
		},
		"c": "lpgbcdwj",
		"g": "Pizzas",
		"e": "Vegetariana",
		"k": false,
		"d": lpgbcdwj,
		"j": {
			"req": false,
			"max": "",
			"active": false,
			"title": "",
			"op": []
		},
		"l": true,
		"a": true,
		"b": false,
		"f": [
			34000,
			null
		],
		"h": "Esta es una descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato. descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato."
	},
	{
		"e": "Tres quesos",
		"j": {
			"title": "Elige los quesos",
			"max": 3,
			"op": [
				{
					"title": "Mozarella",
					"value": 0
				},
				{
					"value": 0,
					"title": "Parmesano"
				},
				{
					"title": "Gorzonla",
					"value": 8000
				},
				{
					"title": "Fontina",
					"value": 12000
				},
				{
					"title": "Doble crema",
					"value": 0
				}
			],
			"active": true,
			"req": true
		},
		"l": true,
		"g": "Pizzas",
		"a": true,
		"k": false,
		"b": false,
		"d": lpgbgusb,
		"h": "Esta es una descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato. descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato.",
		"c": "lpgbgusb",
		"i": {
			"active": true,
			"title": "Bebidas",
			"op": [
				{
					"value": 0,
					"title": "Colombiana Postobón 1.5L"
				},
				{
					"title": "Manzana 1.5L",
					"value": 0
				},
				{
					"title": "Coca-Cola 1.5L",
					"value": 4000
				}
			],
			"req": true
		},
		"f": [
			56000,
			52400
		]
	},
	{
		"l": true,
		"g": "Platos a la carta",
		"j": {
			"op": [
				{
					"value": 2500,
					"title": "2 Patacones"
				},
				{
					"title": "Porción papa francesa",
					"value": 3000
				}
			],
			"req": true,
			"max": 2,
			"title": "Adicionales",
			"active": true
		},
		"h": "Esta es una descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato. descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato.",
		"c": "lpgbkj8n",
		"e": "Sobrebarriga",
		"b": false,
		"d": lpgbkj8n,
		"i": {
			"req": true,
			"active": true,
			"title": "Bebidas",
			"op": [
				{
					"title": "Limonada de la casa",
					"value": 0
				},
				{
					"value": 0,
					"title": "Colombiana 250ml"
				},
				{
					"title": "Manzana 250ml",
					"value": 0
				}
			]
		},
		"k": false,
		"a": true,
		"f": [
			16500,
			14500
		]
	},
	{
		"f": [
			23000,
			18600
		],
		"b": false,
		"l": true,
		"e": "Bagre en salsa",
		"h": "Esta es una descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato. descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato.",
		"d": lpgbmypq,
		"j": {
			"req": false,
			"op": [],
			"title": "",
			"active": false,
			"max": ""
		},
		"i": {
			"active": true,
			"title": "Bebidas",
			"req": true,
			"op": [
				{
					"title": "Limonada natural",
					"value": 0
				},
				{
					"value": 0,
					"title": "Manzana Postobón 250ml"
				},
				{
					"title": "Colombiana Postobón 250ml",
					"value": 0
				},
				{
					"value": 2000,
					"title": "Jarra de Panelada 1L"
				}
			]
		},
		"a": true,
		"g": "Platos a la carta",
		"c": "lpgbmypq",
		"k": false
	},
	{
		"i": {
			"title": "Bebidas",
			"op": [
				{
					"value": 0,
					"title": "Manzana Postobón 1.5L"
				},
				{
					"title": "Colombiana Postobón 1.5L",
					"value": 0
				},
				{
					"value": 0,
					"title": "Jarra de limonada 1L"
				}
			],
			"req": true,
			"active": true
		},
		"j": {
			"op": [
				{
					"title": "Refajo",
					"value": 17500
				},
				{
					"value": 3500,
					"title": "1 Chorizo"
				},
				{
					"title": "Porción papa criolla",
					"value": 4500
				},
				{
					"title": "1 Rellena",
					"value": 4200
				},
				{
					"value": 2200,
					"title": "1 Mazorca"
				}
			],
			"active": true,
			"req": true,
			"title": "Adicionales",
			"max": 5
		},
		"e": "Picada tradicional",
		"c": "lpgbruyr",
		"h": "Esta es una descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato. descripción de relleno para tus productos, puedes detallar los ingredientes y preparación de tu plato.",
		"g": "Para 2 personas",
		"k": true,
		"l": true,
		"b": true,
		"a": true,
		"f": [
			43000,
			null
		],
		"d": lpgbruyr,
	}
]