import React from 'react'
import MainFreeTheme from '../../freetemplate/MainFreeTheme'
import BgMenu from './BgMenu'
import EditCategory from './EditCategory'

const IceCreamII = () => {
  return (
    <MainFreeTheme
      bgMenu={<BgMenu />}
      editCategory={<EditCategory />}
      twoColumns={false}
    />
  )
}

export default IceCreamII