import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CreditModal from './CreditModal'
import Expiration from './Expiration'
import CardsNovelties from './CardsNovelties'
import postImg from '../../../../img/dashboard/btn_post_download.svg'
import stateImg from '../../../../img/dashboard/btn_state_download.svg'
import { resetAllPost } from '../../../../redux/reducer/post'
import { setVideo } from '../../../../redux/reducer/interactions'
import checkVideo from '../../../../firebase/update/checkVideo'





const Novelties = () => {
  

  const {name, credits, active, userID, videos} = useSelector(state => state.log)
  const dispatch = useDispatch()

  const currentYear = new Date().getFullYear()
  

  const sendToPost = ()=>{
    dispatch(resetAllPost())
    document.querySelector('#post-tab').click()
  }
  
  const sendToState = ()=>{
    dispatch(resetAllPost())
    document.querySelector('#state-tab').click()

  }

  const handleVideo = ()=> {
    dispatch(setVideo(1))
    if(!videos[0]){
      checkVideo(userID, videos, 0)
    }
  }



  return (
    <>
      <section className='p-3'>
        <div className="bg-b1 rounded-lg">
          <div className="row">
            <div className="col-xs-6 col-lg-5 align-self-end">
              <div className="plateDash">
                <div className="phrase"></div>
                <div className="img"></div>
              </div>
            </div>
            <div className="col-xs-6 col-lg-7 align-self-center order-xs-first order-md-last px-4 pb-3 pt-0 pt-md-3">
              <div className="row">
                <div className="col-md-6 align-self-center">
                  <h3 className="text-apple mb-0 mt-4 mt-md-0">Digitalizando <br/> comidas</h3>
                </div>
                <div className="col-md-6 order-first order-md-last">
                  <div className={`bg-b2 p-2 rounded text-center ${credits >=1 ? '' : 'inactiveCredits'}`}>
                    {
                      credits >=1 ? 
                      <span className='d-block'>Tienes<span className='credits-text'>{credits || '---'}</span> {credits === 1 ? 'crédito': 'créditos'}</span>
                      :
                      <span className="d-block">No pierdas la oportunidad de prestar un mejor servicio a tus clientes</span>
                    }
                    <button
                      className="addTime"
                      data-bs-toggle="modal"
                      data-bs-target="#paymentModalDash"
                    >Recargar créditos</button>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-11 mt-2 mt-md-4">
                {
                  credits >=1 ? 
                  <p>Hola <span className='credits-text text-capitalize'>{name || 'usuario'}</span> ¿que tal?, nuestra plataforma te ofrece una solución innovadora y práctica para actualizar tus menús de manera constante. Con tan solo unos clics, podrás mantener tus ofertas actualizadas y sorprender a tus clientes con una experiencia moderna y atractiva.</p>
                  :
                  <p>Hola <span className='credits-text text-capitalize'>{name || 'usuario'}</span>. Es una pena que se hayan agotado tus créditos FOODER, la buena noticia es que estás a tiempo de restablecer todos nuestros servicios <span className='text-apple'>AGREGANDO CRÉDITOS</span> a tu menú digital. Trabamos constantemente por una excelente experiencia en el proceso.</p>
                }
              </div>
            </div>
          </div>
        </div>
        {
          credits >= 1 && (
            <div className="row mt-4">
              <div className="col-12 text-center text-md-start mb-4 mb-md-0">
                <button
                  className={`btnVideo ${!videos[0] ? 'active': ''}`}
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#tutorialModal"
                  onClick={handleVideo}
                >Ver tutorial de este módulo</button>
              </div>
            </div>
          )
        }

        {
          credits >= 1 ? 
          (
            <div className="row justify-content-center mb-4">
              <div className="col-md-12">
                <CardsNovelties />
              </div>
              <div className="col-md-12 my-4">
                <div className="row">
                  <div className="col-md-6 align-self-center">
                    <h5 className=' my-3 text-apple'>Descarga de imágenes ilimitada</h5>
                    <p>Promociona tus productos en las redes sociales, ofrecemos dos tamaños de imagen que se adecuan a tus necesidades. <strong className='text-white'>¡Descúbrelos!</strong></p>
                  </div>
                  <div className="col-md-6 align-self-center">
                    <div className="postSectionNovelties">
                      <button type='button' onClick={sendToPost}>
                        <img src={postImg} alt="postimg" className='img-fluid'/>
                        <span>Post</span>
                      </button>
                      <button type='button' onClick={sendToState}>
                        <img src={stateImg} alt="postimg" className='img-fluid'/>
                        <span>Estados</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>



              <div className="col-12 mt-3 text-center mb-5">
                <small>Copyright FOODER {currentYear} | Digitaliza tus comidas | - Todos los derechos reservados</small>
                <div>
                  <small><strong className="text-green">Versión 1.0.1</strong></small>
                </div>
              </div>
            </div>
          )
          :
          <Expiration/>
        }

      </section>
      <CreditModal />
    </>
  )
}

export default Novelties


