import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import firstCat from '../../dummi/source-categories'


const Categories = ({cat, editCategory}) => {

  const {menu, searching} = useSelector(state => state.user)
  const [allCat, setAllCat] = useState([])

  useEffect(()=>{
    if(menu){
      if(menu?.p?.length === 0){
        setAllCat(firstCat)
      }else{
        setAllCat(menu?.n)
      }
    }

  },[menu])

  

  


  const goCategory = cat =>{
    let main = document.querySelector('main')
    let ref = main.querySelector(`[data-ref='${cat}']`)
    main.scrollTop = ref.offsetTop -120
  }

  


  return (
    <>
      <div className={`showCategories ${searching.length > 0 ? 'disabledC': ''}`} ref={cat}>
        {
          allCat.map((el,index) => (
            <button
              key={`categoryButton-${index}`}
              onClick={() =>goCategory(el.name)}
              type='button'
              data-enableswipe='true'
            >{el.name}</button>
          ))
        }
      </div>
      {editCategory}
      <div className={`px-4 ${searching.length > 0 ? 'mt-4': ''}`}>
        <h2 className='mt-5 mb-4 pt-3'>{menu?.q ? menu?.q : '¿Cuál es tu antojo hoy?' }</h2>
      </div>
    {
      searching.length === 0 ? (
        null
      ): <div className="mt-3" />
    }
    </>
  )
}

export default Categories