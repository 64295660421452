import React from 'react'

const WhoAreYou = ({aboutMe,counter}) => {

  return (
    <>
      <div className="bg-b1 p-3 rounded-lg">
        <div className="d-flex align-items-center justify-content-between">
          <p className="text-bold text-apple m-0">Describe quién eres <small className='text-white'>(No dejes espacios en blanco)</small></p>
          <span className='optionalLabel'>Opcional</span>
        </div>
        <textarea
          className={`textareaDash mt-3 ${counter > 300 ? 'text-orange': ''}`}
          name="whoAmI"
          rows="5"
          placeholder='¿Qué hace la empresa? ó ¿Para quién está dirigida? '
          {...aboutMe}
        ></textarea>
        <div className="text-end">
          <span className={`${counter > 300 ? 'text-orange': ''}`}>{counter}/300</span>
        </div>
      </div>
    </>
  )
}

export default WhoAreYou