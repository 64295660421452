import React from 'react'
import { useSelector } from 'react-redux'

const LoadingSent = () => {

  const {loadSendMenu} = useSelector(s => s.user)

  return (
    <div className={`loadMenu ${loadSendMenu ? 'active' : ''}`}><div></div></div>
  )
}

export default LoadingSent