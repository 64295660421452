import React from 'react'
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { formatQuantity } from '../../../tools/formatQuantity'
import visitors from '../../../../img/dashboard/visitors.svg'


Chart.register(ArcElement, Tooltip, Legend)


const CardsNovelties = () => {

  const {listProducts, listCategories} = useSelector(state => state.menu)
  const {cuota, visitCounter} = useSelector(s => s.log)
  const {maxProductLength, limitCategories, counterOrderFromMenu, customerListLimit,customerList} = useSelector(state => state.log)

  const productsData = {
    datasets: [
      {
        data: [
          ((listProducts?.length * 100) / maxProductLength), 
          (100 - ((listProducts?.length * 100) / maxProductLength))
        ],
        backgroundColor: ['#8bf4ac', '#4c4b56'],
        borderWidth: 0,
      },
    ],
  };

  const categoriesData = {
    datasets: [
      {
        data: [((listCategories?.length * 100)/limitCategories), (100-((listCategories?.length * 100)/limitCategories))],
        backgroundColor: ['#8bf4ac', '#4c4b56'],
        borderWidth: 0,
      },
    ],
  };
  const orderData = {
    datasets: [
      {
        data: [((counterOrderFromMenu?.a * 100)/counterOrderFromMenu?.b), (100-(((counterOrderFromMenu?.a * 100)/counterOrderFromMenu?.b)))],
        backgroundColor: ['#8bf4ac', '#4c4b56'],
        borderWidth: 0,
      },
    ],
  };


  //-------------------------------------------------------------------
  
  const customerData = {
    datasets: [
      {
        data: [((customerList?.length * 100)/customerListLimit), (100-(((customerList?.length * 100)/customerListLimit)))],
        data: [((customerList?.length * 100)/customerListLimit), (100-(((customerList?.length * 100)/customerListLimit)))],
        backgroundColor: ['#8bf4ac', '#4c4b56'],
        borderWidth: 0,
      },
    ],
  };
  
  const productRange = {
    cutout: '85%',
    events: [], // Deshabilita los eventos, incluido el hover
    plugins: {
      tooltip: {
        enabled: false, // Deshabilita los tooltips
      },
    },
  };

  //-------------------------------------------------------------------

  return (
    <section>
      <div className="d-block d-md-flex text-center text-md-start align-items-center mb-4 mt-3">
        <h5
          className='m-0 text-apple'>
          Capacidad de registros - PLAN {cuota === 1 && 'EMPRENDEDOR' || cuota === 2 && 'CORPORATIVO' || cuota === 3 && 'PREMIUM' }
        </h5>
        <button
          className="activePlan ms-0 ms-md-2 mt-4 mt-md-0"
          data-bs-target='#modalUpdatePlan'
          data-bs-toggle="modal"
        >ACTUALIZAR PLAN</button>
      </div>
      <div className="row">
        <div className="col-md-6 mb-3 ">
          <div className="bg-b1 rounded-2 p-3 p-md-4">
            <div className="row">
              <div className="col-7 align-self-center">
                <div>
                  <h6 className='text-apple'>Cantidad de productos</h6>
                  <p className='mb-0'>Puedes ampliar la capacidad de inscripción de tus productos según el plan.</p>
                </div>
                {
                  maxProductLength === listProducts?.length && (
                    <Link 
                      to='https://api.whatsapp.com/send?phone=573125599832&text=Hola%20!%20quisiera%20ampliar%20mi%20plan%20b%C3%A1sico%20FOODER.'
                      target='_blank'
                      className='text-link-apple'
                    >Actualizar plan</Link>
                  )
                }
              </div>
              <div className="col-5 pb-2">
                <div className="circularBARSection">
                  <Doughnut data={productsData || null} options={productRange || null} />
                  <div className="total">
                    <span className="text-aqua">{listProducts?.length}</span>
                    <span>/{maxProductLength || 0}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 mb-3 ">
          <div className="bg-b1 rounded-2 p-3 p-md-4">
            <div className="row">
              <div className="col-7 align-self-center">
                <h6 className='text-apple'>Categorías del plan</h6>
                <p className='mb-0'>El número de categorías facilita al usuario filtrar los productos de tu menú.</p>
              </div>
              <div className="col-5 pb-2">
                <div className="circularBARSection">
                  <Doughnut data={categoriesData || null} options={productRange || null}/>
                  <div className="total">
                    <span className="text-aqua">{listCategories?.length}</span>
                    <span>/{limitCategories || null}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 mb-3 ">
          <div className="bg-b1 rounded-2 p-3 p-md-4">
            <div className="row">
              <div className="col-7 align-self-center">
                <h6 className='text-apple'>Libreta de clientes</h6>
                <p className='mb-0'>Aprovecha esta herramienta al máximo para mejorar la atención al cliente en las llamadas que recibes.</p>
              </div>
              <div className="col-5 pb-2">
                <div className="circularBARSection">
                  <Doughnut data={customerData || null} options={productRange || null}/>
                  <div className="total">
                    <span className="text-aqua">{customerList?.length}</span>
                    <span>/{customerListLimit || null}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 mb-3">
          <div className="bg-b1 rounded-2 p-3 p-md-4 h-100">
            <div className="d-flex align-items-center h-100">
              <div className="row">
                <div className="col-7 align-self-center ">
                  <h6 className='text-apple'>Contador de visitas</h6>
                  <p className='mb-0'>Esta información te sirve para saber con precisión la efectividad de tus campañas de marketing.</p>
                </div>
                <div className="col-5 pb-2 align-self-center">
                  <h3 className="text-aqua mb-2 text-center">{formatQuantity(visitCounter)}</h3>
                  <div className="text-center">
                    <img src={visitors} alt="visitantes" className='img-fluid' style={{width: '50px'}}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CardsNovelties