import { db } from "../../../../../firebase/credentials";
import { doc, updateDoc } from "firebase/firestore"

const updatePlan = async(userID, plan)=>{

  let  products;
  let  categories;
  let  list;

  if(plan === 0 || plan === undefined || plan === null){
    return false
  }else{
    if(plan === 1) {
      products = 50
      categories = 5
      list = 500
    }
  
    if(plan === 2) {
      products = 130
      categories = 15
      list = 2500
    }
  
    if(plan === 3) {
      products = 300
      categories = 50
      list = 10000
    }
    
    try {
      const refAdmin = doc(db, 'admin', userID)
      await updateDoc(refAdmin, {
        u: plan,
        m: products,
        o: list,
        q: categories,
      })
      return true
    } catch (error) {
      return false
    }
  }


}

export default updatePlan