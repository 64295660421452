// src/components/SellGraph.jsx

import React, { useEffect, useState, useRef } from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import moment from 'moment'
import $ from 'jquery'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
)

const SellGraph = ({ orderReports }) => {
  const selectRef = useRef(null)
  const currentWeek = moment().week()

  const [orderData, setOrderData] = useState({
    labels: ['Dom', 'Lun', 'Mar', 'Miér', 'Jue', 'Vier', 'Sab'],
    datasets: [
      {
        fill: true,
        label: 'En proceso',
        data: Array(7).fill(0),
        borderColor: '#dceeac',
        backgroundColor: '#dceeac25',
      },
      {
        fill: true,
        label: 'Entregados',
        data: Array(7).fill(0),
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.1)',
      },
      {
        fill: true,
        label: 'Cancelados',
        data: Array(7).fill(0),
        borderColor: '#f2864c',
        backgroundColor: '#f2864c25',
      },
      {
        fill: true,
        label: 'Pedidos en espera',
        data: Array(7).fill(0),
        borderColor: '#734be3',
        backgroundColor: '#734be321',
      },
    ],
  })

  const [weeklyData, setWeeklyData] = useState({})
  const [selectedWeek, setSelectedWeek] = useState(currentWeek)

  useEffect(() => {
    if (orderReports) {
      const allWeeksData = {}

      Object.values(orderReports).forEach(order => {
        const orderDate = moment(order.client.date, 'DD/MMM/YYYY HH:mm A')
        const orderWeek = orderDate.week()
        const dayOfWeek = (orderDate.isoWeekday() % 7) // Make Sunday (0) and Saturday (6)
        const state = order.client.state

        if (!allWeeksData[orderWeek]) {
          allWeeksData[orderWeek] = {
            'En proceso': Array(7).fill(0),
            'Entregados': Array(7).fill(0),
            'Cancelados': Array(7).fill(0),
            'Pedidos en espera': Array(7).fill(0),
          }
        }

        if (state === 1 || state === 2 || state === 3) {
          allWeeksData[orderWeek]['En proceso'][dayOfWeek]++
        } else if (state === 4) {
          allWeeksData[orderWeek]['Entregados'][dayOfWeek]++
        } else if (state === 5) {
          allWeeksData[orderWeek]['Cancelados'][dayOfWeek]++
        } else if (state === 0) {
          allWeeksData[orderWeek]['Pedidos en espera'][dayOfWeek]++
        }
      })

      setWeeklyData(allWeeksData)
    }
  }, [orderReports])

  useEffect(() => {
    if (weeklyData[selectedWeek]) {
      const weekData = weeklyData[selectedWeek]
      setOrderData(prevData => ({
        ...prevData,
        datasets: prevData.datasets.map(dataset => ({
          ...dataset,
          data: weekData[dataset.label],
        })),
      }))
    }
  }, [selectedWeek, weeklyData])

  useEffect(() => {
    if (selectRef.current) {
      $(selectRef.current).select2({
        width: '100%',
        dropdownCssClass: 'select2-custom-dropdown',
        language: {
          noResults: function () {
            return "No hay reportes para esos parámetros de búsqueda"
          }
        }
      })

      $(selectRef.current).on('change', (event) => {
        setSelectedWeek(parseInt(event.target.value))
      })
    }
  }, [weeklyData])

  useEffect(() => {
    if (selectRef.current && weeklyData[selectedWeek]) {
      $(selectRef.current).val(selectedWeek).trigger('change')
    }
  }, [weeklyData, selectedWeek])

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        labels: { color: 'white' },
      },
      title: {
        display: true,
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        ticks: { color: 'white' },
      },
      y: {
        beginAtZero: true,
        ticks: {
          color: 'white',
          stepSize: 1,
          precision: 0,
        },
      },
    },
  }

  const getWeekRange = (week) => {
    const startOfWeek = moment().week(week).startOf('week').format('DD/MM/YYYY')
    const endOfWeek = moment().week(week).endOf('week').format('DD/MM/YYYY')
    return `${startOfWeek} - ${endOfWeek}`
  }

  const availableWeeks = Object.keys(weeklyData).map(week => (
    <option key={week} value={week}>
      Semana {week} ({getWeekRange(week)})
    </option>
  ))

  return (
    <>
      <div className="row mb-3">
        <div className="col-md-6 align-self-center">
          <strong className="text-apple d-block text-center text-md-start my-4 my-md-0">Registro de pedidos por semana</strong>
        </div>
        <div className="col-md-6">
          <select ref={selectRef} defaultValue={selectedWeek}>
            {availableWeeks}
          </select>
        </div>
      </div>
      <div className="graphResumeSell">
        <Line options={options} data={orderData} />
      </div>
    </>
  )
}

export default SellGraph
