import React from 'react'

const CardBenefits = ({icon, text}) => {
  return (
    <div className="cardBenefits">
      <img src={icon} alt="icon" className='img-fluid'/>
      <p className='m-0'>{text}</p>
    </div>
  )
}

export default CardBenefits