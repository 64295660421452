import { doc, getDoc, updateDoc } from 'firebase/firestore'
import { db } from '../credentials'


const sudo = async(key)=>{
  
  try {
    const docRef = doc(db, 'sudo', 'landing');
    
    // Obtén el documento actual
    const docSnap = await getDoc(docRef);
    
    if (docSnap.exists()) {
      // Obtén el valor actual de la clave y le suma 1
      const currentValue = docSnap.data()[key] || 0;
      const newValue = currentValue + 1;
      
      // Actualiza el documento con el nuevo valor de la clave
      await updateDoc(docRef, {
        [key]: newValue,
      });

    } else {
      console.error(`El documento no existe.`);
    }
  } catch (error) {
    console.error(`Error al incrementar ${key}: `, error);
  }

}

export default sudo

