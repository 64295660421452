import React from 'react'
import InProcess from './InProcess'
import Finished from './Finished'
import Delivered from './Delivered'
import { useSelector } from 'react-redux'

const TabKitchen = () => {

  const {filteredOne,filteredTwo,filteredThree} = useSelector(s => s.log)


  return (
    <>
      <section className="py-0 py-md-4">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-md-12 text-center px-0">
              <div className="px-0 px-md-3 py-3">
                <ul className="nav nav-tabs tabsOptionsDash tabsKitchen" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="categoryP-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#k1-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="k1-tab-pane"
                      aria-selected="true"
                    >
                      En preparación {`(${filteredOne.length})`}
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="productsP-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#k2-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="k2-tab-pane"
                      aria-selected="false"
                    >
                      Terminado {`(${filteredTwo.length})`}
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="catalogP-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#k3-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="k3-tab-pane"
                      aria-selected="false"
                    >
                      A entregar {`(${filteredThree.length})`}
                    </button>
                  </li>
                  
                </ul>
              </div>
            </div>
            <div className="col-12 px-0 px-md-3">
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="k1-tab-pane"
                  role="tabpanel"
                  aria-labelledby="categoryP-tab"
                  tabIndex="0"
                >
                  <InProcess data={filteredOne}/>
                </div>
                <div
                  className="tab-pane fade"
                  id="k2-tab-pane"
                  role="tabpanel"
                  aria-labelledby="productsP-tab"
                  tabIndex="0"
                >
                  <Finished data={filteredTwo}/>
                </div>
                <div
                  className="tab-pane fade"
                  id="k3-tab-pane"
                  role="tabpanel"
                  aria-labelledby="catalogP-tab"
                  tabIndex="0"
                >
                  <Delivered data={filteredThree}/>
                </div>
              
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default TabKitchen