import { mailCredits } from "../../firebase/credentials"



const creditsValidate = data =>{
  
  const {credits,email, name, plan } = data
  console.log(credits, email, name,plan)

  if(credits === 30 || credits === 25 || credits === 20
    || credits === 15 || credits === 10 || credits < 3
  ){
    mailCredits({
      credits: credits - plan,
      email,
      name
    })
  }

}

export default creditsValidate