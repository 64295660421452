import { doc, runTransaction } from "firebase/firestore";
import { db } from "../../../firebase/credentials";


const setCountCredit = async (userId, cuota) => {
    if (!userId) {
        console.error("Se requiere un userId válido.");
        return;
    }

    const userDocRef = doc(db, "admin", userId);

    try {
        await runTransaction(db, async (transaction) => {
            const userDoc = await transaction.get(userDocRef);

            // Verifica si el documento existe
            if (!userDoc.exists()) {
                throw new Error("El documento del usuario no existe.");
            }

            // Obtiene el valor actual de "b" y lo incrementa
            const currentB = userDoc.data().b || 0;  // Si "b" no existe, se considera 0
            transaction.update(userDocRef, { b: currentB + cuota });
        });

        console.log("El valor de 'b' se incrementó exitosamente.");
    } catch (error) {
        console.error("Error al incrementar el valor de 'b':", error);
    }
};

export default setCountCredit