import React from 'react'
import InfoGreen from '../InfoGreen'
import img from '../../../../img/vector/no_avalible.svg'

const NoAvalible = () => {
  return (
    <div className="row justify-content-center py-5">
      <div className="col-md-8 col-10">
        <div className="row justify-content-center">
          <div className="col-md-7 align-self-center">
            <h4 className="text-apple mb-4">No es posible visualizar el editor en este dispositivo </h4>
            <InfoGreen
              text='Para acceder al editor debes hacerlo desde un computador'
            />
          </div>
          <div className="col-md-5 col-9">
            <img src={img} alt="no-avalible" className='img-fluid'/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NoAvalible