import React from 'react'
import { useSelector } from 'react-redux'
import BounceLoader from "react-spinners/BounceLoader"
import DotLoader from "react-spinners/DotLoader"
import GridLoader from "react-spinners/GridLoader"
import HashLoader from "react-spinners/HashLoader"
import PacmanLoader from "react-spinners/PacmanLoader"
import PuffLoader from "react-spinners/PuffLoader"
import ScaleLoader from "react-spinners/ScaleLoader"
import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader"

const LoaderSelect = ({loaderdash}) => {

  const {colors, loader} = useSelector(state => state.menu)

  const themeLoaders = [
    {id: 0, c: <BounceLoader color={colors[1]} loading={true} size={40}/>},
    {id: 1, c: <DotLoader color={colors[1]} loading={true} size={40}/>},
    {id: 2, c: <GridLoader color={colors[1]} loading={true} size={12}/>},
    {id: 3, c: <HashLoader color={colors[1]} loading={true} size={40}/>},
    {id: 4, c: <PacmanLoader color={colors[1]} loading={true} size={15}/>},
    {id: 5, c: <PuffLoader color={colors[1]} loading={true} size={65}/>},
    {id: 6, c: <ScaleLoader color={colors[1]} loading={true} size={65}/>},
    {id: 7, c: <ClimbingBoxLoader color={colors[1]} loading={true} size={12}/>},
  ]

  
  return (
    <>
      <div className="bg-b1 p-3 rounded-lg">
        <div className="d-flex align-items-center justify-content-between">
          <p className="text-bold text-apple m-0">¿Qué tipo de loader quieres?</p>
          <span className='optionalLabel'>Opcional</span>
        </div>
        <div className="row mt-3">
          {
            themeLoaders.map((el,index) =>(
              <div className="col-6 col-md-3 mt-3" key={index}>
                <div className="loaderDash" style={{background: colors[2]|| 'white'}}>
                  <label htmlFor={`load-${el.id}`}>
                    <input
                      type="radio"
                      name='loaderdash'
                      data-loader={el.id}
                      checked={loader === el.id}
                      id={`load-${el.id}`}
                      {...loaderdash}
                    />
                    <div className="check" />
                    {el.c}
                  </label>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </>
  )
}

export default LoaderSelect
