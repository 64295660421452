import React from 'react'
import MainFreeTheme from '../../freetemplate/MainFreeTheme'
import EditCategory from './EditCategory'

const Mexican = () => {
  return (
    <MainFreeTheme
      editCategory={<EditCategory />}
    />
  )
}

export default Mexican