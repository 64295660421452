import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { cleaningFavorites, setProductSelected } from '../../../../redux/reducer/user'

const Favorite = () => {

  const {favoriteStorage, menu} = useSelector(state => state.user)
  const [available, setAvailable] = useState(false)
  const dispatch = useDispatch()

  useEffect(()=>{
    if(menu){
      if(menu?.p?.length === 0){
        setAvailable(false)
      }else{
        setAvailable(true)
        dispatch(cleaningFavorites())
      }
    }
  },[menu, dispatch])




  const selectProduct = id =>{
    let reference = menu?.p?.find(el => el?.c === id)
    if(reference){
      dispatch(setProductSelected(reference))
    }
  }

  return (
    <>
      <div className="content py-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-11">
              <div className="row justify-content-center">
                <div className="col-md-4">
                  <div className="d-flex align-items-center mb-4">
                    <svg id="Capa_1" fill={`${menu?.g?.[0]}`} className='icon-sm me-2' data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 225 225">
                      <path d="m114,198.53c-23.76-22.9-47.56-45.75-71.24-68.72-5.85-5.68-11.89-11.32-16.82-17.76-13.53-17.69-15.79-37.17-6.01-57.2,9.65-19.76,26.3-29.67,48.15-29.85,13.44-.11,25.38,4.89,35.18,14.29,3.1,2.98,5.79,6.39,9.36,10.39,3.79-4.18,6.52-7.82,9.87-10.78,15.51-13.71,33.4-17.73,52.9-10.86,19.47,6.86,31.09,21.19,34.75,41.5,3.39,18.77-2.5,34.83-15.71,48.27-26.29,26.74-52.67,53.39-79.01,80.07-.81.83-1.68,1.6-2.53,2.39.37-.58.74-1.15,1.11-1.73Z"/>
                    </svg>
                    <h2 className='mb-0'>TUS FAVORITOS</h2>
                  </div>
                </div>
                <div className="col-md-7 mb-4  align-self-center">
                  {
                    menu?.p?.length === 0 ? (
                      <>
                        <p>En este espacio puedes crear tu lista exclusiva de platillos predilectos. Cuando encuentres un producto que simplemente te encanta, agrégalo a tus favoritos para acceder fácilmente a él en el futuro. <strong className='text-c2'>Tu lista de favoritos solo estará guardado en este dispositivo.</strong></p>
                      </>
                    ):
                    (
                      <>
                        {
                          favoriteStorage?.length>0 ? (
                            <>
                              <p><strong>Aún no tienes favoritos para mostrar.</strong></p>
                            </>
                          ):
                          (
                            <>
                              <p><strong> y... ¿qué quieres probar hoy?</strong></p>
                              <p><small className='d-block '><em>Para eliminar de tu sección de favoritos solamente debes abrir cada producto y desmarcar el corazón :)</em></small></p>
                            </>
                          )
                        }
                      </>
                    )
                  }
                </div>
                {
                  available && (
                    <>
                      <div className="col-md-11 col-12 mt-4">
                        <div className="row contentFavorite">
                          {
                            favoriteStorage?.length>0 ? (
                              favoriteStorage?.map((el, index) => {
                                let product = menu?.p?.filter(item => item?.c === el)
                                if(product[0]?.a){
                                  return (
                                    <div className='col-md-4 col-6 mb-3' key={index}>
                                      <button
                                        type='button'
                                        className='mainCards'
                                        onClick={() => selectProduct(product[0]?.c)}
                                        data-bs-toggle="modal"
                                        data-bs-target="#showMProduct"
                                      >
                                        <div className="label">{ product[0]?.g || 'NA'}</div>
                                        <div className="cc">
                                          <img src={product[0]?.d} alt={product[0]?.h} className='img-fluid'/>
                                          <div className="overlay">
                                            <h6 className='m-0 text-truncate'>{product[0]?.e}</h6>
                                            <div className="plus" />
                                          </div>
                                        </div>
                                      </button>
                                    </div>
                                  )
                                }
                                return null
                              })
                            ):
                            (
                              <p className="text-center"><span>No tienes favoritos seleccionados <span className="text-c1">:(</span></span></p>
                            )
                          }
                        </div>
                      </div>
                    </>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Favorite