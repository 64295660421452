import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateIssue } from '../../../redux/reducer/sudo'

const ErrorsSection = () => {

  const {listErrors} = useSelector(s => s.sudo)
  const dispatch = useDispatch()

  const handleIssue = data => dispatch(updateIssue(data))

  return (
    <section className="p-4">
      <h5 className="text-center text-apple mb-4">Panel de reportes</h5>
      <div className="row justify-content-center">
        {
          listErrors?.length === 0 ? (
            <p className='text-center text-white py-5'>No hay ningún reporte</p>
          ):
          listErrors?.map((el) =>{
            if(!el?.solve) {
              return (
                <div className="col-md-9 mb-3" key={el?.collection}>
                  <div className="bg-b1 rounded-lg p-4 text-white">
                    <h6 className='text-center mb-3'>Descripción de reporte</h6>
                    <hr />
                    <div className="row">
                      <div className="col-md-9 align-self-center">
                        <p className='m-0'>Tipo de reporte: <span className="text-apple">{el?.type}</span></p>
                        <p className='m-0'>Descripción: <span className="text-apple">{el?.message}</span></p>
                        <p className='m-0'>Celular del usuario: <span className="text-apple">+{`(${el?.country})`} {el?.tel}</span></p>
                        <p className='m-0'>Correo electrónico: <span className="text-apple">{el?.email}</span></p>
                      </div>
                      <div className="col-md-3 align-self-center">
                        <div className="bg-b2 rounded p-3 mt-3 mt-md-0">
                          <a
                            href={`https://api.whatsapp.com/send?phone=${el?.country}${el?.tel}`}
                            className='btn-line-apple d-flex justify-content-center align-items-center'
                            target='_blank'
                            rel="noopener noreferrer"
                          >WhatsApp</a>
                          <button
                            className='btn-line-apple mt-2'
                            data-bs-toggle="modal"
                            data-bs-target="#ModalSolveIssue"
                            onClick={()=>handleIssue(el)}
                          >Resuelto</button>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="d-flex justify-content-between align-items-center flex-column flex-md-row">
                      <div className="optionalLabel mb-2">Usuario: {el?.id}</div>
                      <div className="requiredLabel mb-2">Fecha: {el?.date}</div>
                    </div>
                  </div>
                </div>
              )
            }
          })
        }
      </div>
    </section>
  )
}

export default ErrorsSection
