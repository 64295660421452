import React from 'react'
import InfoGreen from '../../InfoGreen'
import { useDispatch, useSelector } from 'react-redux'
import { setActiveMenu } from '../../../../../redux/reducer/menu'

const AvalibleMenu = ({register}) => {

  const {activeMenu} = useSelector(s => s.menu)
  const dispatch = useDispatch()


  return (
    <div className="col-md-12">
      <div className="bg-b1 rounded-lg p-4 text-white my-4">
        <div className="row">
          <div className="col-md-5 align-self-center">
            <div className='d-flex align-items-center'>
              {/* <div className="form-check form-switch justify-content-center">
                <input
                  className="form-check-input me-3"
                  type="checkbox"
                  role="switch"
                  checked={activeMenu}
                  {...register('disabledAccount',{
                    onChange: (e) => dispatch(setActiveMenu(e.target.checked))
                  })}
                />
              </div> */}
              <div className="toggle-border mb-0 me-3">
                <input
                  type="checkbox"
                  role="switch"
                  id='switchAvaliableMenu'
                  checked={activeMenu}
                  {...register('disabledAccount',{
                    onChange: (e) => dispatch(setActiveMenu(e.target.checked))
                  })}
                />
                <label htmlFor="switchAvaliableMenu">
                  <div className="handle"></div>
                </label>
              </div>
              <span className='text-white'>Menú digital activo</span>
            </div>
          </div>
          <div className="col-md-7 align-self-center mt-3 mt-md-0">
            <InfoGreen
              text='¡Está muy bien tomarse unas vacaciones!. Deshabilitar tu menú digital evitará órdenes inesperadas en tu tiempo de descanso.'
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AvalibleMenu
