import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setStateNotification, setTextNotificate } from '../../../redux/reducer/interactions'

const Notification = () => {

  const {textNotification, isNotificate, isError} = useSelector(state => state.ux)
  const dispatch = useDispatch()
  const handleClose = ()=> dispatch(setStateNotification(false))
  

  const handleTimmer = ()=>{
    setTimeout(()=>{
      dispatch(setStateNotification(false))
      dispatch(setTextNotificate(''))
    },4500)
  }

  useEffect(()=>{
    isNotificate && handleTimmer()
  },[isNotificate])

  return (
    <>
      <div className={`contentNotification ${isNotificate ? 'active': ''} ${isError ? 'error': ''} `}>
        <p>{textNotification}</p>
        <button onClick={handleClose}/>
      </div>
    </>
  )
}

export default Notification