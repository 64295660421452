import React from 'react'
import { useSelector } from 'react-redux';
import { convertAmount } from '../../../../tools/format';


const Post24 = () => {

  const {dataPost, imgPostLogo, imgProduct} = useSelector(s => s.post)
  

  return (
    <div className="poster">
      <div className="img" style={{width: '44%', height: '97%', top: '1.7%', left: '1.6%'}}>
        {
          imgProduct && (
            <img
              src={imgProduct}
              className='b7'
              alt="img" />
          )
        }
      </div>
      <div className="svg">
       

        <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">
          <path fill={`${dataPost.colors[0]}`}  d="m0,0v1024h1024V0H0Zm461,1004H19V20h442v984Z"/>
          <rect fill={`${dataPost.colors[1]}`}  x="461" y="20" width="543" height="984"/>
        </svg>
      </div>

      <img
        src={imgPostLogo}
        alt="logo" className="logo text tr"
        style={{right: '5%', top: '5%'}}
      />

      {
        dataPost.text[0] && (
          <div
            className="text tr py-1 px-2 f-1 clamp-2"
            style={{color: dataPost.colors[0], lineHeight: '35px', width: ' 50%',
            fontSize: '35px', right: '20px', top:'17%'
          }}
          >{dataPost.text[0]}</div>
        )
      }
      {
        dataPost.text[1] && (
          <div
            className="w-content text px-3 f-9 cr"
            style={{color: dataPost.colors[1], fontSize:'25px',
              background: `${dataPost.colors[0]}`,
            }}
          >{convertAmount(dataPost.text[1])}</div>
        )
      }
      {
        dataPost.text[2] && (
          <div
            className="f-3 text cr text-truncate text-center px-2 text-uppercase"
            style={{color: dataPost.colors[0], fontSize: '11px',
              width: '28%', right: '2%', marginTop: '-14%'
            }}
          >{dataPost.text[2]}</div>
        )
      }
      {
        dataPost.text[3] && (
          <div
            className="text br py-1 px-2 f-3 clamp-3 text-uppercase text-end"
            style={{color: dataPost.colors[0], fontSize: '12px',
              right: '4%', width: '36%', bottom: '18%'
          }}
          >{dataPost.text[3]}</div>
        )
      }
    
    </div>
  );
};

export default Post24;
