import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {  setIDClientToDelete, setIsError, setStateLoading, setTextNotificate } from '../../../../redux/reducer/interactions'
import updateOnlyOnePiece from '../../../../firebase/update/updateOnlyOnePiece'



const ModalDeleteClient = () => {

  const {userID, customerList} = useSelector(s => s.log)
  const {idClientToDelete} = useSelector(s => s.ux)
  const dispatch = useDispatch()
  


  const handleReset = ()=> dispatch(setIDClientToDelete({}))
  
  
  const deleteOneClient = async()=>{

    dispatch(setStateLoading(true))
    
    let copy = JSON.parse(JSON.stringify(customerList))
    copy.splice(idClientToDelete.index, 1)
    const response = await updateOnlyOnePiece(userID, process.env.REACT_APP_AGENDA,'n', copy)    
    if(response){
      dispatch(setTextNotificate(`Tu cliente "${idClientToDelete.name}" ha sido eliminado de la agenda`))
      dispatch(setIsError(false)) 
    }else{
      dispatch(setTextNotificate(`No ha sido posible eliminar a "${idClientToDelete.name}", ¡ inténtalo mas tarde ! `))
      dispatch(setIsError(true)) 
    }
    dispatch(setStateLoading(false))
    dispatch(setIDClientToDelete({}))
  }


  return (
    <div className="modal modalDashboard fade" data-bs-backdrop="static" id="deleteOneClient" tabIndex="-1" aria-labelledby="deleteInOrderLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body ">
            <div className="p-3 p-md-5 text-center text-white">
              <h4 className='text-apple mb-5'>Confirmar la eliminación de {`"${idClientToDelete.name}"`}</h4>
              <div className="row justify-content-center">
                <div className="col-12">
                  
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-md-6">
                  <button
                    className='btn-line-apple w-100 m-0'
                    data-bs-dismiss="modal"
                    onClick={handleReset}
                  >No, cerrar</button>
                </div>
                <div className="col-md-6 order-first order-md-last mb-4 mb-md-0">
                  <button
                    className='btn-orange w-100 m-0'
                    data-bs-dismiss="modal"
                    onClick={deleteOneClient}
                  >Sí, borrar cliente</button>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default ModalDeleteClient