import React from 'react'
import MainFreeTheme from '../../freetemplate/MainFreeTheme'
import BgMenu from './BgMenu'
import EditCategory from './EditCategory'

const PizzaI = () => {
  return (
    <MainFreeTheme
      bgMenu={<BgMenu />}
      editCategory={<EditCategory />}
      twoColumns={true}
    />
  )
}

export default PizzaI