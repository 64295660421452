import React from 'react'
import { useSelector } from 'react-redux'

const LocationMenu = () => {

  const {admin} = useSelector(state => state.user)

  return (
    <>
      <div className="content">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 text-center">
              <p className="subtitle display-3 mb-4 text-c4 mt-5 pt-5 pt-md-0 mt-md-0">NUESTRA UBICACIÓN</p>
            </div>
            <div className="col-md-12 col-11">
              {
                (admin?.l?.[0]?.length > 0 && admin?.l?.[1]?.length > 0 &&  admin?.l?.[2]?.length > 0 &&  admin?.l?.[3]?.length > 0 && admin?.l?.[4]?.length > 0) ? (
                  <>
                    <div>
                      <div>
                        <iframe
                          className="iframeMenu"
                          width="100%"
                          frameBorder="0"
                          scrolling="no"
                          marginHeight="0"
                          title='Mapa'
                          marginWidth="0"
                          src={`https://maps.google.com/maps?width=600&height=600&hl=en&q= ${admin?.l?.[0]} ${admin?.l?.[1]} ${admin?.l?.[2]} - ${admin?.l?.[3]} ${admin?.l?.[4]} &t=&z=15&ie=UTF8&iwloc=B&output=embed`} 
                        />
                      </div>
                    </div>
                  </>
                ):
                (
                  <>
                    <div className="mapPrev">No hay un mapa definido por el administrador.</div>                  
                  </>
                )
              }

            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LocationMenu